import React, { useEffect, useState } from "react";
import TableComponent from "../../layoutComponents/TableComponent";
import {
  CardDetail,
  CssDialog,
  CssTextField95,
} from "../../styles/StyledComponents";
import { green, primary, red } from "../../styles/colors";
import { filterData, StopScroll } from "../../Regex";
import {
  CssTextField,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import { MenuItem, Tooltip } from "@material-ui/core";
import moment from "moment";
import SailingOutlinedIcon from "@mui/icons-material/SailingOutlined";
import { divide, multiply, subtract, sum } from "lodash";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import { CleaningServices } from "@mui/icons-material";
function Inventory(props) {
  const {
    InvList,
    historyList,
    filterChild,
    BrandList,
    ItemCateoryList,
    ItemList,
    masters,
    PortsFunction,
    loadingDispatch,
    loadingGodown,
    onlyMoveToShipButton,
    selectGodown,
    GodownList,
    updateAgain,
    singlelistView,
  } = props;
  const [loading, setLoadingDispatch] = useState(false);
  useEffect(() => {
   
    setLoadingDispatch(loadingDispatch);
  }, [loadingDispatch]);
  useEffect(() => {

    setLoadingDispatch(loadingDispatch);
  }, []);
  const [customInvoices, setCustomInvoices] = useState([]);
  const [commercialList, setCommercialList] = useState([]);
  const [salesorderList, setsalesorderList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogNew, setOpenDialogNew] = useState(false);
  const [selctedSo, setSelctedSo] = useState({ id: "", value: "" });
  const [selctedCIUnit, setselctedCIUnit] = useState({ unit: "", value: "" });
  const [selctedCi, setSelctedCi] = useState({ id: "", value: "" });

  const [selctedCom, setSelctedCom] = useState({ id: "", value: "" });
  const [values, setValues] = useState([]);
  const [errorMsg, setErrorMsg] = useState({});
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    setErrorMsg(errorMsg);
  }, [errorMsg]);
  useEffect(() => {
    if (onlyMoveToShipButton === true) {
    }
  }, [onlyMoveToShipButton]);
  const InvLists =
    InvList && InvList.length > 0
      ? InvList.map((singleInv) => {
          const brandSelected = filterData(
            BrandList,
            "id",
            singleInv.brand,
            "onlyOne"
          )
            ? filterData(BrandList, "id", singleInv.brand, "onlyOne")
            : "";

          return {
            ...singleInv,
            Brand: brandSelected.brand_name,
            "Item Category": filterData(
              ItemCateoryList,
              "id",
              brandSelected.item_category,
              "onlyOne"
            )
              ? filterData(
                  ItemCateoryList,
                  "id",
                  brandSelected.item_category,
                  "onlyOne"
                ).name
              : "",
            "Item Type": filterData(
              ItemList,
              "id",
              brandSelected.item_type,
              "onlyOne"
            )
              ? filterData(ItemList, "id", brandSelected.item_type, "onlyOne")
                  .broken_percentage
              : "",
            "Total Bags":
              singleInv.bags && singleInv.bags.length > 0
                ? singleInv.bags
                    .map((sP) => {
                      return sP.remaining_bags ? sP.remaining_bags : 0;
                    })
                    .reduce(
                      (previousValue, currentValue) =>
                        Number(previousValue) + Number(currentValue),
                      0
                    )
                : 0,
            bags:
              singleInv.bags && singleInv.bags.length > 0
                ? singleInv.bags
                    .filter(
                      (s) => (s.remaining_bags ? s.remaining_bags : 0) !== 0
                    )
                    .map((sP) => {
                      return {
                        ...sP,
                        "Total Bags": `${
                          sP.remaining_bags ? sP.remaining_bags : 0
                        }`,
                        "Bag Type": sP.bag_type,
                        Size: `${sP.size} ${sP.unit}`,
                        "Total Quantity": multiply(
                          sP.remaining_bags ? sP.remaining_bags : 0,
                          sP.size ? Number(sP.size) : 0
                        ),
                        "Total QuantityUnit": `${multiply(
                          sP.remaining_bags ? sP.remaining_bags : 0,
                          sP.size ? Number(sP.size) : 0
                        )} ${sP.unit}`,
                      };
                    })
                : [],
          };
        })
      : [];
  const [selectedBrandsArr, setSelectedBrandsArr] = useState([]);
  const [submittedData, setSubmittedData] = useState({});
  const moveToShipHeader = ["Size", "Bag Type", "Total Bags", "Total Quantity"];
  const moveToShipHeader2 = [
    "Size",
    "Bag Type",
    "Total Bags",
    "Total Quantity",
    "Shipped Bags",
    "Shipped Quantity",
  ];
  const [error, setError] = useState(false);

  const [loader, setLoad] = useState(false);

  const clearFields = () => {
    setSelctedSo({
      value: "",
      id: "",
    });
    setselctedCIUnit({ unit: "", value: "" });
    setSelctedCi({
      value: "",
      id: "",
    });
    setErrorMsg({});
  };
  const changeUnit = (from, to, weigths, opposite) => {
    //default in kg

    if (opposite) {
      if (from === "MT") {
        return changeTo(to, divide(weigths, 1000), opposite);
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, divide(weigths, 100), opposite);
      }
    } else {
      if (from === "MT") {
        return changeTo(to, multiply(weigths, 1000));
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, multiply(weigths, 100));
      }
    }
  };

  const changeTo = (to, weigths, opposite) => {
    if (opposite) {
      if (to === "MT") {
        return multiply(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return multiply(weigths, 100);
      }
    } else {
      if (to === "MT") {
        return divide(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return divide(weigths, 100);
      }
    }
  };
  const net_value = (used, net) => {
    return subtract(Number(net ? net : 0), Number(used ? used : 0));
  };
  return loading && loading === true ? (
    <div className="alignC justiyC d-flex width-100 height-100">
      <StyledCircularProgress color="inherit" />
    </div>
  ) : loadingGodown && loadingGodown === true ? (
    <div className="alignC justiyC d-flex width-100 height-100">
      <StyledCircularProgress color="inherit" />
    </div>
  ) : (
    <div
      className={`${
        onlyMoveToShipButton ? "" : "width-100 height-100 d-flex d-flex-column"
      }`}
    >
      {error ? (
        <SnackbarComponent
          error={error}
          autoHideDuration={5000}
          // success={success}
          snackbarClose={() => {
            setError(false);
          }}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 10px",
          width: "calc(100% - 20px)",
        }}
      >
        <div
          className="widthFC fontWeight400 alignC d-flex"
          style={{ fontSize: "23px" }}
        >
          All Brands
        </div>
        {onlyMoveToShipButton ? "" : filterChild("Inventory")}
      </div>
      {openDialogNew === true ? (
        <CssDialog
          onSubmit={() => {
            if (selctedSo && selctedSo.id) {
              if (selctedCi && selctedCi.id) {
                if (selectedBrandsArr && selectedBrandsArr.length > 0) {
                  if (
                    submittedData &&
                    Object.values(submittedData) &&
                    Object.values(submittedData).length > 0 &&
                    Object.values(submittedData).reduce(
                      (previousValue, currentValue) =>
                        Number(previousValue) +
                        Number(currentValue ? currentValue : 0),
                      0
                    ) > 0
                  ) {
                    setLoad(true);
                    const newArr = [];
                    const id = [];
                    const checkCustomInvoice = (cI, packaging, noBags) => {
            
                      const array = [];
                      const requires = [];
                      cI.custom_invoice_item_details.map((item) => {
                        if (
                          item.pkg_counter[packaging.id] &&
                          (Number(item.pkg_counter[packaging.id]) >
                            Number(noBags) ||
                            Number(item.pkg_counter[packaging.id]) ===
                              Number(noBags))
                        ) {
                          array.push("succes");
                        }
                        requires.push(item.pkg_counter);
                        return item;
                      });
                      if (array.length > 0) {
                        return "success";
                      } else {
                        return {
                          packagings: packaging,
                          bags: noBags,
                          ciPackagings: requires,
                        };
                      }
                    };
                    const selectedCiDetail = filterData(
                      customInvoices,
                      "id",
                      Number(selctedCi.id),
                      "onlyone"
                    )
                      ? filterData(
                          customInvoices,
                          "id",
                          Number(selctedCi.id),
                          "onlyone"
                        )
                      : "";
                    const all_nums = [];
                    const errors = [];
                    selectedBrandsArr.map((single) => {
                      const sData = filterData(
                        values.bags,
                        "id",
                        Number(single),
                        "onlyOne"
                      )
                        ? filterData(
                            values.bags,
                            "id",
                            Number(single),
                            "onlyOne"
                          )
                        : "";


                      if (sData) {
                        if (
                          !id.includes(single) &&
                          checkCustomInvoice(
                            selectedCiDetail,
                            sData,
                            submittedData[`${single}Shipped`]
                              ? Number(submittedData[`${single}Shipped`])
                              : ""
                          ) === "success"
                        ) {
                          all_nums.push(
                            changeUnit(
                              sData.unit,
                              selctedCIUnit &&
                                selctedCIUnit.unit &&
                                selctedCIUnit.unit,
                              multiply(
                                submittedData[`${single}Shipped`]
                                  ? Number(submittedData[`${single}Shipped`])
                                  : "",
                                sData.size ? Number(sData.size) : 0
                              )
                                ? multiply(
                                    submittedData[`${single}Shipped`]
                                      ? Number(
                                          submittedData[`${single}Shipped`]
                                        )
                                      : "",
                                    sData.size ? Number(sData.size) : 0
                                  )
                                : 0
                            )
                          );
                          newArr.push({
                            is_active: true,
                            custom_invoice: selctedCi.id,
                            commercial_invoice: selctedCom.id,
                            bl_number: selectedCiDetail.bl_number
                              ? selectedCiDetail.bl_number
                              : "",
                            // packaging: single,
                            // bag_number: submittedData[`${single}Shipped`]
                            //   ? Number(submittedData[`${single}Shipped`])
                            //   : "",
                            packaging_count: {
                              [single]: submittedData[`${single}Shipped`]
                                ? Number(submittedData[`${single}Shipped`])
                                : "",
                            },
                            coming_from: "VIA GODOWN",
                            // used_weight: multiply(
                            //   submittedData[`${single}Shipped`] ? Number(submittedData[`${single}Shipped`]) : "",
                            //   sData.size ? Number(sData.size) : 0
                            // )
                            //   ? multiply(
                            //       submittedData[`${single}Shipped`] ? Number(submittedData[`${single}Shipped`]) : "",
                            //       sData.size ? Number(sData.size) : 0
                            //     )
                            //   : 0,
                            used_weight: {
                              [single]: changeUnit(
                                sData.unit,
                                selctedCIUnit &&
                                  selctedCIUnit.unit &&
                                  selctedCIUnit.unit,
                                multiply(
                                  submittedData[`${single}Shipped`]
                                    ? Number(submittedData[`${single}Shipped`])
                                    : "",
                                  sData.size ? Number(sData.size) : 0
                                )
                                  ? multiply(
                                      submittedData[`${single}Shipped`]
                                        ? Number(
                                            submittedData[`${single}Shipped`]
                                          )
                                        : "",
                                      sData.size ? Number(sData.size) : 0
                                    )
                                  : 0
                              ),
                            },
                            brand: values.brand,
                            godown: selectGodown ? selectGodown : "",
                            port: singlelistView.id,
                          });
                       
                          id.push(single);
                        } else {
                          errors.push(
                            checkCustomInvoice(
                              selectedCiDetail,
                              sData,
                              submittedData[`${single}Shipped`]
                                ? Number(submittedData[`${single}Shipped`])
                                : ""
                            )
                          );
                        }
                      }

                      return single;
                    });
                
                    if (
                      errors.length === 0 &&
                      all_nums.length > 0 &&
                      selctedCIUnit &&
                      selctedCIUnit.value &&
                      all_nums.reduce(
                        (previousValue, currentValue) =>
                          Number(previousValue) + Number(currentValue),
                        0
                      ) <= selctedCIUnit.value
                    ) {
                      if (newArr.length === selectedBrandsArr.length) {
                        props
                          .PortsFunction(
                            "post",
                            "arrival/commercial-invoice/packing-list/bulk-create",
                            null,
                            "post",
                            newArr
                          )
                          .then((res2) => {
                            if (res2.error) {
                              setLoad(false);
                            } else {
                              if (selctedCi) {
                                if (updateAgain) {
                                  updateAgain();
                                }
                                setOpenDialogNew(false);
                                setSubmittedData({});
                                clearFields();
                                setSuccess("Bags are shipped!");
                              }
                            }
                          });
                      }
                    } else {
                      setLoad(false);
                 
                      setError(
                        errors.length > 0
                          ? `${errors.map((s) => {
                              return `${s.bags} bags of ${s.packagings.size}${s.packagings.unit}`;
                            })} is not available in this custom invoice!`
                          : `Only ${selctedCIUnit.value} ${
                              selctedCIUnit &&
                              selctedCIUnit.unit &&
                              selctedCIUnit.unit
                            } is available in selected Custom Invoice!`
                      );
                    }
                  } else {
                    setErrorMsg((prev) => {
                      return { ...prev, addBags: true };
                    });
                  }
                } else {
                  setErrorMsg((prev) => {
                    return {
                      ...prev,
                      selectedBrandsArr: "Please select brands!",
                    };
                  });
                }
              } else {
                setErrorMsg((prev) => {
                  return {
                    ...prev,
                    selctedCi: "Please select custom invoice!",
                  };
                });
              }
            } else {
              // const errorMsgs = errorMsg;
              // errorMsgs.selctedSo = "Please select sales order!";
              // console.log(errorMsgs, "errorMsgs");
              setErrorMsg((prev) => {
                return { ...prev, selctedSo: "Please select sales order!" };
              });
            }
          }}
          reset={() => {
            setSubmittedData({}); //state bags
            setSelectedBrandsArr([]); //check
            clearFields();
          }}
          headerPrimary={true}
          height="calc(100% - 120px)"
          width="1200px"
          maxWidth="1200px"
          header={`Move to shipping`}
          addButtonWidth="170px"
          // noActions={true}
          addButton={"Move to shipping"}
          onClose={() => {
            setOpenDialogNew(false);
            setSubmittedData({});
            clearFields();
          }}
          snackbarClose={() => {
            setOpenDialogNew(false);
            setSubmittedData({});
            clearFields();
          }}
          children={() => {
            return (
              <>
                {error || success !== false ? (
                  <SnackbarComponent
                    autoHideDuration={error ? 5000 : 3000}
                    error={error}
                    success={success}
                    snackbarClose={() => {
                      setError(false);
                      if (success !== false) {
                        setLoad(false);
                        setOpenDialogNew(false);
                        setSubmittedData({});
                        clearFields();
                      }
                      setSuccess(false);
                    }}
                  />
                ) : (
                  ""
                )}
                {loader && loader === true ? (
                  <div className="alignC justiyC d-flex width-100 height-100">
                    <StyledCircularProgress color="inherit" />
                  </div>
                ) : (
                  <div className="width-100 height-100 d-flex d-flex-column">
                    <div className="width-100 d-flex flexWrap padding15 pNone">
                      <CssTextField
                        disabled={true}
                        autoComplete="off"
                        style={{
                          width: "calc(33% - 13px)",
                          marginRight: "20px",
                        }}
                        id="outlined-basic"
                        label={`Brand`}
                        variant="outlined"
                        name="singleFileName"
                        value={values["Brand"] ? values["Brand"] : ""}
                      />
                      <CssTextField
                        disabled={true}
                        autoComplete="off"
                        style={{
                          width: "calc(33% - 13px)",
                          marginRight: "20px",
                        }}
                        id="outlined-basic"
                        label={"Item Category"}
                        variant="outlined"
                        name="singleFileName"
                        value={
                          values["Item Category"] ? values["Item Category"] : ""
                        }
                      />
                      <CssTextField
                        disabled={true}
                        autoComplete="off"
                        style={{
                          width: "calc(33% - 13px)",
                          // marginRight: "20px",
                        }}
                        id="outlined-basic"
                        label={"Item Type"}
                        InputProps={{
                          endAdornment: "%",
                        }}
                        variant="outlined"
                        name="singleFileName"
                        value={values["Item Type"] ? values["Item Type"] : ""}
                      />
                    </div>
                    <div className="width-100 d-flex padding15 flexWrap">
                      {errorMsg.selctedSo && errorMsg.selctedSo !== false ? (
                        <CssTextField
                          required={true}
                          autoComplete="off"
                          value={
                            selctedSo
                              ? selctedSo.value
                                ? selctedSo.value
                                : ""
                              : "" //
                          }
                          error={true}
                          helperText={errorMsg.selctedSo}
                          onChange={(e) => {
                            const errorMsgs = errorMsg;
                            errorMsgs.selctedSo = false;
                            if (e.target.value) {
                              setLoad(true);
                              PortsFunction(
                                "get",
                                "arrival/commercial-invoice/list",
                                null,
                                "PortCIs",
                                null,
                                {
                                  sales_order_id: e.currentTarget.id,
                                  // shipment_status: "EXPENSES CALCULATED",
                                  // is_active: "True",
                                  // port_id: singlelistView && singlelistView.id && singlelistView.id,
                                  status: "DRAFT,OPEN",
                                }
                              ).then((commercialList) => {
                                if (commercialList) {
                                  setCommercialList(commercialList);
                                } else {
                                  setCommercialList([]);
                                }
                                setLoad(false);
                              });
                            }

                            setSelctedSo({
                              value: e.target.value,
                              id: e.currentTarget.id,
                            });

                            setErrorMsg(errorMsgs);
                          }}
                          style={{
                            width: "calc(33% - 13px)",
                            marginRight: "20px",
                          }}
                          id="outlined-select-currency-native"
                          select
                          label="Sales Order"
                          variant="outlined"
                          InputLabelProps={{}}
                        >
                          {salesorderList && salesorderList.length > 0 ? (
                            salesorderList
                              .sort((a, b) => b.id - a.id)
                              .map((option) => (
                                <MenuItem
                                  key={option.so_number}
                                  value={option.so_number}
                                  id={option.id}
                                >
                                  {option.so_number}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem
                              disabled={true}
                              key={"No data!"}
                              value={"No data!"}
                              id={"No data!"}
                            >
                              No data!
                            </MenuItem>
                          )}
                        </CssTextField>
                      ) : (
                        <CssTextField
                          required={true}
                          autoComplete="off"
                          value={
                            selctedSo
                              ? selctedSo.value
                                ? selctedSo.value
                                : ""
                              : "" //
                          }
                          error={errorMsg.selctedSo ? true : false}
                          helperText={
                            errorMsg.selctedSo ? errorMsg.selctedSo : ""
                          }
                          onChange={(e) => {
                            const errorMsgs = errorMsg;
                            errorMsgs.selctedSo = false;
                            if (e.target.value) {
                              setLoad(true);
                              PortsFunction(
                                "get",
                                "arrival/commercial-invoice/list",
                                null,
                                "PortCIs",
                                null,
                                {
                                  sales_order_id: e.currentTarget.id,
                                  // shipment_status: "EXPENSES CALCULATED",
                                  // is_active: "True",
                                  // port_id: singlelistView && singlelistView.id && singlelistView.id,
                                  status: "DRAFT,OPEN",
                                }
                              ).then((commercialList) => {
                                if (commercialList) {
                                  setCommercialList(commercialList);
                                } else {
                                  setCommercialList([]);
                                }
                                setLoad(false);
                              });
                            }

                            setSelctedSo({
                              value: e.target.value,
                              id: e.currentTarget.id,
                            });

                            setErrorMsg(errorMsgs);
                          }}
                          style={{
                            width: "calc(33% - 13px)",
                            marginRight: "20px",
                          }}
                          id="outlined-select-currency-native"
                          select
                          label="Sales Order"
                          variant="outlined"
                          InputLabelProps={{}}
                        >
                          {salesorderList && salesorderList.length > 0 ? (
                            salesorderList
                              .sort((a, b) => b.id - a.id)
                              .map((option) => (
                                <MenuItem
                                  key={option.so_number}
                                  value={option.so_number}
                                  id={option.id}
                                >
                                  {option.so_number}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem
                              disabled={true}
                              key={"No data!"}
                              value={"No data!"}
                              id={"No data!"}
                            >
                              No data!
                            </MenuItem>
                          )}
                        </CssTextField>
                      )}
                      {errorMsg.selctedCom && errorMsg.selctedCom !== false ? (
                        <CssTextField
                          required={true}
                          disabled={
                            selctedSo ? (selctedSo.value ? false : true) : true
                          }
                          autoComplete="off"
                          value={
                            selctedCom
                              ? selctedCom.value
                                ? selctedCom.value
                                : ""
                              : "" //
                          }
                          error={true}
                          helperText={errorMsg.selctedCom}
                          onChange={(e) => {
                            const errorMsgs = errorMsg;
                            errorMsgs.selctedCom = false;

                            if (e.target.value) {
                              setLoad(true);
                              PortsFunction(
                                "get",
                                "arrival/custom-invoice/list",
                                null,
                                "PortCIs",
                                null,
                                {
                                  commercial_invoice_id: e.currentTarget.id,
                                }
                              ).then((customInvoices) => {
                                if (customInvoices) {
                                  setCustomInvoices(customInvoices);
                                } else {
                                  setCustomInvoices([]);
                                }
                                setLoad(false);
                              });
                            }
                            setSelctedCom({
                              value: e.target.value,
                              id: e.currentTarget.id,
                            });

                            setErrorMsg(errorMsgs);
                          }}
                          style={{
                            width: "calc(33% - 13px)",
                            marginRight: "20px",
                          }}
                          id="outlined-select-currency-native"
                          select
                          label="Commercial Invoice"
                          variant="outlined"
                          InputLabelProps={{}}
                        >
                          {commercialList && commercialList.length > 0 ? (
                            commercialList
                              .sort((a, b) => b.id - a.id)
                              .map((option) => (
                                <MenuItem
                                  key={option.invoice_number}
                                  value={option.invoice_number}
                                  id={option.id}
                                >
                                  {option.invoice_number}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem
                              disabled={true}
                              key={"No data!"}
                              value={"No data!"}
                              id={"No data!"}
                            >
                              No data!
                            </MenuItem>
                          )}
                        </CssTextField>
                      ) : (
                        <CssTextField
                          required={true}
                          disabled={
                            selctedSo ? (selctedSo.value ? false : true) : true
                          }
                          autoComplete="off"
                          value={
                            selctedCom
                              ? selctedCom.value
                                ? selctedCom.value
                                : ""
                              : "" //
                          }
                          error={errorMsg.selctedCom ? true : false}
                          helperText={
                            errorMsg.selctedCom ? errorMsg.selctedCom : ""
                          }
                          onChange={(e) => {
                            const errorMsgs = errorMsg;
                            errorMsgs.selctedCom = false;

                            if (e.target.value) {
                              setLoad(true);
                              PortsFunction(
                                "get",
                                "arrival/custom-invoice/list",
                                null,
                                "PortCIs",
                                null,
                                {
                                  commercial_invoice_id: e.currentTarget.id,
                                }
                              ).then((customInvoices) => {
                                if (customInvoices) {
                                  setCustomInvoices(customInvoices);
                                } else {
                                  setCustomInvoices([]);
                                }
                                setLoad(false);
                              });
                            }
                            setSelctedCom({
                              value: e.target.value,
                              id: e.currentTarget.id,
                            });

                            setErrorMsg(errorMsgs);
                          }}
                          style={{
                            width: "calc(33% - 13px)",
                            marginRight: "20px",
                          }}
                          id="outlined-select-currency-native"
                          select
                          label="Commercial Invoice"
                          variant="outlined"
                          InputLabelProps={{}}
                        >
                          {commercialList && commercialList.length > 0 ? (
                            commercialList
                              .sort((a, b) => b.id - a.id)
                              .map((option) => (
                                <MenuItem
                                  key={option.invoice_number}
                                  value={option.invoice_number}
                                  id={option.id}
                                >
                                  {option.invoice_number}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem
                              disabled={true}
                              key={"No data!"}
                              value={"No data!"}
                              id={"No data!"}
                            >
                              No data!
                            </MenuItem>
                          )}
                        </CssTextField>
                      )}{" "}
                      {errorMsg.selctedCi && errorMsg.selctedCi !== false ? (
                        <CssTextField
                          required={true}
                          disabled={
                            selctedCom
                              ? selctedCom.value
                                ? false
                                : true
                              : true
                          }
                          autoComplete="off"
                          value={
                            selctedCi
                              ? selctedCi.value
                                ? selctedCi.value
                                : ""
                              : "" //
                          }
                          error={true}
                          helperText={errorMsg.selctedCi}
                          onChange={(e) => {
                            const errorMsgs = errorMsg;
                            errorMsgs.selctedCi = false;
                            const selectedV = filterData(
                              customInvoices,
                              "id",
                              Number(e.currentTarget.id),
                              "onlyOne"
                            )
                              ? filterData(
                                  customInvoices,
                                  "id",
                                  Number(e.currentTarget.id),
                                  "onlyOne"
                                )
                              : false;
                            setselctedCIUnit({
                              value:
                                selectedV && selectedV.net_weight
                                  ? net_value(
                                      selectedV.used_weight
                                        ? selectedV.used_weight
                                        : 0,
                                      selectedV.net_weight
                                    )
                                  : "",
                              unit:
                                selectedV && selectedV.net_weight
                                  ? selectedV.unit
                                  : "",
                            });
                            setSelctedCi({
                              value: e.target.value,
                              id: e.currentTarget.id,
                            });

                            setErrorMsg(errorMsgs);
                          }}
                          style={{
                            width: "calc(33% - 13px)",
                            // marginRight: "20px",
                          }}
                          id="outlined-select-currency-native"
                          select
                          label="Custom Invoice"
                          variant="outlined"
                          InputLabelProps={{}}
                        >
                          {customInvoices && customInvoices.length > 0 ? (
                            customInvoices
                              .sort((a, b) => b.id - a.id)
                              .map((option) => (
                                <MenuItem
                                  key={option.invoice_number}
                                  value={option.invoice_number}
                                  id={option.id}
                                >
                                  {option.invoice_number}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem
                              disabled={true}
                              key={"No data!"}
                              value={"No data!"}
                              id={"No data!"}
                            >
                              No data!
                            </MenuItem>
                          )}
                        </CssTextField>
                      ) : (
                        <CssTextField
                          required={true}
                          disabled={
                            selctedCom
                              ? selctedCom.value
                                ? false
                                : true
                              : true
                          }
                          autoComplete="off"
                          value={
                            selctedCi
                              ? selctedCi.value
                                ? selctedCi.value
                                : ""
                              : "" //
                          }
                          error={errorMsg.selctedCi ? true : false}
                          helperText={
                            errorMsg.selctedCi ? errorMsg.selctedCi : ""
                          }
                          onChange={(e) => {
                            const errorMsgs = errorMsg;
                            errorMsgs.selctedCi = false;
                            const selectedV = filterData(
                              customInvoices,
                              "id",
                              Number(e.currentTarget.id),
                              "onlyOne"
                            )
                              ? filterData(
                                  customInvoices,
                                  "id",
                                  Number(e.currentTarget.id),
                                  "onlyOne"
                                )
                              : false;

                            setselctedCIUnit({
                              value:
                                selectedV && selectedV.net_weight
                                  ? net_value(
                                      selectedV.used_weight
                                        ? selectedV.used_weight
                                        : 0,
                                      selectedV.net_weight
                                    )
                                  : "",
                              unit:
                                selectedV && selectedV.net_weight
                                  ? selectedV.unit
                                  : "",
                            });
                            setSelctedCi({
                              value: e.target.value,
                              id: e.currentTarget.id,
                            });
                            setErrorMsg(errorMsgs);
                          }}
                          style={{
                            width: "calc(33% - 13px)",
                            // marginRight: "20px",
                          }}
                          id="outlined-select-currency-native"
                          select
                          label="Custom Invoice"
                          variant="outlined"
                          InputLabelProps={{}}
                        >
                          {customInvoices && customInvoices.length > 0 ? (
                            customInvoices
                              .sort((a, b) => b.id - a.id)
                              .map((option) => (
                                <MenuItem
                                  key={option.invoice_number}
                                  value={option.invoice_number}
                                  id={option.id}
                                >
                                  {option.invoice_number}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem
                              disabled={true}
                              key={"No data!"}
                              value={"No data!"}
                              id={"No data!"}
                            >
                              No data!
                            </MenuItem>
                          )}
                        </CssTextField>
                      )}
                       <div className="width-100 d-flex flexWrap paddingT15 pNone">
                      {selctedCIUnit && selctedCIUnit.unit ? (
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{
                            width: "calc(33% - 13px)",
                            // marginRight: "20px",
                            
                          }}
                          id="outlined-basic"
                          label={`Custom Invoice Quantity`}
                          variant="outlined"
                          InputProps={{
                            endAdornment: selctedCIUnit.unit
                              ? selctedCIUnit.unit
                              : "",
                          }}
                          value={selctedCIUnit.value ? selctedCIUnit.value : ""}
                        />
                      ) : (
                        ""
                      )}
                      </div>
                    </div>
                    <div className="width-100">
                      <TableComponent
                        singleUnit={
                          selctedCIUnit &&
                          selctedCIUnit.unit &&
                          selctedCIUnit.unit
                        }
                        weight={
                          selctedCIUnit &&
                          selctedCIUnit.unit &&
                          selctedCIUnit.value
                        }
                        changeUnit={(value, unit) => {
                          return changeUnit(
                            unit,
                            selctedCIUnit &&
                              selctedCIUnit.unit &&
                              selctedCIUnit.unit,
                            value
                          );
                        }}
                        bottomBarheader={["Shipped Bags", "Shipped Quantity"]}
                        nobottomBarAction={true}
                        bottomBar={true}
                        setSelectedListArray={(list) => {
                          setSelectedBrandsArr(list);
                        }}
                        selectedListArray={selectedBrandsArr}
                        setSelectedListArrayErrorSet={() => {
                          setErrorMsg((prev) => {
                            return { ...prev, selectedBrandsArr: false };
                          });
                        }}
                        setSelectedListArrayError={
                          errorMsg["selectedBrandsArr"]
                            ? errorMsg["selectedBrandsArr"]
                            : false
                        }
                        checkbox={true}
                        loading={loading}
                        heightNew="500px"
                        maxHeight="calc(100vh - 40px)"
                        widthNew="900px"
                        module={"Bag details"}
                        actions={[]}
                        noAddButton={true}
                        header={moveToShipHeader2}
                        noSearchBar={true}
                        {...props}
                        tableList={
                          values && values.bags.length
                            ? values.bags
                                .sort((a, b) => b.id - a.id)
                                .map((d) => {
                                  return {
                                    ...d,
                                    "Shipped Bags": submittedData[
                                      `${d.id}Shipped`
                                    ]
                                      ? submittedData[`${d.id}Shipped`]
                                      : 0,
                                    "Shipped BagsUI": () => {
                                      return (
                                        <CssTextField95
                                          required={true}
                                          onInput={(e) => {
                                            if (
                                              Number(e.target.value??0) >
                                              d["Total Bags"]
                                                ? Number(d["Total Bags"])
                                                : 0
                                            ) {
                                              setError(
                                                `Only ${d["Total Bags"]} bags are available.`
                                              );
                                              setSubmittedData((prev) => {
                                                console.log(prev,"prevprev")
                                                return {
                                                  ...prev,
                                                  [`${d.id}Shipped`]: d[
                                                    "Total Bags"
                                                  ]
                                                    ? Number(d["Total Bags"])
                                                    : 0,
                                                };
                                              });
                                            }
                                          }}
                                          error={
                                            errorMsg.addBags ? true : false
                                          }
                                          InputProps={{
                                            inputProps: {
                                              min: 0,
                                              max: d["Total Bags"]
                                                ? Number(d["Total Bags"])
                                                : 0,
                                            },
                                          }}
                                          type="number"
                                          onFocus={StopScroll}
                                          autoComplete="off"
                                          id="outlined-basic"
                                          style={{
                                            width: "100%",
                                            margin: "10px 0",
                                          }}
                                          variant="outlined"
                                          value={
                                            submittedData[`${d.id}Shipped`]
                                              ? submittedData[`${d.id}Shipped`]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            setErrorMsg((prev) => {
                                              return {
                                                ...prev,
                                                addBags: false,
                                              };
                                            });
                                            console.log(e.target.value,d["Total Bags"],"cccc")
                                            if (
                                              Number(e.target.value??0) >
                                              d["Total Bags"]
                                                ? Number(d["Total Bags"])
                                                : 0
                                            ) {
                                              
                                              setSubmittedData((prev) => {

                                                console.log(prev,"prevprev")
                                                return prev? {
                                                  ...prev,
                                                  [`${d.id}Shipped`]: d[
                                                    "Total Bags"
                                                  ]
                                                    ? Number(d["Total Bags"])
                                                    : 0,
                                                }:{ [`${d.id}Shipped`]: d[
                                                  "Total Bags"
                                                ]
                                                  ? Number(d["Total Bags"])
                                                  : 0,};
                                              });
                                            } else {
                                              console.log(
                                              Number(e.target.value),
                                               
                                          "hjbsdsbkf")
                                              setSubmittedData((prev) => {
                                                return prev?{ ...prev,[`${d.id}Shipped`]:
                                                Number(e.target.value)}:{
                                                 
                                                  [`${d.id}Shipped`]:
                                                  Number(e.target.value),
                                                };
                                              });
                                            }
                                          }}
                                        />
                                      );
                                    },
                                    "Shipped Quantity": multiply(
                                      submittedData[`${d.id}Shipped`]
                                        ? Number(
                                            submittedData[`${d.id}Shipped`]
                                          )
                                        : 0,
                                      d.size ? Number(d.size) : 0
                                    ),
                                    "Shipped QuantityUnit": `${multiply(
                                      submittedData[`${d.id}Shipped`]
                                        ? Number(
                                            submittedData[`${d.id}Shipped`]
                                          )
                                        : 0,
                                      d.size ? Number(d.size) : 0
                                    )} ${d.unit}`,
                                  };
                                })
                            : []
                        }
                        filterChild={false}
                      />
                    </div>
                  </div>
                )}
              </>
            );
          }}
        />
      ) : (
        ""
      )}
      {onlyMoveToShipButton ? (
        ""
      ) : (
        <>
          <div
            style={{ height: "168.52px", margin: "15px 0" }}
            className="width-100 overflowXA d-flex"
          >
            {InvLists &&
            InvLists.length > 0 &&
            InvLists.map((s) => s.bags) &&
            InvLists.map((s) => s.bags).length > 0 ? (
              InvLists.map((singleList, i) =>
                singleList.bags && singleList.bags.length > 0
                  ? Number(singleList.port) === singlelistView.id && (
                      <CardDetail
                        keyId={i}
                        height="148.45px"
                        width="150px"
                        margin="10px"
                        pointer="cursor"
                        onClick={() => {
                          setValues(singleList);
                          setOpenDialog(true);
                        }}
                        children={() => {
                          return (
                            <div
                              className="fontSize08"
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "100%",
                                height: "100%",
                                justifyContent: "space-around",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {" "}
                              <div className="d-flex alignC justifyFE fontSize09 width-100">
                                <Tooltip
                                  title={singleList["Brand"]}
                                  placement="right-start"
                                >
                                  <div
                                    style={{ width: "calc(100% - 20px)" }}
                                    className="textOEllipse"
                                  >
                                    {singleList["Brand"]}
                                  </div>
                                </Tooltip>
                                {/* <Tooltip title={"View Details"} placement="right-start">
                              <div
                                className="d-flex alignC justifyFE fontSize09 width-100"
                                style={{
                                  width: "20px",
                                  minWidth: "20px",
                                  color: green,
                                  margin: "0 5px",
                                  maxWidth: "20px",
                                }}
                              >
                                <InfoIcon style={{ color: green, height: "22px", width: "22px", cursor: "pointer" }} />
                              </div>
                            </Tooltip>{" "} */}
                                <Tooltip
                                  title={"Move to Shipping"}
                                  placement="right-start"
                                >
                                  {/* {     console.log(singleList,"jjjjjjjjjjjjjjjjj")} */}
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        singleList &&
                                        singleList.bags.length > 0
                                      ) {
                                        setOpenDialogNew(true);
                                        setSubmittedData({});
                                        setValues(singleList);
                                        clearFields();
                                      } else {
                                        setError(
                                          "No bags are pending to be shipped"
                                        );
                                      }

                                      PortsFunction(
                                        "get",
                                        "dispatch/sales-order/list",
                                        null,
                                        "PortCIs",
                                        null,
                                        {
                                          // sales_order_id: e.currentTarget.id,
                                          // // shipment_status: "EXPENSES CALCULATED",
                                          // // is_active: "True",
                                          // // port_id: singlelistView && singlelistView.id && singlelistView.id,
                                          status: "OPEN",
                                          brand: singleList.brand,
                                        }
                                      ).then((salesorderFilterList) => {
                                        if (salesorderFilterList) {
                                          setsalesorderList(
                                            salesorderFilterList
                                          );
                                        } else {
                                          setsalesorderList([]);
                                        }
                                        setLoad(false);
                                      });
                                    }}
                                    // onChange={(e) => {
                                    //   const errorMsgs = errorMsg;
                                    //   errorMsgs.selctedSo = false;
                                    //   if (e.target.value) {
                                    //     setLoad(true);
                                    //     PortsFunction(
                                    //       "get",
                                    //       "arrival/commercial-invoice/list",
                                    //       null,
                                    //       "PortCIs",
                                    //       null,
                                    //       {
                                    //         sales_order_id: e.currentTarget.id,
                                    //         // shipment_status: "EXPENSES CALCULATED",
                                    //         // is_active: "True",
                                    //         // port_id: singlelistView && singlelistView.id && singlelistView.id,
                                    //         status: "DRAFT",
                                    //       }
                                    //     ).then((commercialList) => {
                                    //       if (commercialList) {
                                    //         setCommercialList(commercialList);
                                    //       } else {
                                    //         setCommercialList([]);
                                    //       }
                                    //       setLoad(false);
                                    //     });
                                    //   }
                                    className="d-flex alignC justifyFE fontSize09 width-100"
                                    style={{
                                      width: "20px",
                                      minWidth: "20px",
                                      color: green,
                                      maxWidth: "20px",
                                    }}
                                  >
                                    <SailingOutlinedIcon
                                      style={{
                                        color:
                                          singleList &&
                                          singleList.bags.length > 0
                                            ? primary
                                            : "black",
                                        height: "22px",
                                        width: "22px",
                                        cursor:
                                          singleList &&
                                          singleList.bags.length > 0
                                            ? "pointer"
                                            : "",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                              <div
                                className="width-100 textColorGrey"
                                style={{ margin: "7px 0" }}
                              >
                                Item Details
                              </div>
                              <div
                                className="width-100 textOEllipse"
                                style={{ height: "20px" }}
                              >
                                {`${
                                  singleList["Item Category"]
                                    ? singleList["Item Category"]
                                    : ""
                                } ${
                                  singleList["Item Type"]
                                    ? singleList["Item Type"]
                                    : ""
                                } %`}
                              </div>
                              <div
                                className="width-100 textColorGrey"
                                style={{ margin: "7px 0" }}
                              >
                                Bags :
                              </div>
                              <div
                                className="width-100 textOEllipse "
                                style={{ height: "20px" }}
                              >
                                {singleList["Total Bags"]
                                  ? singleList["Total Bags"]
                                  : "-"}
                              </div>
                              {/* <div
                        style={{
                          cursor: "pointer",
                          borderRadius: "5px",
                          padding: "0 5px",
                          textAlign: "center",
                          background: "#80808052",
                          margin: "4.705px 0px 0 0",
                        }}
                      >
                        Details View
                      </div> */}
                            </div>
                          );
                        }}
                      />
                    )
                  : ""
              )
            ) : (
              <div className="d-flex justifyC alignC height-100 width-100">
                No brands available in this godown !{" "}
              </div>
            )}
          </div>
          <div style={{ height: "calc(100% - 236.44px)", width: "100%" }}>
            {openDialog === true ? (
              <CssDialog
                headerPrimary={true}
                height="calc(100% - 120px)"
                width="80%"
                header={`${
                  values && values["Brand"] && values["Brand"]
                } Details`}
                addButtonWidth="75px"
                noActions={true}
                // addButton={editList ? "Update" : "Add"}
                onClose={() => {
                  setOpenDialog(false);
                }}
                snackbarClose={() => {
                  setOpenDialog(false);
                }}
                onSubmit={() => {}}
                children={() => {
                  return (
                    <div className="width-100 height-100 d-flex d-flex-column scrollBluePrimary overflowYA">
                      <div className="width-100 d-flex flexWrap padding15 pNone">
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{ width: "calc(33% - 13.33px)" }}
                          id="outlined-basic"
                          label={"Port Name"}
                          variant="outlined"
                          value={
                            singlelistView && singlelistView.name
                              ? singlelistView.name
                              : ""
                          }
                        />
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{
                            width: "calc(33% - 13.33px)",
                            margin: "0 20px",
                          }}
                          id="outlined-basic"
                          label={"Godown Name"}
                          variant="outlined"
                          value={
                            selectGodown
                              ? filterData(
                                  GodownList,
                                  "id",
                                  Number(selectGodown),
                                  "onlyOne"
                                )
                                ? filterData(
                                    GodownList,
                                    "id",
                                    Number(selectGodown),
                                    "onlyOne"
                                  ).name
                                : "Godown"
                              : "Godown"
                          }
                        />
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{ width: "calc(34% - 13.34px)" }}
                          id="outlined-basic"
                          label={"Total Bag"}
                          variant="outlined"
                          value={
                            values["Total Bags"] ? values["Total Bags"] : ""
                          }
                        />
                      </div>
                      <div className="width-100 d-flex flexWrap padding15 pNone">
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{ width: "calc(33% - 13.33px)" }}
                          id="outlined-basic"
                          label={`Brand`}
                          variant="outlined"
                          value={values["Brand"] ? values["Brand"] : ""}
                        />
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{
                            width: "calc(33% - 13.33px)",
                            margin: "0 20px",
                          }}
                          id="outlined-basic"
                          label={"Item Category"}
                          variant="outlined"
                          value={
                            values["Item Category"]
                              ? values["Item Category"]
                              : ""
                          }
                        />
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{ width: "calc(34% - 13.34px)" }}
                          id="outlined-basic"
                          label={"Item Type"}
                          InputProps={{
                            endAdornment: "%",
                          }}
                          variant="outlined"
                          value={values["Item Type"] ? values["Item Type"] : ""}
                        />
                      </div>
                      <div
                        className="width-100"
                        style={
                          values && values.bags.length
                            ? {}
                            : { height: "calc(100% - 157.96px)" }
                        }
                      >
                        <TableComponent
                          loading={loading}
                          heightNew="500px"
                          maxHeight="calc(100vh - 40px)"
                          widthNew="900px"
                          module={"Bag Details"}
                          actions={[]}
                          noAddButton={true}
                          header={moveToShipHeader}
                          noSearchBar={true}
                          {...props}
                          tableList={
                            values && values.bags.length
                              ? values.bags.sort((a, b) => b.id - a.id)
                              : []
                          }
                          filterChild={false}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            ) : (
              ""
            )}
            <TableComponent
              loading={loading}
              heightNew="500px"
              maxHeight="calc(100vh - 40px)"
              Title={`${
                selectGodown
                  ? filterData(
                      GodownList,
                      "id",
                      Number(selectGodown),
                      "onlyOne"
                    )
                    ? filterData(
                        GodownList,
                        "id",
                        Number(selectGodown),
                        "onlyOne"
                      ).name
                    : "Godown"
                  : "Godown"
              } History`}
              widthNew="900px"
              module={"Inventory"}
              actions={[]}
              noAddButton={true}
              subMenu={true}
              searchFiled={["status", "Date", "Custom Invoice", "Total Bags"]}
              header={[
                "Sr.No.",
                "Date",
                "Status",
                "Custom Invoice",
                "Total Bags",
              ]}
              subMenuheader={[
                "Sr.No.",
                "Brand",
                "Item Type",
                "Item Category",
                "Size",
                "Bag Type",
                "Total Bags",
                "Total Quantity",
              ]}
              {...props}
              tableList={
                historyList && historyList.length
                  ? historyList
                      .map((singleInventory) => {
                        singleInventory["Status"] = () => {
                          return singleInventory.status === "IN" ? (
                            <div
                              style={{
                                background: red,
                                width: "60px",
                                padding: "6px 10px",
                              }}
                              className="borderRadius7 textCW d-flex alignC justifyC textC"
                            >
                              {singleInventory.status}
                            </div>
                          ) : (
                            <div
                              style={{
                                background: green,
                                width: "60px",
                                padding: "6px 10px",
                              }}
                              className="borderRadius7  textCW  d-flex alignC justifyC textC"
                            >
                              {singleInventory.status}
                            </div>
                          );
                        };

                        singleInventory["Custom Invoice"] =
                          singleInventory.status === "IN"
                            ? ""
                            : singleInventory.custom_invoice_number;

                        singleInventory["Total Bags"] =
                          singleInventory.shipping_bags &&
                          singleInventory.shipping_bags !== null
                            ? singleInventory.shipping_bags
                            : singleInventory.total_bags
                            ? singleInventory.total_bags
                            : 0;
                        singleInventory["Date"] = moment(
                          singleInventory.created_at ?? new Date()
                        ).format("ddd, MMM Do YYYY , h:mm A");
                        singleInventory["subList"] =
                          singleInventory.total_packaging.length > 0
                            ? singleInventory.total_packaging.map((sP) => {
                                const brandSelected = filterData(
                                  BrandList,
                                  "id",
                                  singleInventory.brand,
                                  "onlyOne"
                                )
                                  ? filterData(
                                      BrandList,
                                      "id",
                                      singleInventory.brand,
                                      "onlyOne"
                                    )
                                  : "";
                                return {
                                  ...sP,
                                  Brand: brandSelected.brand_name,
                                  "Item Category": filterData(
                                    ItemCateoryList,
                                    "id",
                                    brandSelected.item_category,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        ItemCateoryList,
                                        "id",
                                        brandSelected.item_category,
                                        "onlyOne"
                                      ).name
                                    : "",
                                  "Item Type": filterData(
                                    ItemList,
                                    "id",
                                    brandSelected.item_type,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        ItemList,
                                        "id",
                                        brandSelected.item_type,
                                        "onlyOne"
                                      ).broken_percentage
                                    : "",

                                  "Total Bags": `${
                                    sP.bags_for_packaging
                                      ? sP.bags_for_packaging
                                      : 0
                                  }`,
                                  "Bag Type": sP.bag_type,
                                  Size: `${sP.size} ${sP.unit}`,
                                  "Total Quantity": multiply(
                                    sP.bags_for_packaging
                                      ? sP.bags_for_packaging
                                      : 0,
                                    sP.size ? Number(sP.size) : 0
                                  ),
                                  "Total QuantityUnit": `${multiply(
                                    sP.bags_for_packaging
                                      ? sP.bags_for_packaging
                                      : 0,
                                    sP.size ? Number(sP.size) : 0
                                  )} ${sP.unit}`,
                                };
                              })
                            : [];
                        singleInventory.datetime =
                          singleInventory.datetime ?? new Date();
                    
                        return singleInventory;
                      })
                      .sort((a, b) => {
                        var dateA = moment(b.created_at).format(
                          "MMM D YYYY h:mm:ss A"
                        );
                        var dateB = moment(a.created_at).format(
                          "MMM D YYYY h:mm:ss A"
                        );
                        return new Date(dateA) - new Date(dateB);
                      })
                  : // .filter((s) => s.godown === Number(selectGodown))
                    []
              }
              filterChild={false}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Inventory;
