import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import { StyledCircularProgress } from "../../styles/StyledComponents";

import NoData from "../../styles/Illustrations/NoData";

import { EndPoints } from "../../utils/EndPoints";
import moment from "moment";
import CreateDialog from "../../layoutComponents/CreateDialog";
import EditIcon from "@mui/icons-material/Edit";
import { Paper, Tooltip } from "@material-ui/core";
import { filterData, getSingleModule_widget } from "../../Regex";
import { accessText } from "../../routes/sidebar";
export class CashDiscountComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["CashDiscount"],
        tooltip: "Click to approve pending CashDiscount",
        data: [],
        route: "/CashDiscount/CashDiscount",
        widget: null,
        goBackRoute: "/CashDiscount",
        primaryField: "Discount",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#0AFF00", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      CashDiscount: [],
      singlelistView: null,
      loading: false,
      editList: false,
      openDialog: false,
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["CashDiscount"].split("/")[0] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {}
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    this.setState({
      singlelistView: value,
    });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };

  setInitialValues(props) {
    const { widget_permission } = getSingleModule_widget(
      props.user,
      accessText.purchase_order,
      accessText.cash_discounts
    );
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      // has_read_permission:true,has_create_permission:true
      tab.widget = widget_permission?.widget
        ? { ...widget_permission?.widget }
        : null;

      tab.data =
        props[EndPoints["CashDiscount"]] &&
        props[EndPoints["CashDiscount"]].length > 0
          ? props[EndPoints["CashDiscount"]]
              .map((sT) => {
                return {
                  ...sT,
                  Discount: `${sT.discount} % - ${sT.expiry_days} days`,
                };
              })
     
              .sort((a, b) => {
                var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })
          : [];
      return tab;
    });
    this.setState({
      listViewArray: props[EndPoints["CashDiscount"]] &&
      props[EndPoints["CashDiscount"]].length > 0
        ? props[EndPoints["CashDiscount"]]
            .map((sT) => {
              return {
                ...sT,
                Discount: `${sT.discount} % - ${sT.expiry_days} days`,
              };
            })
   
            .sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
        : [],
      CashDiscount: props.CashDiscount,
      tabs,
      loading: props.loading,
    });
  }
  fetchList = (res) => {
    if (
      this.state.singlelistView &&
      this.state.singlelistView.id &&
      res &&
      res.id &&
      res.id === this.state.singlelistView.id
    ) {
      this.setState({ singlelistView: res });
    }
    this.props.CashDiscountFunction("get", EndPoints["CashDiscount"]);
  };
  render() {
    const {
      tabs,
      CashDiscount,
      listViewArray,
      singlelistView,
      loading,
      editList,
      openDialog,
    } = this.state;
    const { user } = this.props;
    var labelField = "Discount";
    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.purchase_order,
      accessText.cash_discounts
    );
console.log(editList,"tabstabs")
    // const { isMobile ,user} = this.props;
    return (
      <Layout
        loading={loading[EndPoints["CashDiscount"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: "Search Cash Discount",
          labelField: labelField,
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
        fabClickHandler={() => {
          this.setState({ openDialog: true, editList: false });
        }}>
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {openDialog === true ? (
            <CreateDialog
              module={"Cash Discount"}
              closeOnSave={true}
              selectedList={editList ? {...editList,"Discount":editList.discount} : false}
              editList={editList ? {...editList,"Discount":editList.discount} : false}
              handleClose={() => {
                this.setState({ openDialog: false });
              }}
              noAddAnother={true}
              fetchAgain={this.fetchList}
              postApi="masters/cash-discount/list"
              editApi="masters/cash-discount"
              textFieldStyle={{ width: "calc(50% - 20px)", margin: "10px" }}
              heightNew="300px"
              maxHeight="calc(100vh - 140px)"
              widthNew="700px"
              inputArray={[
                {
                  name: "Active",
                  type: "toggle",
                  defaultValue: {
                    value: editList ? editList.is_active : true,
                  },
                  api: "is_active",
                },
                {
                  name: "Discount",
                  type: "textField",
                  dataType: "number",
                  // onKeyPress: "alphaNum",
                  endAdornment: "%",
                  defaultValue: {
                    value:editList&&editList.discount ? editList.discount : "",
                  },
                  api: "discount",
                 
                },
                {
                  name: "Expiry days",
                  type: "textField",
                  dataType: "text",
                  onKeyPress: "alphaNum",
                  api: "expiry_days",
                  defaultValue: {
                    value:
                      editList && editList.expiry_days
                        ? editList.expiry_days
                        : "",
                  },
                },
              ]}
              postData={this.props.CashDiscountFunction}
            />
          ) : (
            ""
          )}
             
          
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading[EndPoints["CashDiscount"]] === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <>
            <div
          className="width-100 height-100 padding15 backgroundWhite"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
              <div style={{ width: "90%" }}>
                <h1 style={{ fontWeight: "400", fontSize: "25px" }}>
                  Cash Discount Details
                </h1>
              </div>
              
              <Paper
                className="padding15 backgroundWhite"
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                {widget_permission?.widget?.has_update_permission && (
                  <div
                    style={{
                      width: "100%",
                      height: "50px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}>
                    <Tooltip title="Edit">
                      <EditIcon
                        onClick={() => {
                          this.setState({
                            openDialog: true,
                            editList: singlelistView,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                )}
                <div
                  className="overflowYA scrollBluePrimary"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 50px",
                    height: "calc(100% - 31.7px)",
                    // textTransform: "capitalize",
                  }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}>
                      Discount:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",

                        // height: "20vh",
                        // wordWrap: "break-word",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        textTransform: "capitalize",
                        wordBreak: "break-all",
                      }}>
                      {singlelistView.discount}{" "}
                      {singlelistView.discount ? "%" : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}>
                      Expiry Day:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",

                        // height: "20vh",
                        // wordWrap: "break-word",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        textTransform: "capitalize",
                        wordBreak: "break-all",
                      }}>
                      {singlelistView.expiry_days}
                      {singlelistView.expiry_days > 1 ? "days" : "day"}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}>
                      Status:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",

                        // height: "20vh",
                        // wordWrap: "break-word",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        textTransform: "capitalize",
                        wordBreak: "break-all",
                      }}>
                      {singlelistView.is_active ? "Active" : "Inactive"}
                    </p>
                  </div>
                </div>
              </Paper>
              </div>
            </>
          )}
        </Paper>
      </Layout>
    );
  }
}

export default CashDiscountComponent;
