import React, { Component } from "react";
import validator from "validator";
import {
  Backdrop,
  Dialog,
  MenuItem,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import {
  CssTextField,
  CssTextField85,
  StyledButton,
  StyledCircularProgress,
} from "../styles/StyledComponents";
import { primary } from "../styles/colors";
import { CrossIcon, TopArrow, BottomArrow } from "../styles/Icons";
import { CheckCircle } from "@material-ui/icons";
import SnackbarComponent from "./SnackbarComponent";
import { trimEnd } from "lodash";
import ListSubheader from "@mui/material/ListSubheader";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { withStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs

import MultipleSelectBar from "./MultipleSelectBar";
import { EndPoints } from "../utils/EndPoints";
import { CssCreateTextField } from "../Modules/PurchaseOrders/PurchaseOrdersComponent";
import { StopScroll } from "../Regex";
// import JoditEditor from "jodit-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import RowRadioButtonsGroup from "./RadioButton";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiInputBase-root": {background:"rgb(0 0 0 / 1%)"},
    "&.PickerWithState-root-34 .MuiOutlinedInput-root .MuiOutlinedInput-input": {background:"none"},
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "14px 14px",
      caretColor: "transparent",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);

const styles = {
  withDivider50: { width: "calc(25% - 20px)", margin: "10px" },
  withDivider502: { width: "calc(50% - 20px)", margin: "10px" },
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  bottomHeading: {
    margin: "0 10px 0 0",
    width: "390px",
  },
};
export class SignINlayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: "paper",
      dataAdded: false,
      loading: false,
      comment: "",
      error: "",
      success: "",
      submittedData: {},
      addFieldsToAdd: false,
      errorMsg: {
        comment: false,
        file: false,
      },
    };
  }
  reset = () => {
    const { submittedData, inputArray } = this.state;
    const { selectedList } = this.props;
    const noData = [];
    const errorMsg = {};
    const newSubmittedData = {};
    inputArray.map((singleInput) => {
      if (submittedData[singleInput.name]) {
        errorMsg[singleInput.name] = false;
        newSubmittedData[singleInput.name] = "";
        return noData.push(singleInput.name);
      } else {
        return (errorMsg[singleInput.name] = false);
      }
    });
    if (noData.length === 0) {
      this.setState({ error: "Nothing to reset !", errorMsg }, () => {
        if (this.props.reset) {
          this.props.reset();
        }
      });
    } else {
      const newSubmittedData = {};
      inputArray.map((singleInput) => {
        if (
          selectedList[singleInput.name] ||
          selectedList[singleInput.name] === false
        ) {
          errorMsg[singleInput.name] = false;
          if (singleInput.type === "checkBox") {
            return (newSubmittedData[singleInput.name] =
              selectedList[singleInput.name] === "Paid" ? true : false);
          } else if (singleInput.type === "toggle") {
            newSubmittedData[singleInput.name] = selectedList[singleInput.name]
              ? selectedList[singleInput.name]
              : false;
          } else if (singleInput.type === "Month") {
            newSubmittedData[singleInput.name] =
              selectedList[`${singleInput.name}Month`];
          } else {
            newSubmittedData[singleInput.name] = selectedList[singleInput.name];
            newSubmittedData[`${singleInput.name}Id`] = selectedList[
              `${singleInput.name}Id`
            ]
              ? selectedList[`${singleInput.name}Id`]
              : "";

            return newSubmittedData;
          }
        } else if (singleInput.defaultValue) {
          errorMsg[singleInput.name] = false;
          newSubmittedData[singleInput.name] =
            singleInput.type === "toggle"
              ? singleInput.defaultValue.value
                ? singleInput.defaultValue.value
                : false
              : singleInput.type === "checkBox" &&
                singleInput.defaultValue.value === false
              ? false
              : singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : "";

          newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue
            .id
            ? singleInput.defaultValue.id
            : "";

          return newSubmittedData;
        } else if (singleInput.type === "Month") {
          newSubmittedData[singleInput.name] = new Date();
        } else if (singleInput.type === "checkBox") {
          newSubmittedData[singleInput.name] = false;
        } else if (singleInput.type === "toggle") {
          newSubmittedData[singleInput.name] = false;
        } else {
          errorMsg[singleInput.name] = false;
          return (newSubmittedData[singleInput.name] = "");
        }
      });
      this.setState(
        {
          dataAdded: false,
          comment: "",
          submittedData: newSubmittedData,
          errorMsg,
        },
        () => {
          if (this.props.reset) {
            this.props.reset();
          }
          // this.setState({ success: "Reset successfully !" });
        }
      );
    }
  };

  setInitialValues = (next) => {
    const { selectedList, inputArray } = next;
  

    const newSubmittedData = {};
    const errorMsg = this.state.errorMsg;
    inputArray.map((singleInput) => {
      if (
        selectedList[singleInput.name] ||
        selectedList[singleInput.name] === false
      ) {
        errorMsg[singleInput.name] = false;
        if (singleInput.type === "checkBox") {
          return (newSubmittedData[singleInput.name] =
            selectedList[singleInput.name] === "Paid" ? true : false);
        } else if (singleInput.type === "toggle") {
          newSubmittedData[singleInput.name] = selectedList[singleInput.name]
            ? selectedList[singleInput.name]
            : false;
        } else if (singleInput.type === "Month") {
          newSubmittedData[singleInput.name] =
            selectedList[`${singleInput.name}Month`];
        } else {
          newSubmittedData[singleInput.name] = selectedList[singleInput.name];
          newSubmittedData[`${singleInput.name}Id`] = selectedList[
            `${singleInput.name}Id`
          ]
            ? selectedList[`${singleInput.name}Id`]
            : "";

          return newSubmittedData;
        }
      } else if (singleInput.defaultValue) {
        errorMsg[singleInput.name] = false;
        newSubmittedData[singleInput.name] =
          singleInput.type === "toggle"
            ? singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : false
            : singleInput.type === "checkBox" &&
              singleInput.defaultValue.value === false
            ? false
            : singleInput.defaultValue.value
            ? singleInput.defaultValue.value
            : "";

        newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue.id
          ? singleInput.defaultValue.id
          : "";

        return newSubmittedData;
      } else if (singleInput.type === "Month") {
        newSubmittedData[singleInput.name] = new Date();
      } else if (singleInput.type === "checkBox") {
        newSubmittedData[singleInput.name] = false;
      } else if (singleInput.type === "toggle") {
        newSubmittedData[singleInput.name] = false;
      } else {
        errorMsg[singleInput.name] = false;
        return (newSubmittedData[singleInput.name] = "");
      }
    });
    this.setState({
      submittedData: newSubmittedData,
      inputArray: next.inputArray,
      addFieldsToAdd: next.addFieldsToAdd,
      extraDetialValue: next.extraDetialValue,
    });
    // }
  };
  UNSAFE_componentWillReceiveProps(next) {
    if (next.isSetLoad === true || next.isSetLoad === false) {
      this.setState({
        loading: next.isSetLoad,
      });
    }
    const { submittedData } = this.state;
    const { selectedList, inputArray } = next;
    const newSubmittedData = submittedData;
    inputArray.map((singleInput) => {
      if (singleInput.update === true && !newSubmittedData[singleInput.name]) {
        if (
          selectedList[singleInput.name] ||
          selectedList[singleInput.name] === false
        ) {
          if (singleInput.type === "checkBox") {
            return (newSubmittedData[singleInput.name] =
              selectedList[singleInput.name] === "Paid" ? true : false);
          } else if (singleInput.type === "toggle") {
            newSubmittedData[singleInput.name] = selectedList[singleInput.name]
              ? selectedList[singleInput.name]
              : false;
          } else if (singleInput.type === "Month") {
            newSubmittedData[singleInput.name] =
              selectedList[`${singleInput.name}Month`];
          } else {
            newSubmittedData[singleInput.name] = selectedList[singleInput.name];
            newSubmittedData[`${singleInput.name}Id`] = selectedList[
              `${singleInput.name}Id`
            ]
              ? selectedList[`${singleInput.name}Id`]
              : "";

            return newSubmittedData;
          }
        } else if (singleInput.defaultValue) {
          newSubmittedData[singleInput.name] =
            singleInput.type === "toggle"
              ? singleInput.defaultValue.value
                ? singleInput.defaultValue.value
                : false
              : singleInput.type === "checkBox" &&
                singleInput.defaultValue.value === false
              ? false
              : singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : "";

          newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue
            .id
            ? singleInput.defaultValue.id
            : "";

          return newSubmittedData;
        } else if (singleInput.type === "Month") {
          newSubmittedData[singleInput.name] = new Date();
        } else if (singleInput.type === "checkBox") {
          newSubmittedData[singleInput.name] = false;
        } else if (singleInput.type === "toggle") {
          newSubmittedData[singleInput.name] = false;
        } else {
          return (newSubmittedData[singleInput.name] = "");
        }
      }
    });
    this.setState({
      submittedData: newSubmittedData,
      inputArray: next.inputArray,
      addFieldsToAdd: next.addFieldsToAdd,
      extraDetialValue: next.extraDetialValue,
    });
  }
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props);
  }

  handleUpdate = (e) => {
    this.setState({ cancel: false });
    e.preventDefault();
    let file = {};

    if (e.target.files[0]) {
      const kb = (e.target.files[0].size / 1024).toFixed(2);
      const mb = (e.target.files[0].size / 1024.0 / 1024.0).toFixed(2);

      if (kb <= 1024) {
        const size = `${kb}$kb`;
        file.mainSize = size;
        this.setState({ size });
      }
      if (1024 < kb) {
        const size = `${mb}$mb`;
        file.mainSize = size;
        this.setState({ size });
      }

      file.img = e.target.files[0];
      file.url = URL.createObjectURL(e.target.files[0]);
      var errorMsg = this.state.errorMsg;
      errorMsg.file = false;
      this.setState({ file, errorMsg });
    }
  };

  render() {
    const {
      scroll,
      loading,
      dataAdded,
      errorMsg,
      error,
      success,
      submittedData,
      inputArray,
      addFieldsToAdd,
      extraDetialValue,
    } = this.state;
    const {
      module,
      selectedList,
      editList,
      heightNew,
      maxHeight,
      widthNew,
      textFieldWidth,
      textFieldStyle,
      extraDetial,
      detailUI,
      noTitle,
      contentHeight,
      fullScreen,
      onlyView,
      noAddAnother,
    } = this.props;

    const modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, false] }],
        [{ font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
        // ["clean"],
      ],
    };

    return (
      <div style={{ width: "400px" }}>
        {loading ? (
          <Backdrop
            style={{ zIndex: 1, color: "#fff", position: "absolute" }}
            open={loading}
          >
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
        {error || success ? (
          <SnackbarComponent
            error={error}
            autoHideDuration={error ? 5000 : 1500}
            success={success}
            snackbarClose={() => {
              if (this.props.clearError) {
                this.props.clearError();
              }
              if (success === `${module} Edited`) {
                this.setState({ error: "", success: "" }, () => {
                  this.props.handleClose();
                });
              } else {
                if (success && this.props.closeOnSave) {
                  this.props.fetchAgain();
                  this.props.handleClose();
                }
                this.setState({ error: "", success: "" }, () => {});
              }
            }}
          />
        ) : (
          ""
        )}

        <div>
          {dataAdded ? (
            <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
              <CheckCircle
                style={{ width: "100px", height: "100px", color: "rgb(241 154 48)" }}
              />
              <span
                className="heading"
                style={{ color: "rgb(241 154 48)", margin: "10px 0 0 0" }}
              >
                {dataAdded}
              </span>
            </div>
          ) : (
            <div className="height-100 width-100 d-flex">
              <div
                className={`${
                  contentHeight ? "height-100" : "heightFC"
                } width-100 d-flex flexWrap`}
              >
                {detailUI && detailUI()}
                {inputArray &&
                  inputArray.length > 0 &&
                  inputArray.map((singleInput, i) => (
                    <>
                      {singleInput.type === "textField" ? (
                        <CssTextField
                          InputLabelProps={
                            singleInput.shrink
                              ? {
                                  shrink: true,
                                }
                              : {}
                          }
                          autoFocus={singleInput.autoFocus ? true : false}
                          className={`${
                            singleInput.upperCase
                              ? ""
                              : singleInput.capitalize
                              ? "textC"
                              : ""
                          }`}
                          required={
                            singleInput.not_required
                              ? !singleInput.not_required
                              : true
                          }
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : singleInput.helperText
                              ? singleInput.helperText
                              : false
                          }
                          autoComplete="new-password"
                          disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          InputProps={{
                            autocomplete: singleInput.password
                              ? "new-password"
                              : "off",
                            form: {
                              autocomplete: "off",
                            },

                            inputProps: {
                              min: singleInput.min ? singleInput.min : 0,
                              max: singleInput.percentage
                                ? 100
                                : singleInput.max
                                ? singleInput.max
                                : "",
                            },
                            endAdornment: singleInput.endAdornment
                              ? // ? submittedData[singleInput.name]
                                singleInput.endAdornment
                              : // : ''
                                "",
                            startAdornment: singleInput.startAdornment
                              ? // ? submittedData[singleInput.name]
                                singleInput.startAdornment
                              : // : ''
                                "",
                          }}
                          style={
                            textFieldStyle
                              ? textFieldStyle
                              : textFieldWidth
                              ? styles.withDivider502
                              : styles.withDivider50
                          }
                          id="outlined-basic"
                          label={
                            singleInput.name.charAt(0).toUpperCase() +
                            singleInput.name.slice(1)
                          }
                          type={singleInput.dataType}
                          onFocus={
                            singleInput.dataType === "number"
                              ? StopScroll
                              : () => {}
                          }
                          value={
                            submittedData[singleInput.name]
                              ? submittedData[singleInput.name]
                              : ""
                          }
                          name={singleInput.name}
                          onKeyDown={(evt) => {
                            if (singleInput.only_number === true) {
                              evt.key === "." && evt.preventDefault();
                            }
                            if (singleInput.dataType === "number") {
                              evt.key === "e" && evt.preventDefault();

                              evt.key === "E" && evt.preventDefault();
                              evt.key === "-" && evt.preventDefault();
                              evt.keyCode === 38 && evt.preventDefault();
                              evt.keyCode === 40 && evt.preventDefault();
                            }
                          }}
                          onInput={(e) => {
                            // if (singleInput.only_number === true) {
                            //   e.target.value = e.target.value.replace(/[^\w]|_/g, "").toLowerCase();
                            // }
                            // if (singleInput.percentage) {
                            //   e.target.value = e.target.value ? (e.target.value > 100 ? 100 : e.target.value) : "";
                            // }
                            if (
                              singleInput.max &&
                              e.target.value &&
                              Number(e.target.value) > Number(singleInput.max)
                            ) {
                              e.target.value = singleInput.max;
                              this.setState({
                                error: `${singleInput.name} can't be greater than ${singleInput.max}`,
                              });
                            }
                            if (singleInput.onInput === "phone_number") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 10)
                                : "";
                              //  e.target.value
                              //   ? MathonInput.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                              //   : "";
                            }
                            if (singleInput.api === "account_no") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 20)
                                : "";
                              //  e.target.value
                              //   ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 20)
                              //   : "";
                            }
                            if (singleInput.api === "swift_code") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 12)
                                : "";
                              //  e.target.value
                              //   ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                              //   : "";
                            }
                            if (singleInput.onInput === "pincode") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 6)
                                : "";
                            }
                            if (singleInput.onInput === "gst_in") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 15)
                                : "";
                            }
                          }}
                          onChange={(event) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg[event.target.name] = false;
                            const submittedData = this.state.submittedData;
                            submittedData[event.target.name] =
                              event.target.value && singleInput.upperCase
                                ? event.target.value.toUpperCase()
                                : event.target.value;
                            if (singleInput.onChange) {
                              singleInput
                                .onChange(
                                  submittedData,
                                  () => {
                                    this.setState({ loading: true });
                                  },
                                  () => {
                                    this.setState({ loading: false });
                                  }
                                )
                                .then((submittedData) => {
                                  this.setState({
                                    submittedData,
                                    errorMsg,
                                  });
                                });
                            } else {
                              this.setState({
                                submittedData,
                                errorMsg,
                              });
                            }
                          }}
                          onKeyPress={(e) => {
                            if (singleInput.onKeyPress === "textOnly") {
                              const re = /[a-z A-Z]+/g;
                              if (!re.test(e.key)) {
                                e.preventDefault();
                              }
                            }
                            if (singleInput.onKeyPress === "alphaNum") {
                              const re = /[a-z0-9A-Z]+/g;
                              if (!re.test(e.key)) {
                                e.preventDefault();
                              }
                            }
                          }}
                          variant="outlined"
                          // className="textC"
                        />
                      ) : singleInput.type === "addressTextField" ? (
                        <CssTextField
                          autoFocus={singleInput.autoFocus ? true : false}
                          className={`${
                            singleInput.upperCase
                              ? ""
                              : singleInput.capitalize
                              ? "textC"
                              : ""
                          }`}
                          maxRows={3}
                          multiline
                          required={
                            singleInput.not_required
                              ? !singleInput.not_required
                              : true
                          }
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : false
                          }
                          autoComplete="off"
                          disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          InputProps={{
                            inputProps: {
                              min: singleInput.min ? singleInput.min : 0,
                            },
                            style: { padding: "0.5px 0px" },
                            endAdornment: singleInput.endAdornment
                              ? // ? submittedData[singleInput.name]
                                singleInput.endAdornment
                              : // : ''
                                "",
                            startAdornment: singleInput.startAdornment
                              ? // ? submittedData[singleInput.name]
                                singleInput.startAdornment
                              : // : ''
                                "",
                          }}
                          style={{
                            width: "calc(50% - 20px)",
                            margin: "10px",
                            display: "flex",
                          }}
                          id="outlined-basic"
                          label={
                            singleInput.name.charAt(0).toUpperCase() +
                            singleInput.name.slice(1)
                          }
                          type={singleInput.dataType}
                          onFocus={
                            singleInput.dataType === "number"
                              ? StopScroll
                              : () => {}
                          }
                          value={
                            submittedData[singleInput.name]
                              ? submittedData[singleInput.name]
                              : ""
                          }
                          name={singleInput.name}
                          onKeyDown={(evt) => {
                            if (singleInput.only_number === true) {
                              evt.key === "." && evt.preventDefault();
                            }
                            if (singleInput.dataType === "number") {
                              evt.key === "e" && evt.preventDefault();

                              evt.key === "E" && evt.preventDefault();
                              evt.key === "-" && evt.preventDefault();
                              evt.keyCode === 38 && evt.preventDefault();
                              evt.keyCode === 40 && evt.preventDefault();
                            }
                          }}
                          onInput={(e) => {
                            // if (singleInput.only_number === true) {
                            //   e.target.value = e.target.value.replace(/[^\w]|_/g, "").toLowerCase();
                            // }
                            if (singleInput.onInput === "phone_number") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 10)
                                : "";
                              //  e.target.value
                              //   ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                              //   : "";
                            }
                            if (singleInput.api === "account_no") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 20)
                                : "";
                              //  e.target.value
                              //   ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 20)
                              //   : "";
                            }
                            if (singleInput.api === "swift_code") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 12)
                                : "";
                              //  e.target.value
                              //   ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                              //   : "";
                            }
                            if (singleInput.onInput === "pincode") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 6)
                                : "";
                            }
                            if (singleInput.onInput === "gst_in") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 15)
                                : "";
                            }
                          }}
                          onChange={(event) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg[event.target.name] = false;
                            const submittedData = this.state.submittedData;
                            submittedData[event.target.name] =
                              event.target.value && singleInput.upperCase
                                ? event.target.value.toUpperCase()
                                : event.target.value;
                            if (singleInput.onChange) {
                              singleInput
                                .onChange(
                                  submittedData,
                                  () => {
                                    this.setState({ loading: true });
                                  },
                                  () => {
                                    this.setState({ loading: false });
                                  }
                                )
                                .then((submittedData) => {
                                  this.setState({
                                    submittedData,
                                    errorMsg,
                                  });
                                });
                            } else {
                              this.setState({
                                submittedData,
                                errorMsg,
                              });
                            }
                          }}
                          onKeyPress={(e) => {
                            if (singleInput.onKeyPress === "textOnly") {
                              const re = /[a-z A-Z]+/g;
                              if (!re.test(e.key)) {
                                e.preventDefault();
                              }
                            }
                            if (singleInput.onKeyPress === "alphaNum") {
                              const re = /[a-z0-9A-Z]+/g;
                              if (!re.test(e.key)) {
                                e.preventDefault();
                              }
                            }
                          }}
                          variant="outlined"
                          // className="textC"
                        />
                      ) : singleInput.type === "richTextField" ? (
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          autoFocus={singleInput.autoFocus ? true : false}
                          // className={`${
                          //   singleInput.upperCase
                          //     ? ""
                          //     : singleInput.capitalize
                          //     ? "textC"
                          //     : ""
                          // }`}
                          required={
                            singleInput.not_required
                              ? !singleInput.not_required
                              : true
                          }
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : false
                          }
                          autoComplete="new-password"
                          disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          // InputProps={{
                          //   autocomplete: singleInput.password
                          //     ? "new-password"
                          //     : "off",
                          //   form: {
                          //     autocomplete: "off",
                          //   },

                          //   inputProps: {
                          //     min: singleInput.min ? singleInput.min : 0,
                          //   },
                          //   endAdornment: singleInput.endAdornment
                          //     ? // ? submittedData[singleInput.name]
                          //       singleInput.endAdornment
                          //     : // : ''
                          //       "",
                          //   startAdornment: singleInput.startAdornment
                          //     ? // ? submittedData[singleInput.name]
                          //       singleInput.startAdornment
                          //     : // : ''
                          //       "",
                          // }}
                          style={{
                            // display: "flex",
                            width: "100%",
                            maxWidth: "100%",
                            margin: "10px",
                          }}
                          id="outlined-basic"
                          label={
                            singleInput.name.charAt(0).toUpperCase() +
                            singleInput.name.slice(1)
                          }
                          type={singleInput.dataType}
                          onFocus={
                            singleInput.dataType === "number"
                              ? StopScroll
                              : () => {}
                          }
                          value={
                            submittedData[singleInput.name]
                              ? submittedData[singleInput.name]
                              : ""
                          }
                          name={singleInput.name}
                          onChange={(newContent) => {
                          
                            const errorMsg = this.state.errorMsg;
                            errorMsg[singleInput.name] = false;
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] = newContent;
                            if (singleInput.onChange) {
                              singleInput
                                .onChange(
                                  submittedData,
                                  () => {
                                    this.setState({ loading: true });
                                  },
                                  () => {
                                    this.setState({ loading: false });
                                  }
                                )
                                .then((submittedData) => {
                                  this.setState({
                                    submittedData,
                                    errorMsg,
                                  });
                                });
                            } else {
                              this.setState({
                                submittedData,
                                errorMsg,
                              });
                            }
                          }}
                          // onChange={(event) => {

                          // }}
                          variant="outlined"
                        />
                      ) : singleInput.type === "radio" ? (
                        <RowRadioButtonsGroup
                          array={singleInput.array ? singleInput.array : []}
                          width={"100%"}
                          margin="10px 0"
                          selected={
                            submittedData[singleInput.name]
                              ? submittedData[singleInput.name]
                              : ""
                          }
                          onChange={(type) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg[singleInput.name] = false;
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] =
                              type && singleInput.upperCase
                                ? type.toUpperCase()
                                : type;
                            if (singleInput.onChange) {
                              singleInput
                                .onChange(
                                  submittedData,
                                  () => {
                                    this.setState({ loading: true });
                                  },
                                  () => {
                                    this.setState({ loading: false });
                                  }
                                )
                                .then((submittedData) => {
                                  this.setState({
                                    submittedData,
                                    errorMsg,
                                  });
                                });
                            } else {
                              this.setState({
                                submittedData,
                                errorMsg,
                              });
                            }
                          }}
                        />
                      ) : singleInput.type === "select" ? (
                        <CssTextField
                          required={
                            singleInput.not_required
                              ? !singleInput.not_required
                              : true
                          }
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : false
                          }
                          autoComplete="off"
                          value={submittedData[singleInput.name]}
                          disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          style={
                            textFieldStyle
                              ? textFieldStyle
                              : textFieldWidth
                              ? styles.withDivider502
                              : styles.withDivider50
                          }
                          id="outlined-select-currency-native"
                          select
                          label={
                            singleInput.name.charAt(0).toUpperCase() +
                            singleInput.name.slice(1)
                          }
                          SelectProps={{ disableunderline: "true" }}
                          variant="outlined"
                          onChange={(e) => {
                            if (e.target.value) {
                              const id = `${singleInput.name}Id`;
                              const submittedData = this.state.submittedData;
                              submittedData[singleInput.name] = e.target.value;
                              var errorMsg = this.state.errorMsg;
                              errorMsg[singleInput.name] = false;
                              submittedData[id] = e.currentTarget.id;
                              if (singleInput.onChange) {
                                singleInput
                                  .onChange(
                                    submittedData,
                                    () => {
                                      this.setState({ loading: true });
                                    },
                                    () => {
                                      this.setState({ loading: false });
                                    }
                                  )
                                  .then((submittedData) => {
                                    this.setState({
                                      submittedData,
                                      errorMsg,
                                    });
                                  });
                              } else {
                                this.setState({
                                  submittedData,
                                  errorMsg,
                                });
                              }
                            }
                          }}
                        >
                          {singleInput.array2 &&
                            singleInput.array2.length > 0 &&
                            singleInput.array2.map((option) =>
                              option.subheaderTiitle ? (
                                <ListSubheader style={{ pointerEvent: "none" }}>
                                  {singleInput.subheaderTiitle}
                                </ListSubheader>
                              ) : (
                                <MenuItem
                                  key={option[singleInput.value]}
                                  value={option[singleInput.value]}
                                  id={
                                    option.id
                                      ? option.id
                                      : option[singleInput.value]
                                  }
                                >
                                  {singleInput.endAdornment
                                    ? `${option[singleInput.value]} ${
                                        singleInput.endAdornment
                                      }`
                                    : option[singleInput.value]}
                                </MenuItem>
                              )
                            )}
                          {singleInput &&
                          singleInput.array &&
                          singleInput.array.length > 0 ? (
                            singleInput.array.map((option) =>
                              option.subheaderTiitle ? (
                                <ListSubheader style={{ pointerEvent: "none" }}>
                                  {singleInput.subheaderTiitle}
                                </ListSubheader>
                              ) : (
                                <MenuItem
                                  style={{ textTransform: "capitalize" }}
                                  key={option[singleInput.value]}
                                  value={option[singleInput.value]}
                                  id={
                                    option.id
                                      ? option.id
                                      : option[singleInput.value]
                                  }
                                >
                                  {singleInput.endAdornment
                                    ? `${option[singleInput.value]} ${
                                        singleInput.endAdornment
                                      }`
                                    : option[singleInput.value]}
                                </MenuItem>
                              )
                            )
                          ) : (
                            <MenuItem
                              disabled={true}
                              key={`No ${singleInput.name} added yet`}
                              value={`No ${singleInput.name} added yet`}
                              id={`No ${singleInput.name} added yet`}
                            >
                              {`No ${singleInput.name} added yet`}
                            </MenuItem>
                          )}
                        </CssTextField>
                      ) : singleInput.type === "Month" ? (
                        singleInput.enableFutureDates === true ? (
                          <MuiPickersUtilsProvider
                            libInstance={moment()}
                            utils={DateFnsUtils}
                          >
                            <CssKeyboardDatePicker
                              required={
                                singleInput.not_required
                                  ? !singleInput.not_required
                                  : true
                              }
                              disabled={
                                singleInput.disabled
                                  ? singleInput.disabled
                                  : false
                              }
                              variant="inline"
                              onOpen={() => {
                                this.setState({ [`calenderOpen${i}`]: true });
                              }}
                              onKeyUp={(ev) => {
                                ev.preventDefault();
                              }}
                              onKeyDown={(ev) => {
                                ev.preventDefault();
                              }}
                              onClose={() => {
                                this.setState({ [`calenderOpen${i}`]: false });
                              }}
                              style={
                                textFieldStyle
                                  ? textFieldStyle
                                  : textFieldWidth
                                  ? styles.withDivider502
                                  : styles.withDivider50
                              }
                              // disableFuture={singleInput.enableFutureDates === true ? false : true}
                              minDate={new Date()}
                              error={false}
                              invalidLabel=""
                              onKeyPress={(ev) => {
                                ev.preventDefault();
                              }}
                              allowKeyboardControl={false}
                              keyboardIcon={
                                this.state[`calenderOpen${i}`] ? (
                                  <TopArrow />
                                ) : (
                                  <BottomArrow />
                                )
                              }
                              //
                              autoOk
                              inputVariant="outlined"
                              label={singleInput.name}
                              format="do MMM, yyyy"
                              value={
                                submittedData[singleInput.name]
                                  ? submittedData[singleInput.name]
                                  : new Date()
                              }
                              views={singleInput.views && singleInput.views} //['year', 'month']}
                              InputAdornmentProps={{ position: "end" }}
                              onChange={(newValue) => {
                                const submittedData = this.state.submittedData;
                                submittedData[singleInput.name] = newValue;

                                this.setState({
                                  submittedData,
                                });
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        ) : (
                          <MuiPickersUtilsProvider
                            libInstance={moment()}
                            utils={DateFnsUtils}
                          >
                            <CssKeyboardDatePicker
                              disabled={
                                singleInput.disabled
                                  ? singleInput.disabled
                                  : false
                              }
                              variant="inline"
                              onOpen={() => {
                                this.setState({ [`calenderOpen${i}`]: true });
                              }}
                              onClose={() => {
                                this.setState({ [`calenderOpen${i}`]: false });
                              }}
                              maxDate={new Date()}
                              style={
                                textFieldStyle
                                  ? textFieldStyle
                                  : textFieldWidth
                                  ? styles.withDivider502
                                  : styles.withDivider50
                              }
                              // disableFuture={singleInput.enableFutureDates === true ? false : true}
                              // minDate={"2022-01-22"}
                              error={false}
                              invalidLabel=""
                              onKeyPress={(ev) => {
                             
                                ev.preventDefault();
                              }}
                              onKeyUp={(ev) => {
                                ev.preventDefault();
                              }}
                              onKeyDown={(ev) => {
                                ev.preventDefault();
                              }}
                              allowKeyboardControl={false}
                              keyboardIcon={
                                this.state[`calenderOpen${i}`] ? (
                                  <TopArrow />
                                ) : (
                                  <BottomArrow />
                                )
                              }
                              //
                              autoOk
                              inputVariant="outlined"
                              label={singleInput.name}
                              format="do MMM, yyyy"
                              value={
                                submittedData[singleInput.name]
                                  ? submittedData[singleInput.name]
                                  : new Date()
                              }
                              views={singleInput.views && singleInput.views} //['year', 'month']}
                              InputAdornmentProps={{ position: "end" }}
                              onChange={(newValue) => {
                                const submittedData = this.state.submittedData;
                                submittedData[singleInput.name] = newValue;

                                this.setState({
                                  submittedData,
                                });
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        )
                      ) : singleInput.type === "multiInput" ? (
                        <MultipleSelectBar
                          only_disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          freeSolo={
                            singleInput.freeSolo ? singleInput.freeSolo : false
                          }
                          width={
                            textFieldWidth
                              ? textFieldWidth
                              : "calc(100% - 20px)"
                          }
                          margin={
                            textFieldWidth
                              ? "10px"
                              : singleInput.freeSolo
                              ? "10px"
                              : "0 10px"
                          }
                          value={
                            submittedData[singleInput.name]
                              ? submittedData[singleInput.name]
                              : []
                          }
                          array={
                            singleInput &&
                            singleInput.array &&
                            singleInput.array.length > 0
                              ? singleInput.array
                              : []
                          }
                          placeholder={
                            singleInput.placeholder
                              ? singleInput.placeholder
                              : false
                          }
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : false
                          }
                          title={singleInput.value}
                          name={singleInput.name}
                          dataType={singleInput.dataType}
                          selectedArr={(val) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg[singleInput.name] = false;
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] = val;
                            if (singleInput.freeSolo) {
                              submittedData[`${singleInput.name}Id`] = val;
                            } else {
                              submittedData[`${singleInput.name}Id`] =
                                val &&
                                val.map((sA) => {
                                  return sA.id;
                                });
                            }
                            if (singleInput.onChange) {
                              singleInput
                                .onChange(
                                  submittedData,
                                  () => {
                                    this.setState({ loading: true });
                                  },
                                  () => {
                                    this.setState({ loading: false });
                                  }
                                )
                                .then((submittedData) => {
                                  this.setState({
                                    submittedData,
                                    errorMsg,
                                  });
                                });
                            } else {
                              this.setState({
                                submittedData,
                                errorMsg,
                              });
                            }
                          }}
                        />
                      ) : singleInput.type === "checkBox" ? (
                        <FormControlLabel
                          style={{ margin: "0 10px" }}
                          onChange={(newValue) => {
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] =
                              submittedData[singleInput.name] === true ||
                              submittedData[singleInput.name] === "Paid"
                                ? false
                                : true;

                            this.setState({
                              submittedData,
                            });
                          }}
                          control={
                            <Checkbox
                              checked={
                                submittedData[singleInput.name]
                                  ? submittedData[singleInput.name]
                                  : false
                              }
                            />
                          }
                          label={singleInput.name}
                        />
                      ) : singleInput.type === "toggle" ? (
                        <FormControlLabel
                          className="d-flex justifyFE"
                          style={{ margin: "5px", width: "100%" }}
                          control={
                            <Switch
                              disabled={editList ? false : true}
                              checked={
                                submittedData[singleInput.name]
                                  ? submittedData[singleInput.name]
                                  : false
                              }
                              onChange={(newValue) => {
                                const submittedData = this.state.submittedData;
                                submittedData[singleInput.name] =
                                  submittedData[singleInput.name] === true
                                    ? false
                                    : true;

                                this.setState({
                                  submittedData,
                                });
                              }}
                            />
                          }
                          label={
                            submittedData[singleInput.name]
                              ? "Active"
                              : "Inactive"
                          }
                        />
                      ) : singleInput.type === "term" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "1rem",
                            color: "grey",
                            background: "white",
                            minWidth: textFieldWidth
                              ? textFieldWidth
                              : "calc(25% - 20px)",
                            width: singleInput.array ? "100%" : "fit-content",
                            margin: "10px",
                            height: "48.98px",
                          }}
                        >
                          <div
                            className="height-100 d-flex alignC"
                            style={{
                              border: "1px solid #80808042",
                              padding: "0 15px",

                              width: "100%",
                              borderRadius: "5px",
                            }}
                          >
                            {" "}
                            <span style={{ color: "black" }}>
                              {singleInput.name}{" "}
                            </span>
                            <span style={{ margin: "0 10px" }}>
                              {singleInput.description}
                            </span>{" "}
                            {singleInput.array ? (
                              <>
                                <CssTextField85
                                  required={true}
                                  error={
                                    errorMsg[singleInput.name] ? true : false
                                  }
                                  helperText={
                                    errorMsg[singleInput.name]
                                      ? errorMsg[singleInput.name]
                                      : false
                                  }
                                  autoComplete="off"
                                  value={submittedData[singleInput.name]}
                                  disabled={
                                    singleInput.disabled
                                      ? singleInput.disabled
                                      : false
                                  }
                                  style={
                                    { width: "calc(15% - 5px)" } //disabled={tp}
                                  }
                                  id="outlined-select-currency-native"
                                  select
                                  label={
                                    singleInput.name.charAt(0).toUpperCase() +
                                    singleInput.name.slice(1)
                                  }
                                  SelectProps={{ disableunderline: "true" }}
                                  variant="outlined"
                                  onChange={(e) => {
                                    const id = `${singleInput.name}Id`;
                                    const submittedData =
                                      this.state.submittedData;
                                    submittedData[singleInput.name] =
                                      e.target.value;
                                    var errorMsg = this.state.errorMsg;
                                    errorMsg[singleInput.name] = false;
                                    submittedData[id] = e.currentTarget.id;

                                    this.setState({
                                      submittedData,
                                      errorMsg,
                                    });
                                  }}
                                >
                                  {singleInput.array &&
                                  singleInput.array.length > 0 ? (
                                    singleInput.array.map((option) => (
                                      <MenuItem
                                        key={option.days_range.toString()}
                                        value={option.days_range}
                                        id={option.id}
                                      >
                                        {option.days_range}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem
                                      disabled={true}
                                      key={`No ${singleInput.name} added yet`}
                                      value={`No ${singleInput.name} added yet`}
                                      id={`No ${singleInput.name} added yet`}
                                    >
                                      {`No ${singleInput.name} added yet`}
                                    </MenuItem>
                                  )}
                                </CssTextField85>

                                <span style={{ margin: "0 10px" }}>
                                  {singleInput.description2}
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  style={{
                                    margin: "0 5px",
                                    textTransform: "capitalize",
                                    fontWeight: "600",
                                  }}
                                >
                                  {submittedData[singleInput.field]
                                    ? submittedData[singleInput.field]
                                    : ""}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                {extraDetial ? extraDetial(submittedData) : ""}
              </div>
            </div>
          )}
        </div>
        {onlyView ? (
          ""
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="d-flex d-flex-column">
              {editList && dataAdded ? (
                ""
              ) : (
                <>
                  <StyledButton
                    onClick={() => {
                      this.reset();
                    }}
                    name={editList ? "Reset to Default" : "Reset"}
                    // withBg={true}
                    // background={primary}
                    // width="120px"
                    margin="0 10px 0 0"
                  />
                  <StyledButton
                    onClick={() => {
                      const { submittedData } = this.state;
                      let submitData = {}; //new FormData();
                      const correctData = [];
                      inputArray
                        .filter((sA) => sA.api)
                        .map((singleInput) => {
                    
                          if (
                            (singleInput.type === "checkBox" &&
                              (submittedData[singleInput.name] === false ||
                                submittedData[singleInput.name] === true)) ||
                            (singleInput.type === "toggle" &&
                              (submittedData[singleInput.name] === false ||
                                submittedData[singleInput.name] === true)) ||
                            singleInput.not_required === true ||
                            submittedData[singleInput.name]
                          ) {
                            if (
                              (singleInput.type === "toggle" &&
                                (submittedData[singleInput.name] === false ||
                                  submittedData[singleInput.name] === true)) ||
                              singleInput.not_required === true ||
                              (singleInput.validate
                                ? singleInput.validate(submittedData) === true
                                : (singleInput.onInput === "phone_number" &&
                                    validator.isMobilePhone(
                                      submittedData[singleInput.name]
                                    )) ||
                                  (singleInput.onInput !== "phone_number" &&
                                    singleInput.onInput !== "email") ||
                                  (singleInput.onInput === "email" &&
                                    validator.isEmail(
                                      submittedData[singleInput.name]
                                    )))
                            ) {
                              if (
                                (singleInput.onInput !== "gst_in" &&
                                  singleInput.onInput !== "email" &&
                                  singleInput.onInput !== "pincode") ||
                                (singleInput.onInput === "pincode" &&
                                  ((
                                    submittedData[singleInput.name] &&
                                    submittedData[singleInput.name]
                                  ).length === 6 ||
                                    !submittedData[singleInput.name])) ||
                                (singleInput.onInput === "gst_in" &&
                                  ((
                                    submittedData[singleInput.name] &&
                                    submittedData[singleInput.name]
                                  ).length === 15 ||
                                    !submittedData[singleInput.name])) ||
                                (singleInput.onInput === "email" &&
                                  validator.isEmail(
                                    submittedData[singleInput.name]
                                  ))
                              ) {
                                if (singleInput.type === "Month") {
                                  correctData.push(singleInput.name);

                                  if (singleInput.api) {
                                    submitData[singleInput.api] = moment(
                                      submittedData[singleInput.name]
                                    ).format("YYYY-MM-DD");
                                  }
                                } else {
                                  correctData.push(singleInput.name);

                                  if (singleInput.freeSolo) {
                                    if (
                                      submittedData[singleInput.name] &&
                                      submittedData[singleInput.name].length > 0
                                    ) {
                                      submitData[singleInput.api] =
                                        submittedData[singleInput.name];
                                    }
                                  }
                                  if (
                                    singleInput.api &&
                                    editList &&
                                    singleInput.onlyIfUpdated
                                  ) {
                                
                                    if (
                                      editList[singleInput.api] !==
                                      submittedData[singleInput.name]
                                    ) {
                                      submitData[singleInput.api] =
                                        singleInput.sendNameNotId
                                          ? submittedData[singleInput.name]
                                          : singleInput.sendName
                                          ? submittedData[singleInput.name].map(
                                              (s) => {
                                                return s[singleInput.value];
                                              }
                                            )
                                          : submittedData[
                                              `${singleInput.name}Id`
                                            ]
                                          ? submittedData[
                                              `${singleInput.name}Id`
                                            ] === "-"
                                            ? null
                                            : submittedData[
                                                `${singleInput.name}Id`
                                              ]
                                          : singleInput.dataType === "number"
                                          ? singleInput.onInput === "pincode" ||
                                            singleInput.onInput === "gst_in" ||
                                            singleInput.onInput ===
                                              "phone_number" ||
                                            singleInput.onInput === "fax_no"
                                            ? submittedData[singleInput.name]
                                            : submittedData[singleInput.name] // Number(submittedData[singleInput.name])
                                          : submittedData[singleInput.name] ===
                                            "-"
                                          ? null
                                          : submittedData[singleInput.name];
                                    }
                                  } else {
                                    if (singleInput.api) {
                                      submitData[singleInput.api] =
                                        singleInput.sendNameNotId
                                          ? submittedData[singleInput.name]
                                          : singleInput.sendName
                                          ? submittedData[singleInput.name].map(
                                              (s) => {
                                                return s[singleInput.value];
                                              }
                                            )
                                          : submittedData[
                                              `${singleInput.name}Id`
                                            ]
                                          ? submittedData[
                                              `${singleInput.name}Id`
                                            ] === "-"
                                            ? null
                                            : submittedData[
                                                `${singleInput.name}Id`
                                              ]
                                          : singleInput.dataType === "number"
                                          ? singleInput.onInput === "pincode" ||
                                            singleInput.onInput === "gst_in" ||
                                            singleInput.onInput ===
                                              "phone_number" ||
                                            singleInput.onInput === "fax_no"
                                            ? submittedData[singleInput.name]
                                            : submittedData[singleInput.name] // Number(submittedData[singleInput.name])
                                          : submittedData[singleInput.name] ===
                                            "-"
                                          ? null
                                          : submittedData[singleInput.name];
                                    }
                                  }
                                }
                              } else {
                                var errorMsg = this.state.errorMsg;
                                errorMsg[
                                  singleInput.name
                                ] = `Wrong ${singleInput.name} !`;
                                return this.setState({ errorMsg });
                              }
                            } else {
                              var errorMsg = this.state.errorMsg;
                              errorMsg[singleInput.name] = singleInput.validate
                                ? singleInput.validate(submittedData) === true
                                  ? ""
                                  : "Password do not match !"
                                : `Invalid ${singleInput.name} !`;
                              return this.setState({ errorMsg });
                            }
                          } else {
                            var errorMsg = this.state.errorMsg;
                            errorMsg[singleInput.name] = `Please ${
                              singleInput.type === "select" ? "select" : "add"
                            } ${singleInput.name} !`;
                            return this.setState({ errorMsg });
                          }
                        });
                      // if ((extraDetial && extraDetialValue && extraDetialValue.length > 0) || !extraDetial) {
                      if (
                        inputArray &&
                        inputArray.length &&
                        inputArray.filter((sA) => sA.api).length ===
                          correctData.length
                      ) {
                       
                        if (addFieldsToAdd && addFieldsToAdd.length > 0) {
                          addFieldsToAdd.map((sField) => {
                            submitData[sField.name] = sField.value;
                            return sField;
                          });
                        }

                        if (this.props.postDataToState) {
                          if (
                            this.props.checkAllFields &&
                            this.props.checkAllFields() === true
                          ) {
                            this.setState({ loading: true });
                            this.props.postDataToState(submittedData);
                          }
                        } else {
                          this.setState({ loading: true });
                          if (
                            (this.props.checkAllFields &&
                              this.props.checkAllFields() === "success") ||
                            !this.props.checkAllFields
                          ) {
                            this.props

                              .postData(
                                editList ? "patch" : "post",
                                editList
                                  ? this.props.editApi
                                  : this.props.postApi,
                                editList ? selectedList.id : null,
                                "postdata",
                                this.props.formatData
                                  ? this.props.formatData(
                                      submittedData,
                                      editList
                                    )
                                  : submitData
                              )
                              .then((res) => {
                                if (res.error) {
                                  this.setState({
                                    error:
                                      typeof res.data === "string"
                                        ? res.data
                                        : res.data.length > 0
                                        ? res.data
                                            .map((s) => {
                                              return `${
                                                s.field ? `${s.field} :` : ""
                                              } ${
                                                typeof s.message === "string"
                                                  ? s.message
                                                  : s.message.length > 0 &&
                                                    s.message[0] &&
                                                    s.message[0]
                                              }`;
                                            })
                                            .join(" ,")
                                        : res.data.response
                                        ? res.data.response.data.msg.error
                                          ? res.data.response.data.msg.error
                                          : res.data.response.data.msg
                                        : "Error !",
                                    loading: false,
                                  });
                                } else {
                                  this.props
                                    .postData("get", "users", editList.id)
                                    .then((res) => {
                                      const modulePermission =
                                      res.user_accesses&&res.user_accesses.length > 0
                                        ? res.user_accesses[0].modules.filter((m)=>{
                                          const user_widgets_list = m.widgets.filter(
                                            (widget) =>
                                              widget.widget.has_create_permission ===
                                                true ||
                                              widget.widget.has_update_permission ===
                                                true ||
                                              widget.widget.has_read_permission === true
                                          );
                                     
                                          return user_widgets_list.length>0?true:false
                                        }).map(
                                            (module) => module.module.module_name 
                                          )??[]
                                        : [];
                                      if (res && res.id) {
                                        const users = {
                                          uid: editList.uid,
                                          username: res.first_name,
                                          email: res.email,
                                          userData: editList,
                                          ...res,
                                          roles: res.role,
                                          accessibleModules: res.user_accesses?modulePermission:res.modules_access,
                                           user_accesses:res.user_accesses??res.access_details,
                                                       credential_id: res.credential_id,
                                          id: res.id,
                                          isLoggedIn: true,
                                          status: res.status,
                                          miller: res.miller,
                                          broker: res.broker,
                                        };
                                        const localStorageUser = {
                                          uid: editList.uid,
                                          id: res.id,
                                          ...res,
                                          username: res.first_name,
                                          email: res.email,
                                          roles: res.role,
                                                      credential_id: res.credential_id,
                                          isLoggedIn: true,
                                          accessibleModules: res.user_accesses?modulePermission:res.modules_access,
                                          user_accesses:res.user_accesses??res.access_details,
                                          status: res.status,
                                          miller: res.miller,
                                          broker: res.broker,
                                        };
                                        localStorage.setItem(
                                          "briuser",
                                          JSON.stringify(localStorageUser)
                                        );
                                        if (this.props.dispatch) {
                                          this.props.dispatch({
                                            type: "AUTH_USER_LOGGEDIN",
                                            payload: users,
                                          });
                                        }
                                        this.setState({ loading: false });
                                      }
                                    });
                                }
                              });
                          }
                        }
                      }
                    }}
                    name={editList ? "Update" : "Add"}
                    withBg={true}
                    background={"rgb(241 154 48)"}
                    width="120px"
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SignINlayout;
