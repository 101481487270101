import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect, useDispatch } from "react-redux";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { primary } from "../styles/colors";
import "../styles/styles.css";
import { useNavigate } from "react-router-dom";
import { BrandsManagementsFunction } from "../../src/redux";
import {
  StyledCircularProgress,
  StyledSnackbar,
} from "../styles/StyledComponents";
import { Backdrop } from "@material-ui/core";
import SignINlayout from "../layoutComponents/SignINlayout";
import { stateArray } from "../Defaults";
const theme = createTheme({
  palette: {
    primary: {
      light: primary,
      main: primary,
      dark: primary,
    },
    secondary: {
      light: primary,
      main: primary,
      dark: primary,
    },
  },
  status: {
    danger: "orange",
  },
});

function SiginComponent(props) {
  const navigate = useNavigate();
  const { user } = props;
  const [error, setError] = useState(false);
  const [loader, setloader] = useState(false);
  const dispatch = useDispatch();
  const [openDialog, setDialog] = useState(false);
  const editList = { id: user.user.uid, ...user.user };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {error && (
        <StyledSnackbar
          error={error ? error : false}
          message={error}
          handleCloseSnackbar={() => {
            setError(false);
          }}
        />
      )}
      {loader === true ? (
        <Backdrop style={{ zIndex: 1, color: "#fff" }} open={loader}>
          <StyledCircularProgress />
        </Backdrop>
      ) : (
        ""
      )}
      <div className="width-100 d-flex justifyC alignC height100vh">
        <SignINlayout
          dispatch={dispatch}
          noAddAnother={true}
          textFieldWidth="calc(50% - 20px)"
          heightNew="100vh"
          maxHeight="calc(100vh - 40px)"
          widthNew="100%"
          onEditUpdate={(res) => {}}
          module={"Signup"}
          formatData={(sub, edit) => {
            return {
              id: edit.id,
              first_name: sub["Name"],
              phone: sub["phone number"],
              profile_details: {
                name: sub["Name"],
                phone: sub["phone number"],
                address: sub["address"],
                city: sub["city"],
                state: sub["state"],
                pincode: sub["pincode"],
              },
            };
          }}
          selectedList={editList}
          editList={editList}
          handleClose={() => {
            setDialog(true);
          }}
          postApi="users/list"
          editApi="users/user"
          inputArray={[
            {
              name: "Name",
              type: "textField",
              dataType: "text",
              api: "first_name",
              defaultValue: {
                value: editList && editList.name,
              },
            },
            {
              name: "phone number",
              type: "textField",
              dataType: "number",
              onInput: "phone_number",
              not_required: true,
              only_number: true,
              api: "phone",
              defaultValue: {
                value: editList && editList.phone,
              },
            },
            {
              name: "address",
              type: "addressTextField",
              dataType: "text",
              api: "address",
              defaultValue: {
                value: editList && editList.address,
              },
            },

            {
              name: "state",
              type: "select",
              array: stateArray,
              value: "name",
              api: "state",
              defaultValue: {
                value: editList && editList.state,
              },
            },
            {
              name: "city",
              type: "textField",
              dataType: "text",
              api: "city",
              onKeyPress: "textOnly",
              defaultValue: {
                value: editList && editList.city,
              },
            },

            {
              name: "pincode",
              type: "textField",
              onInput: "pincode",
              dataType: "number",
              api: "pincode",
              only_number: true,
              defaultValue: {
                value: editList && editList.pincode,
              },
            },
          ]}
          
          postData={props.BrandsManagementsFunction}
          fetchAgain={() => {
            // props.BrandsManagementsFunction;
          }}
        />
      </div>
    </MuiThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication,
});
const mapDispatchToProps = { BrandsManagementsFunction };

export default connect(mapStateToProps, mapDispatchToProps)(SiginComponent);
