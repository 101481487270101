import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TableComponent from "../../layoutComponents/TableComponent";
import ReactDOM from "react-dom";
import { EndPoints } from "../../utils/EndPoints";
import NoData from "../../styles/Illustrations/NoData";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import TabComponent from "../../layoutComponents/TabComponent";
import MainView from "../../layoutComponents/MainView";
import {
  primary,
  white,
  red,
  mostLightGrey,
  lightGrey,
} from "../../styles/colors";
import {
  Divider,
  Paper,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  MenuItem,
  Tooltip,
  Button,
  Backdrop,
  DialogContent,
  Checkbox,
} from "@material-ui/core";
import { CrossIcon, PlusIcon } from "../../styles/Icons";
import EditIcon from "@mui/icons-material/Edit";
import {
  CssKeyboardDatePicker,
  CssTextField,
  CssTextField95,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { filterData, getSingleModule_widget } from "../../Regex";
import Offers from "./Offers";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MouseOverPopover from "./MouseOverPopover";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { accessText } from "../../routes/sidebar";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
};
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "0 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
export class RatesComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Rates"],
        tooltip: `Click to approve pending ${EndPoints["Rates"]}`,
        data: [],
        route: `/Rates/${EndPoints["Rates"].split("/")[0]}`,
        goBackRoute: "/Rates",
        primaryField: "category_Weight",
        primarySecondValue: "amount",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "amount_unit",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        // avatarField: "src",
        salesList: true,
        onlySecondary: true,
        dispatchList: true,
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "green", "red"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      Rates: [],
      listViewArray: [],
      tabList: [],
      singlelistView: null,
      loading: false,
      openDialog: false,
      unit: [{ name: "Kgs" }, { name: "MT" }, { name: "Qtl" }],
      ItemCateoryName: "",
      ItemTypeName: "",
      Unit: "",
      Amount: "",
      Quantity: "",
      MillerName: "",
      MillerNumber: "",
      ItemCateoryNameId: "",
      ItemTypeNameId: "",
      ContractTypeList: "",
      ContractTypeListId: "",
      UnitId: "",
      error: "",
      success: "",
      editList: false,
      editDialog: false,
      loadingRight: false,
      salesintent: false,
      defaultTab: 0,
      salesIntentLIst: [],
      loader: false,
      addDailog: false,
      offersNav: false,
      contractType: "",
      MillerList: [],
      userRole:
        this.props.user && this.props.user.roles
          ? this.props.user.roles.toString().toLowerCase()
          : "",
      selectedRate: "",
      selctedTab: "details",
      fetchFirst: true,
      loadingPurchaseItent: true,
      anchorEl: null,
      errorMsg: {
        Unit: false,
        ItemCateoryName: false,
        ItemTypeName: false,
        Amount: false,
        Quantity: false,
        ContractTypeList: false,
      },
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    if (
      (this.state.windowWidth &&
        this.state.windowWidth === window.innerWidth) ||
      !this.state.windowWidth
    ) {
      this.setState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });

      this.setInitialValues(next);
    }
  }
  UNSAFE_componentWillMount() {
    this.props.RatesFunction("get", EndPoints["Rates"]).then(() => {});
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Rates"] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {
            if (this.state.singlelistView && this.state.singlelistView.id) {
              this.props.RatesFunction(
                "get",
                EndPoints["Rates"],
                this.state.singlelistView.id,
                "Rates"
              );
            }
          }
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }
  handlePopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };
  fetchPurchaseRate = async (category, page) => {
    console.log(category, page, "categorycategory");

    this.setState({ loadingPurchaseItent: true });
    this.props
      .RatesFunction(
        "get",
        page
          ? `dispatch/purchase-rate/list/?is_active=True&page=${page}&`
          : "dispatch/purchase-rate/list",
        null,
        //state.singlelistView.id,
        "PurchaseRate",
        null,
        category
          ? { is_active: "True", item_category: category.id }
          : { is_active: "True" },
        null,
        page
          ? `https://ssipldev.grainbyte.app/bri/api/dispatch/purchase-rate/list/?is_active=True&item_category=${category.id}&page=${page}`
          : null
      )

      .then((res) => {
        this.setState({
          loadingPurchaseItent: false,
          listViewArray: res,
          fetchFirst: false,
          list_pagination: {
            count: res.count,
            loadMore: (page) => {
              this.fetchPurchaseRate(category, page);
            },
          },
        });
      });
  };
  fetchPurchaseIntent = async (value, page) => {
    console.log(
      page
        ? `https://ssipldev.grainbyte.app/bri/api/dispatch/sales-intent/list/?page=${page}&purchase_rate=${value.id}`
        : null,
      "pagepage"
    );
    this.props
      .RatesFunction(
        "get",
        page
          ? `dispatch/sales-intent/list/?page=${page}&`
          : "dispatch/sales-intent/list",
        null,
        "purchaseIntentList",
        null,
        {
          purchase_rate: value.id,
        },
        null,
        page
          ? `https://ssipldev.grainbyte.app/bri/api/dispatch/sales-intent/list/?page=${page}&purchase_rate=${value.id}`
          : null
        // `https://ssipldev.grainbyte.app/bri/api/dispatch/purchase-rate/list/?is_active=True&item_category=${value.id}&page=${page}`
      )
      .then((purchaseIntentList) => {
        if (purchaseIntentList && purchaseIntentList.results.length > 0) {
          // const data =purchaseIntentList.results??[]
          this.setState({
            loadingDispatch: false,
            purchaseIntentList,
            pagination: {
              count: purchaseIntentList.count,
              loadMore: (page) => {
                this.fetchPurchaseIntent(value, page);
              },
            },
          });
        } else {
          this.setState({
            loadingDispatch: false,
            purchaseIntentList: [],
            pagination: {
              count: purchaseIntentList.count,
              loadMore: (page) => {
                this.fetchPurchaseIntent(value, page);
              },
            },
          });
        }
      });
  };
  // listClickHandler = (value) => {
  //   console.log(value, "jjjjjjjjjjjj");
  //   this.setState({
  //     singlelistView: value,
  //   });
  //   // this.props
  //   //   .RatesFunction(
  //   //     "get",
  //   //     "dispatch/sales-intent/list",
  //   //     null,
  //   //     "salesIntentList",
  //   //     null,
  //   //     {
  //   //       purchase_rate: this.state.singlelistView.id,
  //   //     }
  //   //   )
  //   //   .then((SalesIntentList) => {
  //   //     if (SalesIntentList && SalesIntentList.length > 0) {
  //   //       this.setState({ loadingDispatch: false, SalesIntentList });
  //   //     }

  //   //   });
  //     this.props
  //     .RatesFunction(
  //       "get",
  //       "masters/miller/list",
  //       null,
  //       "MillerList",
  //       null,
  //       { is_active: "True", profile_used: "True" }
  //     )
  //     .then((MillerList) => {
  //       this.setState({ MillerList, loading: false });
  //       console.log(MillerList, "SalesIntentListf2ewtrhy");
  //     });
  // };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  handleBackButtonClick = (childState) => {
    this.setState({ offersNav: childState });
  };
  setInitialValues(props) {
    const { masters } = props;

    const results =
      props[EndPoints["Rates"]] &&
      props[EndPoints["Rates"]].results &&
      props[EndPoints["Rates"]].results.length > 0
        ? props[EndPoints["Rates"]].results
        : [];
    const dataArr =
      results && results.length > 0
        ? results.map((s) => {
            return {
              ...s,
              category_Weight: `₹${s.amount}/ ${s.unit}`,

              amount_unit: `${
                filterData(
                  masters.ItemCateoryList,
                  "id",
                  Number(s.item_category),
                  "onlyone"
                )
                  ? filterData(
                      masters.ItemCateoryList,
                      "id",
                      Number(s.item_category),
                      "onlyone"
                    ).name
                  : ""
              } - W ${s.required_qty} `,
              // ${moment(
              //   s.created_at
              // ).format("YY-MM-DD")}`,
              // ${
              //   filterData(
              //     masters.ItemList,
              //     "id",
              //     Number(s.item_type),
              //     "onlyone"
              //   )
              //     ? filterData(
              //         masters.ItemList,
              //         "id",
              //         Number(s.item_type),
              //         "onlyone"
              //       ).broken_percentage
              //     : ""
              // }
            };
          })
        : [];
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data = dataArr.sort((a, b) => {
        var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
        var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
        return new Date(dateA) - new Date(dateB);
      });

      return tab;
    });

    if (
      masters.ItemCateoryList &&
      masters.ItemCateoryList.length > 1 &&
      this.state.fetchFirst === true
    ) {
      this.fetchPurchaseRate(masters.ItemCateoryList[this.state.defaultTab]);
    }

    // if (
    //   masters.ItemList &&
    //   masters.ItemList.length > 1 &&
    //   this.state.fetchFirst === true
    // ) {
    //   this.fetchPurchaseRate(masters.ItemList[this.state.defaultTab]);
    // }
    if (masters.ItemCateoryList && masters.ItemCateoryList.length > 0) {
      this.fetchPurchaseRate(masters.ItemCateoryList[this.state.defaultTab]);
    }

    this.setState({
      userRole:
        props.user && props.user.roles
          ? props.user.roles.toString().toLowerCase()
          : "admin",
      loader: props.loader,
      tabList: masters.ItemCateoryList,
      tabListItemType: masters.ItemList,
      tabListContractType: masters.ContracrTypeList,
      // Rates: dataArr,
      tabs,
      loading: props.loading,
    });
  }
  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };

  rateHandle = () => {
    this.setState({
      addDailog: true,
      ItemCateoryName: "",
      ItemTypeName: "",
      Rate: "",
      Amount: "",
      Quantity: "",
      Unit: "",
      ContractTypeList: "",
    });
  };
  handleClose = () => {
    this.setState({ addDailog: false });
  };
  render() {
    const {
      tabs,
      listViewArray,
      tabList,
      editList,
      loading,
      openDialog,
      AddRate,
      unit,
      ItemCateoryName,
      ItemTypeName,
      Unit,
      Amount,
      Quantity,
      errorMsg,
      ItemCateoryNameId,
      ItemTypeNameId,
      contractType,

      ContractTypeList,
      ContractTypeListId,
      editDialog,
      error,
      success,
      loadingRight,
      selectedRate,
      loader,
      userRole,
      salesintent,
      MillerName,
      MillerNumber,
      loadingSalesItent,
      salesIntentLIst,
      loadingPurchaseItent,
      tabListItemType,
      tabListContractType,
      addDailog,
      offersNav,
      anchorEl,
      SalesIntentList,
      MillerList,
    } = this.state;

    const open = Boolean(anchorEl);
    const { masters, ItemList } = this.props;
    console.log(masters.ContracrTypeList, "nnnnnnnnnnnnnn");
    var labelField = "title";
    const { widget_permission } = getSingleModule_widget(
      this.props.user,
      accessText.purchase_order,
      accessText.rates
    );
    // console.log(this.props.user.user_accesses, "user.user_accesses");
    return (
      <Layout
        loading={loading[EndPoints["Rates"]]}
        // tabs={userRole !== "admin" ? tabs : undefined}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Rates`,
          labelField: labelField,
        }}
        loading={
          loading[EndPoints["Rates"]]
            ? loading[EndPoints["Rates"]] === true
              ? true
              : loader === true
              ? true
              : false
            : loader === true
            ? true
            : false
        }
        // listClickHandler={this.listClickHandler}
        centerWidth={userRole == "admin" ? "0" : "0"}
        openWidth={userRole == "admin" ? "235px" : "235px"}
        closeWidth={userRole == "admin" ? "57px" : "57px"}>
        {loading[EndPoints["Rates"]] === true ? (
          <div className="alignC justiyC d-flex width-100 height-100">
            <StyledCircularProgress color="rgb(241, 154, 48)" />
          </div>
        ) : offersNav && userRole == "admin" ? (
          <Offers
            RatesFunction={this.props.RatesFunction}
            selectedRate={selectedRate}
            tabList={this.state.purchaseIntentList?.results ?? []}
            tabListItemType={this.state.purchaseIntentList?.results ?? []}
            purchaseIntentList={this.state.purchaseIntentList?.results ?? []}
            pagination={this.state.pagination}
            ItemCateoryList={masters.ItemCateoryList}
            ItemList={masters.ItemList}
            onBackButtonClick={this.handleBackButtonClick}
            fetchAgain={() => {
              this.fetchPurchaseIntent(selectedRate);
            }}
          />
        ) : (
          <Paper className="width-100 height-100 backgroundWhite">
            <MainView
              module={"Rate"}
              noAddButton={
                userRole === "admin"
                  ? false
                  : widget_permission?.widget?.has_create_permission
                  ? true
                  : false
              }
              pagination={this.state.list_pagination}
              tableListRate={
                listViewArray.results && listViewArray.results.length > 0
                  ? listViewArray.results.map((data) => {
                      data["Date"] = moment(data.date).format(
                        "ddd, MMM Do YYYY"
                      );
                      data["Type"] =
                        tabListItemType &&
                        tabListItemType.length > 0 &&
                        tabListItemType.filter(
                          (data1) => data1.id === data.item_type
                        ) &&
                        tabListItemType.filter(
                          (data1) => data1.id === data.item_type
                        )[0] &&
                        tabListItemType.filter(
                          (data1) => data1.id === data.item_type
                        )[0].broken_percentage
                          ? tabListItemType.filter(
                              (data1) => data1.id === data.item_type
                            )[0].broken_percentage
                          : "-";
                      data["Purchase Rate No"] = `${
                        data.purchase_rate_no ? data.purchase_rate_no : "-"
                      }`;

                      data["Rate"] = `${
                        data.amount ? data.amount + "/" + data.unit : "-"
                      }`;
                      data["Required qty"] = `${
                        data.required_qty
                          ? data.required_qty + " " + data.unit
                          : "-"
                      }`;
                      data["Contract Type"] =
                        masters.ContracrTypeList &&
                        masters.ContracrTypeList.length > 0 &&
                        masters.ContracrTypeList.filter(
                          (data1) => data1.id === data.contract_type
                        ) &&
                        masters.ContracrTypeList.filter(
                          (data1) => data1.id === data.contract_type
                        )[0] &&
                        masters.ContracrTypeList.filter(
                          (data1) => data1.id === data.contract_type
                        )[0].contract_type
                          ? masters.ContracrTypeList.filter(
                              (data1) => data1.id === data.contract_type
                            )[0].contract_type
                          : "-";
                      data[
                        userRole !== "miller" &&
                        userRole !== "broker" &&
                        userRole !== "client"
                          ? "Purchased Quantity"
                          : ""
                      ] = `${
                        data.purchased ? data.purchased + " " + data.unit : "-"
                      }`;

                      data["Pending Quantity"] = `${
                        data.pending ? data.pending + " " + data.unit : "-"
                      }`;

                      data[userRole === "admin" ? "Offers" : ""] = () => {
                        return (
                          <>
                            <div
                              className="linkStyle"
                              onClick={() => {
                                this.fetchPurchaseIntent(data);
                                this.setState({
                                  offersNav: true,
                                  selectedRate: data,
                                });
                              }}>{`${data.offers + " " + "OFFERS"}`}</div>
                            {/* <div style={{ margin: "5px 0 0 5px" }}>
                            <MouseOverPopover
                              msg={
                                <InfoOutlinedIcon
                                  fontSize="small"
                                  style={{ color: "rgb(241, 154, 48)" }}
                                />
                              }
                              details={
                                <div className="column-content">
                                  <Typography variant="subtitle1">
                                    Item Category : hgfjh
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    {" "}
                                    Item Type :
                                  </Typography>
                                  <Typography variant="subtitle1">
                                    {" "}
                                    Quantity :
                                  </Typography>
                                </div>
                              }
                            />
                          </div> */}
                          </>
                        );
                      };
                      data["Status"] = data.status;
                      data[
                        userRole === "admin" ||
                        userRole === "accountant" ||
                        userRole === "manager"
                          ? "Action"
                          : ""
                      ] = () => {
                        return userRole === "admin" ||
                          userRole === "accountant" ||
                          userRole === "manager" ? (
                          <>
                            <div
                              onClick={() => {
                                this.setState({
                                  openDialog: true,
                                  editList: data,
                                  ItemCateoryName:
                                    masters.ItemCateoryList.find(
                                      (s) => s.id === data.item_category
                                    )?.name ?? "",
                                  ItemCateoryNameId: data.item_category,
                                  ItemTypeName:
                                    masters.ItemList.find(
                                      (s) => s.id === data.item_type
                                    )?.broken_percentage ?? "",
                                  ItemTypeNameId: data.item_type,
                                  Unit: data.unit,
                                  Quantity: data.required_qty,
                                  ContracrTypeList: "",
                                  Amount: data.amount,
                                  ContractTypeList:
                                    masters.ContracrTypeList.find(
                                      (s) => s.id === data.contract_type
                                    )?.contract_type ?? "",
                                  ContractTypeListId: data.contract_type,
                                });
                              }}>
                              <Tooltip title="Edit">
                                <EditIcon
                                  fontSize="small"
                                  // onClick={() => {
                                  //   this.setState({
                                  //     editDialog: true,
                                  //     editList: singlelistView,
                                  //   });
                                  // }}
                                  style={{
                                    cursor: "pointer",
                                    color: "rgb(241, 154, 48)",
                                  }}
                                />
                              </Tooltip>
                            </div>
                          </>
                        ) : (
                          "-"
                        );
                      };

                      return data;
                    })
                  : []
              }
              header={[
                "Sr.No.",
                "Date",
                "Purchase Rate No",
                "Type",
                "Rate",

                "Required qty",
                "Contract Type",
                "Purchased Quantity",
                "Pending Quantity",
                // "Purchased",
                // "Pending",
                "Offers",
                "Status",
                "Action",
              ]}
              actions={[]}
              rateHandle={() => {
                this.rateHandle();
              }}
              // handleClose={()=>{

              //   this.handleClose(
              selectedValue={this.state.defaultTab}
              onChange={(newVal, newValue) => {
                const selctedCat = newVal;

                this.setState({ defaultTab: newValue });
                this.fetchPurchaseRate(selctedCat);
              }}
              list={
                tabList && tabList.length
                  ? tabList.map((data) => {
                      // data["Date"] = moment(data.date).format("ddd, MMM Do YYYY");
                      return data;
                      // data["Dispatch DateMonth"] =
                      //   data.date;
                    })
                  : []
              }
              tabelLoading={loadingPurchaseItent}
            />
          </Paper>
        )}

        {error || success ? (
          <SnackbarComponent
            error={error}
            autoHideDuration={error ? 5000 : 2000}
            success={success}
            snackbarClose={() => {
              this.setState({
                error: "",
                success: "",
              });
            }}
          />
        ) : (
          ""
        )}
        {openDialog !== false || editDialog !== false || addDailog === true ? (
          // add rate dialog
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "20px";
                target.style.maxHeight = "calc(100vh - 40px)";
                target.style.minHeight = "calc(100vh - 40px)";
                target.style.height = "calc(100vh - 40px)";
                target.style.minWidth = "calc(40% - 100px)";
                target.style.maxWidth = "calc(40% - 100px)";
                target.style.margin = "32px 50px";

                //                 heightNew="590px"
                // maxHeight="calc(100vh - 140px)"
                // widthNew="400px"
                // borderRadius="20px"
              } catch (e) {}
            }}
            open={true}
            onClose={() => {
              this.setState({
                openDialog: false,
                editDialog: false,
                addDailog: false,
              });
            }}
            TransitionComponent={Transition}>
            <DialogActions
              className="d-flex justifySB"
              style={{
                background: primary,
                color: "white",
                padding: "10px 24px",
              }}
              onClick={() => {
                this.setState({
                  openDialog: false,
                  editDialog: false,
                  addDailog: false,
                });
              }}>
              <h3 style={styles.heading}>
                {this.state.editList ? "Update Rate" : "Add Rates"}
              </h3>
              <Tooltip title="close" arrow placement="top">
                <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                  <CrossIcon strokeWidth="2" />
                </IconButton>
              </Tooltip>
            </DialogActions>
            <DialogContent
              className="dashedDividerBottom  width-100 d-flex alignC justifyC d-flex-column"
              style={{ height: "100%" }}>
              {error || success ? (
                <SnackbarComponent
                  error={error}
                  autoHideDuration={error ? 5000 : 2000}
                  success={success}
                  snackbarClose={() => {
                    this.setState({
                      error: "",
                      success: "",
                    });
                  }}
                />
              ) : (
                ""
              )}
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}>
                {labelText("Contract Type")}
                <CssTextField
                  required={true}
                  disabled={openDialog !== false ? true : false}
                  autoComplete="off"
                  value={
                    ContractTypeList ? ContractTypeList : "" //
                  }
                  error={errorMsg.ContractTypeList ? true : false}
                  helperText={
                    errorMsg.ContractTypeList
                      ? "Enter valid ContractType"
                      : false
                  }
                  onChange={(e) => {
                    const errorMsg = this.state.errorMsg;
                    errorMsg.ContractTypeList = false;
                    this.setState({
                      errorMsg,
                      ContractTypeListId: e.currentTarget.id,
                      ContractTypeList: e.target.value,
                    });
                  }}
                  style={{ width: "100%" }}
                  id="contract_type"
                  select
                  // label="Item Category"
                  variant="outlined">
                  {masters.ContracrTypeList &&
                    masters.ContracrTypeList.length > 0 &&
                    masters.ContracrTypeList.map((option) => (
                      <MenuItem
                        key={option.contract_type.toString()}
                        value={option.contract_type}
                        id={option.id}>
                        {option.contract_type}
                      </MenuItem>
                    ))}
                </CssTextField>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}>
                {labelText("Item Category")}
                <CssTextField
                  required={true}
                  disabled={openDialog !== false ? true : false}
                  autoComplete="off"
                  value={
                    ItemCateoryName ? ItemCateoryName : "" //
                  }
                  error={errorMsg.ItemCateoryName ? true : false}
                  helperText={
                    errorMsg.ItemCateoryName ? errorMsg.ItemCateoryName : ""
                  }
                  onChange={(e) => {
                    const errorMsg = this.state.errorMsg;
                    errorMsg.ItemCateoryName = false;
                    this.setState({
                      errorMsg,
                      ItemCateoryNameId: e.currentTarget.id,
                      ItemCateoryName: e.target.value,
                    });
                  }}
                  style={{ width: "100%" }}
                  id="name"
                  select
                  // label="Item Category"
                  variant="outlined">
                  {masters.ItemCateoryList &&
                    masters.ItemCateoryList.length > 0 &&
                    masters.ItemCateoryList.map((option) => (
                      <MenuItem
                        key={option.name.toString()}
                        value={option.name}
                        id={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </CssTextField>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}>
                {labelText("Item Type")}
                <CssTextField
                  required={true}
                  disabled={openDialog !== false ? true : false}
                  autoComplete="off"
                  value={
                    ItemTypeName ? ItemTypeName : "" //width: "80%"
                  }
                  error={errorMsg.ItemTypeName ? true : false}
                  helperText={
                    errorMsg.ItemTypeName ? errorMsg.ItemTypeName : ""
                  }
                  onChange={(e) => {
                    const errorMsg = this.state.errorMsg;
                    errorMsg.ItemTypeName = false;
                    this.setState({
                      errorMsg,
                      ItemTypeNameId: e.currentTarget.id,
                      ItemTypeName: e.target.value,
                    });
                  }}
                  style={{ width: "100%" }}
                  // style={{ width: "100%" }}
                  id="broken_percentage"
                  select
                  // label="Item Type"
                  variant="outlined">
                  {masters.ItemList &&
                    masters.ItemList.length > 0 &&
                    masters.ItemList.map((option) => (
                      <MenuItem
                        key={option.broken_percentage.toString()}
                        value={option.broken_percentage}
                        id={option.id}>
                        {option.broken_percentage}
                      </MenuItem>
                    ))}
                </CssTextField>
              </div>{" "}
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}>
                {labelText("Unit")}
                <CssTextField
                  required={true}
                  disabled={openDialog !== false ? true : false}
                  autoComplete="off"
                  value={
                    Unit ? Unit : "" //
                  }
                  error={errorMsg.Unit ? true : false}
                  helperText={errorMsg.Unit ? errorMsg.Unit : ""}
                  style={{ width: "100%" }}
                  id="name"
                  select
                  //label="Unit"
                  variant="outlined"
                  onChange={(e) => {
                    const errorMsg = this.state.errorMsg;
                    errorMsg.Unit = false;
                    this.setState({
                      errorMsg,
                      Unit: e.target.value,
                    });
                  }}
                  InputLabelProps={{}}>
                  {unit &&
                    unit.length > 0 &&
                    unit.map((option) => (
                      <MenuItem
                        key={option.name.toString()}
                        value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                </CssTextField>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}>
                {labelText("Quantity", true)}
                <CssTextField
                  autoComplete="off"
                  // required={true}
                  required={false}
                  id="outlined-basic"
                  error={errorMsg.Quantity ? true : false}
                  helperText={
                    errorMsg.Quantity ? "Enter valid Quantity" : false
                  }
                  style={{ width: "100%" }}
                  // style={{width:"100%"}}
                  // label="Quantity"
                  type="number"
                  name="Quantity"
                  onKeyDown={(evt) => {
                    // if (singleInput.only_number === true) {
                    //   evt.key === "." && evt.preventDefault();
                    // }
                    evt.key === "e" && evt.preventDefault();

                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    evt.keyCode === 38 && evt.preventDefault();
                    evt.keyCode === 40 && evt.preventDefault();
                  }}
                  InputProps={{
                    endAdornment: Unit,
                  }}
                  value={Quantity}
                  onChange={this.handleChange}
                  variant="outlined"
                  // disabled={true}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}>
                {labelText("Amount")}
                <CssTextField
                  autoComplete="off"
                  required={true}
                  disabled={openDialog !== false ? true : false}
                  id="outlined-basic"
                  error={errorMsg.Amount ? true : false}
                  helperText={errorMsg.Amount ? "Enter valid Amount" : false}
                  style={{ width: "100%" }}
                  // label="Amount"
                  type="number"
                  name="Amount"
                  value={Amount}
                  onKeyDown={(evt) => {
                    // if (singleInput.only_number === true) {
                    //   evt.key === "." && evt.preventDefault();
                    // }
                    evt.key === "e" && evt.preventDefault();

                    evt.key === "E" && evt.preventDefault();
                    evt.key === "-" && evt.preventDefault();
                    evt.keyCode === 38 && evt.preventDefault();
                    evt.keyCode === 40 && evt.preventDefault();
                  }}
                  InputProps={{
                    startAdornment: "₹",
                    endAdornment: "/" + Unit,
                  }}
                  onChange={this.handleChange}
                  variant="outlined"
                  // disabled={true}
                />
              </div>
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                minHeight: "52.5px",
              }}>
              <StyledButton
                onClick={() => {
                  // handleSnack();i
                  if (ContractTypeList) {
                    if (ItemCateoryName) {
                      if (ItemTypeName) {
                        if (Unit) {
                          if (Quantity) {
                            if (Amount) {
                              this.setState({
                                loadingFun: true,
                              });

                              const addData = {
                                amount: Amount,
                                unit: Unit,
                                required_qty: Quantity,
                                is_active: true,
                                item_category: ItemCateoryNameId,
                                item_type: ItemTypeNameId,
                                contract_type: ContractTypeListId,
                              };
                              if (editList !== false) {
                                addData["id"] = editList ? editList.id : "";
                              }
                              this.props
                                .RatesFunction(
                                  // salesintent == true ? "get" : "post",
                                  editList !== false ? "patch" : "post",
                                  // salesintent==true?"dispatch/sales-intent/list":"dispatch/sales-intent/create",
                                  editList !== false
                                    ? "dispatch/purchase-rate"
                                    : "dispatch/purchase-rate/list",
                                  editList !== false ? editList.id : null,
                                  null,

                                  addData,
                                  null,

                                  "noeditparam"
                                )
                                .then((res) => {
                                  if (res.error || res.errors) {
                                    this.setState({
                                      error:
                                        typeof res.data === "string"
                                          ? res.data
                                          : res.data.length > 0
                                          ? res.data
                                              .map((s) => {
                                                return `${
                                                  s.field ? `${s.field} :` : ""
                                                } ${
                                                  typeof s.message === "string"
                                                    ? s.message
                                                    : s.message.length > 0 &&
                                                      s.message[0] &&
                                                      s.message[0]
                                                }`;
                                              })
                                              .join(" ,")
                                          : res.data,
                                      loadingFun: false,
                                    });
                                  } else {
                                    this.props
                                      .RatesFunction("get", EndPoints["Rates"])
                                      .then(() => {
                                        this.setState(
                                          {
                                            loadingFun: false,
                                            openDialog: false,
                                            editList: false,
                                          },
                                          () => {
                                            this.setState({
                                              addDailog: false,
                                              success: editList
                                                ? "Edited suuceesfully"
                                                : "Added",
                                            });
                                          }
                                        );
                                      });
                                  }
                                });
                            } else {
                              // scrollDiv("port");
                              const errorMsg = this.state.errorMsg;
                              errorMsg.Amount = "Please give Amount!";

                              this.setState({ errorMsg });
                            }
                          } else {
                            // scrollDiv("broker");
                            const errorMsg = this.state.errorMsg;
                            errorMsg.Quantity = "Please give an Quantity!";

                            this.setState({ errorMsg });
                          }
                        } else {
                          // Update;
                          const errorMsg = this.state.errorMsg;
                          // scrollDiv("contract-type");
                          errorMsg.Unit = "Please select a Unit!";
                          this.setState({ errorMsg });
                        }
                      } else {
                        const errorMsg = this.state.errorMsg;
                        // scrollDiv("unit");
                        errorMsg.ItemTypeName = "Please select a ItemType!";
                        this.setState({ errorMsg });
                      }
                    } else {
                      // scrollDiv("poNum");
                      const errorMsg = this.state.errorMsg;
                      errorMsg.ItemCateoryName = "Please give ItemCateory!";

                      this.setState({ errorMsg });
                    }
                  } else {
                    // scrollDiv("broker");
                    const errorMsg = this.state.errorMsg;
                    errorMsg.ContractTypeList = "Please give an ContractType!";

                    this.setState({ errorMsg });
                  }
                }}
                name={this.state.editList ? "Update" : "Submit"}
                withBg={true}
                background={"rgb(241 154 48)"}
                width="120px"
              />
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
      </Layout>
    );
  }
}

export default RatesComponent;
