//imports
import ChatsContainer from "../Modules/Chats/ChatsContainer.js";
import RatesContainer from "../Modules/Rates/RatesContainer.js";
import PrecarriersContainer from "../Modules/Precarriers/PrecarriersContainer.js";
import PolicysContainer from "../Modules/Policys/PolicysContainer.js";
import SOpaymentTermsContainer from "../Modules/SOpaymentTerms/SOpaymentTermsContainer.js";
import IncotermsContainer from "../Modules/Incoterms/IncotermsContainer.js";
import ShippingTermsContainer from "../Modules/ShippingTerms/ShippingTermsContainer.js";
import CurrencysContainer from "../Modules/Currencys/CurrencysContainer.js";
import NotifysContainer from "../Modules/Notifys/NotifysContainer.js";
import RiceBagSellersContainer from "../Modules/RiceBagSellers/RiceBagSellersContainer.js";
import BeneficiarysContainer from "../Modules/Beneficiarys/BeneficiarysContainer.js";
import ShippersContainer from "../Modules/Shippers/ShippersContainer.js";
import UsersAndPermissionsContainer from "../Modules/UsersAndPermissions/UsersAndPermissionsContainer.js";
import BrandsManagementsContainer from "../Modules/BrandsManagements/BrandsManagementsContainer.js";
import MillersContainer from "../Modules/Millers/MillersContainer.js";
import BrokersContainer from "../Modules/Brokers/BrokersContainer.js";
import TransportersContainer from "../Modules/Transporters/TransportersContainer.js";
import PortsContainer from "../Modules/Ports/PortsContainer.js";
import HsNsContainer from "../Modules/HsNs/HsNsContainer.js";
import PackagingsContainer from "../Modules/Packagings/PackagingsContainer.js";
import ContractTypesContainer from "../Modules/ContractTypes/ContractTypesContainer.js";
import PaymentTermsContainer from "../Modules/PaymentTerms/PaymentTermsContainer.js";
import ItemTypesContainer from "../Modules/ItemTypes/ItemTypesContainer.js";
import PurchaseOrdersContainer from "../Modules/PurchaseOrders/PurchaseOrdersContainer.js";
import SalesOrdersContainer from "../Modules/SalesOrders/SalesOrdersContainer.js";
import ItemCategoriesContainer from "../Modules/ItemCategories/ItemCategoriesContainer.js";
import SurveyCompanysContainer from "../Modules/SurveyCompanys/SurveyCompanysContainer.js";
import ClientsContainer from "../Modules/Clients/ClientsContainer.js";
import PendingScreen from "../Modules/PendingScreen.js";
import SiginComponent from "../Modules/SiginComponent.js";
import CashDiscountContainer from "../Modules/CashDiscount/CashDiscountContainer.js";
import SalesIntentsContainer from "../Modules/SalesIntents/SalesIntentsContainer.js";
import BrandsContainer from "../Modules/Brand/BrandsContainer.js";
import ContractTypeContainer from "../Modules/ContractTypes/ContractTypesContainer.js"
const routes = [
  {
    path: "/pending",
    component: PendingScreen,
    access: "all",
  },
  {
    path: "/Chats/new",
    component: ChatsContainer,
    access: "Chats",
  },
  {
    path: "/Chats/:tab/:id",
    component: ChatsContainer,
    access: "Chats",
  },
  {
    path: "/Chats",
    component: ChatsContainer,
    access: "Chats",
  },

  // {
  //   path: "/Precarriers/new",
  //   component: PrecarriersContainer,
  //   access: "Masters",
  // },
  // {
  //   path: "/Precarriers/:tab/:id",
  //   component: PrecarriersContainer,
  //   access: "Masters",
  // },
  // {
  //   path: "/Precarriers",
  //   component: PrecarriersContainer,
  //   access: "Masters",
  // },

  // RICE BAGS
  {
    path: "/BrandsManagements/new",
    component: BrandsManagementsContainer,
    access: "Brands Management",
  },
  {
    path: "/BrandsManagements/:tab/:id",
    component: BrandsManagementsContainer,
    access: "Brands Management",
  },
  {
    path: "/BrandsManagements",
    component: BrandsManagementsContainer,
    access: "Brands Management",
  },
  {
    path: "/Brands/new",
    component: BrandsContainer,
    access: "Brands",
  },
  {
    path: "/Brands/:tab/:id",
    component: BrandsContainer,
    access: "Brands",
  },
  {
    path: "/Brands",
    component: BrandsContainer,
    access: "Brands",
  },
  {
    path: "/Packagings/new",
    component: PackagingsContainer,
    access: "Masters",
  },
  {
    path: "/Packagings/:tab/:id",
    component: PackagingsContainer,
    access: "Masters",
  },
  {
    path: "/Packagings",
    component: PackagingsContainer,
    access: "Masters",
  },
  {
    path: "/RiceBagSellers/new",
    component: RiceBagSellersContainer,
    access: "Masters",
  },
  {
    path: "/RiceBagSellers/:tab/:id",
    component: RiceBagSellersContainer,
    access: "Masters",
  },
  {
    path: "/RiceBagSellers",
    component: RiceBagSellersContainer,
    access: "Masters",
  },

  // VENDORS SUBMENU
  {
    path: "/Millers/new",
    component: MillersContainer,
    access: "Millers",
  },
  {
    path: "/Millers/:tab/:id",
    component: MillersContainer,
    access: "Millers",
  },
  {
    path: "/Millers",
    component: MillersContainer,
    access: "Millers",
  },
  {
    path: "/Brokers/new",
    component: BrokersContainer,
    access: "Brokers",
  },
  {
    path: "/Signin",
    component: SiginComponent,
    access: "all",
  },
  {
    path: "/Brokers/:tab/:id",
    component: BrokersContainer,
    access: "Brokers",
  },
  {
    path: "/Brokers",
    component: BrokersContainer,
    access: "Brokers",
  },
  {
    path: "/Transporters/new",
    component: TransportersContainer,
    access: "Transporters",
  },
  {
    path: "/Transporters/:tab/:id",
    component: TransportersContainer,
    access: "Transporters",
  },
  {
    path: "/Transporters",
    component: TransportersContainer,
    access: "Transporters",
  },

  // PORT SUBMENU
  {
    path: "/Ports/new",
    component: PortsContainer,
    access: "Ports",
  },
  {
    path: "/Ports/:tab/:id",
    component: PortsContainer,
    access: "Ports",
  },
  {
    path: "/Ports",
    component: PortsContainer,
    access: "Ports",
  },
  {
    path: "/Shippers/new",
    component: ShippersContainer,
    access: "Ports",
  },
  {
    path: "/Shippers/:tab/:id",
    component: ShippersContainer,
    access: "Ports",
  },
  {
    path: "/Shippers",
    component: ShippersContainer,
    access: "Ports",
  },

  // MASTERS SUBMENU
  {
    path: "/UsersAndPermissions/new",
    component: UsersAndPermissionsContainer,
    access: "Users & Permissions",
  },
  {
    path: "/UsersAndPermissions/:tab/:id",
    component: UsersAndPermissionsContainer,
    access: "Users & Permissions",
  },
  {
    path: "/UsersAndPermissions",
    component: UsersAndPermissionsContainer,
    access: "Users & Permissions",
  },
  {
    path: "/ContractTypes/new",
    component: ContractTypesContainer,
    access: "Users & Permissions",
  },
  {
    path: "/ContractTypes/:tab/:id",
    component: ContractTypesContainer,
    access: "Users & Permissions",
  },
  {
    path: "/ContractTypes",
    component: ContractTypesContainer,
    access: "Users & Permissions",
  },

  {
    path: "/ItemTypes/new",
    component: ItemTypesContainer,
    access: "Users & Permissions",
  },
  {
    path: "/ItemTypes/:tab/:id",
    access: "Users & Permissions",
    component: ItemTypesContainer,
  },
  {
    path: "/ItemTypes",
    component: ItemTypesContainer,
    access: "Users & Permissions",
  },
  {
    path: "/ItemCategories/new",
    component: ItemCategoriesContainer,
    access: "Users & Permissions",
  },
  {
    path: "/ItemCategories/masters/item-category/list/:id",
    component: ItemCategoriesContainer,
    access: "Users & Permissions",
  },
  {
    path: "/ItemCategories",
    component: ItemCategoriesContainer,
    access: "Users & Permissions",
  },
  {
    path: "/SurveyCompanys/new",
    component: SurveyCompanysContainer,
    access: "Users & Permissions",
  },
  {
    path: "SurveyCompanys/masters/survey-company/list/:id",
    component: SurveyCompanysContainer,
    access: "Users & Permissions",
  },
  {
    path: "/SurveyCompanys",
    component: SurveyCompanysContainer,
    access: "Users & Permissions",
  },
  {
    path: "/Beneficiarys/new",
    component: BeneficiarysContainer,
    access: "Users & Permissions",
  },
  {
    path: "/Beneficiarys/:tab/:id",
    component: BeneficiarysContainer,
    access: "Users & Permissions",
  },
  {
    path: "/Beneficiarys",
    component: BeneficiarysContainer,
    access: "Users & Permissions",
  },

  // PO SUBMENU
  {
    path: "/PurchaseOrders/new",
    component: PurchaseOrdersContainer,
    access: "Purchase Orders",
  },
  {
    path: "/PurchaseOrders/:tab/:id",
    component: PurchaseOrdersContainer,
    access: "Purchase Orders",
  },
  {
    path: "/PurchaseOrders",
    component: PurchaseOrdersContainer,
    access: "Purchase Orders",
  },  {
    path: "/Rates/PurchaseOrders",
    component: PurchaseOrdersContainer,
    access: "Purchase Orders",
  },
  
  {
    path: "/PaymentTerms/new",
    component: PaymentTermsContainer,
    access: "Purchase Orders",
  },
  {
    path: "/PaymentTerms/:tab/:id",
    component: PaymentTermsContainer,
    access: "Purchase Orders",
  },
  {
    path: "/PaymentTerms",
    component: PaymentTermsContainer,
    access: "Purchase Orders",
  },
  {
    path: "/HsNs/new",
    component: HsNsContainer,
    access: "Purchase Orders",
  },
  {
    path: "/HsNs/:tab/:id",
    component: HsNsContainer,
    access: "Purchase Orders",
  },
  {
    path: "/HsNs",
    component: HsNsContainer,
    access: "Purchase Orders",
  },
  {
    path: "/CashDiscount/new",
    component: CashDiscountContainer,
    access: "Purchase Orders",
  },
  {
    path: "/CashDiscount/:tab/:id",
    component: CashDiscountContainer,
    access: "Purchase Orders",
  },
  {
    path: "/CashDiscount",
    component: CashDiscountContainer,
    access: "Purchase Orders",
  },
  {
    path: "/contractType/new",
    component: ContractTypeContainer,
    access: "Contract Type",
  },
  {
    path: "/contractType/:tab/:id",
    component: ContractTypeContainer,
    access: "Contract Type",
  },
  {
    path: "/contractType",
    component: ContractTypeContainer,
    access: "Contract Type",
  },

  {
    path: "/Rates/new",
    component: RatesContainer,
    access: "Purchase Orders",
  },
  {
    path: "/Rates/:tab/:id",
    component: RatesContainer,
    access: "Purchase Orders",
  },
  {
    path: "/Rates",
    component: RatesContainer,
    access: "Purchase Orders",
  },
  {
    path: "/SalesIntents/new",
    component: SalesIntentsContainer,
    access: "Purchase Orders",
  },
  {
    path: "/SalesIntents/:tab/:id",
    component: SalesIntentsContainer,
    access: "Purchase Orders",
  },
  {
    path: "/SalesIntents",
    component: SalesIntentsContainer,
    access: "Purchase Orders",
  },

  // SO SUBMENU
  {
    path: "/SalesOrders/new",
    component: SalesOrdersContainer,
    access: "Sales Orders",
  },
  {
    path: "/SalesOrders/:tab/:id",
    component: SalesOrdersContainer,
    access: "Sales Orders",
  },
  {
    path: "/SalesOrders",
    component: SalesOrdersContainer,
    access: "Sales Orders",
  },
  {
    path: "/Clients/new",
    component: ClientsContainer,
    access: "Sales Orders",
  },
  {
    path: "/Clients/masters/clients/list/:id",
    access: "Sales Orders",
    component: ClientsContainer,
  },
  {
    path: "/Clients",
    component: ClientsContainer,
    access: "Sales Orders",
  },
  {
    path: "/Currencys/new",
    component: CurrencysContainer,
    access: "Sales Orders",
  },
  {
    path: "/Currencys/:tab/:id",
    component: CurrencysContainer,
    access: "Sales Orders",
  },
  {
    path: "/Currencys",
    component: CurrencysContainer,
    access: "Masters",
  },
  {
    path: "/Notifys/new",
    component: NotifysContainer,
    access: "Sales Orders",
  },
  {
    path: "/Notifys/:tab/:id",
    component: NotifysContainer,
    access: "Sales Orders",
  },
  {
    path: "/Notifys",
    component: NotifysContainer,
    access: "Sales Orders",
  },
  {
    path: "/Policys/new",
    component: PolicysContainer,
    access: "Sales Orders",
  },
  {
    path: "/Policys/:tab/:id",
    component: PolicysContainer,
    access: "Sales Orders",
  },
  {
    path: "/Policys",
    component: PolicysContainer,
    access: "Sales Orders",
  },
  {
    path: "/SOpaymentTerms/new",
    component: SOpaymentTermsContainer,
    access: "Sales Orders",
  },
  {
    path: "/SOpaymentTerms/:tab/:id",
    component: SOpaymentTermsContainer,
    access: "Sales Orders",
  },
  {
    path: "/SOpaymentTerms",
    component: SOpaymentTermsContainer,
    access: "Sales Orders",
  },
  {
    path: "/Incoterms/new",
    component: IncotermsContainer,
    access: "Sales Orders",
  },
  {
    path: "/Incoterms/:tab/:id",
    component: IncotermsContainer,
    access: "Sales Orders",
  },
  {
    path: "/Incoterms",
    component: IncotermsContainer,
    access: "Sales Orders",
  },
  {
    path: "/ShippingTerms/new",
    component: ShippingTermsContainer,
    access: "Sales Orders",
  },
  {
    path: "/ShippingTerms/:tab/:id",
    component: ShippingTermsContainer,
    access: "Sales Orders",
  },
  {
    path: "/ShippingTerms",
    component: ShippingTermsContainer,
    access: "Sales Orders",
  },
];

export default routes;
