import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import { LogOutFunction } from "../redux/index";
import { PowerButton } from "../styles/Icons";
import Typography from "@mui/material/Typography";
const useStyles = makeStyles((theme) => ({
  profile: {
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% - 30px)",
    alignItems: "center",
  },
}));

function RightIcon(props) {
  const user = localStorage.getItem("briuser") ? JSON.parse(localStorage.getItem("briuser")) : "";

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px 10px",
        width: "100%",
      }}
    >
      {props.open && (
        <Tooltip title={user && user.roles && user.roles} placement="bottom">
          <Avatar
            variant="rounded"
            alt="Profile Information"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            // src={props?.user?.photoURL}
            style={{
              borderRadius: "20px",
              height: "40px",
              width: "40px",
              minWidth: "30px",
              textTransform: "capitalize",
            }}
          >
            {user && user.username && user.username.substring(0, 1)}
          </Avatar>
        
        </Tooltip>
      )}
      <div className={classes.profile}>
        {props.open && (
          <>
            {" "}
            <div
              className="d-flex d-flex-column alignC "
              style={{
                padding: "0 0 0 10px",
                textTransform: "capitalize",
                fontSize: "0.8rem",
                width: "calc(100% - 26px)",
              }}
            >
              <span style={{ width: "100%",color:"black" }} className="textOEllipse">
                {user && user.username && user.username.split("@")[0]}
             
              </span>
              <span className="textOEllipse" style={{ width: "100%", textTransform: "lowercase" ,color:"black"}}>
                {user && user.email && user.email}
              </span>
            </div>
          </>
        )}{" "}
        <Tooltip title={"Sign Out"} placement="right-start">
          <div
            onClick={(e) => {
              e.preventDefault();
              props.LogOutFunction().then(() => props.navigate("/login"));
            }}
            style={{
              display: "flex",
              padding: "0 0 0 0px",
              width: "24px",
              justifyContent: "center",
              margin: "0 0 0 10px",
              cursor: "pointer",
            }}
          >
            <PowerButton />{" "}
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  loader: state.authentication.loading,
});
const mapDispatchToProps = { LogOutFunction };

export default connect(mapStateToProps, mapDispatchToProps)(RightIcon);
