import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@material-ui/core";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import {

  withStyles,

} from "@material-ui/core";
export const CssDialog = withStyles({
  root: {
    "& .css-rnmm7m-MuiPaper-root-MuiDialog-paper {": {
     height:"100%"
    },
    
 
  },
})(Dialog);
const Popup = ({ children, openPopup, setOpenPopup, isNew }) => {
  return (
    <CssDialog
      open={openPopup}
      keepMounted
      onClose={() => setOpenPopup(false)}
      fullWidth
      maxWidth="md"
      style={{
       height:"100%"
      }}
    >
      <DialogTitle
        style={{
          fontSize: "20.8px",
          fontWeight: "400",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgb(241 154 48)",
          color: "#fff",
          padding: "5px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "flex-start",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <PollOutlinedIcon
            style={{
              color: "white",

              marginRight: "7px",
            }}
          /> */}
          {isNew ? "Add Survey Company" : "Edit Survey Company Details"}
        </div>
        <Tooltip title="Close">
          <CloseIcon
            style={{ cursor: "pointer" }}
            color="#fff"
            onClick={() => setOpenPopup(false)}
          />
        </Tooltip>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </CssDialog>
  );
};

export default Popup;
