import moment from "moment";
import React from "react";
import DownloadUi from "../../../layoutComponents/DownloadUi";
import {
  calculateGstAmount,
  calculatePercentage,
  filterData,
} from "../../../Regex";
import converter from "number-to-words";
import PackingList from "../PackingList";

function CommercialPackingList(props) {
  const { downloadCI, onClose, masters, tableList } = props;
  const selectedSo =
    downloadCI &&
    downloadCI !== false &&
    downloadCI &&
    downloadCI.custom_invoice_details &&
    downloadCI.custom_invoice_details.length > 0 &&
    downloadCI.custom_invoice_details[0].sales_order_details &&
    downloadCI.custom_invoice_details[0].sales_order_details[0]
      ? downloadCI.custom_invoice_details[0].sales_order_details[0]
      : false;
  const details = (
    name,
    value,
    v1,
    v2,
    v3,
    border1,
    flex,
    center,
    height,
    bheight,
    noP,
    padding
  ) => {
    return (
      <div
        style={{
          width: "100%",
          padding: noP ? "" : border1 ? "" : height === "10px" ? "" : "2.5px",
          display: flex ? "flex" : "",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            maxWidth: "100%",
            width: flex ? "fit-content" : "100%",
            borderBottom: border1 ? "1px solid rgba(0, 0, 0, 0.12)" : "",
            justifyContent: center ? center : "",
            textAlign: center ? center : "",
            display: "flex",
            padding: border1 ? "2.5px" : "",
            minHeight: height ? height : "",
            alignItems: "center",
            wordBreak: "break-word",
          }}
        >
          {name}
        </div>
        {value && (
          <div
            style={{
              padding: padding ? padding : border1 ? "5px" : "",
              width: flex ? "fit-content" : "100%",
              justifyContent: center ? "center" : "",
              display: "flex",
              minHeight: bheight ? bheight : "",
              height: "fit-content",
              wordBreak: "break-word",
              flexWrap: "wrap",
            }}
          >
            {value}
          </div>
        )}
        {v1 && (
          <div style={{ padding: border1 ? "5px" : "", width: "100%" }}>
            {v1}
          </div>
        )}
        {v2 && (
          <div style={{ padding: border1 ? "5px" : "", width: "100%" }}>
            {v2}
          </div>
        )}
        {v3 && (
          <div style={{ padding: border1 ? "5px" : "", width: "100%" }}>
            {v3}
          </div>
        )}
      </div>
    );
  };
  const merge = (first, second) => {
    for (let i = 0; i < second.length; i++) {
      first.push(second[i]);
    }
    return first;
  };
  const ciUI = (
    <div style={{ padding: "20px", fontSize: "0.5rem" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: "0.8rem",
          textTransform: "uppercase",
        }}
      >
        Commercial PACKING LIST
      </div>
      <div
        style={{
          width: "100%",
          color: "black",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          margin: "10px 0 0 0",
        }}
      >
        <div
          style={{
            width: "100%",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "calc(50% - 0.5px)",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "calc(50% - 0.5px)",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              {details(
                "Comm Invoice No.:",
                downloadCI && downloadCI.invoice_number
              )}
            </div>
            <div style={{ width: "calc(50% - 0.5px)" }}>
              {details(
                "Comm Date :",
                moment(downloadCI.invoice_date).format("ddd, MMM Do YYYY")
              )}
            </div>
          </div>
          <div style={{ width: "calc(50% - 0.5px)", display: "flex" }}>
            <div
              style={{
                width: "calc(50% - 0.5px)",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              {details(
                "Contract Number :",
                selectedSo && selectedSo.so_number ? selectedSo.so_number : ""
              )}
            </div>
            <div style={{ width: "calc(50% - 0.5px)" }}>
              {details(
                "Dated :",
                moment(downloadCI.sales_order_date).format("ddd, MMM Do YYYY")
              )}
            </div>
          </div>
        </div>
        {tableList &&
          tableList.map((list) => {
            const selectedShipper =
              list && list.shipper
                ? filterData(
                    masters && masters.shippersList,
                    "id",
                    list.shipper,
                    "onlyOne"
                  )
                  ? filterData(
                      masters && masters.shippersList,
                      "id",
                      list.shipper,
                      "onlyOne"
                    )
                  : ""
                : "";
            const selectedBenificiary =
              list && list.beneficiary
                ? filterData(
                    masters && masters.benificiarysList,
                    "id",
                    list.beneficiary,
                    "onlyOne"
                  )
                  ? filterData(
                      masters && masters.benificiarysList,
                      "id",
                      list.beneficiary,
                      "onlyOne"
                    )
                  : ""
                : "";

            const buyer =
              list && list.buyer
                ? filterData(
                    masters && masters.buyersList,
                    "id",
                    list.buyer,
                    "onlyOne"
                  )
                  ? filterData(
                      masters && masters.buyersList,
                      "id",
                      list.buyer,
                      "onlyOne"
                    )
                  : ""
                : "";
            return (
              <>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "calc(40% - 0.5px)",
                      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {details(
                      "Shipper :",
                      selectedShipper && selectedShipper.name,
                      selectedShipper && selectedShipper.address,
                      `GST Number: ${selectedShipper && selectedShipper.gst_no}`
                    )}
                  </div>
                  <div
                    style={{
                      width: "calc(60% - 0.5px)",
                      textTransform: "uppercase",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "calc(50% - 0.5px)",
                          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        {details(
                          "Custom In. :",
                          list && list.invoice_number ? list.invoice_number : ""
                        )}
                      </div>
                      <div style={{ width: "calc(50% - 0.5px)" }}>
                        {details(
                          "Date :",
                          moment(list.invoice_date).format("ddd, MMM Do YYYY")
                        )}
                      </div>
                    </div>
                    <div style={{ width: "100%", display: "flex" }}>
                      <div
                        style={{
                          width: "calc(50% - 0.5px)",
                          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        {details(
                          "BL NO. :",
                          list && list.bl_number ? list.bl_number : ""
                        )}
                      </div>{" "}
                      <div style={{ width: "calc(50% - 0.5px)" }}>
                        {details(
                          "Exporter ERf. :",
                          list && list.exporter_ref_no
                            ? list.exporter_ref_no
                            : ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "calc(40% - 0.5px)",
                      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {details(
                      "Consignee :",
                      selectedSo && selectedSo.consignee
                        ? selectedSo.consignee
                        : "",
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )}
                  </div>
                  <div
                    style={{
                      width: "calc(60% - 0.5px)",
                      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {details(
                      "Buyer :",
                      buyer ? buyer.client_name : "",
                      buyer && `${buyer.address} ${buyer.city} ${buyer.state}`,
                      `Phone : ${buyer && buyer.phone}`,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "calc(40% - 0.5px)",
                      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {details(
                      "Notify 1 :",

                      `${
                        filterData(
                          masters && masters.notifyList,
                          "id",
                          list && list.notify_1 ? Number(list.notify_1) : "",
                          "onlyOne"
                        )
                          ? filterData(
                              masters && masters.notifyList,
                              "id",
                              list && list.notify_1
                                ? Number(list.notify_1)
                                : "",
                              "onlyOne"
                            ).notify
                          : ""
                      }`
                    )}
                    {/* // list && list.notify_1 ? list.notify_1 : "")} */}
                  </div>
                  <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                    <div
                      style={{
                        width: "calc(40% - 0.5px)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      {details(
                        "Notify 2 :",
                        `${
                          filterData(
                            masters && masters.notifyList,
                            "id",
                            list && list.notify_2 ? Number(list.notify_2) : "",
                            "onlyOne"
                          )
                            ? filterData(
                                masters && masters.notifyList,
                                "id",
                                list && list.notify_2
                                  ? Number(list.notify_2)
                                  : "",
                                "onlyOne"
                              ).notify
                            : ""
                        }`
                      )}
                    </div>
                    <div style={{ width: "calc(60% - 0.5px)" }}>
                      <div
                        style={{
                          width: "100%",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "uppercase",
                        }}
                      >
                        {details(
                          "Country of origin of Goods: INDIA",
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center"
                        )}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "uppercase",
                        }}
                      >
                        {details(
                          "PLace of DELIVERY/supply:",
                          list && list.delivery_place
                            ? list.delivery_place
                            : "",
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    textTransform: "uppercase",
                  }}
                >
                  <div
                    style={{
                      width: "calc(40% - 0.5px)",
                      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {details(
                      "name of carrying vessel/flight no.",
                      `${list && list.vessel_number} ${
                        list && list.flight_number
                      }`,
                      null,
                      null,
                      null,
                      "border",
                      null,
                      null
                      // "27.46px"
                    )}
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        minHeight: "24.98px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      {details("Port of loading:", "any port in INDIA")}
                    </div>
                    {details(
                      "Port of discharge",
                      list && list.discharge_port ? list.discharge_port : ""
                    )}
                  </div>
                  <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                    <div
                      style={{
                        width: "calc(40% - 0.5px)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                     
                      {details(
                        "term of delivery & payment :",
                        `Delivery : ${
                          list && list.delivery_place ? list.delivery_place : ""
                        } ,`,
                        `Payment : ${
                          filterData(
                            masters && masters.soPaymentTermsList,
                            "id",
                            selectedSo && selectedSo.so_payment_term
                              ? Number(selectedSo.so_payment_term)
                              : "",
                            "onlyOne"
                          )
                            ? filterData(
                                masters && masters.soPaymentTermsList,
                                "id",
                                selectedSo && selectedSo.so_payment_term
                                  ? Number(selectedSo.so_payment_term)
                                  : "",
                                "onlyOne"
                              ).term
                            : "false"
                        } `,
                        null,
                        null,
                        "border"
                      )}
                    </div>
                    <div style={{ width: "calc(60% - 0.5px)" }}>
                      {details(
                        "beneficiary name :",
                        selectedBenificiary && selectedBenificiary.name,
                        null,
                        null,
                        null,
                        "border",
                        null,
                        null,
                        // "27.46px",
                        null,
                        null,
                        "5px 2.5px 0"
                      )}
                      {details(
                        "A/C NO.:",
                        selectedBenificiary && selectedBenificiary.account_no,
                        null,
                        null,
                        null,
                        null,
                        "d-flex"
                      )}
                      {details(
                        "BANK NAME :",
                        selectedBenificiary && selectedBenificiary.bank_name,
                        null,
                        null,
                        null,
                        null,
                        "d-flex"
                      )}
                      {details(
                        selectedBenificiary && selectedBenificiary.bank_address
                      )}
                      {details(
                        "SWIFT CODE :",
                        selectedBenificiary && selectedBenificiary.swift_code,
                        null,
                        null,
                        null,
                        null,
                        "d-flex"
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        {tableList.map((list) => {
          if (list.subList && list.subList.length > 0) {
            return list.subList.map((subList) => {
         
              return (
                <>
                  <div
                    style={{
                      width: "100%",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      display: "flex",
                      textTransform: "uppercase",
                    }}
                  >
                    <div
                      style={{
                        width: "calc(40% - 0.5px)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "calc(40% - 0.5px)",
                          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        {details(
                          "marks and number of container no.",
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center"
                        )}
                      </div>
                      <div style={{ width: "calc(60% - 0.5px)" }}>
                        {details(
                          "No. and kind of package",
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                      </div>
                    </div>
                    <div
                      style={{ width: "calc(60% - 0.5px)", display: "flex" }}
                    >
                      <div
                        style={{
                          width: "calc(40% - 0.5px)",
                          display: "flex",
                          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <div
                          style={{
                            width: "calc(50% - 0.5px)",
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            height: "27.46px",
                            padding: "5px",
                          }}
                        >
                          {details(
                            "Description",
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "center",
                            null,
                            null,
                            true
                          )}
                          {details(
                            "of goods",
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "center",
                            null,
                            null
                          )}
                        </div>
                        <div style={{ width: "calc(50% - 0.5px)" }}>
                          {details(
                            `Quantity in (${
                              downloadCI && downloadCI.unit
                                ? downloadCI.unit
                                : ""
                            })`,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "center",
                            "27.46px"
                          )}
                        </div>
                      </div>
                      <div
                        style={{ width: "calc(60% - 0.5px)", display: "flex" }}
                      >
                        {details(
                          `Remark`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      display: "flex",
                      textTransform: "uppercase",
                    }}
                  >
                    <div
                      style={{
                        width: "calc(40% - 0.5px)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "calc(40% - 0.5px)",
                          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <div style={{ padding: "5px" }}>
                          {subList && subList["Buyer Marking"]
                            ? details(
                                subList["Buyer Marking"],
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                true
                              )
                            : ""}
                          {subList &&
                          subList.container_numbers &&
                          subList.container_numbers.length > 0
                            ? subList.container_numbers.map((ss) =>
                                details(
                                  ss,
                                  null,
                                  null,
                                  null,
                                  null,
                                  null,
                                  null,
                                  null,
                                  null,
                                  null,
                                  true
                                )
                              )
                            : ""}
                        </div>
                      </div>
                      <div style={{ width: "calc(60% - 0.5px)" }}>
                        {details(
                          subList &&
                            subList.container_numbers &&
                            subList.container_numbers.length > 0
                            ? `${subList.container_numbers.length} X  20' CONTS`
                            : "",
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                        {/* {details(
                          `Number of bags`,
                          subList
                            ? subList.packagingDetail.reduce(
                                (previousValue, currentValue) =>
                                  Number(previousValue) +
                                  Number(
                                    currentValue.total_bags
                                      ? currentValue.total_bags
                                      : 0
                                  ),
                                0
                              )
                            : "",
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )} */}
               
                        {details(
                          subList
                            ? `Packaging in  ${subList.packagingDetail.map(
                                (sP, i) => {
                                  return `${sP.total_bags ?? "-"} bags of ${
                                    sP.size ? sP.size : ""
                                  }${sP.unit ? sP.unit : ""} ${
                                    sP.bag_type ? sP.bag_type : ""
                                  }${
                                    subList.packagingDetail.length === i + 1
                                      ? "."
                                      : " "
                                  }`;
                                }
                              )}`
                            : "",
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                      </div>
                    </div>
                    <div
                      style={{ width: "calc(60% - 0.5px)", display: "flex" }}
                    >
                      <div
                        style={{
                          width: "calc(40% - 0.5px)",
                          display: "flex",
                          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <div
                          style={{
                            width: "calc(50% - 0.5px)",
                            maxWidth: "calc(50% - 0.5px)",
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          {" "}
                          {details(
                            subList.Brand ? subList.Brand : "",
                            null,
                            null,
                            null,
                            null,
                            null,
                            "center"
                            // "20px"
                          )}
                          {details(
                            "HSN CODE :",
                            subList && subList["HSN Code"],
                            null,
                            null,
                            null,
                            null,
                            null,
                            null
                          )}
                        </div>
                        <div
                          style={{
                            maxWidth: "calc(50% - 0.5px)",
                            width: "calc(50% - 0.5px)",
                          }}
                        >
                          {details(
                            `GROSS WT.`,
                            subList.gross_weight && subList.gross_weight,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "center",
                            "20px"
                          )}
                          {details(
                            downloadCI && downloadCI.unit,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "center",
                            "10px"
                          )}
                          {details(
                            `NET WT.`,
                            subList.net_weight && subList.net_weight,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "center",
                            "20px"
                          )}
                          {details(
                            downloadCI && downloadCI.unit,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            "center",
                            "11px"
                          )}
                        </div>
                      </div>
                      <div
                        style={{ width: "calc(60% - 0.5px)", display: "flex" }}
                      >
                        {details(
                          subList["Remark"] ? subList["Remark"] : "",
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          "27.46px"
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            });
          } else {
            return "";
          }
        })}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            minHeight: "60px",
            height: "60px",
          }}
        >
          <div
            style={{
              padding: "5px",
              display: "flex",
              minHeight: "100%",
              height: "100%",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                padding: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
                   For BALAJI RICE INDUSTRIES
              <div style={{ margin: "0 20px 0 0" }}></div>
            </div>
            <div
              style={{
                padding: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              Authorized Signatory Director
              <div style={{ margin: "0 20px 0 0" }}></div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        style={{
          width: "calc(100% - 40px)",
          display: "flex",
          flexDirection: "column",

          margin: "5px 0",
        }}
      >
        {details(
          "CIN NUMBER : U14200CT2007PTC020290",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          true
        )}
        {details(
          "EMAIL : sainath@srisainath.com",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          true
        )}
        {details(
          "WEB : www.srisainath.com",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          true
        )}
        {details(
          "FAX : +91 771 4032240",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          true
        )}
      </div>
    </div>
  );

  return (
    <DownloadUi
      download={downloadCI}
      onClose={onClose}
      noLayout={true}
      fileName={downloadCI.invoice_number}
    >
      {ciUI}
    </DownloadUi>
  );
}

export default CommercialPackingList;
