import React, { Component } from "react";
// import validator from "validator";
import {
  Backdrop,
  Dialog,
  MenuItem,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import { divide, multiply, sum, subtract } from "lodash";
import {
  CssTextField,
  StyledButton,
  StyledCircularProgress,
} from "../styles/StyledComponents";
import { green, primary } from "../styles/colors";
import { CrossIcon, TopArrow, BottomArrow } from "../styles/Icons";
import { CheckCircle } from "@material-ui/icons";
import EventIcon from "@mui/icons-material/Event";
import SnackbarComponent from "./SnackbarComponent";
import { trimEnd } from "lodash";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { withStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import MultipleSelectBar from "./MultipleSelectBar";
import { EndPoints } from "../utils/EndPoints";
import { filterData, StopScroll } from "../Regex";
import { getMinValue } from "../Modules/PurchaseOrders/PurchaseOrdersComponent";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiInputBase-root": { background: "rgb(0 0 0 / 1%)" },
    "&.PickerWithState-root-34 .MuiOutlinedInput-root .MuiOutlinedInput-input":
      { background: "none" },
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "14px 14px",
      caretColor: "transparent",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);

const styles = {
  withDivider50: { width: "calc(25% - 20px)", margin: "10px" },
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  bottomHeading: {
    margin: "0 10px 0 0",
    width: "390px",
  },
};
export class CreateDialogPO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: "paper",
      dataAdded: false,
      loading: false,
      comment: "",
      error: "",
      success: "",
      submittedData: {},
      viewData: {},
      errorMsg: {},
      tableErrorValue: [],
      remaining_quantity: 0,
      tableValueDetailArray: [],
      billError: false,
    };
  }
  reset = () => {
    const { submittedData, inputArray } = this.state;
    const { selectedList } = this.props;
    const noData = [];
    const errorMsg = {};
    const newSubmittedData = {};
    inputArray.map((singleInput) => {
      if (submittedData[singleInput.name]) {
        errorMsg[singleInput.name] = false;
        newSubmittedData[singleInput.name] = "";
        return noData.push(singleInput.name);
      } else {
        return (errorMsg[singleInput.name] = false);
      }
    });
    if (noData.length === 0) {
      // this.setState({ error: "Nothing to reset !", errorMsg });
    } else {
      const newSubmittedData = {};
      inputArray.map((singleInput) => {
        if (selectedList[singleInput.name]) {
          errorMsg[singleInput.name] = false;
          if (singleInput.type === "Month") {
            newSubmittedData[singleInput.name] =
              selectedList[`${singleInput.name}Month`];
          } else if (singleInput.type === "checkBox") {
            return (newSubmittedData[singleInput.name] =
              selectedList[singleInput.name] === "Paid" ? true : false);
          } else {
            newSubmittedData[singleInput.name] = selectedList[singleInput.name];
            newSubmittedData[`${singleInput.name}Id`] = selectedList[
              `${singleInput.name}Id`
            ]
              ? selectedList[`${singleInput.name}Id`]
              : "";

            return newSubmittedData;
          }
        } else if (singleInput.defaultValue) {
          errorMsg[singleInput.name] = false;
          newSubmittedData[singleInput.name] =
            singleInput.type === "checkBox" &&
            singleInput.defaultValue.value === false
              ? false
              : singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : "";

          newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue
            .id
            ? singleInput.defaultValue.id
            : "";

          return newSubmittedData;
        } else if (singleInput.type === "Month") {
          newSubmittedData[singleInput.name] = new Date();
        } else if (singleInput.type === "checkBox") {
          newSubmittedData[singleInput.name] = false;
        } else {
          errorMsg[singleInput.name] = false;
          return (newSubmittedData[singleInput.name] = "");
        }
      });
      if (!this.props.editList && this.props.onAdd) {
        this.props.onAdd();
      } else {
        // this.setInitialValues(this.props);
      }
      this.setState(
        {
          dataAdded: false,
          comment: "",
          submittedData: newSubmittedData,
          viewData: newSubmittedData,
          focusedFields: [],
          errorMsg,
        },
        () => {
          // this.setState({ success: "Reset successfully !" });
        }
      );
    }
  };
  changeTo = (to, weigths, opposite) => {
    if (opposite) {
      if (to === "MT") {
        return multiply(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return multiply(weigths, 100);
      }
    } else {
      if (to === "MT") {
        return divide(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return divide(weigths, 100);
      }
    }
  };
  changeUnit = (from, to, weigths, opposite) => {
    //default in kg

    if (opposite) {
      if (from === "MT") {
        return this.changeTo(to, divide(weigths, 1000), opposite);
      } else if (from === "Kgs") {
        return this.changeTo(to, weigths);
      } else if (from === "Qtl") {
        return this.changeTo(to, divide(weigths, 100), opposite);
      }
    } else {
      if (from === "MT") {
        return this.changeTo(to, multiply(weigths, 1000));
      } else if (from === "Kgs") {
        return this.changeTo(to, weigths);
      } else if (from === "Qtl") {
        return this.changeTo(to, multiply(weigths, 100));
      }
    }
  };
  setInitialValues = (next) => {
    const { selectedList, inputArray, tableErrorValue, tableValueDetailArray } =
      next;
    const newSubmittedData = {};
    const errorMsg = this.state.errorMsg;
    console.log(
      "N",
      next,
      selectedList,
      inputArray,
      tableErrorValue,
      tableValueDetailArray
    );
    inputArray
      .filter((sA) => sA)
      .map((singleInput) => {
        if (selectedList[singleInput.name]) {
          errorMsg[singleInput.name] = false;
          if (singleInput.type === "checkBox") {
            return (newSubmittedData[singleInput.name] =
              selectedList[singleInput.name] === "Paid" ? true : false);
          } else if (singleInput.type === "Month") {
            newSubmittedData[singleInput.name] =
              selectedList[`${singleInput.name}Month`];
          } else {
            newSubmittedData[singleInput.name] = selectedList[singleInput.name];
            newSubmittedData[`${singleInput.name}Id`] = selectedList[
              `${singleInput.name}Id`
            ]
              ? selectedList[`${singleInput.name}Id`]
              : "";

            return newSubmittedData;
          }
        } else if (singleInput.defaultValue) {
          errorMsg[singleInput.name] = false;
          newSubmittedData[singleInput.name] =
            singleInput.type === "checkBox" &&
            singleInput.defaultValue.value === false
              ? false
              : singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : "";

          newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue
            .id
            ? singleInput.defaultValue.id
            : "";

          return newSubmittedData;
        } else if (singleInput.type === "Month") {
          newSubmittedData[singleInput.name] = new Date();
        } else if (singleInput.type === "checkBox") {
          newSubmittedData[singleInput.name] = false;
        } else {
          errorMsg[singleInput.name] = false;
          return (newSubmittedData[singleInput.name] = "");
        }
      });
    if (selectedList) {
    } else {
      // newSubmittedData["Miller Bill Amount"] = next.tableErrorValue
      //   .reduce(
      //     (previousValue, currentValue) =>
      //       Number(previousValue) +
      //       Number(
      //         filterData(
      //           tableValueDetailArray,
      //           "po_item_id",
      //           currentValue,
      //           "onlyOne"
      //         )
      //           ? filterData(
      //               tableValueDetailArray,
      //               "po_item_id",
      //               currentValue,
      //               "onlyOne"
      //             )["packagageArrUsed"]
      //             ? filterData(
      //                 tableValueDetailArray,
      //                 "po_item_id",
      //                 currentValue,
      //                 "onlyOne"
      //               )["packagageArrUsed"].reduce(
      //                 (previousValue1, currentValue1) =>
      //                   sum([
      //                     Number(previousValue1),
      //                     currentValue1.total_amount
      //                       ? Number(currentValue1.total_amount)
      //                       : 0,
      //                   ]),
      //                 0
      //               )
      //             : 0
      //           : 0
      //       ),
      //     0
      //   )
      //   .toFixed(2);
      newSubmittedData["Dispatch Weight"] = tableErrorValue
        .reduce(
          (previousValue, currentValue) =>
            Number(previousValue) +
            Number(
              filterData(
                tableValueDetailArray,
                "po_item_id",
                currentValue,
                "onlyOne"
              )
                ? filterData(
                    tableValueDetailArray,
                    "po_item_id",
                    currentValue,
                    "onlyOne"
                  )["packagageArrUsed"]
                  ? filterData(
                      tableValueDetailArray,
                      "po_item_id",
                      currentValue,
                      "onlyOne"
                    )["packagageArrUsed"].reduce(
                      (previousValue1, currentValue1) =>
                        Number(previousValue1) +
                        (currentValue1.total_dispatch
                          ? Number(currentValue1.total_dispatch)
                          : 0),
                      0
                    )
                  : 0
                : 0
            ),
          0
        )
        .toFixed(3);

      newSubmittedData["Total Freight"] = this.changeUnit(
        "MT",
        newSubmittedData["Unit"],
        multiply(
          divide(
            Number(
              getMinValue(
                newSubmittedData["Dispatch Weight"],
                newSubmittedData["Net Weight"],
                newSubmittedData["Miller Bill Weight"]
              )
            ),
            1000
          ),
          newSubmittedData["Freight Rate"]
            ? newSubmittedData["Freight Rate"]
            : 0
        ).toFixed(2)
      ).toFixed(2);
      newSubmittedData["Transporter Balance"] = subtract(
        newSubmittedData["Total Freight"]
          ? newSubmittedData["Total Freight"]
          : 0,
        newSubmittedData["Transporter Advance"]
          ? Number(newSubmittedData["Transporter Advance"])
          : 0
      ).toFixed(2);
      newSubmittedData["TDS Amount"] = newSubmittedData
        ? multiply(
            divide(
              newSubmittedData["Miller TDS"]
                ? Number(newSubmittedData["Miller TDS"])
                : 0,
              100
            ),
            newSubmittedData["Miller Bill Amount"]
              ? newSubmittedData["Miller Bill Amount"]
              : 0
          ).toFixed(2)
        : "-";
      newSubmittedData["Miller Total Amount"] = sum([
        sum([
          Number(
            subtract(
              multiply(
                getMinValue(
                  newSubmittedData["Dispatch Weight"],
                  newSubmittedData["Net Weight"],
                  newSubmittedData["Miller Bill Weight"]
                ),
                tableValueDetailArray.length > 0
                  ? Number(tableValueDetailArray[0]["Contract Rate"])
                  : 1
              ),

              sum([
                Number(newSubmittedData["Miller Advance"] ?? 0),
                Number(newSubmittedData["TDS Amount"] ?? 0),
                Number(newSubmittedData["BRI Brokerage Amount"] ?? 0),
                Number(newSubmittedData["Miller Side Brokerage Amount"] ?? 0),
              ])
            )
          ),
          Number(newSubmittedData["Transporter Advance"] ?? 0),
        ]),
        Number(newSubmittedData["GST Amount"] ?? 0),
      ]);
    }
    this.setState({
      submittedData: newSubmittedData,
      viewData: newSubmittedData,
      inputArray: next.inputArray.filter((sA) => sA),
      tableErrorValue: next.tableErrorValue,
      remaining_quantity: next.remaining_quantity,
      tableValueDetailArray: tableValueDetailArray,
    });

    this.setState({
      error: next.error ? next.error : "",
      // submittedData:newSubmittedData
    });

    // }
  };
  UNSAFE_componentWillReceiveProps(next) {
    if (this.state.tableErrorValue.length !== next.tableErrorValue) {
      console.log("N2", next, this.state.submittedData);
      const { tableValueDetailArray } = next;
      const { tableErrorValue } = this.state;
      const newSubmittedData = this.state.submittedData;

      // newSubmittedData["Miller Bill Amount"] = next.tableErrorValue
      //   .reduce(
      //     (previousValue, currentValue) =>
      //       Number(previousValue) +
      //       Number(
      //         filterData(
      //           tableValueDetailArray,
      //           "po_item_id",
      //           currentValue,
      //           "onlyOne"
      //         )
      //           ? filterData(
      //               tableValueDetailArray,
      //               "po_item_id",
      //               currentValue,
      //               "onlyOne"
      //             )["packagageArrUsed"]
      //             ? filterData(
      //                 tableValueDetailArray,
      //                 "po_item_id",
      //                 currentValue,
      //                 "onlyOne"
      //               )["packagageArrUsed"].reduce(
      //                 (previousValue1, currentValue1) =>
      //                   Number(previousValue1) +
      //                   (currentValue1.total_amount
      //                     ? Number(currentValue1.total_amount)
      //                     : 0),
      //                 0
      //               )
      //             : 0
      //           : 0
      //       ),
      //     0
      //   )
      //   .toFixed(2);
      newSubmittedData["Dispatch Weight"] = tableErrorValue
        .reduce(
          (previousValue, currentValue) =>
            Number(previousValue) +
            Number(
              filterData(
                tableValueDetailArray,
                "po_item_id",
                currentValue,
                "onlyOne"
              )
                ? filterData(
                    tableValueDetailArray,
                    "po_item_id",
                    currentValue,
                    "onlyOne"
                  )["packagageArrUsed"]
                  ? filterData(
                      tableValueDetailArray,
                      "po_item_id",
                      currentValue,
                      "onlyOne"
                    )["packagageArrUsed"].reduce(
                      (previousValue1, currentValue1) =>
                        Number(previousValue1) +
                        (currentValue1.total_dispatch
                          ? Number(currentValue1.total_dispatch)
                          : 0),
                      0
                    )
                  : 0
                : 0
            ),
          0
        )
        .toFixed(3);

      newSubmittedData["Miller Side Brokerage Amount"] = multiply(
        newSubmittedData["Miller Side Brokerage Rate"]
          ? Number(newSubmittedData["Miller Side Brokerage Rate"])
          : 0,
        getMinValue(
          newSubmittedData["Dispatch Weight"],
          newSubmittedData["Net Weight"],
          newSubmittedData["Miller Bill Weight"]
        )
      );
      newSubmittedData["BRI Brokerage Amount"] = multiply(
        Number(newSubmittedData["BRI brokerage rate"] ?? 0) *
          getMinValue(
            newSubmittedData["Dispatch Weight"],
            newSubmittedData["Net Weight"],
            newSubmittedData["Miller Bill Weight"]
          )
      );
      newSubmittedData["Total Freight"] = this.changeUnit(
        "MT",
        newSubmittedData["Unit"],
        multiply(
          divide(
            Number(
              getMinValue(
                newSubmittedData["Dispatch Weight"],
                newSubmittedData["Net Weight"],
                newSubmittedData["Miller Bill Weight"]
              )
            ),
            1000
          ),
          newSubmittedData["Freight Rate"]
            ? newSubmittedData["Freight Rate"]
            : 0
        ).toFixed(2)
      ).toFixed(2);
      newSubmittedData["Transporter Balance"] = subtract(
        newSubmittedData["Total Freight"]
          ? newSubmittedData["Total Freight"]
          : 0,
        newSubmittedData["Transporter Advance"]
          ? Number(newSubmittedData["Transporter Advance"])
          : 0
      ).toFixed(2);
      newSubmittedData["TDS Amount"] = newSubmittedData
        ? multiply(
            divide(
              newSubmittedData["Miller TDS"]
                ? Number(newSubmittedData["Miller TDS"])
                : 0,
              100
            ),
            newSubmittedData["Miller Bill Amount"]
              ? newSubmittedData["Miller Bill Amount"]
              : 0
          ).toFixed(2)
        : "-";
      newSubmittedData["Miller Total Amount"] = sum([
        sum([
          Number(
            subtract(
              multiply(
                getMinValue(
                  newSubmittedData["Dispatch Weight"],
                  newSubmittedData["Net Weight"],
                  newSubmittedData["Miller Bill Weight"]
                ),
                tableValueDetailArray.length > 0
                  ? Number(tableValueDetailArray[0]["Contract Rate"])
                  : 1
              ),
              sum([
                Number(newSubmittedData["Miller Advance"] ?? 0),
                Number(newSubmittedData["TDS Amount"] ?? 0),
                Number(newSubmittedData["BRI Brokerage Amount"] ?? 0),
                Number(newSubmittedData["Miller Side Brokerage Amount"] ?? 0),
              ])
            )
          ),

          Number(newSubmittedData["Transporter Advance"] ?? 0),
        ]),
        Number(newSubmittedData["GST Amount"] ?? 0),
      ]);

      this.setState({
        submittedData: newSubmittedData,
        viewData: newSubmittedData,
        tableErrorValue: next.tableErrorValue,
      });
    }

    this.setState({
      inputArray: next.inputArray.filter((sA) => sA),
      remaining_quantity: next.remaining_quantity,
      tableValueDetailArray: next.tableValueDetailArray,
      errorMsg: next.errorMsgs ? next.errorMsgs : this.state.errorMsg,
      // submittedData:newSubmittedData
    });

    this.setState({
      error: next.error ? next.error : "",
      // submittedData:newSubmittedData
    });
  }
  UNSAFE_componentWillMount() {
    this.setInitialValues(this.props);
  }
  getBagsCount = (tableErrorValue) => {
    const { tableValueDetailArray } = this.state;
    return tableErrorValue.length > 0
      ? tableErrorValue.reduce(
          (previousValue, currentValue) =>
            Number(previousValue) +
            Number(
              filterData(
                tableValueDetailArray,
                "po_item_id",
                currentValue,
                "onlyOne"
              )
                ? filterData(
                    tableValueDetailArray,
                    "po_item_id",
                    currentValue,
                    "onlyOne"
                  )["packagageArrUsed"]
                  ? filterData(
                      tableValueDetailArray,
                      "po_item_id",
                      currentValue,
                      "onlyOne"
                    )["packagageArrUsed"].reduce(
                      (previousValue1, currentValue1) =>
                        Number(previousValue1) +
                        (currentValue1.used_bags
                          ? Number(currentValue1.used_bags)
                          : 0),
                      0
                    )
                  : 0
                : 0
            ),
          0
        )
      : 0;
  };

  handleUpdate = (e) => {
    this.setState({ cancel: false });
    e.preventDefault();
    let file = {};

    if (e.target.files[0]) {
      const kb = (e.target.files[0].size / 1024).toFixed(2);
      const mb = (e.target.files[0].size / 1024.0 / 1024.0).toFixed(2);

      if (kb <= 1024) {
        const size = `${kb}${" "}kb`;
        file.mainSize = size;
        this.setState({ size });
      }
      if (1024 < kb) {
        const size = `${mb}${" "}mb`;
        file.mainSize = size;
        this.setState({ size });
      }

      file.img = e.target.files[0];
      file.url = URL.createObjectURL(e.target.files[0]);
      var errorMsg = this.state.errorMsg;
      errorMsg.file = false;
      this.setState({ file, errorMsg });
    }
  };

  handleChange = (event, singleInput, errorMsgs, seterrorMsgs) => {
    var errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;
    if (
      singleInput.name === "Tare Weight" ||
      singleInput.name === "Gross Weight"
    ) {
      errorMsg["Net Weight"] = false;
    }
    const submittedData = this.state.submittedData;
    const viewData = this.state.viewData;
    if (singleInput.endAdornment === "%" && event.target.value > 100) {
      return; // Prevent updating value if it's greater than 100%
    } else {
      let dataValue =
        event.target.value && singleInput.upperCase
          ? event.target.value.toUpperCase()
          : event.target.value.includes(".")
          ? event.target.value.match(/^\d*\.?\d{0,3}$/)
            ? event.target.value
            : viewData[event.target.name] || submittedData[event.target.name]
          : event.target.value;
      submittedData[event.target.name] = dataValue;
      viewData[event.target.name] = dataValue;
      this.setState({ viewData: viewData });
    }
    if (errorMsgs && errorMsgs[singleInput.name] && seterrorMsgs) {
      seterrorMsgs(singleInput.name);
    }
    if (singleInput.onChange) {
      singleInput.onChange(submittedData).then((submittedData) => {
        this.setState({
          submittedData,
          errorMsg,
        });
      });
    } else {
      this.setState({
        submittedData,
        errorMsg,
      });
    }
  };

  handleBottomFieldChange = (event, singleInput, errorMsgs, seterrorMsgs) => {
    if (
      this.props.whenAddedBeforeValue() &&
      this.props.whenAddedBeforeValue() === true
    ) {
      var errorMsg = this.state.errorMsg;
      errorMsg[event.target.name] = false;
      if (
        singleInput.name === "Tare Weight" ||
        singleInput.name === "Gross Weight"
      ) {
        errorMsg["Net Weight"] = false;
      }
      const submittedData = this.state.submittedData;
      const viewData = this.state.viewData;

      if (
        errorMsgs &&
        errorMsgs[singleInput.name] &&
        seterrorMsgs
      ) {
        seterrorMsgs(singleInput.name);
      }
      if (
        singleInput.endAdornment === "%" &&
        event.target.value > 100
      ) {
      } else {
        let dataValue =
        event.target.value && singleInput.upperCase
          ? event.target.value.toUpperCase()
          : event.target.value.includes(".")
          ? event.target.value.match(/^\d*\.?\d{0,3}$/)
            ? event.target.value
            : viewData[event.target.name] || submittedData[event.target.name]
          : event.target.value;
      submittedData[event.target.name] = dataValue;
      viewData[event.target.name] = dataValue;
      this.setState({ viewData: viewData });
      }
      if (singleInput.onChange) {
        singleInput
          .onChange(submittedData)
          .then((submittedData) => {
            this.setState({
              submittedData,
              errorMsg,
            });
          });
      } else {
        this.setState({
          submittedData,
          errorMsg,
        });
      }
    } else {
    }
  };

  // handleFocus = (fieldNames) => {
  //   this.setState({ focusedFields: [... fieldNames], });
  // };

  render() {
    const {
      scroll,
      loading,
      dataAdded,
      errorMsg,
      error,
      success,
      submittedData,
      viewData,
      inputArray,
      tableErrorValue,
      tableValueDetailArray,
      billError,
    } = this.state;
    const {
      module,
      selectedList,
      editList,
      seterrorMsgs,
      errorMsgs,
      noAddAnother,
    } = this.props;

    return (
      <Dialog
        open={true}
        onClose={() => {
          if (dataAdded) {
            // this.props.fetchAgain(billError);
          }
          this.props.handleClose();
        }}
        scroll={scroll}
        ref={(e) => {
          try {
            const target = ReactDOM.findDOMNode(e).children
              ? ReactDOM.findDOMNode(e).children[2].children[0]
              : null;
            target.style.borderRadius = 0;
            target.style.width = "90%";
            target.style.maxWidth = "100%";
            target.style.height = "calc(100vh - 40px)";
            target.style.minHeight = "calc(100vh - 40px)";

            target.style.maxHeight = "calc(100vh - 40px)";
            target.style.margin = "20px 0";
            target.style.position = "relative";
            target.style.overflow = "hidden";
          } catch (e) {}
        }}
      >
        {loading ? (
          <Backdrop
            style={{ zIndex: 1, color: "#fff", position: "absolute" }}
            open={loading}
          >
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
        {error || success ? (
          <SnackbarComponent
            error={error}
            autoHideDuration={error ? 5000 : 2000}
            success={success}
            snackbarClose={() => {
              if (this.props.clearError) {
                this.props.clearError();
              }
              if (
                success === `${module} Edited` ||
                success === `${module} Added`
              ) {
                this.setState({ error: "", success: "" }, () => {
                  var myInterval = setInterval(async () => {
                    this.props.fetchAgain(billError);
                    this.props.handleClose();

                    clearInterval(myInterval);
                  }, 100);
                });
              }
              // else if (success === `${module} Edited`)  {
              //   if (success&& this.props.closeOnSave) {
              //     // this.props.fetchAgain(billError);
              //     this.props.handleClose();
              //   }
              //   this.setState({ error: "", success: "" });
              // }
            }}
          />
        ) : (
          ""
        )}

        <DialogActions
          // className="d-flex justifySB"
          style={{ background: primary, color: "white", padding: "10px 24px" }}
        >
          <h3 style={styles.heading}>
            {editList ? "Edit " : "Add "}
            {trimEnd(module, "s")}
          </h3>

          <Tooltip title="close" arrow placement="top">
            <IconButton
              style={{ padding: "5px", borderRadius: "5px" }}
              onClick={() => {
                if (dataAdded) {
                  this.props.fetchAgain(billError);
                }
                this.props.handleClose();
              }}
            >
              <CrossIcon strokeWidth="2" />
            </IconButton>
          </Tooltip>
        </DialogActions>
        <DialogContent
          dividers={scroll === "paper"}
          className="dashedDividerBottom"
          style={{ height: "100%" }}
        >
          {dataAdded ? (
            <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
              <CheckCircle
                style={{
                  width: "100px",
                  height: "100px",
                  color: "rgb(241 154 48)",
                }}
              />
              <span
                className="heading"
                style={{ color: "rgb(241 154 48)", margin: "10px 0 0 0" }}
              >
                {dataAdded}
              </span>
            </div>
          ) : (
            <div className="height-100 width-100 d-flex">
              <div className="heightFC width-100 d-flex flexWrap">
                {console.log("inputArray", inputArray)}
                {/* Top Fields */}
                {inputArray &&
                  inputArray.length > 0 &&
                  inputArray.slice(0, 11).map((singleInput, i) =>
                    singleInput.type === "textField" ? (
                      <div style={styles.withDivider50}>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            color: "rgb(76, 89, 103)",
                            fontWeight: 500,
                            margin: "0 0 5px 0",
                          }}
                        >{`${
                          singleInput.name.charAt(0).toUpperCase() +
                          singleInput.name.slice(1)
                        } ${
                          singleInput.not_required === true ? "" : "*"
                        }`}</div>
                        <CssTextField
                          key={`${i}input`}
                          className={`${
                            singleInput.upperCase
                              ? ""
                              : singleInput.capitalize
                              ? "textC"
                              : ""
                          } ${
                            singleInput.disabled &&
                            singleInput.disabled === true
                              ? "disabled"
                              : ""
                          }`}
                          required={
                            singleInput.not_required
                              ? !singleInput.not_required
                              : true
                          }
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : false
                          }
                          autoComplete="off"
                          disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          onKeyDown={(evt) => {
                            if (singleInput.only_number === true) {
                              if (evt.key === ".") {
                                const value = evt.target.value;
                                // Prevent multiple dots in the number
                                if (value.includes(".")) {
                                  evt.preventDefault();
                                }
                              }
                            }
                            if (singleInput.dataType === "number") {
                              (["e", "E", "+", "-"].includes(evt.key) ||
                                evt.which === 38 ||
                                evt.which === 40) &&
                                evt.preventDefault();
                            }
                          }}
                          onInput={(e) => {
                            if (singleInput.onInput === "phone_number") {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10);
                            }
                          }}
                          InputProps={{
                            inputProps: {
                              min: singleInput.min ? singleInput.min : 0,
                              max:
                                singleInput.endAdornment === "%"
                                  ? 100
                                  : singleInput.max
                                  ? singleInput.max
                                  : 0,
                            },
                            endAdornment: singleInput.endAdornment
                              ? singleInput.endAdornment
                              : "",
                            startAdornment: singleInput.startAdornment
                              ? singleInput.startAdornment
                              : "",
                          }}
                          style={{ width: "100%" }}
                          id="outlined-basic"
                          type={singleInput.dataType}
                          onFocus={
                            singleInput.dataType === "number"
                              ? StopScroll
                              : () => {}
                          }
                          value={
                            viewData[singleInput.name]
                              ? viewData[singleInput.name]
                              : ""
                          }
                          name={singleInput.name}
                          onChange={(event) => {
                            this.handleChange(
                              event,
                              singleInput,
                              errorMsgs,
                              seterrorMsgs
                            );
                            // var errorMsg = this.state.errorMsg;
                            // errorMsg[event.target.name] = false;
                            // if (
                            //   singleInput.name === "Tare Weight" ||
                            //   singleInput.name === "Gross Weight"
                            // ) {
                            //   errorMsg["Net Weight"] = false;
                            // }
                            // const submittedData = this.state.submittedData;
                            // if (
                            //   singleInput.endAdornment === "%" &&
                            //   event.target.value > 100
                            // ) {
                            //   return; // Prevent updating value if it's greater than 100%
                            // } else {
                            //   submittedData[event.target.name] =
                            //     event.target.value && singleInput.upperCase
                            //       ? event.target.value.toUpperCase()
                            //       : event.target.value.includes(".")
                            //       ? event.target.value.match(/^\d*\.?\d{0,3}$/) ? event.target.value : event.preventDefault()
                            //       : event.target.value
                            // }
                            // if (
                            //   errorMsgs &&
                            //   errorMsgs[singleInput.name] &&
                            //   seterrorMsgs
                            // ) {
                            //   seterrorMsgs(singleInput.name);
                            // }
                            // if (singleInput.onChange) {
                            //   singleInput
                            //     .onChange(submittedData)
                            //     .then((submittedData) => {
                            //       this.setState({
                            //         submittedData,
                            //         errorMsg,
                            //       });
                            //     });
                            // } else {
                            //   this.setState({
                            //     submittedData,
                            //     errorMsg,
                            //   });
                            // }
                          }}
                          variant="outlined"
                        />
                      </div>
                    ) : singleInput.type === "select" ? (
                      <div style={styles.withDivider50}>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            color: "rgb(76, 89, 103)",
                            fontWeight: 500,
                            margin: "0 0 5px 0",
                          }}
                        >{`${
                          singleInput.name.charAt(0).toUpperCase() +
                          singleInput.name.slice(1)
                        } ${
                          singleInput.not_required === true ? "" : "*"
                        }`}</div>
                        <CssTextField
                          key={`${i}input`}
                          className={
                            singleInput.disabled &&
                            singleInput.disabled === true
                              ? "disabled"
                              : ""
                          }
                          placeholder="Select the value"
                          required={
                            singleInput.not_required
                              ? !singleInput.not_required
                              : true
                          }
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : false
                          }
                          autoComplete="off"
                          value={submittedData[singleInput.name]}
                          disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          style={{ width: "100%" }}
                          id="outlined-select-currency-native"
                          select
                          // label={
                          //   singleInput.name.charAt(0).toUpperCase() +
                          //   singleInput.name.slice(1)
                          // }
                          SelectProps={{ disableunderline: "true" }}
                          variant="outlined"
                          onChange={(e) => {
                            const id = `${singleInput.name}Id`;
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] = e.target.value;
                            if (
                              errorMsgs &&
                              errorMsgs[singleInput.name] &&
                              seterrorMsgs
                            ) {
                              seterrorMsgs(singleInput.name);
                            }
                            var errorMsg = this.state.errorMsg;
                            errorMsg[singleInput.name] = false;
                            submittedData[id] = e.currentTarget.id;
                            if (singleInput.onChange) {
                              singleInput
                                .onChange(
                                  submittedData,
                                  () => {
                                    this.setState({ loading: true });
                                  },
                                  () => {
                                    this.setState({ loading: false });
                                  }
                                )
                                .then((submittedData) => {
                                  this.setState({
                                    submittedData,
                                    errorMsg,
                                    // loading: false,
                                  });
                                });
                            } else {
                              this.setState({
                                submittedData,
                                errorMsg,
                              });
                            }
                          }}
                        >
                          {singleInput &&
                          singleInput.array &&
                          singleInput.array.length > 0
                            ? singleInput.array.map((option) => (
                                <MenuItem
                                  key={option[singleInput.value]}
                                  value={option[singleInput.value]}
                                  id={
                                    option.id
                                      ? option.id
                                      : option[singleInput.value]
                                  }
                                >
                                  {option[singleInput.value]}
                                </MenuItem>
                              ))
                            : ""}
                        </CssTextField>
                      </div>
                    ) : singleInput.type === "Month" ? (
                      <div style={styles.withDivider50}>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            color: "rgb(76, 89, 103)",
                            fontWeight: 500,
                            margin: "0 0 5px 0",
                          }}
                        >{`${
                          singleInput.name.charAt(0).toUpperCase() +
                          singleInput.name.slice(1)
                        } ${
                          singleInput.not_required === true ? "" : "*"
                        }`}</div>
                        <MuiPickersUtilsProvider
                          libInstance={moment()}
                          utils={DateFnsUtils}
                          key={`${i}input`}
                        >
                          <CssKeyboardDatePicker
                            className={
                              singleInput.disabled &&
                              singleInput.disabled === true
                                ? "disabled"
                                : ""
                            }
                            disabled={
                              singleInput.disabled
                                ? singleInput.disabled
                                : false
                            }
                            variant="inline"
                            onOpen={() => {
                              this.setState({ [`calenderOpen${i}`]: true });
                            }}
                            onClose={() => {
                              this.setState({ [`calenderOpen${i}`]: false });
                            }}
                            onKeyUp={(ev) => {
                              ev.preventDefault();
                            }}
                            onKeyDown={(ev) => {
                              ev.preventDefault();
                            }}
                            maxDate={new Date()}
                            style={{ width: "100%" }}
                            disableFuture
                            // minDate={"2022-01-22"}
                            error={false}
                            invalidLabel=""
                            onKeyPress={(ev) => {
                              ev.preventDefault();
                            }}
                            allowKeyboardControl={false}
                            keyboardIcon={
                              this.state[`calenderOpen${i}`] ? (
                                <EventIcon />
                              ) : (
                                <EventIcon />
                              )
                            }
                            //
                            autoOk
                            inputVariant="outlined"
                            // label={singleInput.name}
                            format="do MMM, yyyy"
                            value={
                              submittedData[singleInput.name]
                                ? submittedData[singleInput.name]
                                : new Date()
                            }
                            views={singleInput.views && singleInput.views} //['year', 'month']}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={(newValue) => {
                              const submittedData = this.state.submittedData;
                              submittedData[singleInput.name] = newValue;

                              this.setState({
                                submittedData,
                              });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    ) : singleInput.type === "multiInput" ? (
                      <div style={styles.withDivider50}>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            color: "rgb(76, 89, 103)",
                            fontWeight: 500,
                            margin: "0 0 5px 0",
                          }}
                        >{`${
                          singleInput.name.charAt(0).toUpperCase() +
                          singleInput.name.slice(1)
                        } ${
                          singleInput.not_required === true ? "" : "*"
                        }`}</div>
                        <MultipleSelectBar
                          key={`${i}input`}
                          className={
                            singleInput.disabled &&
                            singleInput.disabled === true
                              ? "disabled"
                              : ""
                          }
                          width={"calc(25% - 20px)"}
                          margin={"10px"}
                          value={
                            submittedData[singleInput.name]
                              ? submittedData[singleInput.name]
                              : []
                          }
                          array={
                            singleInput &&
                            singleInput.array &&
                            singleInput.array.length > 0
                              ? singleInput.array
                              : []
                          }
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : false
                          }
                          title={singleInput.value}
                          selectedArr={(val) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg[singleInput.name] = false;
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] = val;

                            submittedData[`${singleInput.name}Id`] =
                              val &&
                              val.map((sA) => {
                                return sA.id;
                              });

                            this.setState({ errorMsg, submittedData });
                          }}
                        />
                      </div>
                    ) : singleInput.type === "checkBox" ? (
                      <div style={styles.withDivider50}>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            color: "rgb(76, 89, 103)",
                            fontWeight: 500,
                            margin: "0 0 5px 0",
                          }}
                        >{`${
                          singleInput.name.charAt(0).toUpperCase() +
                          singleInput.name.slice(1)
                        } ${
                          singleInput.not_required === true ? "" : "*"
                        }`}</div>
                        <FormControlLabel
                          key={`${i}input`}
                          style={{ margin: "0 10px" }}
                          onChange={(newValue) => {
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] =
                              submittedData[singleInput.name] === true ||
                              submittedData[singleInput.name] === "Paid"
                                ? false
                                : true;

                            this.setState({
                              submittedData,
                            });
                          }}
                          control={
                            <Checkbox
                              checked={
                                submittedData[singleInput.name]
                                  ? submittedData[singleInput.name]
                                  : false
                              }
                            />
                          }
                          // label={singleInput.name}
                        />
                      </div>
                    ) : (
                      ""
                    )
                  )}
                {/* Middle Field Table */}
                <div className="width-100 d-flex heightFC">
                  {this.props.tableData
                    ? this.props.tableData(
                        editList,
                        submittedData,
                        selectedList
                      )
                    : ""}
                </div>
                {/* Bottom Fields */}
                <div
                  className="width-100 d-flex heightFC flexWrap"
                  onClick={() => {
                    if (this.props.whenAddedBeforeValue) {
                      this.props.whenAddedBeforeValue();
                    }
                  }}
                >
                  {inputArray &&
                    inputArray.length > 0 &&
                    inputArray
                      .slice(11, inputArray.length)
                      .map((singleInput, i) =>
                        singleInput.type === "textField" ? (
                          <div style={styles.withDivider50}>
                            <div
                              style={{
                                fontSize: "0.85rem",
                                color: "rgb(76, 89, 103)",
                                fontWeight: 500,
                                margin: "0 0 5px 0",
                              }}
                            >{`${
                              singleInput.name.charAt(0).toUpperCase() +
                              singleInput.name.slice(1)
                            } ${
                              singleInput.not_required === true ? "" : "*"
                            }`}</div>
                            <CssTextField
                              key={`${i}input`}
                              className={`${
                                singleInput.upperCase
                                  ? ""
                                  : singleInput.capitalize
                                  ? "textC"
                                  : ""
                              }`}
                              required={true}
                              error={errorMsg[singleInput.name] ? true : false}
                              helperText={
                                errorMsg[singleInput.name]
                                  ? errorMsg[singleInput.name]
                                  : false
                              }
                              autoComplete="off"
                              disabled={
                                singleInput.disabled
                                  ? singleInput.disabled
                                  : tableErrorValue &&
                                    tableErrorValue.length > 0 &&
                                    this.getBagsCount(tableErrorValue) !== 0
                                  ? false
                                  : true
                              }
                              onInput={(e) => {
                                if (singleInput.onInput === "phone_number") {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 10);
                                }
                              }}
                              InputProps={{
                                inputProps: {
                                  min: singleInput.min ? singleInput.min : 0,
                                  max:
                                    singleInput.endAdornment === "%"
                                      ? 100
                                      : singleInput.max
                                      ? singleInput.max
                                      : 0,
                                },
                                endAdornment: singleInput.endAdornment
                                  ? // ? submittedData[singleInput.name]
                                    singleInput.endAdornment
                                  : // : ''
                                    "",
                                startAdornment: singleInput.startAdornment
                                  ? // ? submittedData[singleInput.name]
                                    singleInput.startAdornment
                                  : // : ''
                                    "",
                              }}
                              style={{ width: "100%" }}
                              id="outlined-basic"
                              // label={
                              //   singleInput.name.charAt(0).toUpperCase() +
                              //   singleInput.name.slice(1)
                              // }
                              type={singleInput.dataType}
                              onFocus={
                                singleInput.dataType === "number"
                                  ? StopScroll
                                  : () => {}
                              }
                              value={viewData[singleInput.name]}
                              name={singleInput.name}
                              onKeyDown={(evt) => {
                                if (singleInput.dataType === "number") {
                                  (["e", "E", "+", "-"].includes(evt.key) ||
                                    evt.which === 38 ||
                                    evt.which === 40) &&
                                    evt.preventDefault();
                                }
                              }}
                              onChange={(event) => {
                                this.handleBottomFieldChange(event,singleInput, errorMsgs, seterrorMsgs)
                                // if (
                                //   this.props.whenAddedBeforeValue() &&
                                //   this.props.whenAddedBeforeValue() === true
                                // ) {
                                //   var errorMsg = this.state.errorMsg;
                                //   errorMsg[event.target.name] = false;
                                //   if (
                                //     singleInput.name === "Tare Weight" ||
                                //     singleInput.name === "Gross Weight"
                                //   ) {
                                //     errorMsg["Net Weight"] = false;
                                //   }
                                //   const submittedData =
                                //     this.state.submittedData;
                                //   if (
                                //     errorMsgs &&
                                //     errorMsgs[singleInput.name] &&
                                //     seterrorMsgs
                                //   ) {
                                //     seterrorMsgs(singleInput.name);
                                //   }
                                //   if (
                                //     singleInput.endAdornment === "%" &&
                                //     event.target.value > 100
                                //   ) {
                                //   } else {
                                //     submittedData[event.target.name] =
                                //       event.target.value &&
                                //       singleInput.upperCase
                                //         ? event.target.value.toUpperCase()
                                //         : event.target.value.includes(".")
                                //         ? event.target.value.match(
                                //             /^\d*\.?\d{0,3}$/
                                //           )
                                //           ? event.target.value
                                //           : event.preventDefault()
                                //         : event.target.value;
                                //   }
                                //   if (singleInput.onChange) {
                                //     singleInput
                                //       .onChange(submittedData)
                                //       .then((submittedData) => {
                                //         this.setState({
                                //           submittedData,
                                //           errorMsg,
                                //         });
                                //       });
                                //   } else {
                                //     this.setState({
                                //       submittedData,
                                //       errorMsg,
                                //     });
                                //   }
                                // } else {
                                // }
                              }}
                              variant="outlined"
                            />
                          </div>
                        ) : singleInput.type === "select" ? (
                          <div style={styles.withDivider50}>
                            <div
                              style={{
                                fontSize: "0.85rem",
                                color: "rgb(76, 89, 103)",
                                fontWeight: 500,
                                margin: "0 0 5px 0",
                              }}
                            >{`${
                              singleInput.name.charAt(0).toUpperCase() +
                              singleInput.name.slice(1)
                            } ${
                              singleInput.not_required === true ? "" : "*"
                            }`}</div>
                            <CssTextField
                              key={`${i}input`}
                              required={
                                singleInput.not_required
                                  ? !singleInput.not_required
                                  : true
                              }
                              error={errorMsg[singleInput.name] ? true : false}
                              helperText={
                                errorMsg[singleInput.name]
                                  ? errorMsg[singleInput.name]
                                  : false
                              }
                              autoComplete="off"
                              value={submittedData[singleInput.name]}
                              disabled={
                                singleInput.disabled
                                  ? singleInput.disabled
                                  : tableErrorValue &&
                                    tableErrorValue.length > 0 &&
                                    this.getBagsCount(tableErrorValue) !== 0
                                  ? false
                                  : true
                              }
                              style={{ width: "100%" }}
                              id="outlined-select-currency-native"
                              select
                              // label={
                              //   singleInput.name.charAt(0).toUpperCase() +
                              //   singleInput.name.slice(1)
                              // }
                              SelectProps={{ disableunderline: "true" }}
                              variant="outlined"
                              onChange={(e) => {
                                const id = `${singleInput.name}Id`;
                                const submittedData = this.state.submittedData;
                                submittedData[singleInput.name] =
                                  e.target.value;
                                var errorMsg = this.state.errorMsg;
                                if (
                                  errorMsgs &&
                                  errorMsgs[singleInput.name] &&
                                  seterrorMsgs
                                ) {
                                  seterrorMsgs(singleInput.name);
                                }
                                errorMsg[singleInput.name] = false;
                                submittedData[id] = e.currentTarget.id;
                                if (singleInput.onChange) {
                                  singleInput
                                    .onChange(
                                      submittedData,
                                      () => {
                                        this.setState({ loading: true });
                                      },
                                      () => {
                                        this.setState({ loading: false });
                                      }
                                    )
                                    .then((submittedData) => {
                                      this.setState({
                                        submittedData,
                                        errorMsg,
                                      });
                                    });
                                } else {
                                  this.setState({
                                    submittedData,
                                    errorMsg,
                                  });
                                }
                              }}
                            >
                              {singleInput &&
                              singleInput.array &&
                              singleInput.array.length > 0
                                ? singleInput.array.map((option) => (
                                    <MenuItem
                                      key={option[singleInput.value]}
                                      value={option[singleInput.value]}
                                      id={
                                        option.id
                                          ? option.id
                                          : option[singleInput.value]
                                      }
                                    >
                                      {option[singleInput.value]}
                                    </MenuItem>
                                  ))
                                : ""}
                            </CssTextField>
                          </div>
                        ) : singleInput.type === "Month" ? (
                          <div style={styles.withDivider50}>
                            <div
                              style={{
                                fontSize: "0.85rem",
                                color: "rgb(76, 89, 103)",
                                fontWeight: 500,
                                margin: "0 0 5px 0",
                              }}
                            >{`${
                              singleInput.name.charAt(0).toUpperCase() +
                              singleInput.name.slice(1)
                            } ${
                              singleInput.not_required === true ? "" : "*"
                            }`}</div>
                            <MuiPickersUtilsProvider
                              libInstance={moment()}
                              utils={DateFnsUtils}
                              key={`${i}input`}
                            >
                              <CssKeyboardDatePicker
                                className={
                                  singleInput.disabled &&
                                  singleInput.disabled === true
                                    ? "disabled"
                                    : tableErrorValue &&
                                      tableErrorValue.length > 0 &&
                                      this.getBagsCount(tableErrorValue) !== 0
                                    ? ""
                                    : "disabled"
                                }
                                disabled={
                                  singleInput.disabled
                                    ? singleInput.disabled
                                    : tableErrorValue &&
                                      tableErrorValue.length > 0 &&
                                      this.getBagsCount(tableErrorValue) !== 0
                                    ? false
                                    : true
                                }
                                variant="inline"
                                onOpen={() => {
                                  this.setState({ [`calenderOpen${i}`]: true });
                                }}
                                onClose={() => {
                                  this.setState({
                                    [`calenderOpen${i}`]: false,
                                  });
                                }}
                                onKeyUp={(ev) => {
                                  ev.preventDefault();
                                }}
                                onKeyDown={(ev) => {
                                  ev.preventDefault();
                                }}
                                maxDate={new Date()}
                                style={{ width: "100%" }}
                                disableFuture
                                // minDate={"2022-01-22"}
                                error={false}
                                invalidLabel=""
                                onKeyPress={(ev) => {
                                  ev.preventDefault();
                                }}
                                allowKeyboardControl={false}
                                keyboardIcon={
                                  this.state[`calenderOpen${i}`] ? (
                                    <EventIcon />
                                  ) : (
                                    <EventIcon />
                                  )
                                }
                                //
                                autoOk
                                inputVariant="outlined"
                                // label={singleInput.name}
                                format="do MMM, yyyy"
                                value={
                                  submittedData[singleInput.name]
                                    ? submittedData[singleInput.name]
                                    : new Date()
                                }
                                views={singleInput.views && singleInput.views} //['year', 'month']}
                                InputAdornmentProps={{ position: "end" }}
                                onChange={(newValue) => {
                                  const submittedData =
                                    this.state.submittedData;
                                  submittedData[singleInput.name] = newValue;

                                  this.setState({
                                    submittedData,
                                  });
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        ) : singleInput.type === "multiInput" ? (
                          // <div style={styles.withDivider50}>
                          //   <div
                          //     style={{
                          //       fontSize: "0.85rem",
                          //       color: "rgb(76, 89, 103)",
                          //       fontWeight: 500,
                          //       margin: "0 0 5px 0",
                          //     }}>{`${
                          //     singleInput.name.charAt(0).toUpperCase() +
                          //     singleInput.name.slice(1)
                          //   } ${
                          //     singleInput.not_required === true ? "" : "*"
                          //   }`}</div>

                          //   <MultipleSelectBar
                          //     key={`${i}input`}
                          //     width={"calc(25% - 20px)"}
                          //     margin={"10px"}
                          //     value={
                          //       submittedData[singleInput.name]
                          //         ? submittedData[singleInput.name]
                          //         : []
                          //     }
                          //     array={
                          //       singleInput &&
                          //       singleInput.array &&
                          //       singleInput.array.length > 0
                          //         ? singleInput.array
                          //         : []
                          //     }
                          //     error={errorMsg[singleInput.name] ? true : false}
                          //     helperText={
                          //       errorMsg[singleInput.name]
                          //         ? errorMsg[singleInput.name]
                          //         : false
                          //     }
                          //     title={singleInput.value}
                          //     selectedArr={(val) => {
                          //       const errorMsg = this.state.errorMsg;
                          //       errorMsg[singleInput.name] = false;
                          //       const submittedData = this.state.submittedData;
                          //       submittedData[singleInput.name] = val;

                          //       submittedData[`${singleInput.name}Id`] =
                          //         val &&
                          //         val.map((sA) => {
                          //           return sA.id;
                          //         });

                          //       this.setState({ errorMsg, submittedData });
                          //     }}
                          //   />
                          // </div>
                          <div
                            style={styles.withDivider50}
                            //  width={
                            //   textFieldWidth ? textFieldWidth : "calc(25% - 20px)"
                            // }
                          >
                            <div
                              style={{
                                fontSize: "0.85rem",
                                color: "rgb(76, 89, 103)",
                                fontWeight: 500,
                                // margin: "0 0 5px 0",
                              }}
                            >{`${
                              singleInput.name.charAt(0).toUpperCase() +
                              singleInput.name.slice(1)
                            } ${
                              singleInput.not_required === true ? "" : "*"
                            }`}</div>
                            <MultipleSelectBar
                              onlyDisable={
                                singleInput.disabled
                                  ? singleInput.disabled
                                  : false
                              }
                              width={"100%"}
                              // width={
                              //   textFieldWidth ? textFieldWidth : "calc(25% - 20px)"
                              // }
                              style={{ border: "1px solid rgb(0 0 0 / 1%)" }}
                              margin={"5px"}
                              noLabel={true}
                              value={
                                submittedData[singleInput.name]
                                  ? submittedData[singleInput.name]
                                  : []
                              }
                              array={
                                singleInput &&
                                singleInput.array &&
                                singleInput.array.length > 0
                                  ? singleInput.array
                                  : []
                              }
                              error={errorMsg[singleInput.name] ? true : false}
                              helperText={
                                errorMsg[singleInput.name]
                                  ? errorMsg[singleInput.name]
                                  : false
                              }
                              title={singleInput.value}
                              selectedArr={(val) => {
                                const errorMsg = this.state.errorMsg;
                                errorMsg[singleInput.name] = false;
                                const submittedData = this.state.submittedData;
                                submittedData[singleInput.name] = val;

                                submittedData[`${singleInput.name}Id`] =
                                  val &&
                                  val.map((sA) => {
                                    return sA.id;
                                  });

                                this.setState({ errorMsg, submittedData });
                              }}
                            />
                          </div>
                        ) : singleInput.type === "checkBox" ? (
                          <div style={styles.withDivider50}>
                            <div
                              style={{
                                fontSize: "0.85rem",
                                color: "rgb(76, 89, 103)",
                                fontWeight: 500,
                                margin: "0 0 5px 0",
                              }}
                            >{`${
                              singleInput.name.charAt(0).toUpperCase() +
                              singleInput.name.slice(1)
                            } ${
                              singleInput.not_required === true ? "" : "*"
                            }`}</div>
                            <FormControlLabel
                              key={`${i}input`}
                              style={{ margin: "0 10px" }}
                              onChange={(newValue) => {
                                const submittedData = this.state.submittedData;
                                submittedData[singleInput.name] =
                                  submittedData[singleInput.name] === true ||
                                  submittedData[singleInput.name] === "Paid"
                                    ? false
                                    : true;

                                this.setState({
                                  submittedData,
                                });
                              }}
                              control={
                                <Checkbox
                                  checked={
                                    submittedData[singleInput.name]
                                      ? submittedData[singleInput.name]
                                      : false
                                  }
                                />
                              }
                              // label={singleInput.name}
                            />
                          </div>
                        ) : (
                          ""
                        )
                      )}
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            className="d-flex justifyFE"
            style={{ width: "calc(100% - 400px)" }}
          >
            {editList && dataAdded ? (
              ""
            ) : !editList && dataAdded ? (
              noAddAnother ? (
                ""
              ) : (
                <StyledButton
                  onClick={() => {
                    this.reset();
                  }}
                  name={"Add Another"}
                  withBg={true}
                  background={"rgb(241 154 48)"}
                  width="120px"
                />
              )
            ) : (
              <>
                <StyledButton
                  onClick={() => {
                    this.reset();
                  }}
                  name={editList ? "Reset to default" : "Reset"}
                  // withBg={true}
                  // background={primary}
                  // width="120px"
                  margin="0 10px 0 0"
                />
                <StyledButton
                  onClick={() => {
                    const { submittedData } = this.state;
                    let submitData = {};
                    const correctData = [];
                    inputArray
                      .filter((sA) => sA.api)
                      .map((singleInput, i) => {
                        if (i === 12) {
                          if (
                            this.props.tableErrorValue &&
                            this.props.tableErrorValue.length > 0
                          ) {
                          } else {
                            this.props.settableErrorValue();
                          }
                        }
                        if (
                          (singleInput.type === "checkBox" &&
                            (submittedData[singleInput.name] === false ||
                              submittedData[singleInput.name] === true)) ||
                          singleInput.not_required === true ||
                          (singleInput.dataType === "number"
                            ? singleInput.zeroAccepted &&
                              Number(submittedData[singleInput.name]) === 0
                              ? true
                              : submittedData[singleInput.name] &&
                                Number(submittedData[singleInput.name]) > 0
                            : submittedData[singleInput.name])
                        ) {
                          if (singleInput.type === "Month") {
                            correctData.push(singleInput.name);

                            if (singleInput.api) {
                              submitData[singleInput.api] = moment(
                                submittedData[singleInput.name]
                              ).format("YYYY-MM-DD");
                            }
                          } else {
                            correctData.push(singleInput.name);

                            if (singleInput.api) {
                              submitData[singleInput.api] = submittedData[
                                `${singleInput.name}Id`
                              ]
                                ? submittedData[`${singleInput.name}Id`] === "-"
                                  ? null
                                  : singleInput.numeric
                                  ? Number(
                                      submittedData[`${singleInput.name}Id`]
                                    )
                                  : submittedData[`${singleInput.name}Id`]
                                : singleInput.dataType === "number"
                                ? singleInput.onInput === "gst_in" ||
                                  singleInput.onInput === "pincode"
                                  ? submittedData[singleInput.name]
                                  : Number(submittedData[singleInput.name])
                                : submittedData[singleInput.name] === "-"
                                ? null
                                : submittedData[singleInput.name];
                            }
                          }
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg[singleInput.name] =
                            singleInput.dataType === "number" &&
                            submittedData[singleInput.name] &&
                            Number(submittedData[singleInput.name]) < 0
                              ? "Negative value is not accepted"
                              : `Please add ${singleInput.name} !`;
                          return this.setState({ errorMsg });
                        }
                      });

                    if (
                      inputArray &&
                      inputArray.length &&
                      inputArray.filter((sA) => sA.api).length ===
                        correctData.length
                    ) {
                      this.setState({ loading: true });

                      submitData["total_bags"] = tableErrorValue
                        .reduce(
                          (previousValue, currentValue) =>
                            Number(previousValue) +
                            Number(
                              filterData(
                                tableValueDetailArray,
                                "po_item_id",
                                currentValue,
                                "onlyOne"
                              )
                                ? filterData(
                                    tableValueDetailArray,
                                    "po_item_id",
                                    currentValue,
                                    "onlyOne"
                                  )["packagageArrUsed"]
                                  ? filterData(
                                      tableValueDetailArray,
                                      "po_item_id",
                                      currentValue,
                                      "onlyOne"
                                    )["packagageArrUsed"].reduce(
                                      (previousValue1, currentValue1) =>
                                        Number(previousValue1) +
                                        (currentValue1.used_bags
                                          ? Number(currentValue1.used_bags)
                                          : 0),
                                      0
                                    )
                                  : 0
                                : 0
                            ),
                          0
                        )
                        .toFixed(3);
                      submitData["dispatch_weight"] = tableErrorValue
                        .reduce(
                          (previousValue, currentValue) =>
                            Number(previousValue) +
                            Number(
                              filterData(
                                tableValueDetailArray,
                                "po_item_id",
                                currentValue,
                                "onlyOne"
                              )
                                ? filterData(
                                    tableValueDetailArray,
                                    "po_item_id",
                                    currentValue,
                                    "onlyOne"
                                  )["packagageArrUsed"]
                                  ? filterData(
                                      tableValueDetailArray,
                                      "po_item_id",
                                      currentValue,
                                      "onlyOne"
                                    )["packagageArrUsed"].reduce(
                                      (previousValue1, currentValue1) =>
                                        Number(previousValue1) +
                                        (currentValue1.total_dispatch
                                          ? Number(currentValue1.total_dispatch)
                                          : 0),
                                      0
                                    )
                                  : 0
                                : 0
                            ),
                          0
                        )
                        .toFixed(3);

                      const newPOTOADDF = {};
                      newPOTOADDF.dispatch = submitData;
                      const newDispatchPackagingARr = [];
                      const newDataArr = tableErrorValue.map((currentValue) => {
                        const arrVal =
                          tableValueDetailArray.filter(
                            (sA) => sA.po_item_id === currentValue
                          ) &&
                          tableValueDetailArray.filter(
                            (sA) => sA.po_item_id === currentValue
                          )[0];

                        if (arrVal) {
                          const submitData2 = {
                            total_bags: arrVal.total_bags
                              ? arrVal.total_bags
                              : arrVal["packagageArrUsed"] &&
                                arrVal["packagageArrUsed"].length > 0
                              ? arrVal["packagageArrUsed"].reduce(
                                  (previousValue, currentValue) =>
                                    Number(previousValue) +
                                    Number(
                                      currentValue.used_bags
                                        ? currentValue.used_bags
                                        : 0
                                    ),
                                  0
                                )
                              : 0,
                            is_active: true,
                            brand: arrVal["Brandid"],
                            item_category: arrVal["Item Categoryid"],
                            item_type: arrVal["Item Typeid"],
                            dispatch_packaging:
                              arrVal["packagageArrUsed"] &&
                              arrVal["packagageArrUsed"].length > 0
                                ? arrVal["packagageArrUsed"].map((sp) => {
                                    const newObj = {
                                      dispatch_items: sp.dispatch_items
                                        ? sp.dispatch_items
                                        : "",
                                      net_weight: sp.net_weight
                                        ? sp.net_weight
                                        : "",
                                      total_bags_dispatched: Number(
                                        sp.used_bags
                                      ),
                                      packaging: sp.packaging,
                                      id: sp.id,
                                    };
                                    newDispatchPackagingARr.push(newObj);

                                    const newIb = {
                                      packaging: Number(sp.id),
                                      total_bags_dispatched: Number(
                                        sp.used_bags
                                      ),
                                    };
                                    return newIb;
                                  })
                                : [],
                            hsn_code: arrVal["HSN Codeid"],
                            packaging:
                              arrVal["packagageArrUsed"] &&
                              arrVal["packagageArrUsed"].length > 0
                                ? arrVal["packagageArrUsed"].map((sp) => {
                                    return sp.id;
                                  })
                                : [],
                          };
                          submitData2["total_quantity"] =
                            arrVal["packagageArrUsed"] &&
                            arrVal["packagageArrUsed"].length > 0
                              ? arrVal["packagageArrUsed"].reduce(
                                  (previousValue1, currentValue1) =>
                                    Number(previousValue1) +
                                    (currentValue1.total_dispatch
                                      ? Number(currentValue1.total_dispatch)
                                      : 0),
                                  0
                                )
                              : 0;
                          if (editList) {
                            submitData2["dispatch"] = selectedList.id;

                            submitData2["id"] = arrVal.id;

                            submitData2["po_item"] = arrVal.po_item;
                          } else {
                            submitData2["po_item"] = arrVal.po_item_id;
                          }

                          return submitData2;
                        } else {
                          return false;
                        }
                      });

                      newPOTOADDF.dispatch_items = newDataArr.filter(
                        (sD) => sD !== false
                      );

                      if (newPOTOADDF.dispatch_items.length === 0) {
                        this.setState({
                          error: "Please select atleast one po item!",
                        });
                      } else if (
                        (submitData["dispatch_weight"]
                          ? submitData["dispatch_weight"]
                          : 0) <=
                        (this.state.remaining_quantity
                          ? this.state.remaining_quantity
                          : 0)

                        //  ( submitData["remaining_quantity"] =
                        //   arrVal["packagageArrUsed"] &&
                        //   arrVal["packagageArrUsed"].length > 0
                        //     ? arrVal["packagageArrUsed"].reduce(
                        //         (previousValue1, currentValue1) =>
                        //           Number(previousValue1) +
                        //           (currentValue1.total_dispatch
                        //             ? Number(currentValue1.total_dispatch)
                        //             : 0),
                        //         0
                        //       )
                        //     : 0

                        //   )
                      ) {
                        this.props
                          .postData(
                            editList ? "patch" : "post",

                            editList ? this.props.editApi : this.props.postApi,
                            editList ? selectedList.id : null,
                            "postdata",
                            editList ? submitData : newPOTOADDF,
                            null,
                            "noeditparam"
                          )
                          .then((res) => {
                            if (res.error) {
                              this.setState({
                                error:
                                  typeof res.data === "string"
                                    ? res.data
                                    : res.data.length > 0
                                    ? res.data
                                        .map((s) => {
                                          return `${
                                            s.field ? `${s.field} :` : ""
                                          } ${
                                            typeof s.message === "string"
                                              ? s.message
                                              : s.message.length > 0 &&
                                                s.message[0] &&
                                                s.message[0]
                                          }`;
                                        })
                                        .join(" ,")
                                    : res.data,
                                loading: false,
                              });
                            } else {
                              this.setState({ billError: res.toString() });

                              if (editList) {
                                this.props
                                  .postData(
                                    "put",
                                    "dispatch/dispatch-packaging/bulk-update",
                                    null,
                                    "postdata",
                                    newDispatchPackagingARr
                                  )
                                  .then((res2) => {
                                    if (res2.error) {
                                      this.setState({
                                        error:
                                          typeof res2.data === "string"
                                            ? res2.data
                                            : res2.data.length > 0
                                            ? res2.data
                                                .map((s) => {
                                                  return `${
                                                    s.field
                                                      ? `${s.field} :`
                                                      : ""
                                                  } ${
                                                    typeof s.message ===
                                                    "string"
                                                      ? s.message
                                                      : s.message.length > 0 &&
                                                        s.message[0] &&
                                                        s.message[0]
                                                  }`;
                                                })
                                                .join(" ,")
                                            : res2.data,
                                        loading: false,
                                      });
                                    } else {
                                      this.props
                                        .postData(
                                          "put",

                                          "dispatch/dispatch-items/bulk-update",
                                          null,
                                          "postdata",
                                          newDataArr.filter(
                                            (sD) => sD !== false
                                          )
                                        )
                                        .then((res1) => {
                                          if (res1.error) {
                                            this.setState({
                                              error: res1.data,
                                              loading: false,
                                            });
                                          } else {
                                            this.setState(
                                              {
                                                dataAdded: editList
                                                  ? `${module} Edited`
                                                  : `New ${module} Added`,
                                                loading: false,
                                              },
                                              () => {
                                                // this.setState(
                                                //   {
                                                //     success: editList
                                                //       ? `${module} Edited`
                                                //       : "",
                                                //   },)
                                                this.props.fetchAgain(
                                                  billError,
                                                  editList
                                                    ? `${module} Edited`
                                                    : `${module} Added`
                                                );
                                              }
                                            );
                                          }
                                        });
                                    }
                                  });
                              } else {
                                this.setState(
                                  {
                                    dataAdded: `New ${module} Added`,
                                    loading: false,
                                  },
                                  () => {
                                    // this.setState(
                                    //   {
                                    //     success: editList
                                    //       ? `${module} Added`
                                    //       : "",
                                    //   },)
                                    this.props.fetchAgain(
                                      billError,
                                      editList
                                        ? `${module} Edited`
                                        : `${module} Added`
                                    );
                                  }
                                );
                                // this.setState(
                                //   {
                                //     dataAdded: `New ${module} Added`,
                                //     loading: false,
                                //   },
                                // ()=>{
                                //   this.setState(
                                //   {
                                //     success: `${module} Added` ,

                                //   },
                                //   () => {

                                //     if (editList||this.props.closeOnSave) {

                                //       this.props.fetchAgain(
                                //         this.state.billError
                                //       );
                                //     }
                                //   }
                                // );})
                              }
                            }
                          });
                      } else {
                        this.setState({
                          loading: false,
                          error: `Maximum ${this.state.remaining_quantity} PO quantity is available!`,
                        });
                      }
                    } else {
                    }
                  }}
                  name={editList ? "Update" : "Submit"}
                  withBg={true}
                  background={"rgb(241 154 48)"}
                  width="120px"
                />
              </>
            )}
          </div>{" "}
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateDialogPO;
