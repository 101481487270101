import React, { Component } from "react";
import Layout from "../../layouts/Layout";

import { StyledCircularProgress } from "../../styles/StyledComponents";

import NoData from "../../styles/Illustrations/NoData";
import { Paper } from "@material-ui/core";
import Icon from "@mui/material/Icon";
import { ModeEdit } from "@mui/icons-material";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { EndPoints } from "../../utils/EndPoints";
import Popup from "./components/Popup";
// import DeleteWarning from "./components/DeleteWarning";
import EditForm from "./components/EditForm";
import NewForm from "./components/NewForm";
import { filterData, getSingleModule_widget } from "../../Regex";
import { accessText } from "../../routes/sidebar";
export class ClientsComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Clients"],
        tooltip: `Click to approve pending ${EndPoints["Clients"]}`,
        data: [],
        route: `/Clients/${EndPoints["Clients"]}`,
        goBackRoute: "/Clients",
        primaryField: "client_name",
        widget: null,
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",
        status: false,
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#0AFF00", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.setStateOfPopup.bind(this);
    this.state = {
      tabs: templateTabs,
      summaryData: [],
      Clients: [],
      singlelistView: null,
      listViewArray: [],
      loading: false,
      isPopup: false,
      isNew: false,
    };
  }

  setStateOfPopup = (value) => {
    this.setState({ isPopup: value, isNew: false });
  };

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Clients"] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
            // status: this.state.tabs[0].data[next.match.params["id"]]
            //   ? this.state.tabs[0].data[next.match.params["id"]].is_active
            //   : "",
          },
          () => {
            if (this.state.singlelistView && this.state.singlelistView.id) {
              this.props.ClientsFunction(
                "get",
                this.props.user.uid,
                EndPoints["Clients"],
                this.state.singlelistView.id,
                "Clients"
              );
            }
          }
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }
  listClickHandler = (value) => {
    this.setState(
      {
        singlelistView: value,

        // Status: value.status,
      },
      () => {
        // if (this.state.singlelistView && this.state.singlelistView.id) {
        //   this.props.ClientsFunction(
        //     "get",
        //     EndPoints["Clients"],
        //     this.state.singlelistView.id,
        //     "Clients"
        //   );
        // }
      }
    );
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
    // if (value && value.monthId) {
    //   if (this.state.selectedMonth.monthId !== value.monthId) {
    //     this.setState({ selectedMonth: value, singleDate: null, singleDateTrips: [], singleDateTripsCopy: [] });
    //     this.props.fetchTodos(this.props.user.uid, value.monthId, value.yearId).then((dateRR) => {
    //       this.props.setTodos(dateRR);
    //     });
    //   }
    // }
  };

  // deleteClient = (event) => {
  //   const deleteURL = moduleIdURL(
  //     "masters/clients",
  //     this.state.singlelistView.id
  //   );
  //   requests({
  //     method: "delete",
  //     url: deleteURL,
  //   })
  //     .then((response) => {

  //       if (response.data) {
  //         this.setState({ singlelistView: response.data });
  //         // this.setState({ singlelistView: null });

  //       }
  //       this.props.ClientsFunction("get", EndPoints["Clients"]);
  //     })
  // };

  setInitialValues(props) {
    const { widget_permission } = getSingleModule_widget(
      props.user,
      accessText.sales_orders,
      accessText.clients
    );
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      // has_read_permission:true,has_create_permission:true
      tab.widget = widget_permission?.widget
        ? { ...widget_permission?.widget }
        : null;
      tab.data =
        props[EndPoints["Clients"]] && props[EndPoints["Clients"]].length > 0
          ? props[EndPoints["Clients"]]
          : [];
      tab.data =
        props[EndPoints["Clients"]] && props[EndPoints["Clients"]].length > 0
          ? props[EndPoints["Clients"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [];
      return tab;
    });
    this.setState({
      listViewArray:
        props[EndPoints["Clients"]] && props[EndPoints["Clients"]].length > 0
          ? props[EndPoints["Clients"]]
          : [],
      Clients: props.Clients,
      tabs,
      loading: props.loading,
    });
  }
  render() {
    const {
      tabs,
      // summaryData,
      listViewArray,
      // Clients,
      // status,
      singlelistView,
      loading,
    } = this.state;
    // const { isMobile ,user} = this.props;
    var labelField = "client_name";
    const { user } = this.props;

    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.sales_orders,
      accessText.clients
    );
    // if (listViewArray && listViewArray.length > 0 && listViewArray[0]) {
    //   Object.keys(listViewArray[0]).map((sKey) => {
    //     if (typeof listViewArray[0][sKey] === "string") {
    //       const aa = listViewArray[0].client_name;

    //       return (labelField = sKey);
    //     } else {
    //       return null;
    //     }
    //   });
    //   // labelField="" //assign search field
    // }
    return (
      <Layout
        loading={loading[EndPoints["Clients"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          // data: listViewArray[0].client_name,
          hintText: `Search Clients`,
          labelField: labelField,
        }}
        listClickHandler={this.listClickHandler}
        fabClickHandler={() => {
        
          localStorage.removeItem("fetchagain-bri")

          this.setState({
            isPopup: true,
            isNew: true,
            // status: true,
          });
        }}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
      >
        <Paper
          className="width-100 height-100 padding15 backgroundWhite"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Clients === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            //  ( <TabComponent   list={[
            //       { name: "All Clients", component: <TableComponent
            //        module={"Clients"}
            //        {...this.state}
            //       //  header={["title"]}
            //       //  inputArray={[
            //       //   { name: "title", type: "textField", dataType: "text" },
            //       //   // { name: "user", type: "select", dataType: "text" },
            //       //   { name: "body", type: "textField", dataType: "text" },
            //       // ]}
            //         {...this.props}
            //         tableList={ Clients && Clients.length ? Clients : [ Clients ]}
            //         postData={this.props.ClientsFunction}
            //         fetchAgain={() => {
            //           if (this.state.singlePosts && this.state.singlePosts.id) {
            //             this.props.ClientsFunction("get", EndPoints["Clients"]);
            //             this.props.ClientsFunction("get", EndPoints["Clients"], this.state.singlelistView.id, "Clients");
            //           }
            //         }}
            //         /> },

            //       { name: "Summary", component: <SummaryTable summaryData={summaryData}{...this.state} {...this.props}/> },
            //     ]}
            //      />)
            <>
              <div style={{ width: "90%" }}>
                <h1 style={{ fontWeight: "400", fontSize: "25px" }}>
                  Client Details
                </h1>
              </div>
              <Paper
                className="padding15 backgroundWhite  postionR overflowYA scrollBluePrimary"
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                 {widget_permission?.widget?.has_update_permission && (
                <div
                  style={{
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    justifyContent: "flex-end",
                    // paddingRight: "4%",
                  }}
                >
                  <Tooltip title="Edit">
                    <Icon
                      onClick={
                        // () =>
                        // this.props.ClientsFunction(
                        //   "get",
                        //   this.props.user.uid,
                        //   EndPoints["Clients"],
                        //   this.state.singlelistView.id,
                        //   "Clients"
                        // ),
                        () => {
                          this.setState({ isPopup: true });
                        }
                      }
                      style={{ cursor: "pointer" }}
                      component={ModeEdit}
                    />
                  </Tooltip>
                
                </div>
                 )}

                <div
                  className="overflowYA scrollBluePrimary"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 50px",
                    height: "calc(100% - 31.7px)",
                    // textTransform: "capitalize",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Name:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        textTransform: "capitalize",
                        wordBreak: "break-all",
                      }}
                    >
                      {singlelistView.client_name}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Address:
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        wordBreak: "break-all",
                      }}
                    >
                      {singlelistView.address}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      City:{" "}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        wordBreak: "break-all",
                      }}
                    >
                      {singlelistView.city}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      State:{" "}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        wordBreak: "break-all",
                      }}
                    >
                      {singlelistView.state}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      wordBreak: "break-all",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Country:{" "}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}
                    >
                      {singlelistView.country}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      wordBreak: "break-all",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Email:{" "}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}
                    >
                      {singlelistView.email}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}
                    >
                      Phone:{" "}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}
                    >
                      {singlelistView.phone}
                    </p>
                  </div>
                  {/* <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontSize: "18px", margin: "8px 0" }}>
                      Status:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        marginLeft: "10px",
                        color: "#808080",
                        margin: "8px 10px",
                      }}
                    >
                      {singlelistView.is_active ? "Active" : "Inactive"}
                    </p>
                  </div> */}
                </div>
              </Paper>
            </>
          )}
        </Paper>
        <Popup
          openPopup={this.state.isPopup}
          setOpenPopup={this.setStateOfPopup}
          isNew={this.state.isNew}
        >
          {this.state.isNew ? (
            <NewForm
              // status={this.status}
              // setState={this.setState}
              setOpenPopup={this.setStateOfPopup}
              getFn={
                () => {
                  
        
          localStorage.setItem("fetchagain-bri","client")
                  this.props.ClientsFunction("get", EndPoints["Clients"])}
                // .then((res) => {
                //   if (res) {
                //     this.setState({ singlelistView: res });
                //   }
                // })
              }
            />
          ) : (
            singlelistView !== null && (
              <EditForm
                isPopup={this.state.isPopup}
                // status={this.status}
                setState={this.setState}
                getFn={(response) => {
                  if (response.data) {
                    this.setState({ singlelistView: response.data });
                  }   localStorage.setItem("fetchagain-bri","client")
                  this.props.ClientsFunction("get", EndPoints["Clients"]);
                }}
                setOpenPopup={this.setStateOfPopup}
                id={singlelistView.id}
                name={singlelistView.client_name}
                address={singlelistView.address}
                city={singlelistView.city}
                state={singlelistView.state}
                country={singlelistView.country}
                email={singlelistView.email}
                phone={singlelistView.phone}
                status={singlelistView.is_active}
              />
            )
          )}
        </Popup>
      </Layout>
    );
  }
}

export default ClientsComponent;
