import React, { Component } from "react";
import Layout from "../../layouts/Layout";

import NoData from "../../styles/Illustrations/NoData";
import moment from "moment";

import { StyledCircularProgress } from "../../styles/StyledComponents";
import { Paper, Tooltip } from "@material-ui/core";
import { EndPoints } from "../../utils/EndPoints";
import CreateDialog from "../../layoutComponents/CreateDialog";
import EditIcon from "@mui/icons-material/Edit";
import { filterData, getSingleModule_widget } from "../../Regex";
import { accessText } from "../../routes/sidebar";
export class BeneficiarysComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: "Beneficiarys",
        tooltip: "Click to approve pending Beneficiarys",
        data: [],
        route: "/Beneficiarys/Beneficiarys",
        goBackRoute: "/Beneficiarys",
        widget: null, 
        primaryField: "name",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#0AFF00", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      Beneficiarys: [],
      singlelistView: null,
      loading: false,
      editList: false,
      openDialog: false,
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Beneficiarys"].split("/")[0] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {}
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    this.setState({
      singlelistView: value,
    });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  setInitialValues(props) {
    const { widget_permission } = getSingleModule_widget(
      props.user,
      accessText.settings,
      accessText.benificiaries
    );
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      // has_read_permission:true,has_create_permission:true
      tab.widget = widget_permission?.widget
        ? { ...widget_permission?.widget }
        : null;

      tab.data =
        props[EndPoints["Beneficiarys"]] &&
        props[EndPoints["Beneficiarys"]].length > 0
          ? props[EndPoints["Beneficiarys"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [];
      return tab;
    });
    this.setState({
      Beneficiarys:
        props[EndPoints["Beneficiarys"]] &&
        props[EndPoints["Beneficiarys"]].length > 0
          ? props[EndPoints["Beneficiarys"]]
          : [],
      tabs,
    });
  }
  fetchList = (res) => {
    if (
      this.state.singlelistView &&
      this.state.singlelistView.id &&
      res &&
      res.id &&
      res.id === this.state.singlelistView.id
    ) {
      this.setState({ singlelistView: res });
    }
    this.props.BrandsManagementsFunction("get", EndPoints["Beneficiarys"]);
  };
  render() {
    const {
      tabs,
      Beneficiarys,
      singlelistView,
      loading,
      editList,
      openDialog,
    } = this.state;
    const { user } = this.props;

    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.settings,
      accessText.benificiaries
    );
    // const { isMobile ,user} = this.props;
    return (
      <Layout
        loading={loading[EndPoints["Beneficiarys"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: Beneficiarys && Beneficiarys.length > 0 ? Beneficiarys : [],
          hintText: "Search Beneficiary",
          labelField: "name",
        }}

        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
        fabClickHandler={() => {
          this.setState({ openDialog: true, editList: false });
        }}
      >
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {openDialog === true ? (
            <CreateDialog
              module={"Beneficiary"}
              closeOnSave={true}
              selectedList={editList ? editList : false}
              editList={editList ? editList : false}
              handleClose={() => {
                this.setState({ openDialog: false });
              }}
              noAddAnother={true}
              fetchAgain={this.fetchList}
              postApi="masters/benificiary/list"
              editApi="masters/benificiary"
              textFieldStyle={{ width: "calc(50% - 20px)", margin: "10px" }}
              heightNew="500px"
              maxHeight="calc(100vh - 140px)"
              widthNew="700px"
              inputArray={[
                {
                  name: "Active",
                  type: "toggle",
                  defaultValue: {
                    value: editList ? editList.is_active : true,
                  },
                  api: "is_active",
                },
                {
                  name: "Name",
                  type: "textField",
                  dataType: "text",
                  api: "name",
                  defaultValue: {
                    value: editList && editList.name ? editList.name : "",
                  },
                },
                {
                  name: "Account No",
                  type: "textField",
                  dataType: "number",
                  api: "account_no",
                  defaultValue: {
                    value:
                      editList && editList.account_no
                        ? editList.account_no
                        : "",
                  },
                  helperText: "ex: 0112345678",
                },
                {
                  name: "Bank Name",
                  type: "textField",
                  dataType: "text",
                  api: "bank_name",
                  defaultValue: {
                    value:
                      editList && editList.bank_name ? editList.bank_name : "",
                  },
                },
                {
                  name: "Bank Address",
                  type: "addressTextField",
                  dataType: "text",
                  api: "bank_address",
                  defaultValue: {
                    value:
                      editList && editList.bank_address
                        ? editList.bank_address
                        : "",
                  },
                },
                {
                  name: "Swift Code",
                  type: "textField",
                  dataType: "text",
                  onKeyPress: "alphaNum",
                  // not_required: true,
                  onInput: "swift_code",
                  api: "swift_code",
                  defaultValue: {
                    value: editList && editList.swift_code,
                  },
                  helperText: "ex: AXISINBB276",
                },
              ]}
              postData={this.props.BrandsManagementsFunction}
            />
          ) : (
            ""
          )}
          
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading[EndPoints["Beneficiarys"]] === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <div
            className="width-100 height-100 padding15 backgroundWhite"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
                <div style={{ width: "90%" }}>
                  <h1 style={{ fontWeight: "400", fontSize: "25px" }}>
                  Beneficiary Details
                  </h1>
            <Paper
              className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="width-100 d-flex justifyFE">
              {widget_permission?.widget?.has_update_permission && (
                <Tooltip title="Edit">
                  <EditIcon
                    onClick={() => {
                      this.setState({
                        openDialog: true,
                        editList: singlelistView,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>)}
              </div>

              <div
                className="overflowYA scrollBluePrimary"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 50px",
                  height: "calc(100% - 31.7px)",
                  textTransform: "capitalize",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Name:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      textTransform: "capitalize",
                      wordBreak: "break-all",
                    }}
                  >
                    {singlelistView.name}
                  </p>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Account No:
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                    }}
                  >
                    {singlelistView.account_no}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Bank Name:{" "}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      wordBreak: "break-all",
                    }}
                  >
                    {singlelistView.bank_name}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                      wordBreak: "break-all",
                    }}
                  >
                    Bank Address:{" "}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                    }}
                  >
                    {singlelistView.bank_address}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Swift Code:{" "}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                    }}
                  >
                    {singlelistView.swift_code}
                  </p>
                </div>
              </div>
            </Paper>
            </div></div>
          )}
        
        </Paper>
      </Layout>
    );
  }
}

export default BeneficiarysComponent;
