export const EndPoints = {
  Chats: "dispatch/chat/list",
  Rates: "dispatch/purchase-rate/list",
  SalesIntents: "dispatch/purchase-rate/list",
  CashDiscount:"masters/cash-discount/list",
  Packagings: "masters/packaging/list",
  ContractTypes: "masters/contract-type/list",
  PaymentTerms: "masters/payment-terms/list",
  ItemTypes: "masters/item-type/list",
  ItemCategories: "masters/item-category/list",
  SurveyCompanys: "masters/survey-company/list",
  Clients: "masters/clients/list",
  Millers: "masters/miller/list",
  Brokers: "masters/broker/list",
  Transporters: "masters/transporter/list",
  Ports: "masters/port/list",
  PurchaseOrders: "dispatch/purchase-order/list",
  SalesOrders: "dispatch/sales-order/list",
  Shippers: "masters/shipper/list",
  Beneficiarys: "masters/benificiary/list",
  HsNs: "masters/hsn/list",
  BrandsManagements: "masters/brand/list",
  Brands:"masters/brand/list",
  UsersAndPermissions: "users/list",
  RiceBagSellers: "masters/ricebagseller/list",
  Notifys: "masters/notify/list",
  Currencys: "masters/currency/list",
  Incoterms: "masters/inco-term/list",
  SOpaymentTerms: "masters/so-payment-term/list",
  ShippingTerms: "masters/shipping-term/list",
  Precarriers: "masters/precarrier/list",
  Policys: "masters/so-policy/list",
  Chats:"dispatch/chat/list",
};
