import { loginURL, moduleIdURL, verifyOtpURL } from "../../http/config";
import { requests } from "../../requests";

export const verifyOtp = (otp, onLogin) => async (dispatch, getState) => {
  try {
    dispatch({ type: "AUTH_USER_REQUEST" });
    const options = {
      method: "post",
      url: verifyOtpURL,
      data: JSON.stringify(otp),
    };
    const response1 = await requests(options).then((response) => {
      if (response.error) {
        return { error: true, msg: response.data };
      } else {
        console.log(response,"resporesponsense")
        const id= response.data.user_id
        const options_user = {
          method: "get",
          url:moduleIdURL("users", id) ,
          params:{ id: id },
        };
        const data = response.data.data;
        requests(options_user,{uid: data.token}).then((res) => {

       
        const user_id = response.data.user_id;
        const modulePermission =
        data.user_accesses&&data.user_accesses.length > 0
          ? data.user_accesses[0].modules.filter((m)=>{
            const user_widgets_list = m.widgets.filter(
              (widget) =>
                widget.widget.has_create_permission ===
                  true ||
                widget.widget.has_update_permission ===
                  true ||
                widget.widget.has_read_permission === true
            );
       
            return user_widgets_list.length>0?true:false
          }).map(
              (module) => module.module.module_name 
            )??[]
          : [];
        const user = {
          uid: data.token, //"4300261de1a00f95318e76846b885e5ba87e5c62",
          username: data.first_name,
          email: data.email,
          userData: data,
          ...data,
          roles: data.roles,
          accessibleModules:  data.user_accesses&&data.user_accesses.length > 0?modulePermission:data.accessibleModules,
         
          user_accesses:data.user_accesses??data.access_details,
          id: user_id,
          isLoggedIn: true,
          credential_id: res.data.credential_id,
          status: data.user_status,
          miller: data.miller,
          broker: data.broker,
        };
        const localStorageUser = {
          uid: data.token,
          ...data,
          id: user_id,
          username: data.first_name,
          email: data.email,
          roles: data.roles,
          isLoggedIn: true,
          accessibleModules:  data.user_accesses&&data.user_accesses.length > 0?modulePermission:data.accessibleModules,
        
          user_accesses:data.user_accesses??data.access_details,
          credential_id: res.data.credential_id,
          status: data.user_status,
          miller: data.miller,
          broker: data.broker,
        };
        console.log(localStorageUser, "data", data, "localStorageUser");
        const newFunc = async () => {
          await localStorage.setItem(
            "briuser",
            JSON.stringify(localStorageUser)
          );
        };
        newFunc().then(() => {
          const users = localStorage.getItem("briuser")
            ? JSON.parse(localStorage.getItem("briuser"))
            : "";

          if (users && onLogin) {
            onLogin().then(() => {
              dispatch({
                type: "AUTH_USER_LOGGEDIN",
                payload: user,
              });
            });
          }
        });
      })
        return response.error ? response : response.data;
      }
    });

    return await response1;
  } catch (error) {
    return {
      error: true,
      msg: error.response
        ? error.response.data
        : "Some error occured, please try again!",
    };
  }
};

export const LoginFunction = (userData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "AUTH_USER_REQUEST" });
    const options = {
      method: "post",
      url: loginURL,
      data: {
        email: userData.email,
        password: userData.password,
      },
    };
    const response1 = await requests(options).then((response) => {
      
      if (response.error) {
        return response;
      } else {
        response.data = userData;
        const user = {
          email: userData.email,
          password: userData.password,
          accessibleModules: ["all"],
        };

        dispatch({ type: "AUTH_USER_LOGGEDIN", payload: user });
        return response;
      }
    });
    return await response1;
  } catch (error) {
    return {
      error: true,
      msg: error.response
        ? error.response.data
          ? error.response.data
          : error.response.msg
        : "Some error occured, please try again!",
    };
  }
};

export const LogOutFunction = () => async (dispatch, getState) => {
  localStorage.removeItem("briuser");

  dispatch({ type: "AUTH_USER_LOGGEDIN", payload: null });
  return {
    error: false,
  };
};
