import moment from "moment";
import React, { useEffect, useState } from "react";
import CustomStepper from "../../layoutComponents/CustomStepper";
import ShowDetails from "../../layoutComponents/ShowDetails";
import { filterData } from "../../Regex";
import { primary, orange } from "../../styles/colors";
import {
  CrossIcon,
  Download,
  EditIcon,
  EyeIcon,
  PlusIcon,
} from "../../styles/Icons";
import {
  CardDetail,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
  StyledLoadingButton,
  StyledSearchBar,
} from "../../styles/StyledComponents";
import CommercialInvoice from "./CommercialInvoice";
import CreateCustomInvoice from "./CreateCustomInvoice";
import DocumentUploads from "./DocumentUploads";
import EXpenseInput from "./EXpenseInput";
import PackingList from "./PackingList";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import { divide, multiply } from "lodash";
import CustomIn from "./PDFS/CustomIn";
import TableComponent from "../../layoutComponents/TableComponent";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import ShowSpecifications from "../../layoutComponents/ShowSpecifications";
import ItemTableWIthINpitDetails from "./ItemTableWIthINpitDetails";
import { format } from "date-fns";
function CustomInvoice(props) {
  const {
    noAddButton,
    PortsFunction,
    singlelistView,
    masters,
    setSElectedCI,
    PackagingList,
    changeTab,
  } = props;

  const [tableListCopy, setTableListCopy] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loader, setLoad] = useState(false);
  const [dowLoader, setDowLoad] = useState({});

  const [editList, setEditList] = useState(false);
  const [selectedCustomInvoice, setSelectedCustomInvoice] = useState(false);
  const [defaultStep, setdefaultStep] = useState(2);
  const [downloadCI, setDownloadCI] = useState(false);
  const [currentStep, setcurrentStep] = useState(2);

  const [unit, setUnit] = useState(false);

  const [soItems, setSoItems] = useState([]);
  const fetchSoItems = (soId, download) => {
    if (soId) {
      if (download) {
        setDowLoad({ [download.id]: true });
      } else {
        setLoad(true);
      }
      PortsFunction("get", "dispatch/so-items/list", null, "PortCIs", null, {
        so_id: soId,
      }).then((soItems) => {
        setSoItems(soItems);
        if (download) {
          setDowLoad(false);
        } else {
          setLoad(false);
        }

        if (download) {
          setDownloadCI(download);
        }
      });
    }
  };
  useEffect(() => {
    if (
      selectedCustomInvoice &&
      selectedCustomInvoice !== false &&
      selectedCustomInvoice.sales_order
    ) {
      setUnit(
        selectedCustomInvoice && selectedCustomInvoice.sales_order
          ? filterData(
              masters && masters.salesOrderList,
              "id",
              Number(selectedCustomInvoice.sales_order),
              "onlyOne"
            )
            ? filterData(
                masters && masters.salesOrderList,
                "id",
                Number(selectedCustomInvoice.sales_order),
                "onlyOne"
              ).unit
            : ""
          : ""
      );
      fetchSoItems(selectedCustomInvoice.sales_order);
    }
  }, [selectedCustomInvoice]);
  const fetchCustomInvoices = () => {
    if (singlelistView) {
      setLoad(true);
      PortsFunction(
        "get",
        "arrival/custom-invoice/list",
        null,
        "PortCIs",
        null,
        noAddButton
          ? {
              sales_order:
                singlelistView && singlelistView.id && singlelistView.id,
            }
          : {
              port_id: singlelistView && singlelistView.id && singlelistView.id,
            }
      ).then((customInvoices) => {
        const fData = filterData(
          customInvoices,
          noAddButton ? "sales_order" : "loading_port",
          singlelistView && singlelistView.id && singlelistView.id
        );
        if (fData && fData.length > 0) {
          setTableListCopy(fData);
          setTableList(fData);
        } else {
          setTableListCopy([]);
          setTableList([]);
        }
        setLoad(false);
      });
    }
  };
  const updateValues = (res) => {
    if (res.shipment_process_status) {
      setdefaultStep(getStepIndex(res.shipment_process_status, res.status));
      setcurrentStep(getStepIndex(res.shipment_process_status, res.status));
    }
    setSelectedCustomInvoice(res);
  };
  const getStepIndex = (type, status, defaultI, h) => {
    switch (type) {
      case "CUSTOM INVOICE GENERATED":
        return noAddButton || h ? 0 : 1;
      case "DOCUMENTS UPLOADED":
        return noAddButton || h ? 1 : 2;

      case "EXPENSES CALCULATED":
        return noAddButton || h ? 2 : 3;
      case "PACKAGING LIST GENERATED":
        return noAddButton || h ? 3 : 4;
      case "COMMERCIAL INVOICE GENERATED":
        return status === "CLOSED"
          ? defaultI
            ? 4
            : h
            ? 4
            : 5
          : noAddButton
          ? 4
          : 4;
      default:
    }
  };


  const ciItemTable = () => {
    return (
      <div
        style={{ width: "100%", height: "fit-content", margin: "0px 0 10px 0" }}
      >
        <HeadingLine
          maxWidth="100%"
          width="fit-content"
          children={`Item Details`}
          center={true}
          margin={"0 0 10px 0px"}
        />
        <TableComponent
          padding={"0px"}
          nobottomBarAction={true}
          loading={loader}
          noAddAnother={true}
          heightNew="800px"
          maxHeight="calc(100vh - 40px)"
          widthNew="100%"
          module={"Bag details"}
          actions={[]}
          noAddButton={true}
          header={[
            "Details",
            "Brand",
            "Packaging",
            "Total Item Quantity",
            "Contract Rate",
            "HSN Code",
            "Buyer Marking",
            "Specifications",
          ]}
          noSearchBar={true}
          {...props}
          tableList={
            selectedCustomInvoice &&
            selectedCustomInvoice.custom_invoice_item_details &&
            selectedCustomInvoice.custom_invoice_item_details.length
              ? selectedCustomInvoice.custom_invoice_item_details
                  .sort((a, b) => b.id - a.id)
                  .map((s) => {
                    const details = filterData(
                      soItems,
                      "id",
                      s.so_item,
                      "onlyOne"
                    )
                      ? filterData(soItems, "id", s.so_item, "onlyOne")
                      : false;
                    const rowHeader = [
                      "Exchange rate",
                      `Price (${
                        filterData(
                          masters["CurrencyList"],
                          "id",
                          details.currency,
                          "onlyOne"
                        )
                          ? filterData(
                              masters["CurrencyList"],
                              "id",
                              details.currency,
                              "onlyOne"
                            ).name
                          : ""
                      }) PER ${unit}`,
                      `Amount in ${
                        filterData(
                          masters["CurrencyList"],
                          "id",
                          details.currency,
                          "onlyOne"
                        )
                          ? filterData(
                              masters["CurrencyList"],
                              "id",
                              details.currency,
                              "onlyOne"
                            ).name
                          : ""
                      }`,
                      `PRICE (INR) PER ${unit}`,
                      `Amount in (INR)`,
                    ];
                    const singleSo =
                      selectedCustomInvoice && selectedCustomInvoice.sales_order
                        ? filterData(
                            masters && masters.salesOrderList,
                            "id",
                            Number(selectedCustomInvoice.sales_order),
                            "onlyOne"
                          )
                          ? filterData(
                              masters && masters.salesOrderList,
                              "id",
                              Number(selectedCustomInvoice.sales_order),
                              "onlyOne"
                            )
                          : ""
                        : "";
                    const data = {};
                    data[`Fob Value ${rowHeader[2]}`] = s.fob_value_in_other;
                    data[`Fob Value ${rowHeader[3]}`] = s.fob_value_per_unit;
                    data[`Fob Value ${rowHeader[4]}`] = s.fob_value_in_inr;
                    data[`Ocean Freight ${rowHeader[2]}`] =
                      s.ocean_freight_in_other;
                    data[`Ocean Freight ${rowHeader[3]}`] =
                      s.ocean_freight_per_unit;
                    data[`Ocean Freight ${rowHeader[4]}`] =
                      s.ocean_freight_in_inr;
                    data[`Insurance ${rowHeader[2]}`] = s.insurance_in_other;
                    data[`Insurance ${rowHeader[3]}`] = s.insurance_per_unit;
                    data[`Insurance ${rowHeader[4]}`] = s.insurance_in_inr;
                    data["Gross Weight"] = s.gross_weight;
                    data["Net Weight"] = s.net_weight;
                    data["custom_invoice_item_id"] = s.id;
                    data["rate_of_igst"] = s.rate_of_igst;
                    data[`IGST Amount ${rowHeader[2]}`] =
                      s.igst_amount_in_other;
                    data[`IGST Amount ${rowHeader[4]}`] = s.igst_amount_in_inr;
                    data[`Final ${rowHeader[2]}`] =
                      s.final_shipped_amount_in_other;
                    data[`Final ${rowHeader[4]}`] =
                      s.final_shipped_amount_in_inr;
                    data[`Total ${rowHeader[2]}`] = s.total_amount_in_other;
                    data[`Total ${rowHeader[3]}`] = s.total_amount_per_unit;
                    data[`Total ${rowHeader[4]}`] = s.total_amount_in_inr;
                    details.packaging &&
                      details.packaging.length > 0 &&
                      details.packaging.map((sP) => {
                        data[`${sP}-bags`] = s.pkg_counter[sP]
                          ? s.pkg_counter[sP]
                          : "";
                        return sP;
                      });
                  
             
                    return details
                      ? {
                          ...s,
                          Details: "View Details",
                          DetailsUI: () => {
                            return (
                              <ItemTableWIthINpitDetails
                                errorMsg={{}}
                                ci_type={
                                  selectedCustomInvoice["Type"]
                                    ? selectedCustomInvoice["Type"]
                                    : ""
                                }
                                editData={data ? { ...s, ...data } : false}
                                masters={masters}
                                selected={{
                                  ...s,
                                  packaging: details.packaging,
                                  Brand: `${details.brand_details.brand_name}`,
                                  "Item Category": filterData(
                                    masters.ItemCateoryList,
                                    "id",
                                    details.brand_details.item_category,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        masters.ItemCateoryList,
                                        "id",
                                        details.brand_details.item_category,
                                        "onlyOne"
                                      ).name
                                    : "",
                                  "Item Type": filterData(
                                    masters.ItemList,
                                    "id",
                                    details.brand_details.item_type,
                                    "onlyOne"
                                  )
                                    ? `${Number(
                                        filterData(
                                          masters.ItemList,
                                          "id",
                                          details.brand_details.item_type,
                                          "onlyOne"
                                        ).broken_percentage
                                      ).toFixed(2)} `
                                    : "",
                                  "HSN Code": filterData(
                                    masters.HSNlist,
                                    "id",
                                    details.hsn_code,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        masters.HSNlist,
                                        "id",
                                        details.hsn_code,
                                        "onlyOne"
                                      ).hsn_code
                                    : "",
                                  "Total Item Quantity": `${
                                    details.total_quantity
                                  } ${unit ? unit : ""}`,
                                  "Contract Rate": 
                                  // `${
                                  //   filterData(
                                  //     masters["CurrencyList"],
                                  //     "id",
                                  //     details.currency,
                                  //     "onlyOne"
                                  //   )
                                  //     ? filterData(
                                  //         masters["CurrencyList"],
                                  //         "id",
                                  //         details.currency,
                                  //         "onlyOne"
                                  //       ).name
                                  //     : ""
                                  // }
                                   `${Number(details.contract_rate).toFixed(
                                    2
                                  )}/${unit ? unit : ""}`,
                                  "Buyer Marking": details.buyers_marking
                                    ? details.buyers_marking
                                    : "-",
                                  Currency: filterData(
                                    masters["CurrencyList"],
                                    "id",
                                    details.currency,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        masters["CurrencyList"],
                                        "id",
                                        details.currency,
                                        "onlyOne"
                                      ).name
                                    : "",
                                }}
                                selectedSo={singleSo}
                                alldisabled={true}
                                onlyView={true}
                              />
                            );
                          },
                          Brand: `${details.brand_details.brand_name} - ${
                            filterData(
                              masters.ItemCateoryList,
                              "id",
                              details.brand_details.item_category,
                              "onlyOne"
                            )
                              ? filterData(
                                  masters.ItemCateoryList,
                                  "id",
                                  details.brand_details.item_category,
                                  "onlyOne"
                                ).name
                              : ""
                          } - ${
                            filterData(
                              masters.ItemList,
                              "id",
                              details.brand_details.item_type,
                              "onlyOne"
                            )
                              ? `${Number(
                                  filterData(
                                    masters.ItemList,
                                    "id",
                                    details.brand_details.item_type,
                                    "onlyOne"
                                  ).broken_percentage
                                ).toFixed(2)}%`
                              : ""
                          }`,
                          "Item Category": filterData(
                            masters.ItemCateoryList,
                            "id",
                            details.brand_details.item_category,
                            "onlyOne"
                          )
                            ? filterData(
                                masters.ItemCateoryList,
                                "id",
                                details.brand_details.item_category,
                                "onlyOne"
                              ).name
                            : "",
                          "Item Type": filterData(
                            masters.ItemList,
                            "id",
                            details.brand_details.item_type,
                            "onlyOne"
                          )
                            ? `${Number(
                                filterData(
                                  masters.ItemList,
                                  "id",
                                  details.brand_details.item_type,
                                  "onlyOne"
                                ).broken_percentage
                              ).toFixed(2)} `
                            : "",
                          Packaging: "deferferfeeeeeeeeeee",
                          PackagingUI: (
                            <ShowPackaging
                              totalCiBags={true}
                              noTotalbags={true}
                              returned_bags={false}
                              noBags={true}
                              details={details.packaging.map((sP) => {
                                const pVal = filterData(
                                  masters.PackagingList,
                                  "id",
                                  Number(sP),
                                  "onlyOne"
                                )
                                  ? filterData(
                                      masters.PackagingList,
                                      "id",
                                      Number(sP),
                                      "onlyOne"
                                    )
                                  : "";

                                return {
                                  ...pVal,
                                  total_bags: s.pkg_counter[sP]
                                    ? s.pkg_counter[sP]
                                    : "",
                                };
                              })}
                            />
                          ),
                          "HSN Code": filterData(
                            masters.HSNlist,
                            "id",
                            details.hsn_code,
                            "onlyOne"
                          )
                            ? filterData(
                                masters.HSNlist,
                                "id",
                                details.hsn_code,
                                "onlyOne"
                              ).hsn_code
                            : "",
                          "Total Item Quantity": `${details.total_quantity} ${
                            unit ? unit : ""
                          }`,
                          "Contract Rate": 
                          // `${
                          //   filterData(
                          //     masters["CurrencyList"],
                          //     "id",
                          //     details.currency,
                          //     "onlyOne"
                          //   )
                          //     ? filterData(
                          //         masters["CurrencyList"],
                          //         "id",
                          //         details.currency,
                          //         "onlyOne"
                          //       ).name
                          //     : ""
                          // } 
                          `${Number(details.contract_rate).toFixed(2)}/${
                            unit ? unit : ""
                          }`,
                          "Buyer Marking": details.buyers_marking
                            ? details.buyers_marking
                            : "-",
                          Currency: filterData(
                            masters["CurrencyList"],
                            "id",
                            details.currency,
                            "onlyOne"
                          )
                            ? filterData(
                                masters["CurrencyList"],
                                "id",
                                details.currency,
                                "onlyOne"
                              ).name
                            : "",
                          Specifications: "WEFERFRT4G56YH6",
                          SpecificationsUI: (
                            <ShowSpecifications
                              details={
                                details.so_specifications &&
                                details.so_specifications.length > 0
                                  ? details.so_specifications.map((sP) => {
                                      // sP.used_bags =0
                                      return sP;
                                    })
                                  : []
                              }
                            />
                          ),
                        }
                      : { ...s };
                  })
              : []
          }
          filterChild={false}
        />{" "}
        {/* <div
          style={{
            backgroundColor: "rgba(251, 224, 191, 1)",
            padding: "1em 10px",
            borderRadius: "10px",
            margin: "10px 0",
            display: "flex",
            width: "100%",
            minWidth: "100%",
          }}
        >
          <div style={{ color: "black", width: "120px", margin: "0 10px 0 0" }}>Delivery Terms : </div>{" "}
          <div style={{ width: "calc(100% - 130px)" }}>
            {console.log(selectedCustomInvoice && selectedCustomInvoice.sales_order.so_delivery_terms, "wefewefe")}{" "}
            {selectedCustomInvoice &&
            selectedCustomInvoice.custom_invoice_item_details &&
            selectedCustomInvoice.custom_invoice_item_details.length > 0
              ? selectedCustomInvoice.custom_invoice_item_details.map((singleItem, i) => {
                  const details = filterData(soItems, "id", singleItem.so_item, "onlyOne")
                    ? filterData(soItems, "id", singleItem.so_item, "onlyOne")
                    : false;
                  console.log(details.contract_rate, details, "detailsdetails");
                  return (
                    <div style={{ color: "black", textAlign: "left", width: "100%" }}>
                      {`${singleItem.delivery_terms[0]} ${Number(details.contract_rate).toFixed(2)}/${
                        singleItem.delivery_terms[2]
                      }, ${singleItem.delivery_terms[3]}, ${singleItem.delivery_terms[4]},
                   ${singleItem.delivery_terms[5]}, ${
                        singleItem.delivery_terms[6] && singleItem.delivery_terms[6].length > 0
                          ? singleItem.delivery_terms[6].map((s) => s)
                          : ""
                      }${
                        singleItem.delivery_terms[7] && singleItem.delivery_terms[7].length > 0
                          ? `, ${singleItem.delivery_terms[7].map((s) => s)}.`
                          : "."
                      }`}
                    </div>
                  );
                })
              : ""}
          </div>
        </div> */}
      </div>
    );
  };
  const steps = [
    {
      label: "Custom Invoice Details and Custom Packing Details",
      expanded: defaultStep === 0 ? true : false,
      onClick: () => {
        if (defaultStep === 0) {
          setdefaultStep(currentStep);
        } else {
          setdefaultStep(0);
        }
      },
      content: () => {
        const paymentTerms =
          masters &&
          masters.soPaymentTermsList &&
          masters.soPaymentTermsList.length > 0
            ? masters.soPaymentTermsList
            : masters.SoPaymentList && masters.SoPaymentList.length > 0
            ? masters.SoPaymentList
            : [];
    
        return (
          <>
            <ShowDetails
              heightFc={true}
              loading={
                dowLoader[selectedCustomInvoice.id]
                  ? dowLoader[selectedCustomInvoice.id]
                  : false
              }
              download={(e) => {
                // e.stopPropagation();
                fetchSoItems(
                  selectedCustomInvoice.sales_order,
                  selectedCustomInvoice
                );
              }}
              editButton={
                noAddButton || selectedCustomInvoice.status === "CLOSED"
                  ? false
                  : () => {
                      setOpenDialog(true);
                      setEditList(selectedCustomInvoice);
                    }
              }
              minLeftWidth={"200px"}
              array={[
                {
                  label: "Custom Invoice No.",
                  value:
                    selectedCustomInvoice &&
                    selectedCustomInvoice.invoice_number
                      ? selectedCustomInvoice.invoice_number
                      : "-",
                  label2: "Invoice Date",
                  value2:
                    selectedCustomInvoice && selectedCustomInvoice.invoice_date
                      ? moment(selectedCustomInvoice.invoice_date).format(
                          "ddd, MMM Do YYYY"
                        )
                      : new Date(),
                },
                {
                  label: "Status",
                  value:
                    selectedCustomInvoice && selectedCustomInvoice.status
                      ? selectedCustomInvoice.status
                      : "OPEN",
                  label2: "Unit",
                  value2:
                    selectedCustomInvoice && selectedCustomInvoice.unit
                      ? selectedCustomInvoice.unit
                      : unit,
                },
                {
                  label: "LC Number",
                  value:
                    selectedCustomInvoice && selectedCustomInvoice.lc_no
                      ? selectedCustomInvoice.lc_no
                      : "",
                  label2: "LC Date",
                  value2:
                    selectedCustomInvoice && selectedCustomInvoice.lc_date
                      ? moment(selectedCustomInvoice.lc_date).format(
                          "ddd, MMM Do YYYY"
                        )
                      : "-",
                },
                {
                  label: "Sales Order",
                  value:
                    selectedCustomInvoice && selectedCustomInvoice.sales_order
                      ? filterData(
                          masters && masters.salesOrderList,
                          "id",
                          Number(selectedCustomInvoice.sales_order),
                          "onlyOne"
                        )
                        ? filterData(
                            masters && masters.salesOrderList,
                            "id",
                            Number(selectedCustomInvoice.sales_order),
                            "onlyOne"
                          ).so_number
                        : ""
                      : "",
                  label2: "Contract Date",
                  value2:
                    selectedCustomInvoice && selectedCustomInvoice.sales_order
                      ? filterData(
                          masters && masters.salesOrderList,
                          "id",
                          Number(selectedCustomInvoice.sales_order),
                          "onlyOne"
                        )
                        ? moment(
                            filterData(
                              masters && masters.salesOrderList,
                              "id",
                              Number(selectedCustomInvoice.sales_order),
                              "onlyOne"
                            ).contract_date
                          ).format("ddd, MMM Do YYYY")
                        : ""
                      : "",

                  // selectedCustomInvoice &&
                  // selectedCustomInvoice.

                  // sales_order_details[0].contract_date
                  //   ? moment(selectedCustomInvoice.sales_order_details[0].contract_date).format(
                  //       "ddd, MMM Do YYYY"
                  //     )
                  //   : "",
                  dontCapitalize: true,
                },
                {
                  label: "Shipper",
                  value:
                    selectedCustomInvoice && selectedCustomInvoice.shipper
                      ? filterData(
                          masters && masters.shippersList,
                          "id",
                          selectedCustomInvoice.shipper,
                          "onlyOne"
                        )
                        ? filterData(
                            masters && masters.shippersList,
                            "id",
                            selectedCustomInvoice.shipper,
                            "onlyOne"
                          ).name
                        : ""
                      : "",

                  label2: "Beneficiary",
                  value2:
                    selectedCustomInvoice && selectedCustomInvoice.beneficiary
                      ? filterData(
                          masters && masters.benificiarysList,
                          "id",
                          selectedCustomInvoice.beneficiary,
                          "onlyOne"
                        )
                        ? filterData(
                            masters && masters.benificiarysList,
                            "id",
                            selectedCustomInvoice.beneficiary,
                            "onlyOne"
                          ).name
                        : ""
                      : "",
                },
                {
                  label: "Buyer",
                  value:
                    selectedCustomInvoice && selectedCustomInvoice.buyer
                      ? filterData(
                          masters && masters.buyersList,
                          "id",
                          selectedCustomInvoice.buyer,
                          "onlyOne"
                        )
                        ? filterData(
                            masters && masters.buyersList,
                            "id",
                            selectedCustomInvoice.buyer,
                            "onlyOne"
                          ).client_name
                        : ""
                      : "",

                  label2: "Exporter Ref No.",
                  value2:
                    selectedCustomInvoice &&
                    selectedCustomInvoice.exporter_ref_no,
                },
                {
                  label: "Discharge Port",
                  value:
                    selectedCustomInvoice &&
                    selectedCustomInvoice.discharge_port
                      ? selectedCustomInvoice.discharge_port
                      : "",

                  label2: "Delivery Port",
                  value2:
                    selectedCustomInvoice &&
                    selectedCustomInvoice.delivery_place
                      ? selectedCustomInvoice.delivery_place
                      : "",
                },
                {
                  label: "Notify 1",
                  value:
                    selectedCustomInvoice && selectedCustomInvoice.notify_1
                      ? filterData(
                          masters && masters.notifyList,
                          "id",
                          Number(selectedCustomInvoice.notify_1),
                          "onlyOne"
                        )
                        ? filterData(
                            masters && masters.notifyList,
                            "id",
                            Number(selectedCustomInvoice.notify_1),
                            "onlyOne"
                          ).notify
                        : ""
                      : "",

                  label2: "Notify 2",
                  value2:
                    selectedCustomInvoice && selectedCustomInvoice.notify_2
                      ? filterData(
                          masters && masters.notifyList,
                          "id",
                          Number(selectedCustomInvoice.notify_2),
                          "onlyOne"
                        )
                        ? filterData(
                            masters && masters.notifyList,
                            "id",
                            Number(selectedCustomInvoice.notify_2),
                            "onlyOne"
                          ).notify
                        : ""
                      : "",
                },
                {
                  label: "Vessel/Flight Name",
                  value:
                    selectedCustomInvoice && selectedCustomInvoice.vessel_number
                      ? selectedCustomInvoice.vessel_number
                      : "",

                  label2: "Vessel/Flight no.",
                  value2:
                    selectedCustomInvoice && selectedCustomInvoice.flight_number
                      ? selectedCustomInvoice.flight_number
                      : "",
                },
                {
                  label: "Payment Term",
                  value:
                    selectedCustomInvoice && selectedCustomInvoice.payment_terms
                      ? filterData(
                          paymentTerms,
                          "id",
                          Number(selectedCustomInvoice.payment_terms),
                          "onlyOne"
                        )
                        ? filterData(
                            paymentTerms,
                            "id",
                            Number(selectedCustomInvoice.payment_terms),
                            "onlyOne"
                          ).term
                        : ""
                      : "",
                },
                {
                  label: "Delivery terms",
                  value:
                    selectedCustomInvoice &&
                    selectedCustomInvoice.custom_invoice_item_details &&
                    selectedCustomInvoice.custom_invoice_item_details.length > 0
                      ? selectedCustomInvoice.custom_invoice_item_details.map(
                          (singleItem, i) => {
                            const details = filterData(
                              soItems,
                              "id",
                              singleItem.so_item,
                              "onlyOne"
                            )
                              ? filterData(
                                  soItems,
                                  "id",
                                  singleItem.so_item,
                                  "onlyOne"
                                )
                              : false;
                            return (
                              singleItem.delivery_terms && (
                                <div
                                  style={{ textAlign: "left", width: "100%" }}
                                >
                                  {`${singleItem.delivery_terms[0]} ${Number(
                                    details.contract_rate
                                  ).toFixed(2)}/${
                                    singleItem.delivery_terms[2]
                                  }, ${singleItem.delivery_terms[3]}, ${
                                    singleItem.delivery_terms[4]
                                  },
                         ${singleItem.delivery_terms[5]}, ${
                                    singleItem.delivery_terms[6] &&
                                    singleItem.delivery_terms[6].length > 0
                                      ? singleItem.delivery_terms[6].map(
                                          (s) => s
                                        )
                                      : ""
                                  }${
                                    singleItem.delivery_terms[7] &&
                                    singleItem.delivery_terms[7].length > 0
                                      ? typeof singleItem.delivery_terms[7] ===
                                        "string"
                                        ? `, ${singleItem.delivery_terms[7]}.`
                                        : `, ${singleItem.delivery_terms[7].map(
                                            (s) => s
                                          )}.`
                                      : "."
                                  }`}
                                </div>
                              )
                            );
                          }
                        )
                      : "",
                },
                // { label: "sd", value: "wd" },
              ]}
              childData={ciItemTable()}
            />
          </>
        );
      },
    },
    {
      label: "Documents Upload",
      expanded: defaultStep === 1 ? true : false,
      content: () => {
        return (
          <DocumentUploads
            {...props}
            selectedCustomInvoice={selectedCustomInvoice}
            postData={PortsFunction}
            updateValues={updateValues}
          />
        );
      },
      onClick: () => {
        if (defaultStep === 1 && currentStep > 1) {
          setdefaultStep(currentStep);
        } else {
          setdefaultStep(1);
        }
      },
    },
  ];
  const stepsHorizontal = [
    {
      label: "Custom Invoice Details",
      onClick: () => {
        if (defaultStep === 1 && currentStep > 1) {
          setdefaultStep(currentStep);
        } else {
          setdefaultStep(1);
        }
      },
    },
    {
      label: "Documents Upload",

      onClick: () => {
        if (defaultStep === 1 && currentStep > 1) {
          setdefaultStep(currentStep);
        } else {
          setdefaultStep(2);
        }
      },
    },
  ];
  const [masterss, setmasterss] = useState({});
  useEffect(() => {
    fetchCustomInvoices();
  }, [singlelistView]);

  useEffect(() => {
    setmasterss(masters ? masters : {});
  }, [masters]);
  useEffect(() => {
    setSelectedCustomInvoice(false);
  }, [singlelistView]);
  return (
    <div
      className={
        noAddButton
          ? selectedCustomInvoice
            ? "width-100 height-100 d-flex d-flex-column"
            : "width-100 minHeightFC d-flex d-flex-column"
          : "width-100 height-100 d-flex d-flex-column"
      }
    >
      {" "}
      {openDialog === true || editList ? (
        <CreateCustomInvoice
          singlelistView={singlelistView}
          onClose={() => {
            setOpenDialog(false);
            setEditList(false);
          }}
          masters={masterss}
          editList={editList}
          fetchAgain={() => {
            fetchCustomInvoices();
          }}
          postData={PortsFunction}
        />
      ) : (
        ""
      )}
      {dowLoader === true ? (
        ""
      ) : downloadCI !== false ? (
        <CustomIn
          soItems={soItems}
          masters={masters}
          singlelistView={singlelistView}
          downloadCI={downloadCI}
          onClose={() => {
            setDownloadCI(false);
          }}
        />
      ) : (
        ""
      )}
      {selectedCustomInvoice ? (
        <>
          <div
            className=" d-flex borderRadius7 padding5"
            style={{ width: "100%", background: "rgba(158, 158, 158, 0.48)" }}
          >
            <div
              className="height-100 d-flex alignC"
              style={{ width: "calc(100% - 40px)", fontSize: "1.1rem" }}
            >
              <div
                style={{ width: "34px" }}
                className="height-100 d-flex alignC"
              >
                <InsertDriveFileOutlinedIcon />
              </div>
              {`Invoice Number ${
                selectedCustomInvoice &&
                selectedCustomInvoice.invoice_number &&
                selectedCustomInvoice.invoice_number
              }`}
            </div>
            <StyledButton
              tooltip="Close"
              name={<CrossIcon color={primary} />}
              onClick={() => {
                setSelectedCustomInvoice(false);
                if (setSElectedCI) {
                  setSElectedCI(false);
                }
                setdefaultStep(0);
              }}
              width={"40px"}
              minWidth={"40px"}
            />
          </div>
          <div
            style={{
              height: noAddButton
                ? "calc(100% - 56.81px)"
                : "calc(100% - 36.81px)",
              marginTop: "30px",
            }}
            className="width-100 d-flex d-flex-column overflowYA scrollBluePrimary"
          >
            <CustomStepper
              steps={steps}
              defaultStep={defaultStep}
              currentAStep={currentStep}
            />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: noAddButton ? "20px" : "",
            }}
          >
            {noAddButton ? (
              <div
                className="widthFC fontWeight400 alignC d-flex"
                style={{ fontSize: "23px" }}
              >
                All Custom Invoices
              </div>
            ) : (
              ""
            )}
            <StyledSearchBar
              padding={"9px"}
              bgC={"#F2F2F2"}
              fieldName={[
                "invoice_number",
                "net_weight",
                "status",
                "used_weight",
              ]}
              copyData={tableListCopy}
              filteredData={(filterNotify) => {
                setTableList(filterNotify);
              }}
              onClear={() => {
                setTableList(tableListCopy);
              }}
              placeholder={`Search Custom Invoice!`}
            />

            {noAddButton ? (
              ""
            ) : (
              <StyledButton
                withBg={true}
                onClick={() => {
                  setOpenDialog(true);
                }}
                name="Add New Custom Invoice"
                icon={<PlusIcon />}
              />
            )}
          </div>

          <div
            className="width-100"
            style={{
              padding: noAddButton ? "0 2px" : "",
              margin: "20px 0 0 0",
              height: "calc(100% - 58px)",
            }}
          >
            {loader ? (
              <StyledCircularProgress />
            ) : (
              <div
                className="d-flex d-flex-column scrollBluePrimary overflowXA height-100"
                style={{ width: "calc(100% + 4px)", margin: "0 -2px" }}
              >
                <CardDetail
                  keyId={"-1"}
                  height="30px"
                  width="calc(100% - 4px)"
                  background="rgba(158, 158, 158, 0.48)"
                  margin="10px 2px"
                  children={() => {
                    return (
                      <div className="width-100 d-flex">
                        <div
                          className="height-100 d-flex alignC"
                          style={{
                            width: noAddButton
                              ? "calc(100% - 43px)"
                              : "calc(100% - 86px)",
                          }}
                        >
                          <div
                            style={{ width: "394.17px", minWidth: "394.17px" }}
                            className="d-flex"
                          >
                            <div
                              style={{
                                margin: "0 10px 0 0",
                                minWidth: "12.69px",
                              }}
                            >
                              {""}
                            </div>
                            <div
                              style={{ minWidth: "72.39px" }}
                              className="d-flex justifyC"
                            >
                              Invoice No.
                            </div>
                            <div
                              style={{ margin: "0 10px", minWidth: "135px" }}
                              className="d-flex justifyC"
                            >
                              Total Weight
                            </div>
                            <div
                              style={{ minWidth: "71.78px" }}
                              className="d-flex justifyC"
                            >
                              Status
                            </div>
                            <div
                              style={{ minWidth: "71.78px" }}
                              className="d-flex justifyC"
                            >
                              Type
                            </div>
                          </div>
                          <div
                            className="d-flex justifyC"
                            style={{
                              minWidth: "calc(100% - 394.17px)",
                              overflow: "hidden",
                              margin: "0 0 0 10px",
                            }}
                          ></div>
                        </div>
                        <div
                          style={{ minWidth: noAddButton ? "43px" : "86px" }}
                          className="d-flex justifyC"
                        >
                          {noAddButton ? "View" : "Actions"}
                        </div>
                      </div>
                    );
                  }}
                />
                {filterData(
                  tableList,
                  noAddButton ? "sales_order" : "loading_port",
                  singlelistView && singlelistView.id && singlelistView.id
                ) &&
                filterData(
                  tableList,
                  noAddButton ? "sales_order" : "loading_port",
                  singlelistView && singlelistView.id && singlelistView.id
                ).length > 0 ? (
                  <>
                    {filterData(
                      tableList,
                      noAddButton ? "sales_order" : "loading_port",
                      singlelistView && singlelistView.id && singlelistView.id
                    ) &&
                      filterData(
                        tableList,
                        noAddButton ? "sales_order" : "loading_port",
                        singlelistView && singlelistView.id && singlelistView.id
                      )
                        .sort((a, b) => b.id - a.id)
                        .map((singleList, i) => (
                          <CardDetail
                            background={
                              !((i + 1) % 2) ? "rgba(158, 158, 158, 0.05)" : ""
                            }
                            keyId={i}
                            height="40px"
                            width="calc(100% - 4px)"
                            margin="10px 2px"
                            children={() => {
                              return (
                                <div
                                  className="width-100 d-flex cursorP"
                                  onClick={(e) => {
                                    if (singleList.shipment_process_status) {
                                      setcurrentStep(
                                        getStepIndex(
                                          singleList.shipment_process_status,
                                          singleList.status
                                        )
                                      );
                                    }
                                    setSelectedCustomInvoice(singleList);
                                    if (setSElectedCI) {
                                      setSElectedCI(singleList);
                                    }
                                  }}
                                >
                                  <div
                                    className="height-100 d-flex alignC"
                                    style={{
                                      width: noAddButton
                                        ? "calc(100% - 86px)"
                                        : "calc(100% - 129px)",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "394.17px",
                                        minWidth: "394.17px",
                                      }}
                                      className="d-flex alignC"
                                    >
                                      <div
                                        style={{ margin: "0 10px 0 0" }}
                                        className="d-flex justifyC alignC"
                                      >
                                        {filterData(
                                          tableList,
                                          noAddButton
                                            ? "sales_order"
                                            : "loading_port",
                                          singlelistView &&
                                            singlelistView.id &&
                                            singlelistView.id
                                        ) &&
                                        filterData(
                                          tableList,
                                          noAddButton
                                            ? "sales_order"
                                            : "loading_port",
                                          singlelistView &&
                                            singlelistView.id &&
                                            singlelistView.id
                                        ).length -
                                          i <
                                          10
                                          ? `0${
                                              filterData(
                                                tableList,
                                                noAddButton
                                                  ? "sales_order"
                                                  : "loading_port",
                                                singlelistView &&
                                                  singlelistView.id &&
                                                  singlelistView.id
                                              ).length - i
                                            }`
                                          : filterData(
                                              tableList,
                                              noAddButton
                                                ? "sales_order"
                                                : "loading_port",
                                              singlelistView &&
                                                singlelistView.id &&
                                                singlelistView.id
                                            ).length - i}
                                      </div>
                                      <div
                                        style={{ minWidth: "72.39px" }}
                                        className="heightFC textOEllipse"
                                      >
                                        {singleList.invoice_number
                                          ? singleList.invoice_number
                                          : ""}
                                      </div>
                                      <div
                                        style={{
                                          margin: "0 10px",
                                          minWidth: "135px",
                                          fontSize: "0.6rem",
                                          maxWidth: "135px",
                                          overflow: "hidden",
                                        }}
                                        className="d-flex justifyC  d-flex-column alignC"
                                      >
                                        {/* <div
                                          style={{
                                            width: "100%",
                                            height: "5px",
                                            // boxShadow:
                                            //   "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px green, 0px 1px 8px 0px rgba(251, 224, 191, 1)",
                                            zIndex: "1",
                                            fontSize: "0.7rem",
                                            position: "relative",
                                            borderRadius: "7px",
                                            margin: "5px 0",
                                            background: "#E5E5E5",
                                          }}
                                        >
                                          <div
                                            className={`progressbar ${
                                              multiply(
                                                divide(
                                                  singleList.used_weight
                                                    ? singleList.used_weight
                                                    : 0,
                                                  singleList.net_weight
                                                ),
                                                100
                                              ) === 100
                                                ? "borderRadius7"
                                                : ""
                                            }`}
                                            style={{
                                              width: `${multiply(
                                                divide(
                                                  Number(
                                                    singleList.used_weight
                                                      ? singleList.used_weight
                                                      : 0
                                                  ).toFixed(3),
                                                  singleList.net_weight
                                                    ? Number(
                                                        singleList.net_weight
                                                      ).toFixed(3)
                                                    : Number(0.0).toFixed(3)
                                                ),
                                                100
                                              )}%`,
                                            }}
                                          ></div>
                                        </div> */}
                                        <div className="d-flex width-100 justifyC">
                                          {Number(
                                            singleList.net_weight
                                              ? singleList.net_weight
                                              : 0
                                          ).toFixed(3)}
                                          {/* / */}
                                          {/* {`${
                                            singleList.net_weight
                                              ? Number(
                                                  singleList.net_weight
                                                ).toFixed(3)
                                              : Number(0.0).toFixed(3)
                                          } ${singleList.unit}`} */}
                                        </div>
                                      </div>
                                   
                                      <div
                                        style={{
                                          minWidth: "71.78px",
                                          color:
                                            singleList.status === "OPEN" &&
                                            orange,
                                        }}
                                        className="d-flex justifyC alignC"
                                      >
                                        {singleList.status}
                                      </div>
                                      <div
                                        style={{
                                          minWidth: "71.78px",
                                          color:
                                            singleList.invoice_type === "GST"
                                              ? "#e74c3c"
                                              : "#27ae60",
                                        }}
                                        className="d-flex justifyC alignC"
                                      >
                                        {singleList.invoice_type}
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex justifyC alignC"
                                      style={{
                                        width: "calc(100% - 394.17px)",
                                        overflow: "hidden",
                                        margin: "0 0 0 10px",
                                      }}
                                    >
                                      <CustomStepper
                                        steps={stepsHorizontal}
                                        currentAStep={getStepIndex(
                                          singleList.shipment_process_status,
                                          singleList.status
                                        )}
                                        // {getStepIndex(
                                        //   singleList.shipment_process_status,
                                        //   singleList.status,
                                        //   null,
                                        //   "h"
                                        // )}

                                        onlyLabel={true}
                                        orientation={"Horizontal"}
                                      />
                                    </div>
                                  </div>
                                  <StyledLoadingButton
                                    tooltip="Download PDF"
                                    loading={
                                      dowLoader[singleList.id]
                                        ? dowLoader[singleList.id]
                                        : false
                                    }
                                    name={<Download />}
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      fetchSoItems(
                                        singleList.sales_order,
                                        singleList
                                      );
                                    }}
                                    width={"43px"}
                                    minWidth={"43px"}
                                    onlyTextColor={primary}
                                  />
                                  <StyledButton
                                    tooltip="View"
                                    name={<EyeIcon />}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (singleList.shipment_process_status) {
                                        setdefaultStep(
                                          getStepIndex(
                                            singleList.shipment_process_status,
                                            singleList.status,
                                            "defaultI"
                                          )
                                        );
                                        setcurrentStep(
                                          getStepIndex(
                                            singleList.shipment_process_status,
                                            singleList.status,
                                            null,
                                            "h"
                                          )
                                        );
                                      }
                                      setSelectedCustomInvoice(singleList);
                                      if (setSElectedCI) {
                                        setSElectedCI(singleList);
                                      }
                                    }}
                                    width={"43px"}
                                    minWidth={"43px"}
                                    onlyTextColor={primary}
                                  />
                                  {noAddButton ? (
                                    ""
                                  ) : singleList.status === "CLOSED" ? (
                                    <div
                                      className="justifyC alignC d-flex "
                                      style={{ height: "36px", width: "43px" }}
                                    >
                                      -
                                    </div>
                                  ) : (
                                    <StyledButton
                                      tooltip="Edit"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenDialog(true);
                                        setEditList(singleList);
                                      }}
                                      name={<EditIcon />}
                                      width={"43px"}
                                      minWidth={"43px"}
                                      onlyTextColor={primary}
                                    />
                                  )}
                                </div>
                              );
                            }}
                          />
                        ))}
                    {
                      <div
                        style={{ marginBottom: noAddButton ? "80px" : "" }}
                      ></div>
                    }
                  </>
                ) : (
                  <div
                    className="width-100 justifyC alignC d-flex"
                    style={{ height: "150px" }}
                  >
                    Custom Invoice is not created yet!
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default CustomInvoice;
