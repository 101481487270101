import React from "react";
import AlwaysShowSidebar from "../../layoutComponents/PDF/AlwaysShowSidebar";
import { CssDialog, StyledCircularProgress } from "../../styles/StyledComponents";

function ShowPdf(props) {
  const { open, details, selectedCustomInvoice, loader, close,fromSO } = props;

  return open === true ? (
    <CssDialog
      noappbar={fromSO||details && details.file_url ? false : true}
      headerPrimary={true}
      height="calc(100% - 100px)"
      width="calc(100% - 150px)"
      padding={fromSO||details && details.file_url ? "20px" : "0"}
      noActions={true}
      header={`${selectedCustomInvoice?selectedCustomInvoice.invoice_number:""} ${
        details.pdf_file ? details.pdf_file.split(".pdf") : details.name?details.name:""
      }`}
      onClose={() => {
        close();
      }}
      snackbarClose={() => {
        close();
      }}
      children={() => {
        return loader ? (
          <StyledCircularProgress />
        ) : (details && !details.name) ? (
          <iframe
            title="pdf"
            scrolling="no"
            seamless="seamless"
            className="width-100 height-100 objFC example overflowH"
            src={details.file_url?details.file_url:details}
          ></iframe>
        ) : (
          details && (
            <div className="width-100 height-100 d-flex alignC justifyC  postionR" style={{ maxHeight: "100%" }}>
              <AlwaysShowSidebar fileUrl={URL.createObjectURL(details)} handleClose={() => close()} />
               </div>
          )
        );
      }}
    />
  ) : (
    ""
  );
}

export default ShowPdf;
