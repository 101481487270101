import React, { useEffect, useState } from "react";
import {  Button } from "@material-ui/core";
import { moduleURL } from "../../../http/config";
import { requests } from "../../../requests";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Backdrop } from "@material-ui/core";
//import { StyledCircularProgress } from "../../../styles/StyledComponents";
import {
  CssKeyboardDatePicker,
  CssTextField,
  CssTextField95,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
} from "../../../styles/StyledComponents";
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "0 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: "15px",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  CssTextField: {
    width: "40%",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
};

const EditForm = ({ closePopup, getFn }) => {
  const [fieldValues, setFieldValues] = useState({
    daysRangeInput: "",
    statusInput: true,
  });

  const [isValid, setIsValid] = useState({
    daysRangeInput: true,
  });
  const [error, setError] = useState("");
  const [pushNotier, setPushNotier] = useState(false);
  const [pushNoti, setPushNoti] = useState(false);
  const [loading, setLoading] = useState(false);

  const addNewHandler = (e) => {
    e.preventDefault();
    if (fieldValues.daysRangeInput.length === 0) {
      setIsValid((prev) => ({ ...prev, daysRangeInput: false }));
    }
    if (fieldValues.daysRangeInput.length > 0) {
      setLoading(true);

      const postURL = moduleURL("masters/payment-terms/list");
      requests({
        method: "post",
        url: postURL,
        data: {
          days_range: fieldValues.daysRangeInput,
          is_active: fieldValues.statusInput,
        },
      }).then((res) => {
        if (res.error) {
         
          setError(res.data[0].message[0]);
         
          setPushNotier(true);
        } else {
          getFn();
          setError("");
          setPushNoti(true);
        }
      });
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={addNewHandler}>
        <div style={classes.formGroup}>
        <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}>
        {labelText("Range")}
          <CssTextField
            type="text"
            onKeyDown={(evt) => {
              // evt.key === "." && evt.preventDefault();
              // evt.key === "e" && evt.preventDefault();
              // evt.key === "E" && evt.preventDefault();
              // evt.key === "-" && evt.preventDefault();
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
            }}
            style={classes.CssTextField}
            variant="outlined"
            // placeholder="ex: 10-15"
            // label="Range *"
            value={fieldValues.daysRangeInput}
            onKeyPress={(e) => {
              const re = /[0-9 -]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 9);
            }}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, daysRangeInput: true }));
              }
              setFieldValues((prev) => ({
                ...prev,
                daysRangeInput: target.value,
              }));
            }}
            error={!isValid.daysRangeInput}
            helperText={
              isValid.daysRangeInput ? "ex: 10-15" : "Enter Range of days"
            }
          />
          </div>
        </div>
        <div style={classes.formActions}>
          <FormControlLabel
            control={
              <Switch
                disabled
                defaultChecked
                style={{ color: "#B0B0B0" }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: true,
                    }));
                  } else {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: false,
                    }));
                  }
                }}
              />
            }
            label={fieldValues.statusInput ? "Active" : "Inactive"}
          />
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "rgb(241 154 48)",
              color: "white",
              textTransform: "capitalize",
            }}
          >
            Add
          </Button>
        </div>
      </form>
      {loading ? (
        <Backdrop
          style={{ zIndex: 1, color: "#fff", position: "absolute" }}
          open={loading}
        >
          <StyledCircularProgress color="rgb(30, 53, 89)" />
        </Backdrop>
      ) : (
        ""
      )}

      <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setPushNoti(false);
          setLoading(false);

          closePopup();
        }}
      >
        <Alert style={{ backgroundColor: "rgb(241 154 48)" }}>
          New Payment Term Added!
        </Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
      <Snackbar
        open={pushNotier}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="error"
        autoHideDuration={5000}
        onClose={() => {
          // setOpenPopup(false);
          setPushNotier(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}
      >
        <Alert severity="error">{error}</Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
    </div>
  );
};

export default EditForm;
