import React, { useRef, useState, useEffect } from "react";

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { CompanyFullName } from "../Defaults";
import { primary } from "../styles/colors";
import { Divider } from "@material-ui/core";
import moment from "moment";
import PdfLayout from "./PdfLayout";
import converter from "number-to-words";
import { set } from "date-fns";
const styles = {
  widthWithDivider: { width: "calc(50% - 0.5px)" },
  widthWithDivider25: { width: "calc(25% - 0.5px)" },
  widthWithDivider75: { width: "calc(75% - 0.5px)" },
  widthWithDivider40: { width: "calc(40% - 0.5px)" },

  widthWithDivider60: { width: "calc(60% - 0.5px)" },
  widthWithDivider33: { width: "calc(33% - 0.5px)" },
  widthWithDivider67: { width: "calc(67% - 0.5px)" },
  widthWithDivider30: { width: "calc(30% - 0.5px)" },
  widthWithDivider70: { width: "calc(70% - 0.5px)" },
};
function InvoicePdfUi(props) {
  const {
    downloadPdf,
    singleInvoiceSelected,
    tableList,
    module,
    singlelistView,
    pdfHeader,
    subMenuheader,
    onlyShow,
    partial_payment
  } = props;
  const contentArea = useRef(null);
  const [onlyShowPf, setonlyShow] = useState(false);
  useEffect(() => {
    setonlyShow(onlyShow ? onlyShow : false);
  }, [onlyShow]);

  const totalAmount =
    module === "broker" && singleInvoiceSelected.amount
      ? singleInvoiceSelected.amount
      : tableList && tableList.length
      ? tableList.reduce(
          (previousValue, currentValue) =>
            Number(previousValue) +
            Number(currentValue.totalamount ? currentValue.totalamount : 0),
          0
        )
      : "";

  const handleExportWithFunction = (event) => {
    savePDF(contentArea.current, {
      paperSize: "A4",
      landscape: true,
      fileName: `${partial_payment.invoice_number}.pdf`,
    });
    props.onClose();
  };
  console.log(onlyShowPf,"onlyShowPf")
  useEffect(() => {
    if (onlyShowPf === true) {
    } else {
      if (contentArea ) {
        handleExportWithFunction();
      }
    }
  }, [ onlyShowPf]);

  const HDivider = <Divider style={{ minWidth: "100%", width: "100%" }} />;
  const VDivider = (
    <Divider
      variant="vertical"
      style={{ height: "100%", minHeight: "29.19px" }}
    />
  );
  console.log(tableList,"tabtableListleList")
  return (
    <div
      style={
        onlyShow === true
          ? { height: "100%", width: "100%", transform: "scale(0.8)" }
          : { height: "0", width: "0", overflow: "hidden" }
      }
    >
      
      <div
        ref={contentArea}
        style={{
          height: "100%",

          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {" "}
        <PdfLayout
          title={`${module}'s Invoice`}
          file={{
            name: "Invoice No.",

            value: partial_payment.invoice_number,
            date:partial_payment.payment_datetime,
          }}
        >
          <div
            style={{
              width: "calc(100% + 1px)",
              height: "calc(100% + 2px)",
              padding: "0",
            }}
          >
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "calc(100% + 0.5px)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      minHeight: "16px",
                      maxHeight: "16px",
                      display: "flex",
                      fontWeight: "bold",
                      alignItems: "center",
                      textTransform: "capitalize",
                    }}
                  >
                    {module ? `${module}'s Details` : "Consignee (Ship to)"}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      minHeight: "16px",
                      maxHeight: "16px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textTransform: "capitalize",
                    }}
                  >
                    {" "}
                    {singlelistView
                      ? `${singlelistView.name ? singlelistView.name : ""}, ${
                          singlelistView.address ? singlelistView.address : ""
                        }, ${
                          singlelistView.gstin
                            ? `GSTIN: ${singlelistView.gstin},`
                            : ""
                        }, ${
                          singlelistView.state ? singlelistView.state : ""
                        }, ${
                          singlelistView.pincode ? singlelistView.pincode : ""
                        }.`
                      : ""}{" "}
                  </div>
                </div>
                {HDivider}
              </div>
              {/* <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "calc(50% - 0.5px)",
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: "calc(50% - 0.5px)",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        padding: "5px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          minHeight: "16px",
                          maxHeight: "16px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Dispatches Count
                      </div>
                      <div
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          minHeight: "16px",
                          maxHeight: "16px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {singleInvoiceSelected
                          ? singleInvoiceSelected.dispatch_count
                            ? singleInvoiceSelected.dispatch_count > 9
                              ? singleInvoiceSelected.dispatch_count
                              : `0${singleInvoiceSelected.dispatch_count}`
                            : ""
                          : tableList && tableList.length > 0
                          ? tableList.length > 9
                            ? tableList.length
                            : `0${tableList.length}`
                          : ""}
                      </div>
                    </div>{" "}
                    {HDivider}
                  </div>

                  {VDivider}
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: "calc(50% - 0.5px)",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        padding: "5px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          minHeight: "16px",
                          maxHeight: "16px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Remark
                      </div>
                      <div
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          minHeight: "16px",
                          maxHeight: "16px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      ></div>
                    </div>{" "}
                    {HDivider}
                  </div>
                </div>
              </div> */}
            </div>
            <div style={{ display: "flex", width: "100%", fontSize: "0.6rem" }}>
              <div
                style={{
                  background: "#9e9e9e0d",
                  width: "100%",
                  display: "flex",
                  // overflow: "hidden",
                }}
              >
                {pdfHeader &&
                  pdfHeader.length > 0 &&
                  pdfHeader.map((name, i) => (
                    <>
                      <div
                        style={{
                          minWidth:"calc(14.28% - 0.86px)",
                          maxWidth:"calc(14.28% - 0.86px)",
                          width:"calc(14.28% - 0.86px)",
                          padding: "0px",
                          minHeight: "20px",
                          display: "flex",
                          alignItems:
                            i === pdfHeader.length ? "flex-start" : "center",
                          justifyContent:
                            i === pdfHeader.length ? "flex-start" : "center",
                        }}
                      >
                        {name}
                      </div>
                      {pdfHeader.length - 1 === i ? "" : VDivider}
                    </>
                  ))}
              </div>
            </div>
            {HDivider}
            {tableList && tableList.length > 0
              ? tableList.map((sInvoice, i2) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        minHeight: "36px",
                        height: "36px",

                        fontSize: "0.6rem",
                      }}
                    >
                      {pdfHeader &&
                        pdfHeader.length > 0 &&
                        pdfHeader.map((name, i) => (
                          <>
                            <div
                              style={{
                                minWidth:"calc(14.28% - 0.86px)",
                                maxWidth:"calc(14.28% - 0.86px)",
                                width:"calc(14.28% - 0.86px)",
                                padding: "0px",
                                minHeight: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems:
                                  i === pdfHeader.length
                                    ? "flex-start"
                                    : "center",
                                justifyContent:
                                  i === pdfHeader.length
                                    ? "flex-start"
                                    : "center",
                              }}
                            >
                              {name === "Sr.No."
                                ? i2 + 1 < 10
                                  ? `0${i2 + 1}`
                                  : i2 + 1
                                : sInvoice[name]
                                ? sInvoice[name]
                                : "-"}
                            </div>
                            {pdfHeader.length - 1 === i ? "" : VDivider}
                          </>
                        ))}
                    </div>
                    {HDivider}
                    <div
                      style={{
                        background: "#9e9e9e0d",
                        width: "100%",
                        display: "flex",
                        overflow: "hidden",
                        fontSize: "0.6rem",
                      }}
                    >
                      {subMenuheader &&
                        subMenuheader.length > 0 &&
                        subMenuheader.map((name, i) => (
                          <>
                            <div
                              style={{
                                minWidth:"calc(14.28% - 0.86px)",
                                maxWidth:"calc(14.28% - 0.86px)",
                                width:"calc(14.28% - 0.86px)",
                                padding: "0px",
                                minHeight: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {name}
                            </div>
                            {subMenuheader.length - 1 === i ? "" : VDivider}
                          </>
                        ))}
                    </div>
                    {HDivider}
                    {sInvoice.subList &&
                      sInvoice.subList.length > 0 &&
                      sInvoice.subList.map((sBrand) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              minHeight: "36px",
                              height: "36px",
                              // overflow: "hidden",
                              fontSize: "0.6rem",
                              fontFamily: "DejaVuSans",
                            }}
                          >
                            {" "}
                            {subMenuheader &&
                              subMenuheader.length > 0 &&
                              subMenuheader.map((name, i) => (
                                <div
                                  style={{
                                    minHeight: "100%",
                                    height: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      minWidth:"calc(14.28% - 0.86px)",
                                      maxWidth:"calc(14.28% - 0.86px)",
                                      width:"calc(14.28% - 0.86px)",
                                      padding: "0px",
                                      minHeight: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {name === "Sr.No."
                                      ? i + 1 < 10
                                        ? `0${i + 1}`
                                        : i + 1
                                      :sBrand? sBrand[`${name}Unit`]
                                      ? sBrand[`${name}Unit`]
                                      : sBrand[`${name}UIPdf`]
                                      ? sBrand[`${name}UIPdf`]
                                      : sBrand[name]
                                      ? sBrand[name]
                                      : "-":""}
                                  </div>
                                  {subMenuheader.length - 1 === i
                                    ? ""
                                    : VDivider}
                                </div>
                              ))}
                          </div>{" "}
                          {HDivider}
                        </>
                      ))}
                  </>
                ))
              : ""}{" "}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "230px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                Amount Chargeable (in words)
              </div>
              <div
                style={{
                  width: "calc(100% - 230px)",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                  textTransform: "capitalize",
                }}
              >
                Indian Rupees{" "}
                {totalAmount ? converter.toWords(totalAmount) : "-"}
              </div>
            </div>
            {HDivider}
            {/* <div style={{ display: "flex", width: "100%", minHeight: "fit-content", height: "150.12px" }}>
              <div style={{ width: "calc(30% - 0.5px)", display: "flex", flexDirection: "column", height: "100%" }}>
                <div
                  style={{
                    padding: "5px",
                    justifyContent: "flex-end",
                    display: "flex",
                    width: "100%",
                    minHeight: "26px",
                    height: "26px",
                  }}
                >
                  Taxable
                </div>
                {HDivider}
                <div
                  style={{
                    padding: "5px",
                    justifyContent: "flex-end",
                    display: "flex",
                    width: "100%",
                    minHeight: "26px",
                    height: "26px",
                  }}
                >
                  Value
                </div>
                {HDivider}
                <div
                  style={{
                    padding: "5px",
                    justifyContent: "flex-end",
                    display: "flex",
                    width: "100%",
                    minHeight: "26px",
                    height: "26px",
                  }}
                >
                  2342355.454
                </div>
                {HDivider}

                <div
                  style={{
                    padding: "5px",
                    justifyContent: "flex-end",
                    display: "flex",
                    width: "100%",
                    minHeight: "26px",
                    fontWeight: "bold",
                    height: "26px",
                  }}
                >
                  Total : 1,23,333.00
                </div>
              </div>
              {VDivider}
              <div style={{ display: "flex", width: "calc(70% - 0.5px)", height: "100%" }}>
                <div style={{ display: "flex", width: "calc(40% - 0.5px)", height: "100%", flexDirection: "column" }}>
                  <div style={{ display: "flex", width: "100%", height: "100%", flexDirection: "column" }}>
                    <div
                      style={{
                        padding: "5px",
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        minHeight: "26px",
                        fontWeight: "bold",
                        background: "#9e9e9e0d",
                      }}
                    >
                      Centeral Tax
                    </div>

                    {HDivider}
                    <div style={{ display: "flex", width: "100%", height: "113.12px" }}>
                      {" "}
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          width: "calc(50% - 0.5px)",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            minHeight: "26px",
                            fontWeight: "bold",
                            background: "#9e9e9e0d",
                          }}
                        >
                          Rate
                        </div>
                        {HDivider}
                        <div
                          style={{
                            padding: "5px",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            minHeight: "26px",
                            fontWeight: "bold",
                          }}
                        >
                          9%
                        </div>
                        {HDivider}{" "}
                        <div
                          style={{
                            padding: "5px",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            minHeight: "26px",
                            fontWeight: "bold",
                          }}
                        ></div>
                      </div>
                      {VDivider}
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          width: "calc(50% - 0.5px)",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            minHeight: "26px",
                            fontWeight: "bold",
                            background: "#9e9e9e0d",
                          }}
                        >
                          Amount
                        </div>
                        {HDivider}
                        <div
                          style={{
                            padding: "5px",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            minHeight: "26px",
                            fontWeight: "bold",
                          }}
                        >
                          11,667.24
                        </div>
                        {HDivider}
                        <div
                          style={{
                            padding: "5px",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            minHeight: "26px",
                            fontWeight: "bold",
                          }}
                        >
                          11,667.24
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>

                {VDivider}
                <div style={{ display: "flex", width: "calc(40% - 0.5px)", height: "100%", flexDirection: "column" }}>
                  <div
                    style={{
                      padding: "5px",
                      justifyContent: "center",
                      display: "flex",
                      width: "100%",
                      minHeight: "26px",
                      fontWeight: "bold",
                      background: "#9e9e9e0d",
                    }}
                  >
                    State Tax
                  </div>

                  {HDivider}
                  <div style={{ display: "flex", width: "100%", height: "100%" }}>
                    {" "}
                    <div
                      style={{ flexDirection: "column", display: "flex", width: "calc(50% - 0.5px)", height: "100%" }}
                    >
                      <div
                        style={{
                          padding: "5px",
                          justifyContent: "center",
                          display: "flex",
                          width: "100%",
                          minHeight: "26px",
                          fontWeight: "bold",
                          background: "#9e9e9e0d",
                        }}
                      >
                        Rate
                      </div>
                      {HDivider}
                      <div
                        style={{
                          padding: "5px",
                          justifyContent: "center",
                          display: "flex",
                          width: "100%",
                          minHeight: "26px",
                          fontWeight: "bold",
                        }}
                      >
                        9%
                      </div>
                      {HDivider}
                      <div
                        style={{
                          padding: "5px",
                          justifyContent: "center",
                          display: "flex",
                          width: "100%",
                          minHeight: "26px",
                          fontWeight: "bold",
                        }}
                      ></div>
                    </div>
                    {VDivider}
                    <div
                      style={{ flexDirection: "column", display: "flex", width: "calc(50% - 0.5px)", height: "100%" }}
                    >
                      <div
                        style={{
                          padding: "5px",
                          justifyContent: "center",
                          display: "flex",
                          width: "100%",
                          minHeight: "26px",
                          fontWeight: "bold",
                          background: "#9e9e9e0d",
                        }}
                      >
                        Amount
                      </div>
                      {HDivider}
                      <div
                        style={{
                          padding: "5px",
                          justifyContent: "center",
                          display: "flex",
                          width: "100%",
                          minHeight: "26px",
                          fontWeight: "bold",
                        }}
                      >
                        11,667.24
                      </div>
                      {HDivider}
                      <div
                        style={{
                          padding: "5px",
                          justifyContent: "center",
                          display: "flex",
                          width: "100%",
                          minHeight: "26px",
                          fontWeight: "bold",
                        }}
                      >
                        11,667.24
                      </div>
                    </div>{" "}
                  </div>
                </div>
                {VDivider}
                <div style={{ display: "flex", flexDirection: "column", width: "calc(20% - 1px)", height: "100%" }}>
                  <div style={{ flexDirection: "column", display: "flex", width: "100%", height: "100%" }}>
                    <div
                      style={{
                        padding: "5px",
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        minHeight: "26px",
                        fontWeight: "bold",
                        background: "#9e9e9e0d",
                      }}
                    >
                      Total
                    </div>

                    {HDivider}
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flexDirection: "column", display: "flex", width: "100%" }}>
                        <div
                          style={{
                            padding: "5px",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            minHeight: "26px",
                            fontWeight: "bold",
                            background: "#9e9e9e0d",
                          }}
                        >
                          Tax Amount
                        </div>
                        {HDivider}
                        <div
                          style={{
                            padding: "5px",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            minHeight: "26px",
                            fontWeight: "bold",
                          }}
                        >
                          23,334.48
                        </div>
                        {HDivider}
                        <div
                          style={{
                            padding: "5px",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                            minHeight: "26px",
                            fontWeight: "bold",
                          }}
                        >
                          23,334.48
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {HDivider} */}
            {/* <div
            style={{
              padding: "5px",
              display: "flex",
              width: "100%",
              minWidth: "100%",
              minHeight: "26px",
              height: "26px",
            }}
          >
            Company's Bank Details
          </div>{" "}
          {HDivider}{" "}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              minHeight: "36px",
              height: "36px",
            }}
          >
            {" "}
            <div
              style={{
                padding: "5px",
                display: "flex",
                minHeight: "100%",
                height: "100%",
                width: "calc(50% - 0.5px)",
              }}
            >
              A/c Holders's Name :<div style={{ minWidth: "3px", width: "3px" }} />
              <div style={{ fontWeight: "bold" }}> SSIPL Corporation</div>
            </div>
            {VDivider}{" "}
            <div
              style={{
                padding: "5px",
                display: "flex",
                minHeight: "100%",
                height: "100%",
                width: "calc(50% - 0.5px)",
              }}
            >
              Bank Name :<div style={{ minWidth: "3px", width: "3px" }} />
              <div style={{ fontWeight: "bold" }}>Kotak Mahindra Bank Ltd</div>
            </div>
          </div>
          {HDivider}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              minHeight: "36px",
              height: "36px",
            }}
          >
            <div
              style={{
                padding: "5px",
                display: "flex",
                minHeight: "36px",
                height: "36px",
                width: "calc(50% - 0.5px)",
              }}
            >
              A/c No. :<div style={{ minWidth: "3px", width: "3px" }} />
              <div style={{ fontWeight: "bold" }}>5512944767</div>
            </div>{" "}
            {VDivider}{" "}
            <div
              style={{
                padding: "5px",
                display: "flex",
                minHeight: "36px",
                height: "36px",
                width: "calc(50% - 0.5px)",
              }}
            >
              Company's PAN :<div style={{ minWidth: "3px", width: "3px" }} />
              <div style={{ fontWeight: "bold" }}>GSYUGJHKGSRR</div>
            </div>
          </div>
          {HDivider}
           <div
            style={{
              padding: "5px",
              display: "flex",
              width: "100%",
              minHeight: "26px",
            }}
          >
            Brnach and IFSE Code :<div style={{ minWidth: "3px", width: "3px" }} />
            <div style={{ fontWeight: "bold", width: "calc(100% - 140px)" }}>
              Ground Floor,Dhand Compound, Civil Line Branch & KKBK0000132{" "}
            </div>
          </div>
          {HDivider} */}
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  width: "110px",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                Declaration:
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                {" "}
                We declare that this invoice shows the actual price of the goods
                described and that all particulars are true and correct.
              </div>
            </div>
            {HDivider}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                minHeight: "60px",
                height: "60px",
              }}
            >
              <div
                style={{
                  padding: "5px",
                  display: "flex",
                  minHeight: "100%",
                  height: "100%",
                  width: "100%",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
        <div style={{ padding: "5px", width: "100%", display: "flex" }}>For BALAJI RICE INDUSTRIES PVT LTD</div>
                <div
                  style={{
                    padding: "5px",
                    width: "100%",
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <div> Authorized Signatory</div>
                </div>
              </div>
            </div>
          </div>{" "}
        </PdfLayout>
      </div>
    </div>
  );
}

export default InvoicePdfUi;
