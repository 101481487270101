import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import { StyledCircularProgress } from "../../styles/StyledComponents";
import NoData from "../../styles/Illustrations/NoData";
import { EndPoints } from "../../utils/EndPoints";
import { Paper } from "@material-ui/core";
import ChatUI from "./ChatUI";
import moment from "moment";
export class ChatsComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Chats"],
        tooltip: `Click to approve pending ${EndPoints["Chats"]}`,
        data: [],
        route: `/Chats/${EndPoints["Chats"].split("/")[0]}`,
        goBackRoute: "/Chats",
        primaryField: "ReceiverName",
        primaryFieldMessage: "ReceiverName",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "lastRate",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",
        onlySecondary: true,
        dispatchList: true,
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "green", "red"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      Chats: [],
      singlelistView: null,
      loading: false,
      loadingMessages: false,
      messageLIst: [],
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    if (next.chat_room_id) {
    } else {
      this.setInitialValues(next);
    }
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.chat_room_id) {
    } else {
      if (next.params && next.params.tab) {
        let tab = next.params.tab;
        // if (tab === EndPoints["Chats"] && next.params.id) {
        //   this.setState(
        //     {
        //       singlelistView: this.state.tabs[0].data[next.params.id],
        //     },
        //     () => {
        //       if (this.state.singlelistView && this.state.singlelistView.id) {
        //         this.props.ChatsFunction(
        //           "get",
        //           EndPoints["Chats"],
        //           this.state.singlelistView.id,
        //           "Chats"
        //         );
        //       }
        //     }
        //   );
        // } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
        // }
      } else {
        this.setState({ singlelistView: null });
      }
      this.setInitialValues(this.props);
    }
  }

  listClickHandler = (value) => {
    localStorage.removeItem("pagination_loader");
    localStorage.removeItem("allSenderUids");
    localStorage.removeItem("oldchatarr");
    localStorage.setItem("room", JSON.stringify(value));

    console.log(value.room_id, "item.room_idvalue", value);
    this.setState({
      singlelistView: value,
    });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        props[EndPoints["Chats"]] &&
        Array.isArray(props[EndPoints["Chats"]]) &&
        props[EndPoints["Chats"]].length > 0
          ? props[EndPoints["Chats"]]
              .map((sT) => {
                console.log(props.user.roles, "sTsT");
                return {
                  ...sT,
                  ReceiverName: `${
                    sT.participants.length < 2
                      ? sT.other_details.user_name
                      : sT.other_details.receiver_name
                  }`,
                  time: sT.other_details.time,
                  lastRate: `Hi ! The rate I can offer is Rs ${sT.other_details.last_sales_intent_rate}`,
                  // ${`${<div style={{fontSize:"0.7rem"}}>{moment(sT.created_at).format("h:mm A")}</div>}`}` ,
                  // Date:`${sT.other_details.time}`
                };
              })

              .sort((a, b) => {
                var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })
          : [];
      return tab;
    });
    this.setState({
      listViewArray: [
        // {
        //   participants: "anu",
        //   id: "room_id",
        //   // messageType: "message",
        //   // createdAt: new Date(),
        //   // createdBy: "rtfgyh",
        // },
      ],
      // props[EndPoints["Chats"]] && props[EndPoints["Chats"]].length > 0
      //   ? props[EndPoints["Chats"]]
      //   : [],
      tabs,
      loading: props.loading,
    });
  }
  render() {
    const { tabs, listViewArray, singlelistView, loading, messageLIst } =
      this.state;
    var labelField = "ReceiverName";
    if (listViewArray && listViewArray.length > 0 && listViewArray[0]) {
      Object.keys(listViewArray[0]).map((sKey) => {
        if (typeof listViewArray[0][sKey] === "string") {
          return (labelField = sKey);
        } else {
          return null;
        }
      });
      labelField="ReceiverName" //assign search field
    }
    return this.props.chat_room_id ? (
      <ChatUI
        item={{ room_id: this.props.chat_room_id }}
        modulesFunction={this.props.ChatsFunction}
        user={this.props.user}
      />
    ) : (
      <Layout
        loading={loading[EndPoints["Chats"]]}
        tabs={tabs}
        noTab={true}
        noAppBar={this.props.selected ? true : undefined}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Users`,
          labelField: labelField,
          chatSearch: this.props.selected ? true : undefined,
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
        selectedIndex={true}>
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Chats === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <ChatUI
              item={singlelistView}
              modulesFunction={this.props.ChatsFunction}
              user={this.props.user}
            />
          )}
        </Paper>
      </Layout>
    );
  }
}

export default ChatsComponent;
