import { Divider } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { CompanyFullName } from "../Defaults";
import { primary } from "../styles/colors";

function PdfLayoutSo(props) {
  const { title, file, noBottom, tableList } = props;
  const today = moment().format("DD-MM-YYYY");
  const so_num = file.value;
  const buyerDet =
    file.buyer +
    ", " +
    file.buyerAddress +
    ", " +
    file.buyerCity +
    ", " +
    file.buyerState +
    ", " +
    "TEL:" +
    " " +
    file.buyerPhone;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      
      {file && (
        <div style={{ display: "flex", flexDirection: "column",width:"100%" }}>
         
          <br />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              marginTop:"-20px",
              fontSize: "0.6rem",
              textDecoration: "underline",
            }}
          >
            This Contract is entered in to between:
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              alignItems: "center",
              
              fontSize: "0.6rem",
            }}
          >
            <div>
              <br />
              <strong>{buyerDet.toUpperCase()}</strong> <br />
              (Herein after called the BUYER)
              <br />
            </div>
            AND
            <div>
              <br />
              <strong>
                {" "}
                BALAJI RICE INDUSTRIES NEAR BUS STAND, MAIN ROAD, KHARORA ,RAIPUR-493225,CHATTISGARH (INDIA)
              </strong>{" "}
              <br />
              (Herein after called the SELLER)
              <br />
              <br />
              <br />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex",
              alignItems: "center",
              fontSize: "0.6rem",
              margin:"0"
            }}
          >
            Who has agreed to sell the following goods on the following terms
            and conditions set out hereunder:
            
          </div>
          {/* <div
            style={{
              // width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              // alignItems: "center",
              
              fontSize: "0.6rem",
            }}
          >
            <br />
            <br />
            <strong>Article 1: COMMODITY AND SPECIFICATION</strong>
            <br />          
          </div> */}
          <br />
        </div>
      )}
      <div
        style={{
          width: "100%",
          height: noBottom
            ? file
              ? "calc(100% - 84px)"
              : "calc(100% - 53.18px)"
            : file
            ? "calc(100% - 124px)"
            : "calc(100% - 93.18px)",
          // borderTop: `2px solid ${primary}`,

          fontSize: "0.6rem",
          position: "relative",
          color: "black",
          // border: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "calc(100% - 60px)",
            position: "relative",
          }}
        >
          {props.children}
        </div>
      </div>
      {/* <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            minHeight: "60px",
            height: "60px",
          }}
        >
          <div
            style={{
              padding: "5px",
              display: "flex",
              minHeight: "100%",
              height: "100%",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div style={{ padding: "5px", width: "100%", display: "flex" }}>
              For SRI SAINATH INDUSTRY PVT LTD
            </div>
            <div
              style={{
                padding: "5px",
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <div> Authorized Signatory</div> <div> Broker</div>
              <div> Accepted - Seller ( with sign and seal )</div>
            </div>
          </div>
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default PdfLayoutSo;
