import React, { Component } from "react";
import Layout from "../../layouts/Layout";

import NoData from "../../styles/Illustrations/NoData";

import { EndPoints } from "../../utils/EndPoints";
import moment from "moment";
import { StyledCircularProgress } from "../../styles/StyledComponents";

import CreateDialog from "../../layoutComponents/CreateDialog";
import EditIcon from "@mui/icons-material/Edit";
import { Paper, Tooltip } from "@material-ui/core";
import { filterData, getSingleModule_widget } from "../../Regex";
import { accessText } from "../../routes/sidebar";
export class BrandsComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Brands"],
        tooltip: "Click to approve pending Brands",
        data: [],
        route: "/Brands/Brands",
        widget: null, 
        goBackRoute: "/Brands",
        primaryField: "brand_name",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#0AFF00", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      Brands: [],
      singlelistView: null,
      loading: false,
      editList: false,
      openDialog: false,
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Brands"].split("/")[0] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {}
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    this.setState({
      singlelistView: value,
    });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };

  // setInitialValues(props) {
  //   const { widget_permission } = getSingleModule_widget(
  //     props.user,
  //     accessText.rice_bags_details,
  //     accessText.brands
  //   );
  //   const tabs = this.state.tabs.map((e, i) => {
  //     const tab = e;
  //     // has_read_permission:true,has_create_permission:true
  //     tab.widget = widget_permission?.widget
  //       ? { ...widget_permission?.widget }
  //       : null;
  //     tab.data =
  //       props[EndPoints["Brands"]] && props[EndPoints["Brands"]].length > 0
  //         ? props[EndPoints["Brands"]].sort((a, b) => {
  //             var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
  //             var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
  //             return new Date(dateA) - new Date(dateB);
  //           })
  //         : [];
  //     return tab;
  //   });
  //   this.setState({
  //     listViewArray:
  //       props[EndPoints["Brands"]] && props[EndPoints["Brands"]].length > 0 ? props[EndPoints["Brands"]] : [],
  //     Brands: props.Brands,
  //     tabs,
  //     loading: props.loading,
  //   });
  // }
  setInitialValues = (props) => {
    const { BrandList } = props;
    const { ItemCateoryList, ItemList, PackagingList, MillerList, POList } =
      this.state;

    const { widget_permission } = getSingleModule_widget(
      props.user,
      accessText.rice_bags,
      accessText.brands
    );
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      // has_read_permission:true,has_create_permission:true
      tab.widget = widget_permission?.widget
        ? { ...widget_permission?.widget }
        : null;
      // const tabs = this.state.tabs.map((e, i) => {
      //   const tab = e;
      tab.data =
      props[EndPoints["Brands"]] && props[EndPoints["Brands"]].length > 0
        ? props[EndPoints["Brands"]]
        : [];
    tab.data =
      props[EndPoints["Brands"]] && props[EndPoints["Brands"]].length > 0
        ? props[EndPoints["Brands"]].sort((a, b) => {
            var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
            var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
            return new Date(dateA) - new Date(dateB);
          })
        : [];
    return tab;
  });
//       tab.data =
//         props[EndPoints["Brands"]] &&
//         props[EndPoints["Brands"]].length > 0
//           ? props[EndPoints["Brands"]]
//               .sort((a, b) => {
//                 var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
//                 var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
//                 return new Date(dateA) - new Date(dateB);
//               })
//               .map((sBrand) => {
//                 if (
//                   ItemCateoryList &&
//                   ItemCateoryList.length > 0 &&
//                   ItemList &&
//                   ItemList.length > 0
//                 ) {
//                   sBrand.item_category_name = ItemCateoryList.filter(
//                     (sB) => sB.id === sBrand.item_category
//                   )
//                     ? ItemCateoryList.filter(
//                         (sB) => sB.id === sBrand.item_category
//                       ) &&
//                       ItemCateoryList.filter(
//                         (sB) => sB.id === sBrand.item_category
//                       )[0] &&
//                       ItemCateoryList.filter(
//                         (sB) => sB.id === sBrand.item_category
//                       )[0].name
//                     : "";
//                   sBrand.item_type_name =
//                     ItemList &&
//                     ItemList.filter((sB) => sB.id === sBrand.item_type)
//                       ? ItemList.filter(
//                           (sB) => sB.id === sBrand.item_type
//                         )[0] &&
//                         ItemList.filter((sB) => sB.id === sBrand.item_type)[0]
//                           .broken_percentage
//                         ? ItemList.filter((sB) => sB.id === sBrand.item_type)[0]
//                             .broken_percentage
//                         : ""
//                       : "";
//                   sBrand.primary_field = `${sBrand.item_category_name?sBrand.item_category_name:"-"}
//                -
//                   ${sBrand.brand_name && sBrand.brand_name ? sBrand.brand_name : ""}`;
// console.log(sBrand.brand_name,)
//                   sBrand.secondary_field = `${sBrand.item_type_name}%`;
//                   return sBrand;
//                 } else {
//                   return sBrand;
//                 }
//               })
//           : [];

    //   return tab;
    // });
   
   
  };
  fetchList = (res) => {

    localStorage.setItem("fetchagain-bri","inco-term")
    if (
      this.state.singlelistView &&
      this.state.singlelistView.id &&
      res &&
      res.id &&
      res.id === this.state.singlelistView.id
    ) {
      this.setState({ singlelistView: res });
    }
    this.props.BrandsManagementsFunction("get", EndPoints["Brands"]);
  };
  render() {
    const { tabs, Brands, listViewArray, singlelistView, loading, editList, openDialog } = this.state;
    const { user } = this.props;

    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.rice_bags,
      accessText.brands
    );
    console.log(widget_permission,accessText.rice_bags,accessText.brands,"accessText.brands")
    // const { isMobile ,user} = this.props;
    return (
      <Layout
        loading={loading[EndPoints["Brands"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: "Search Brands",
          labelField: "name",
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
        fabClickHandler={() => {
          localStorage.removeItem("fetchagain-bri")
          this.setState({ openDialog: true, editList: false });
        }}
      >
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {openDialog === true ? (
            <CreateDialog
              module={"Brands"}
              closeOnSave={true}
              selectedList={editList ? editList : false}
              editList={editList ? editList : false}
              handleClose={() => {
                this.setState({ openDialog: false });
              }}
              noAddAnother={true}
              fetchAgain={this.fetchList}
              postApi="masters/brand/list"
              editApi="masters/brand"
              textFieldStyle={{ width: "calc(50% - 20px)", margin: "10px" }}
              heightNew="300px"
              maxHeight="calc(100vh - 140px)"
              widthNew="700px"
              inputArray={[
                {
                  name: "Active",
                  type: "toggle",
                  defaultValue: {
                    value: editList ? editList.is_active : true,
                  },
                  api: "is_active",
                },
                {
                  name: "Brand",
                  type: "addressTextField",
                  dataType: "text",
                  api: "brand_name",
                  onlyIfUpdated: true,
                  defaultValue: {
                    value: editList && editList.brand_name ? editList.brand_name : "",
                  },
                },
              ]}
              postData={this.props.BrandsManagementsFunction}
            />
          ) : (
            ""
          )}
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading[EndPoints["Brands"]] === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <Paper
              className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="width-100 d-flex justifyFE">
                {console.log(widget_permission,"widget_permission")}
              {widget_permission?.widget?.has_update_permission && (
                <Tooltip title="Edit">
                  <EditIcon
                    onClick={() => {
                      this.setState({
                        openDialog: true,
                        editList: singlelistView,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              )}
              </div>
              <div
                className="overflowYA scrollBluePrimary"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 50px",
                  height: "calc(100% - 31.7px)",
                  // textTransform: "capitalize",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Name:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "18px",

                      // height: "20vh",
                      // wordWrap: "break-word",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      textTransform: "capitalize",
                      wordBreak: "break-all",
                    }}
                  >
                    {singlelistView.brand_name}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Status:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "18px",

                      // height: "20vh",
                      // wordWrap: "break-word",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      textTransform: "capitalize",
                      wordBreak: "break-all",
                    }}
                  >
                    {singlelistView.is_active}
                  </p>
                </div>
              </div>
            </Paper>
          )}
        </Paper>
      </Layout>
    );
  }
}

export default BrandsComponent;
