import React, { Component } from "react";
import Layout from "../../layouts/Layout";

import NoData from "../../styles/Illustrations/NoData";

import { StyledCircularProgress } from "../../styles/StyledComponents";
import { Paper, Tooltip } from "@material-ui/core";
import { EndPoints } from "../../utils/EndPoints";
import CreateDialog from "../../layoutComponents/CreateDialog";
import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";
import { filterData, getSingleModule_widget } from "../../Regex";
import { accessText } from "../../routes/sidebar";
export class ShippersComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: "Shippers",
        tooltip: "Click to approve pending Shippers",
        data: [],
        route: "/Shippers/Shippers",
        goBackRoute: "/Shippers",
        widget: null, 
        primaryField: "name",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#0AFF00", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      Shippers: [],
      singlelistView: null,
      loading: false,
      openDialog: false,
      editList: false,
      benificiaryList: [],
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Shippers"].split("/")[0] && next.params.id) {
        this.setState({
          singlelistView: this.state.tabs[0].data[next.params.id],
        });
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }
  fetchList = (res) => {
    localStorage.setItem("fetchagain-bri","shipper")
    if (
      this.state.singlelistView &&
      this.state.singlelistView.id &&
      res &&
      res.id &&
      res.id === this.state.singlelistView.id
    ) {
      this.setState({ singlelistView: res });
    }
    this.props.BrandsManagementsFunction("get", EndPoints["Shippers"]);
  };
  listClickHandler = (value) => {
    this.setState({
      singlelistView: value,
    });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  setInitialValues(props) {
    const { widget_permission } = getSingleModule_widget(
      props.user,
      accessText.ports,
      accessText.shippers
    );
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      // has_read_permission:true,has_create_permission:true
      tab.widget = widget_permission?.widget
        ? { ...widget_permission?.widget }
        : null;

      tab.data =
        props[EndPoints["Shippers"]] && props[EndPoints["Shippers"]].length > 0
          ? props[EndPoints["Shippers"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [];
      return tab;
    });
    this.setState({
      Shippers:
        props[EndPoints["Shippers"]] && props[EndPoints["Shippers"]].length > 0
          ? props[EndPoints["Shippers"]]
          : [],
      benificiaryList:
        props[EndPoints["Beneficiarys"]] &&
        props[EndPoints["Beneficiarys"]].length > 0
          ? props[EndPoints["Beneficiarys"]]
          : [],
      tabs,
    });
  }

  render() {
    const {
      tabs,
      Shippers,
      singlelistView,
      loading,
      openDialog,
      editList,
      benificiaryList,
    } = this.state;
    // const { isMobile ,user} = this.props;
    const { user } = this.props;

    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.ports,
      accessText.shippers
    );
    console.log(widget_permission,"widget_permission")
    return (
      <Layout
        loading={loading[EndPoints["Shippers"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: Shippers && Shippers.length > 0 ? Shippers : [],
          hintText: "Search Shippers",
          labelField: "name",
        }}
        listClickHandler={this.listClickHandler}
        fabClickHandler={() => {
          localStorage.removeItem("fetchagain-bri")
          this.setState({ openDialog: true, editList: false });
        }}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
      >
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {openDialog === true ? (
            <CreateDialog
              module={"Shipper"}
              closeOnSave={true}
              selectedList={editList ? editList : false}
              editList={editList ? editList : false}
              handleClose={() => {
                this.setState({ openDialog: false });
              }}
              noAddAnother={true}
              fetchAgain={this.fetchList}
              postApi="masters/shipper/list"
              editApi="masters/shipper"
              textFieldStyle={{ width: "calc(50% - 20px)", margin: "10px" }}
              heightNew="500px"
              maxHeight="calc(100vh - 140px)"
              widthNew="700px"
              inputArray={[
                {
                  name: "Active",
                  type: "toggle",
                  defaultValue: {
                    value: editList ? editList.is_active : true,
                  },
                  api: "is_active",
                },
                {
                  name: "Name",
                  type: "textField",
                  dataType: "text",
                  api: "name",
                  defaultValue: {
                    value: editList && editList.name ? editList.name : "",
                  },
                },
                {
                  name: "Address",
                  type: "addressTextField",
                  dataType: "text",
                  api: "address",
                  defaultValue: {
                    value: editList && editList.address ? editList.address : "",
                  },
                },
                {
                  name: "GST No",
                  type: "textField",
                  dataType: "text",
                  onKeyPress: "alphaNum",
                  onInput: "gst_in",
                  api: "gstin",
                  defaultValue: {
                    value: editList && editList.gstin,
                  },
                  helperText: "ex: 22AAAAA0000A1Z5",
                },
                {
                  name: "Cin No",
                  type: "textField",
                  dataType: "text",
                  onKeyPress: "alphaNum",
                  not_required: true,
                  onInput: "cin_no",
                  api: "cin_no",
                  defaultValue: {
                    value: editList && editList.cin_no,
                  },
                },
                {
                  name: "Fax No",
                  type: "textField",
                  dataType: "number",
                  only_number: true,
                  // not_required: true,
                  onInput: "fax_no",
                  api: "fax_no",
                  sendNameNotId: true,
                  defaultValue: {
                    value: editList && editList.fax_no,
                  },
                },
                {
                  name: "Email",
                  type: "textField",
                  dataType: "text",
                  api: "email",
                  // not_required: true,
                  onInput: "email",
                  defaultValue: {
                    value: editList && editList.email,
                  },
                },
                {
                  name: "Website",
                  type: "textField",
                  dataType: "text",
                  api: "website",
                  not_required: true,
                  defaultValue: {
                    value: editList && editList.website,
                  },
                },

                {
                  name: "Beneficiary",
                  type: "select",
                  dataType: "text",
                  array:
                    benificiaryList && benificiaryList.length > 0
                      ? benificiaryList
                      : [],
                  value: "name",
                  defaultValue: {
                    value:
                      editList && editList.benificiary
                        ? filterData(
                            benificiaryList,
                            "id",
                            editList.benificiary,
                            "onlyOne"
                          )
                          ? filterData(
                              benificiaryList,
                              "id",
                              editList.benificiary,
                              "onlyOne"
                            ).name
                          : ""
                        : "",
                    id:
                      editList && editList.benificiary
                        ? editList.benificiary
                        : "",
                  },
                  api: "benificiary",
                },
              ]}
              postData={this.props.BrandsManagementsFunction}
            />
          ) : (
            ""
          )}
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Shippers === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <div
            className="width-100 height-100 padding15 backgroundWhite"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
                <div style={{ width: "90%" }}>
                  <h1 style={{ fontWeight: "400", fontSize: "25px" }}>
                    Shipper Details
                  </h1>
            <Paper
              className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div className="width-100 d-flex justifyFE">
              {widget_permission?.widget?.has_update_permission && (
                <Tooltip title="Edit">
                  <EditIcon
                    onClick={() => {
                      this.setState({
                        openDialog: true,
                        editList: singlelistView,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>)}
              </div>

              <div
                className="overflowYA scrollBluePrimary"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 50px",
                  height: "calc(100% - 31.7px)",
                  // textTransform: "capitalize",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Name:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      textTransform: "capitalize",
                      wordBreak: "break-all",
                    }}
                  >
                    {singlelistView.name}
                  </p>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Address:
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      wordBreak: "break-all",
                    }}
                  >
                    {singlelistView.address}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    GSTIN:{" "}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                    }}
                  >
                    {singlelistView.gstin}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Cin no:{" "}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                    }}
                  >
                    {singlelistView.cin_no}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Email:{" "}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      textTransform: "lowercase",
                    }}
                  >
                    {singlelistView.email}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                      textTransform: "lowercase",
                    }}
                  >
                    Website:{" "}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                    }}
                  >
                    {singlelistView.website}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Fax No:{" "}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                    }}
                  >
                    {singlelistView.fax_no}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: "200px",
                    }}
                  >
                    Beneficiary Name:{" "}
                  </p>{" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                    }}
                  >
                    {filterData(
                      benificiaryList,
                      "id",
                      singlelistView.benificiary,
                      "onlyOne"
                    )
                      ? filterData(
                          benificiaryList,
                          "id",
                          singlelistView.benificiary,
                          "onlyOne"
                        ).name
                      : ""}
                  </p>
                </div>
              </div>
            </Paper>
            </div></div>
          )}
        </Paper>
      </Layout>
    );
  }
}

export default ShippersComponent;
