import { Divider, Paper } from "@material-ui/core";
import { Download } from "@mui/icons-material";
import React from "react";
import { EditIcon } from "../styles/Icons";
import { StyledButton, StyledLoadingButton } from "../styles/StyledComponents";

function ShowDetails(props) {
  const { minLeftWidth, array, download, heightFc, childData, loading, editButton } = props;
  return (
    <Paper
      className={`padding15 backgroundWhite postionR overflowYA scrollBluePrimary d-flex d-flex-column ${
        heightFc ? "heightFC" : "height-100"
      } width-100 textC`}
    >
      {array &&
        array.length > 0 &&
        array
          .filter((s) => s !== false)
          .map((singleObj, i) => (
            <div className="width-100 d-flex alignC">
              <div
                style={{ width: singleObj.label2 ? "50%" : "" }}
                className={`${singleObj.label2 ? "" : "width-100"} d-flex `}
              >
                <p
                  style={{
                    fontSize: "18px",
                    margin: "10px 0 ",
                    minWidth: minLeftWidth ? minLeftWidth : "200px",
                  }}
                  className="d-flex justifySB"
                >
                  {singleObj.label}
                  <div style={{ margin: "0 10px" }}>{" : "}</div>
                </p>
                <div
                  className={`d-flex `}
                  style={{
                    justifyContent: download ? "space-between" : "",
                    width: `calc(100% - ${minLeftWidth ? minLeftWidth : "200px"})`,
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 10px 10px",
                      color: "#808080",
                      textTransform: singleObj.dontCapitalize ? "" : "capitalize",
                    }}
                  >
                    {singleObj.value}
                  </p>
                  {download && i === 0 && !singleObj.label2 ? (
                    <div className="d-flex">
                      <StyledLoadingButton
                        loading={loading ? loading : false}
                        tooltip="Download PDF"
                        name={<Download />}
                        margin="5px 0"
                        maxHeight="35.74px"
                        onClick={(e) => {
                          e.stopPropagation();
                          download();
                        }}
                        width={"43px"}
                        minWidth={"43px"}
                        withBg={true}
                        background="rgb(241 154 48)"
                      />
                      {editButton ? (
                        <StyledLoadingButton
                          tooltip="Edit"
                          name={<EditIcon />}
                          margin="5px 0 5px 10px"
                          maxHeight="35.74px"
                          onClick={(e) => {
                            editButton();
                          }}
                          width={"43px"}
                          minWidth={"43px"}
                          withBg={true}
                          background="rgb(241 154 48)"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {singleObj.label2 && (
                <div style={{ width: "50%" }} className={` d-flex`}>
                  {" "}
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "10px 0 ",
                      minWidth: minLeftWidth ? minLeftWidth : "200px",
                    }}
                    className="d-flex justifySB"
                  >
                    {singleObj.label2}
                    <div style={{ margin: "0 10px" }}>{" : "}</div>
                  </p>
                  <div
                    className={`d-flex `}
                    style={{
                      justifyContent: download ? "space-between" : "",
                      width: `calc(100% - ${minLeftWidth ? minLeftWidth : "200px"})`,
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                        textTransform: singleObj.dontCapitalize ? "" : "capitalize",
                      }}
                    >
                      {singleObj.value2}
                    </p>
                    {download && i === 0 ? (
                      <div className="d-flex">
                        {" "}
                        <StyledLoadingButton
                          loading={loading ? loading : false}
                          tooltip="Download PDF"
                          name={<Download />}
                          margin="5px 0"
                          maxHeight="35.74px"
                          onClick={(e) => {
                            e.stopPropagation();
                            download();
                          }}
                          width={"43px"}
                          minWidth={"43px"}
                          withBg={true}
                          background="rgb(241 154 48)"
                        />
                        {editButton ? (
                          <StyledLoadingButton
                            tooltip="Edit"
                            name={<EditIcon />}
                            margin="5px 0 5px 5px"
                            maxHeight="35.74px"
                            onClick={(e) => {
                              editButton();
                            }}
                            width={"43px"}
                            minWidth={"43px"}
                            withBg={true}
                            background="rgb(241 154 48)"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
      {childData ? <Divider style={{ width: "100%", margin: "10px 0" }} /> : ""}
      {childData ? childData : ""}
    </Paper>
  );
}

export default ShowDetails;
