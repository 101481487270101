import React, { useState, useEffect } from 'react';

export function useLocalStorage(key) {
  const [value, setValue] = useState(localStorage.getItem(key));

  useEffect(() => {
    const handleChange = () => {
      console.log("fetchfetchAgainAgainreload 0 1")
      setValue(localStorage.getItem(key));
    };

    window.addEventListener('storage', handleChange);

    return () => {
      window.removeEventListener('storage', handleChange);
    };
  }, [key]);

  return value;
}