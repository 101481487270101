import React, { useEffect /* useRef, useEffect */ } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import WebViewAppBar from "./WebViewAppBar";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
// import MobileViewAppBar from "./MobileViewAppBar";
import SubMenuLayout from "./SubMenuLayout";
// import SwipeView from "./SwipeView";
// import { withRouter } from "react-router-dom";
import { primary } from "../styles/colors";
import { useNavigate } from "react-router-dom";
//import { poppinsBold, poppinsLight, poppinsMedium, poppinsRegular } from "../styles/Fonts"
import { CssBaseline } from "@material-ui/core";
// import { primary } from "../styles/colors";
import { connect } from "react-redux";
import CustomAppBar from "./CustomAppBar";
// import MobileViewBottomBar from "./MobileViewBottomBar";

// const theme = createTheme({
//   typography: {
//   },

//   overrides: {
//     MuiPickersToolbar: {
//       toolbar: { backgroundColor: primary },
//     },
//     MuiPickersCalendarHeader: {
//       iconButton: {
//         backgroundColor: "transparent",
//         color: primary,
//       },
//       dayLabel: {
//         color: primary, //days in calendar
//       },
//       transitionContainer: {
//         color: primary,
//       },
//     },

//     MuiPickersDay: {
//       // 	day: {
//       // 		color: CURRENT_THEME.textInv, //days in calendar
//       // 	},
//       daySelected: {
//         backgroundColor: primary,
//         color: white, //calendar circle
//       },

//       current: {
//         backgroundColor: green,
//         color: white, //calendar circle
//       },
//     },

//     MuiPickersClock: {
//       // 	clock: {
//       // 		backgroundColor: white,
//       // 		color: primary,
//       // 	},
//       pin: {
//         backgroundColor: primary,
//       },
//     },
//     MuiPickersClockPointer: {
//       pointer: { backgroundColor: primary },
//     },
//     MuiPickersClockNumber: {
//       // clockNumber: {
//       // 	color: "#FF3D00",
//       // },
//       numberSelected: {
//         backgroundColor: primary,
//         color: white, //calendar circle
//       },
//       MuiMuiPickersTime: {
//         timeSelected: {
//           backgroundColor: primary,
//           color: white, //calendar circle
//         },
//       },
//       MuiPickersModal: {
//         dialogAction: {
//           color: white, //no se
//           backgroundColor: "yellow",
//         },
//       },
//     },
//     MuiCssBaseline: {
//       "@global": {
//         "@font-face": [poppinsBold, poppinsLight, poppinsMedium, poppinsRegular],
//       },
//     },
//   },
//   palette: {
//     primary: {
//       light: "rgb(128, 128, 128)",
//       main: "rgb(128, 128, 128)",
//       dark: "rgb(128, 128, 128)",
//     },
//     secondary: {
//       light: primary,
//       main: primary,
//       dark: primary,
//     },
//   },
// });

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },

  palette: {
    primary: {
      light: primary,
      main: primary,
      dark: primary,
    },
    secondary: {
      light: primary,
      main: primary,
      dark: primary,
    },
  },
  // palette: {
  //   primary: purple,
  //   secondary: green,
  // },
  status: {
    danger: "orange",
  },
});

function Layout(props) {
  const navigate = useNavigate();
  // const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = React.useState(false);
  // const handler = useCallback(() => setWidth(window.innerWidth), [setWidth]);
  // useEventListener("resize", handler);
  // const isMobile = width <= 500;

  // if (isMobile) {
  //   return (
  //     <MuiThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <div className="height-100vh width-100 overflowH">
  //         <MobileViewAppBar {...props} isMobile={isMobile} />
  //         <div style={{ width: "100%", height: "calc(100% - 120px)" }}> {props.children}</div>
  //         <MobileViewBottomBar {...props} isMobile={isMobile} />
  //       </div>
  //       <SwipeView {...props} isMobile={isMobile}>
  //         {props.children}
  //       </SwipeView>
  //     </MuiThemeProvider>
  //   );
  // } else {
  useEffect(() => {
    if (props.noAppBar) {
      setOpen(false);
    }
  }, [props.noAppBar]);
  const { pathname } = useLocation();
  const { tab, id } = useParams();

  return (
    <div
      className={
        props.noAppBar
          ? "height-100 width-100 overflowH d-flex"
          : "height-100vh width-100 overflowH d-flex"
      }>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />{" "}
        {props.noAppBar ? (
          ""
        ) : (
          <WebViewAppBar
            {...props}
            open={open}
            setOpen={setOpen}
            navigate={navigate}
            pathname={pathname}
            params={{ tab: tab, id: id }}
          />
        )}
        <div
          style={{
            transition: open
              ? "235px 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
              : "57px 0.3s cubic-bezier(0.4, 0, 0.2, 1)",

            minHeight: "52px",
            //zIndex: "999999",
            position: "absolute",
            top: "0",
            left: props.noAppBar ? 0 : open ? "235px" : "57px",
            background: "white",
            boxShadow: "0px 2px 2px rgb(241, 154, 48)",
            width: `calc(100% - ${
              props.noAppBar ? 0 : open ? "235px" : "57px"
            })`,
          }}>
          <CustomAppBar
            {...props}
            open={true}
            setOpen={setOpen}
            navigate={navigate}
            pathname={pathname}
            params={{ tab: tab, id: id }}
          />
        </div>
        {props.tabs &&
        props.tabs[0].widget !== null &&
        props.tabs[0].widget?.has_read_permission === false ?"":props.tabs&& (
          
              <SubMenuLayout
                setOpen={setOpen}
                {...props}
                navigate={navigate}
                pathname={pathname}
                params={{ tab: tab, id: id }}
              />)}
        {props.tabs &&
        props.tabs[0].widget !== null &&
        props.tabs[0].widget?.has_read_permission === false ? (
          <div className="d-flex width-100 height-100 justifyC alignC">
            No permission
          </div>
        ) : (
          <div
            style={{
              marginTop: "57px",
              height: "calc(100% - 57px)",
              width: props.noAppBar
                ? `calc(100% - ${props.centerWidth})`
                : open
                ? props.centerWidth
                  ? `calc(100% - ${props.openWidth})`
                  : props.width
                  ? props.width
                  : "calc(100% - 561.47px)"
                : props.centerWidth
                ? `calc(100% - ${props.closeWidth})`
                : props.width
                ? props.width
                : "calc(100% - 327px)",
            }}>
            {props.children}
          </div>
        )}
      </MuiThemeProvider>
    </div>
  );
  // }
}

// function useEventListener(eventName, handler, element = window) {
//   const savedHandler = useRef();
//   useEffect(() => {
//     savedHandler.current = handler;
//   }, [handler]);

//   useEffect(() => {
//     const isSupported = element && element.addEventListener;
//     if (!isSupported) return;
//     const eventListener = (event) => savedHandler.current(event);
//     element.addEventListener(eventName, eventListener);
//     return () => {
//       element.removeEventListener(eventName, eventListener);
//     };
//   }, [eventName, element]);
// }
const mapStateToProps = (state) => ({
  user: state.authentication.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
