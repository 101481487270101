import axios from "axios";

export const requests = async (options,users) => {
  const user =users?users: localStorage.getItem("briuser") ? JSON.parse(localStorage.getItem("briuser")) : "";


  const res = await axios({
    method: options.method,
    url: options.url,
    params: options.params ? options.params : "",
    data: options.data ? options.data : {},
    headers:
      user && user.uid
        ? {
            Authorization: `JWT ${user && user.uid ? user.uid : ""}`,
            "Content-Type": "application/json;charset=UTF-8",
          }
        : { "Content-Type": "application/json;charset=UTF-8" },
  })
    .then((response) => {
    
      return response;
    })
    .catch((error) => {
      return {
        error: true,
        data:
          error.response && error.response.data
            ? typeof error.response.data === "string"
              ? error.response.statusText
              : error.response.data.errors
              ? error.response.data.errors
              : error
            : error,
      };
    });
  return res;
};
