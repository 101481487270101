import React, { useEffect, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import { moduleIdURL, moduleURL } from "../../../http/config";
import { requests } from "../../../requests";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Backdrop } from "@material-ui/core";
import { StyledCircularProgress } from "../../../styles/StyledComponents";
import MenuItem from "@mui/material/MenuItem";
import { stateArray } from "../../../Defaults";
import { StopScroll } from "../../../Regex";
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "0 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: "15px",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "8px 0",
  },
  textField: {
    width: "40%",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    // margin: "15px 0",
  },
  alignBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
};
let out;
var c = 0;

const EditForm = ({
  getFn,
  getFnSurvey,
  companyId,
  isNew,
  name,
  phone,
  address,
  gstin,
  contactPerson,
  city,
  state,
  pincode,
  status,
  surveyors,
  closePopup,
  isPopup,
  // surveyorId,
}) => {
  const [responseSurveyor, setResponseSurveyor] = useState([]);
  const [surv, setSurv] = useState([]);

  const [fieldValues, setFieldValues] = useState({
    nameInput: name,
    phoneInput: phone,
    addressInput: address,
    gstinInput: gstin,
    contactPersonInput: contactPerson,
    cityInput: city,
    stateInput: state,
    pincodeInput: pincode,
    surveyorsInput: surveyors,
    statusInput: status,
  });

  const [isValid, setIsValid] = useState({
    nameInput: true,
    // phoneInput: true,
    addressInput: true,
    gstinInput: true,
    contactPersonInput: true,
    cityInput: true,
    stateInput: true,
    pincodeInput: true,
    surveyorsInput: [true],
  });

  const [confirm, setConfirm] = useState(false);

  // const [surveyBulk, setSurveyBulk] = useState(fieldValues.surveyorsInput);
  const [pushNoti, setPushNoti] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pushNotier, setPushNotier] = useState(false);
  const [patchSurvey, setPatchSurvey] = useState();

  const [setDelete, setSetDelete] = useState([]);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setFieldValues({
      nameInput: name,
      phoneInput: phone,
      addressInput: address,
      gstinInput: gstin,
      contactPersonInput: contactPerson,
      cityInput: city,
      stateInput: state,
      pincodeInput: pincode,
      surveyorsInput: surveyors,
      statusInput: status,
    });
  }, [
    name,
    phone,
    address,
    gstin,
    contactPerson,
    city,
    state,
    pincode,
    surveyors,
    status,
    isPopup,
  ]);

  const handleReset = () => {
    let survv = [];
    // c = 0;
    setLoading(true);

    const getUrl = moduleIdURL("masters/survey-company", companyId);
    requests({
      method: "get",
      url: getUrl,
    })
      // .then((r) => r.json())
      .then((r) => {
        {
          setSurv([]);
          r.data.surveyors_detail &&
            r.data.surveyors_detail.map((item, index) => {
              surv.push(item);
            });
          // setSurv(survv);

          setLoading(false);

          // setFieldValues({
          //   surveyorsInput: surv,
          // });
          setResponseSurveyor(r.data.surveyors_detail);
          setCounter(r.data.surveyors_detail.length);
        }
      });

    setFieldValues({
      nameInput: name,
      phoneInput: phone,
      addressInput: address,
      gstinInput: gstin,
      contactPersonInput: contactPerson,
      cityInput: city,
      stateInput: state,
      pincodeInput: pincode,
      surveyorsInput: surv,

      statusInput: status,
    });
    // responseSurveyor.map((item) => {
    //   if (item) {
    //     c = c + 1;
    //   }
    // });
    // setCounter(c);
  };
  // useEffect(() => {
  //   let timeout;
  //   if (loading) {
  //     timeout = setTimeout(() => setLoading(false), 2000);
  //   }
  //   return () => clearTimeout(timeout);
  // }, [loading]);

  useEffect(() => {
    const getUrl = moduleIdURL("masters/survey-company", companyId);
    c = 0;
    requests({
      method: "get",
      url: getUrl,
    })
      // .then((r) => r.json())
      .then((r) => {
        setResponseSurveyor(r.data.surveyors_detail);
        setCounter(r.data.surveyors_detail.length);
      });

    // console.log(responseSurveyor, "rrrrr");
    responseSurveyor.map((item) => {
      if (item) {
        c = c + 1;
      }
    });
    setCounter(c);
    // console.log(counter, "counter");
  }, [
    // fieldValues.nameInput,
    // fieldValues.phoneInput,
    // fieldValues.addressInput,
    // fieldValues.gstinInput,
    // fieldValues.contactPersonInput,
    // fieldValues.cityInput,
    // fieldValues.stateInput,
    // fieldValues.pincodeInput,
    surveyors,
  ]);

  let deleted = 0;
  let patchArr = [];
  let nameArr = [];
  let postArr = [];

  const handleSurveyorInput = (e, index) => {
    let { value } = e.target;
    // if (e.target.value.length < 0) {
    //   value.value = "";
    //   e.target.value = "";
    //   console.log(e.target.value, "valueee");
    // } else {
    //   if (e.target.value.length === 1) {
    //     e.target.value = " ";
    //   }
    // }
    // console.log(e.target.value, "valueee else");
    // if (e.target.value === "[object Object]") {
    // }
    // console.log(value, "valuee");
    const list = [...fieldValues.surveyorsInput];
    // console.log(list[index], "listtt");

    if (index < counter) {
      if (list[index] === "") {
        list[index].name = value;
        // c = c - 1;
      } else list[index].name = value;
    } else {
      list[index] = value;
    }
    // if (list[index] === "") {
    //   list[index] = value;
    // }
    setFieldValues((prev) => ({ ...prev, surveyorsInput: list }));
    // setSurveyBulk
    // surveyBulk[index].name = value;
  };

  // var del = [];

  const handleDeleteSurveyor = (index) => {
    // console.log(index, "delete index");
    setConfirm(true);
    const list = [...fieldValues.surveyorsInput];
    // console.log(list, "listtttt");
    const listAfterdel = list.filter((_, itemIndex) => itemIndex !== index);
    setSetDelete(list);
    setFieldValues((prev) => ({ ...prev, surveyorsInput: listAfterdel }));

    // if (index < c) {
    setPatchSurvey(fieldValues.surveyorsInput[index].id);
    // }

    deleted = deleted + 1;
  };
  // console.log(fieldValues.surveyorsInput, "surveyors listtt");
  const hadnleDontConfirm = () => {
    setConfirm(false);
    setFieldValues((prev) => ({ ...prev, surveyorsInput: setDelete }));
  };
  const handleClose = (event, reason) => {
    setConfirm(false);
    if (reason && reason == "backdropClick") {
      setFieldValues((prev) => ({ ...prev, surveyorsInput: setDelete }));
    }
  };
  const handleConfirm = () => {
    // setFieldValues((prev) => ({ ...prev, surveyorsInput: listAfterdel }));

    const deleteURL = moduleIdURL("masters/surveyor", patchSurvey);
    requests({
      method: "patch",
      url: deleteURL,
      data: { is_active: "false" },
    }).then((res) => {
      // getFnSurvey(res);
      // console.log(res.statusText, "delete response");
      if (res.statusText === "OK") {
        setCounter(counter - 1);
      }
    });
    setConfirm(false);
    onEditHandler();
    // closePopup();
  };

  // let surveyPost = fieldValues.surveyorsInput.map((item) => {
  //   return {
  //     id: item.id,
  //     name: item.name,
  //     company: item.company,
  //   };
  // });
  // console.log(counter, "counter");
  // console.log(fieldValues.surveyorsInput.length, "lengthhhh");
  // console.log(c, "cccc");

  for (let i = 0; i < fieldValues.surveyorsInput.length; i++) {
    // console.log(fieldValues.surveyorsInput.length, "lengthhh");
    // console.log(counter, "counter");
    if (counter > i) {
      patchArr.push(fieldValues.surveyorsInput[i]);
      // console.log(fieldValues.surveyorsInput[i], "inputtt");
    } else {
      nameArr.push(fieldValues.surveyorsInput[i]);
      // console.log(nameArr, "first name");
    }
  }

  // for (let j = 0; j < fieldValues.surveyorsInput.length; j++) {
  //   for (let i = 0; j < c; i++) {
  //     patchArr.push(fieldValues.surveyorsInput[j]);
  //   }
  //   for (let k = 0; j > c; k++) {
  //     postArr.push(fieldValues.surveyorsInput[j]);
  //   }
  // }

  // console.log(patchArr, "patch array");

  nameArr.map((user) => {
    out = { name: user, is_active: true, company: companyId };
    postArr.push(out);
  });

  const onEditHandler = (e) => {
    e.preventDefault();

    const patchSurveyor = moduleURL("masters/surveyor/bulk-update");
    const postURL = moduleURL("masters/surveyor/list");

    const finalPost = [];
    postArr.map((item) => {
      if (item.name !== "") {
        finalPost.push(item);
      }
    });
    // console.log(finalPost, "ffffffff");

    // requests({
    //   method: "put",
    //   url: patchSurveyor,
    //   data: patchArr,
    // }).then((res) => {
    //   // getFnSurvey(res);
    // });

    // console.log(postArr, "post array");
    // // if (postArr !== []) {
    // requests({
    //   method: "post",
    //   url: postURL,
    //   data: postArr,
    // }).then((respo) => {
    //   // setPushNoti(true);
    // });

    if (fieldValues.nameInput.length === 0) {
      setIsValid((prev) => ({ ...prev, nameInput: false }));
    }
    if (fieldValues.cityInput.length === 0) {
      setIsValid((prev) => ({ ...prev, cityInput: false }));
    }
    // if (fieldValues.gstinInput.length < 15) {
    //   setIsValid((prev) => ({ ...prev, gstinInput: false }));
    // }
    if (fieldValues.addressInput.length === 0) {
      setIsValid((prev) => ({ ...prev, addressInput: false }));
    }
    if (fieldValues.stateInput.length === 0) {
      setIsValid((prev) => ({ ...prev, stateInput: false }));
    }
    // if (fieldValues.phoneInput.length < 10) {
    //   setIsValid((prev) => ({ ...prev, phoneInput: false }));
    // }
    if (fieldValues.contactPersonInput.length === 0) {
      setIsValid((prev) => ({ ...prev, contactPersonInput: false }));
    }
    if (
      fieldValues.pincodeInput.length < 6 ||
      fieldValues.pincodeInput[0] === 0
    ) {
      console.log(fieldValues.pincodeInput[0], "pinnnnnn");
      setIsValid((prev) => ({ ...prev, pincodeInput: false }));
    }

    if (
      fieldValues.nameInput.length > 0 &&
      // fieldValues.phoneInput.length > 9 &&
      fieldValues.cityInput.length > 0 &&
      // fieldValues.gstinInput.length > 14 &&
      fieldValues.addressInput.length > 0 &&
      fieldValues.stateInput.length > 0 &&
      fieldValues.pincodeInput.length > 5 &&
      fieldValues.contactPersonInput.length > 0
      // && fieldValues.surveyorsInput.length > 0
    ) {
      setLoading(true);

      const patchURL = moduleIdURL("masters/survey-company", companyId);
      // responseSurveyor.map((item, index) => {

      const patchSurveyor = moduleURL("masters/surveyor/bulk-update");
      const postURL = moduleURL("masters/surveyor/list");

      // delArr.map((item) => {
      //   const deleteURL = moduleIdURL("masters/surveyor", item.id);
      //   requests({
      //     method: "delete",
      //     url: deleteURL,
      //     data: item,
      //   })
      //     .then((r) => r.json())
      // });

      // let finalPatch = [];
      // let finalPost = [];

      // patchArr.map((item) => {
      //   if (typeof item === "string") {
      //     let out = { name: item, is_active: true, company: companyId };
      //     finalPost.push(out);
      //   } else {
      //     finalPatch.push(item);
      //   }
      // });

      // console.log(finalPatch, "final patch");
      // console.log(finalPost, "final post");
      requests({
        method: "put",
        url: patchSurveyor,
        data: patchArr,
      }).then((res) => {
        // getFnSurvey(res);
        console.log(patchArr, "patch arr");
        getFn();
      });

      if (finalPost !== []) {
        requests({
          method: "post",
          url: postURL,
          data: finalPost,
        }).then((respo) => {
          getFn();
          console.log(postArr, "posstttt");
        });
      }

      // });
      requests({
        method: "patch",
        url: patchURL,
        data: {
          name: fieldValues.nameInput,
          phone: fieldValues.phoneInput ? fieldValues.phoneInput : "",
          address: fieldValues.addressInput,
          gstin: fieldValues.gstinInput,
          contact_person: fieldValues.contactPersonInput,
          city: fieldValues.cityInput,
          state: fieldValues.stateInput,
          pincode: fieldValues.pincodeInput,
          is_active: fieldValues.statusInput,
          // surveyors: fieldValues.surveyorsInput,
          // created_by: 0,
          // modified_by: 0,
        },
      }).then((response) => {
        setIsValid({
          nameInput: true,
          // phoneInput: true,
          addressInput: true,
          gstinInput: true,
          contactPersonInput: true,
          cityInput: true,
          stateInput: true,
          pincodeInput: true,
          surveyorsInput: [true],
        });
        if (response.error) {
          // setError(response.data[0].message[0]);
          setError(
            typeof response.data === "string"
              ? response.data
              : response.data.length > 0
              ? response.data
                  .map((s) => {
                    return `${s.field ? `${s.field} :` : ""} ${
                      typeof s.message === "string"
                        ? s.message
                        : s.message.length > 0 && s.message[0] && s.message[0]
                    }`;
                  })
                  .join(" ,")
              : response.data.response
              ? response.data.response.data.msg.error
                ? response.data.response.data.msg.error
                : response.data.response.data.msg
              : "Error !"
          );

          setPushNotier(true);
        } else {
          setError("");
          setPushNoti(true);
          postArr = [];
          patchArr = [];
          getFn(response);
          // delArr = [];
        }
      });
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={onEditHandler}>
        <div style={classes.formGroup}>
          <div
            style={{
              width: "40%",

              //// marginRight: "20px",
            }}>
            {labelText("Name")}
            <TextField
              type="text"
              style={classes.textField}
              variant="outlined"
              // label="Name *"
              value={fieldValues.nameInput}
              onKeyPress={(e) => {
                const re = /[a-z A-Z]+/g;
                if (!re.test(e.key)) {
                  e.preventDefault();
                }
              }}
              style={{ width: "100%" }}
              onChange={({ target }) => {
                if (target.length !== 0) {
                  setIsValid((prev) => ({ ...prev, nameInput: true }));
                }
                setFieldValues((prev) => ({
                  ...prev,
                  nameInput: target.value,
                }));
              }}
              error={!isValid.nameInput}
              helperText={isValid.nameInput ? "" : "Name should not be empty"}
            />
          </div>{" "}
          <div
            style={{
              width: "40%",

              //// marginRight: "20px",
            }}>
            {labelText("Phone")}
            <TextField
              type="number"
              onFocus={StopScroll}
              style={classes.textField}
              variant="outlined"
              // label="Phone"
              onKeyDown={(evt) => {
                evt.key === "." && evt.preventDefault();

                evt.key === "e" && evt.preventDefault();

                evt.key === "E" && evt.preventDefault();
                evt.key === "-" && evt.preventDefault();
                evt.keyCode === 38 && evt.preventDefault();
                evt.keyCode === 40 && evt.preventDefault();
              }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              style={{ width: "100%" }}
              value={fieldValues.phoneInput}
              onChange={({ target }) => {
                setFieldValues((prev) => ({
                  ...prev,
                  phoneInput: target.value,
                }));
                // if (fieldValues.phoneInput.length > 0) {
                // setIsValid((prev) => ({ ...prev, phoneInput: true }));
                // }
              }}
              // error={!isValid.phoneInput}
              // helperText={
              //   isValid.phoneInput ? "" : "Phone should be a 10 digit number"
              // }
            />
          </div>
        </div>
        <div style={classes.formGroup}>
          <div
            style={{
              width: "40%",

              //// marginRight: "20px",
            }}>
            {labelText("Address")}
            <TextField
              type="text"
              multiline
              multiline
              maxRows={3}
              style={classes.textField}
              variant="outlined"
              style={{ width: "100%" }}
              // label="Address *"
              value={fieldValues.addressInput}
              onChange={({ target }) => {
                if (target.length !== 0) {
                  setIsValid((prev) => ({ ...prev, addressInput: true }));
                }
                setFieldValues((prev) => ({
                  ...prev,
                  addressInput: target.value,
                }));
              }}
              error={!isValid.addressInput}
              helperText={
                isValid.addressInput ? "" : "Address should not be empty"
              }
            />
          </div>
          <div
            style={{
              width: "40%",

              //// marginRight: "20px",
            }}>
            {labelText("GSTIN")}
            <TextField
              type="text"
              style={classes.textField}
              variant="outlined"
              // label="GSTIN *"
              value={fieldValues.gstinInput}
              onKeyPress={(e) => {
                const re = /[0-9a-zA-Z]+/g;
                if (!re.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={({ target }) => {
                if (target.length !== 0) {
                  setIsValid((prev) => ({ ...prev, gstinInput: true }));
                }
                setFieldValues((prev) => ({
                  ...prev,
                  gstinInput: target.value,
                }));
              }}
              style={{ width: "100%" }}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 15);
              }}
              error={!isValid.gstinInput}
              helperText={
                isValid.gstinInput
                  ? "ex- 22AAAAA0000A1Z5"
                  : "GSTIN should have 15 characters"
              }
            />
          </div>
        </div>
        <div style={classes.formGroup}>
          <div
            style={{
              width: "40%",

              //// marginRight: "20px",
            }}>
            {labelText("Contact Person")}
            <TextField
              type="text"
              style={classes.textField}
              variant="outlined"
              // label="Contact Person *"
              value={fieldValues.contactPersonInput}
              onKeyPress={(e) => {
                const re = /[a-z A-Z]+/g;
                if (!re.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={({ target }) => {
                if (target.length !== 0) {
                  setIsValid((prev) => ({
                    ...prev,
                    contactPersonInput: true,
                  }));
                }
                setFieldValues((prev) => ({
                  ...prev,
                  contactPersonInput: target.value,
                }));
              }}
              style={{ width: "100%" }}
              error={!isValid.contactPersonInput}
              helperText={
                isValid.contactPersonInput ? "" : "Enter Contact Person"
              }
            />
          </div>{" "}
          <div
            style={{
              width: "40%",

              //// marginRight: "20px",
            }}>
            {labelText("City")}
            <TextField
              type="text"
              style={classes.textField}
              variant="outlined"
              // label="City *"
              value={fieldValues.cityInput}
              onKeyPress={(e) => {
                const re = /[a-z A-Z]+/g;
                if (!re.test(e.key)) {
                  e.preventDefault();
                }
              }}
              style={{ width: "100%" }}
              onChange={({ target }) => {
                if (target.length !== 0) {
                  setIsValid((prev) => ({ ...prev, cityInput: true }));
                }
                setFieldValues((prev) => ({
                  ...prev,
                  cityInput: target.value,
                }));
              }}
              error={!isValid.cityInput}
              helperText={isValid.cityInput ? "" : "City should not be empty"}
            />
          </div>
        </div>
        <div style={classes.formGroup}>
          <div
            style={{
              width: "40%",

              //// marginRight: "20px",
            }}>
            {labelText("State")}
            <TextField
              type="text"
              style={classes.textField}
              select
              variant="outlined"
              style={{ width: "100%" }}
              // label="State *"
              value={fieldValues.stateInput}
              onKeyPress={(e) => {
                const re = /[a-z A-Z]+/g;
                if (!re.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={({ target }) => {
                if (target.length !== 0) {
                  setIsValid((prev) => ({ ...prev, stateInput: true }));
                }
                setFieldValues((prev) => ({
                  ...prev,
                  stateInput: target.value,
                }));
              }}
              error={!isValid.stateInput}
              helperText={isValid.stateInput ? "" : "Please select State"}>
              {stateArray.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </div>{" "}
          <div
            style={{
              width: "40%",

              //// marginRight: "20px",
            }}>
            {labelText("Pincode")}
            <TextField
              type="text"
              style={classes.textField}
              variant="outlined"
              // label="Pincode *"
              value={fieldValues.pincodeInput}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              }}
              onChange={({ target }) => {
                if (target.length !== 0) {
                  setIsValid((prev) => ({ ...prev, pincodeInput: true }));
                }

                setFieldValues((prev) => ({
                  ...prev,
                  pincodeInput: target.value,
                }));
              }}
              onKeyDown={(evt) => {
                evt.key === "." && evt.preventDefault();

                evt.key === "e" && evt.preventDefault();

                evt.key === "E" && evt.preventDefault();
                evt.key === "-" && evt.preventDefault();
                evt.keyCode === 38 && evt.preventDefault();
                evt.keyCode === 40 && evt.preventDefault();
              }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).slice(
                  0,
                  6
                );
              }}
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const re = /[0-9]+/g;
                if (!re.test(e.key)) {
                  e.preventDefault();
                }
              }}
              error={!isValid.pincodeInput}
              helperText={
                isValid.pincodeInput ? "" : "Pincode should be a 6 digit number"
              }
            />
          </div>
        </div>
        <div style={classes.formGroup}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "100%",
            }}>
            <p style={{ fontSize: "1rem" }}>Edit Surveyors:</p>
            {fieldValues.surveyorsInput.map((item, index) => (
              <div
                style={{
                  width: "100%",
                  dispaly: "flex",
                  alignItems: "center",
                }}>
                {/* {console.log(item, "itemmmm")} */}
                <div
                  style={{
                    width: "40%",

                    //// marginRight: "20px",
                  }}>
                  {labelText("Surveyors", true)}
                  <TextField
                    type="text"
                    style={{ ...classes.textField, marginBottom: "15px" }}
                    variant="outlined"
                    value={item ? item.name : ""}
                    onKeyPress={(e) => {
                      const re = /[a-z A-Z]+/g;
                      if (!re.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    style={{ width: "100%" }}
                    onChange={(e) => handleSurveyorInput(e, index)}
                  />
                </div>
                <DeleteIcon
                  color="error"
                  style={{ marginLeft: "15px", cursor: "pointer" }}
                  onClick={() => handleDeleteSurveyor(index)}
                />
              </div>
            ))}
            <div style={classes.alignBtn}>
              <Button
                variant="outlined"
                color="primary"
                style={{ width: "200px" }}
                onClick={() =>
                  setFieldValues((prev) => ({
                    ...prev,
                    surveyorsInput: [...prev.surveyorsInput, ""],
                  }))
                }>
                Add Surveyor
              </Button>
              <div style={classes.formActions}>
                <FormControlLabel
                  label={fieldValues.statusInput ? "Active" : "Inactive"}
                  control={
                    <Switch
                      style={{ color: "rgb(241 154 48)" }}
                      checked={fieldValues.statusInput ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValues((prev) => ({
                            ...prev,
                            statusInput: true,
                          }));
                        } else {
                          setFieldValues((prev) => ({
                            ...prev,
                            statusInput: false,
                          }));
                        }
                      }}
                    />
                  }
                />

                <div>
                  <Button
                    onClick={handleReset}
                    color="primary"
                    style={{
                      fontSize: "14px",
                      textTransform: "capitalize",
                      marginRight: "20px",
                    }}>
                    Reset to default
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      textTransform: "capitalize",
                    }}>
                    update
                  </Button>
                </div>
                {/* <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "rgb(241 154 48)", color: "white" }}
                >
                  update
                </Button> */}
              </div>
            </div>
          </div>
          <br />
        </div>
      </form>

      {loading ? (
        <Backdrop
          style={{ zIndex: 1, color: "#fff", position: "absolute" }}
          open={loading}>
          <StyledCircularProgress color="rgb(30, 53, 89)" />
        </Backdrop>
      ) : (
        ""
      )}
      <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setPushNoti(false);
          setLoading(false);

          closePopup();
        }}>
        <Alert style={{ backgroundColor: "rgb(241 154 48)" }}>
          Changes have been saved!
        </Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
      <Snackbar
        open={pushNotier}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="error"
        autoHideDuration={5000}
        onClose={() => {
          // setOpenPopup(false);
          setPushNotier(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}>
        <Alert severity="error">{error}</Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
      <Dialog
        open={confirm}
        onClose={handleClose}
        aria-labelledby="confirm-dialog">
        <DialogTitle id="confirm-dialog">Make Changes ?</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this surveyor ?
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={hadnleDontConfirm}
            style={{
              backgroundColor: "rgb(241 154 48)",
              color: "white",
            }}>
            No
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            style={{
              backgroundColor: "rgb(241 154 48)",
              color: "white",
            }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditForm;
