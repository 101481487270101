import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import { StyledCircularProgress } from "../../styles/StyledComponents";
import NoData from "../../styles/Illustrations/NoData";
import { EndPoints } from "../../utils/EndPoints";
import { Paper } from "@material-ui/core";
import Icon from "@mui/material/Icon";
import { ModeEdit } from "@mui/icons-material";
import Tooltip from "@material-ui/core/Tooltip";
import Popup from "./components/Popup";
import EditForm from "./components/EditForm";
import NewForm from "./components/NewForm";
import moment from "moment";
import { filterData, getSingleModule_widget } from "../../Regex";
import { accessText } from "../../routes/sidebar";
export class PackagingsComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Packagings"],
        tooltip: `Click to approve pending ${EndPoints["Packagings"]}`,
        data: [],
        route: `/Packagings/${EndPoints["Packagings"].split("/")[0]}`,
        goBackRoute: "/Packagings",
        widget: null,
        primaryField: "sizeUnit",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#0AFF00", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.setStateOfPopup.bind(this);
    this.state = {
      tabs: templateTabs,
      Packagings: [],
      singlelistView: null,
      loading: false,
      isPopup: false,
      isNew: false,
    };
  }

  setStateOfPopup = (value) => {
    this.setState({ isPopup: value, isNew: false });
  };

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Packagings"] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {
            if (this.state.singlelistView && this.state.singlelistView.id) {
              this.props.PackagingsFunction(
                "get",
                EndPoints["Packagings"],
                this.state.singlelistView.id,
                "Packagings"
              );
            }
          }
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    this.setState(
      {
        singlelistView: value,
      },
      () => {
        // if (this.state.singlelistView && this.state.singlelistView.id) {
        //   this.props.PackagingsFunction(
        //     "get",
        //     EndPoints["Packagings"],
        //     this.state.singlelistView.id,
        //     "Packagings"
        //   );
        // }
      }
    );
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
    // if (value && value.monthId) {
    //   if (this.state.selectedMonth.monthId !== value.monthId) {
    //     this.setState({ selectedMonth: value, singleDate: null, singleDateTrips: [], singleDateTripsCopy: [] });
    //     this.props.fetchlistViewArray(this.props.user.user.uid, value.monthId, value.yearId).then((dateRR) => {
    //       this.props.setlistViewArray(dateRR);
    //     });
    //   }
    // }
  };
  setInitialValues(props) {
    const { widget_permission } = getSingleModule_widget(
      props.user,
      accessText.rice_bags,
      accessText.packagings
    );
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      // has_read_permission:true,has_create_permission:true
      tab.widget = widget_permission?.widget
        ? { ...widget_permission?.widget }
        : null;
      tab.data =
        props[EndPoints["Packagings"]] &&
        props[EndPoints["Packagings"]].length > 0
          ? props[EndPoints["Packagings"]]
              .map((sP) => {
                sP.sizeUnit = `${sP.size} ${sP.unit}`;
                return sP;
              })
              .sort((a, b) => {
                var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })
          : [];
      return tab;
    });
    this.setState({
      listViewArray:
        props[EndPoints["Packagings"]] &&
        props[EndPoints["Packagings"]].length > 0
          ? props[EndPoints["Packagings"]]
              .map((sP) => {
                sP.sizeUnit = `${sP.size} ${sP.unit}`;
                return sP;
              })
              .sort((a, b) => {
                var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })
          : [],
      Packagings: props.Packagings,
      tabs,
      loading: props.loading,
    });
  }
  render() {
    const { tabs, listViewArray, Packagings, singlelistView, loading } =
      this.state;
    const { user } = this.props;

    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.rice_bags,
      accessText.packagings
    );
    // const { isMobile ,user} = this.props;
    // var labelField = "title";
    // if (listViewArray && listViewArray.length > 0 && listViewArray[0]) {
    //   Object.keys(listViewArray[0]).map((sKey) => {
    //     if (typeof listViewArray[0][sKey] === "string") {

    //       return (labelField = sKey);
    //     } else {
    //       return null;
    //     }
    //   });
    // }
    var labelField = "sizeUnit"; //assign search field
   

    return (
      <Layout
        loading={loading[EndPoints["Packagings"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Packagings`,
          labelField: labelField,
        }}
        listClickHandler={this.listClickHandler}
        fabClickHandler={() => {
          this.setState({
            isPopup: true,
            isNew: true,
          });
        }}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px">
        <Paper
          className="width-100 height-100 padding15 backgroundWhite"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}>
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Packagings === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <div style={{ width: "90%" }}>
                <h1 style={{ fontWeight: "400", fontSize: "25px" }}>
                  Packaging Details
                </h1>
              </div>
              <Paper
                className="padding15 backgroundWhite"
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                {widget_permission?.widget?.has_update_permission && (
                  <div
                    style={{
                      width: "100%",
                      height: "50px",
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "4%",
                    }}>
                    <Tooltip title="Edit">
                      <Icon
                        onClick={() => this.setState({ isPopup: true })}
                        style={{ cursor: "pointer" }}
                        component={ModeEdit}
                      />
                    </Tooltip>
                  </div>
                )}
                <div
                  className="overflowYA scrollBluePrimary"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 50px",
                    height: "calc(100% - 31.7px)",
                    // textTransform: "capitalize",
                  }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}>
                      Size:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}>
                      {singlelistView.size} {singlelistView.unit}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}>
                      Bag Type:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}>
                      {singlelistView.bag_type}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}>
                      Bag Weight:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}>
                      {singlelistView.bag_weight}{" "}
                      {singlelistView.bag_weight ? "grams" : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 ",
                        minWidth: "200px",
                      }}>
                      Status:{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "10px 0 10px 10px",
                        color: "#808080",
                      }}>
                      {singlelistView.is_active ? "Active" : "Inactive"}
                    </p>
                  </div>
                </div>
              </Paper>
            </>
          )}
        </Paper>
        <Popup
          isNew={this.state.isNew}
          openPopup={this.state.isPopup}
          setOpenPopup={this.setStateOfPopup}>
          {this.state.isNew ? (
            <NewForm
              closePopup={this.setStateOfPopup}
              getFn={() =>
                this.props.PackagingsFunction("get", EndPoints["Packagings"])
              }
            />
          ) : (
            singlelistView !== null && (
              <EditForm
                isPopup={this.state.isPopup}
                getFn={(response) => {
                  // this.props
                  //   .PackagingsFunction(
                  //     "get",
                  //     "masters/packaging",
                  //     singlelistView.id,
                  //     "Packagings"
                  //   )
                  //   .then((res) => {
                  //     if (res) {
                  //       this.setState({ singlelistView: res });
                  //     }
                  //   })
                  if (response.data) {
                    this.setState({ singlelistView: response.data });
                  }
                  this.props.PackagingsFunction("get", EndPoints["Packagings"]);
                  // if (response.data.is_active === false) {
                  //   this.setState({ singlelistView: null });
                  //   this.setStateOfPopup(false);
                  // }
                }}
                id={singlelistView.id}
                size={singlelistView.size}
                unit={singlelistView.unit}
                bag={singlelistView.bag_type}
                status={singlelistView.is_active}
                bagWeight={singlelistView.bag_weight}
                closePopup={this.setStateOfPopup}
              />
            )
          )}
        </Popup>
      </Layout>
    );
  }
}

export default PackagingsComponent;
