import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TabComponent from "../../layoutComponents/TabComponent";
import EditIcon from "@mui/icons-material/Edit";
import TableComponent from "../../layoutComponents/TableComponent";
import Tooltip from "@material-ui/core/Tooltip";
import NoData from "../../styles/Illustrations/NoData";
import { Paper } from "@material-ui/core";
import CreateDialogForPort from "../../layoutComponents/CreateDialogForPort";
import { EndPoints } from "../../utils/EndPoints";
import { CrossIcon, TopArrow, BottomArrow } from "../../styles/Icons";
import ReactDOM from "react-dom";
import { primary, white, lightGrey } from "../../styles/colors";

import ShowPackagingPort from "../../layoutComponents/ShowPackagingPort";
import {
  CssTextField,
  CssTextField95,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import CommercialiInvoiceStepper from "./CommercialiInvoiceStepper";
import MenuItem from "@mui/material/MenuItem";
import { subtract, multiply, divide, sum } from "lodash";
import {
  Divider,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  Button,
  DialogContent,
} from "@material-ui/core";
import moment from "moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import DialogTitle from "@material-ui/core/DialogTitle";
import { stateArray } from "../../Defaults";
import CustomInvoice from "./CustomInvoice";
import { filterData, getSingleModule_widget, StopScroll } from "../../Regex";
import { accessText } from "../../routes/sidebar";
import Inventory from "./Inventory";
import { useLocalStorage } from "./useLocalStorage";
import PackingList from "./PackingList";
import { getMinValue } from "../PurchaseOrders/PurchaseOrdersComponent";
import EventIcon from '@mui/icons-material/Event';
export const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiInputBase-root": { background: "rgb(0 0 0 / 1%)" },
    "&.PickerWithState-root-34 .MuiOutlinedInput-root .MuiOutlinedInput-input":
      { background: "none" },
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "14px 14px",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "0 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  withDivider50: { width: "calc(40% - 5px)" },
  mainDiv: {
    width: "100%",
    height: "100%",
  },
  innerDiv: {
    height: "50%",
    background: "white",
    borderRadius: "10px",
    marginBottom: "20px",
    pointerEvents: "none",
    width: "100%",
  },
  innerDiv1: {
    height: "65%",
    background: "rgba(251, 224, 191, 1)",
    borderRadius: "10px",
    padding: "10px 10px 0 10px",
    width: "100%",
    pointerEvents: "none",
  },
  innerMainDiv: {
    width: "100%",
    display: "flex",
  },
};
export class PortsComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Ports"],
        tooltip: `Click to approve pending ${EndPoints["Ports"]}`,
        data: [],
        widget: null,
        route: `/Ports/${EndPoints["Ports"].split("/")[0]}`,
        goBackRoute: "/Ports",
        primaryField: "name",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "city",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "rgb(241 154 48)", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      loadingDispatch: true,
      arrival_date: new Date(),
      summaryData: [],
      Ports: [],
      singlelistView: null,
      listViewArray: [],
      loading: false,
      New: [""],
      returnButtonError: false,
      portDialog: false,
      editList: false,
      PortDispatchedList: [],
      selctedTab: "port Details",
      selctedTab2: "Intransit",
      PortDispatchedItemList: [],
      ArrivalDialog: false,
      widthList: {},
      colorArr: [white, lightGrey],
      statusArrival: ["Continue to Shipping", "Move to Godown"],
      loadingDialog: false,
      error: false,
      success: false,
      editArrival: false,
      openAddBagDialog: false,
      calenderOpen: false,
      deleteDialog: false,
      gDown: [],
      salesOrderList: [],
      masters: {},
      selectGodown: 0,
      ShippingList: [],
      commercialList: [],
      customList: [],
      portUnreachedheader: [
        "Brand",
        "Item Category",
        "Item Type",
        "Packaging",
        // "HSN Code",
        "Total Bags",
        // "Contract Rate",

        "Remark",
        "Status",
        "Godown",
        "Sales Order",
        "Commercial Invoice",
        "Custom Invoice",
      ],
      errorMsg: { Godown: false },
      bagerror: "",
      bagsuccess: "",
      tabNumber: 0,
    };
  }
  fetchNotify = () => {
    this.props
      .PortsFunction("get", "masters/notify/list", null, "notifyList", null, {
        is_active: "True",
      })
      .then((notifyList) => {
        localStorage.removeItem("fetchagain-bri");
        const masters = this.state.masters;
        masters["notifyList"] =
          notifyList && notifyList.length > 0 ? notifyList : [];
        this.setState({ masters });
      });
  };
  fetchShipper = () => {
    this.props
      .PortsFunction("get", EndPoints["Shippers"], null, "shipper", null, {
        is_active: "True",
      })
      .then((ShippersList) => {
        localStorage.removeItem("fetchagain-bri");
        const masters = this.state.masters;
        masters["shippersList"] =
          ShippersList && ShippersList.length > 0 ? ShippersList : [];
        this.setState({ masters });
      });
  };
  fetchSurvey = () => {
    this.props
      .PortsFunction(
        "get",
        "masters/survey-company/list",
        null,
        // this.state.singlelistView.id,
        "SurveyCompanyList",
        null,
        { is_active: "True" }
      )
      .then((SurveyCompanyList) => {
        localStorage.removeItem("fetchagain-bri");
        if (SurveyCompanyList) {
          this.setState({ SurveyCompanyList });
        }
      });
  };
  fetchDEtails = () => {
    // this.props
    //   .PortsFunction("get", "masters/notify/list", null, "notifyList", null, {
    //     is_active: "True",
    //   })
    //   .then((notifyList) => {
    //     const masters = this.state.masters;
    //     masters["notifyList"] =
    //       notifyList && notifyList.length > 0 ? notifyList : [];
    //     this.setState({ masters });
    //   });

    this.props
      .PortsFunction("get", EndPoints["Shippers"], null, "shipper", null, {
        is_active: "True",
      })
      .then((ShippersList) => {
        const masters = this.state.masters;
        masters["shippersList"] =
          ShippersList && ShippersList.length > 0 ? ShippersList : [];
        this.setState({ masters });
      });
    // this.setState({ loadingGodown: true });
    // this.props.PortsFunction("get", "masters/godown/list", null, "GodownList").then((GodownList) => {
    //   if (GodownList) {
    //     this.setState({
    //       loadingGodown: false,
    //       GodownList: filterData(GodownList, "port", this.state.singlelistView.id)
    //         ? filterData(GodownList, "port", this.state.singlelistView.id)
    //         : [],
    //     });
    //   } else {
    //     this.setState({ loadingGodown: false });
    //   }
    // });
    this.props
      .PortsFunction(
        "get",
        "masters/benificiary/list",
        null,
        "benificiarysList"
      )
      .then((benificiarysList) => {
        if (benificiarysList) {
          const masters = this.state.masters;
          masters["benificiarysList"] =
            benificiarysList && benificiarysList.length > 0
              ? benificiarysList
              : [];
          this.setState({ masters });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/so-payment-term/list",
        null,
        "soPaymentTermsList",
        null,
        { is_active: "True" }
      )
      .then((soPaymentTermsList) => {
        if (soPaymentTermsList) {
          const masters = this.state.masters;
          masters["soPaymentTermsList"] =
            soPaymentTermsList && soPaymentTermsList.length > 0
              ? soPaymentTermsList
              : [];
          this.setState({ masters });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/packaging/list",
        null,
        "packagingList",
        null,
        { is_active: "True" }
      )
      .then((packagingList) => {
        if (packagingList) {
          const masters = this.state.masters;
          masters["packagingList"] =
            packagingList && packagingList.length > 0 ? packagingList : [];
          this.setState({ masters });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/payment-terms/list",
        null,
        //state.singlelistView.id,
        "PaymentTermList",
        null,
        { is_active: "True" }
      )
      .then((PaymentTermList) => {
        if (PaymentTermList) {
          const masters = this.state.masters;
          masters["paymentTermList"] =
            PaymentTermList && PaymentTermList.length > 0
              ? PaymentTermList
              : [];
          this.setState({ masters });
        } else {
          const masters = this.state.masters;
          masters["paymentTermList"] = [];
          this.setState({ masters });
        }
      });
    // this.props
    // .PortsFunction(
    //   "get",
    //   "dispatch/so-packaging/list",
    //   null,
    //   //state.singlelistView.id,
    //   "soPackahging",
    //   null,
    //   { is_active: "True" }
    // )
    // .then((soPackahging) => {
    //   const masters = this.state.masters;
    //   masters["soPackahging"] =
    //     soPackahging && soPackahging.length > 0 ? soPackahging : [];
    //   this.setState({ masters });
    // });

    this.props
      .PortsFunction("get", "masters/clients/list", null, "buyersList")
      .then((buyersList) => {
        if (buyersList) {
          const masters = this.state.masters;
          masters["buyersList"] =
            buyersList && buyersList.length > 0 ? buyersList : [];
          this.setState({ masters });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/brand/list",
        null,
        // this.state.singlelistView.id,
        "BrandList",
        null,
        { is_active: "True" }
      )
      .then((BrandList) => {
        if (BrandList) {
          const masters = this.state.masters;
          masters["BrandList"] =
            BrandList && BrandList.length > 0 ? BrandList : [];
          this.setState({ masters, BrandList });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/item-category/list",
        null,
        // this.state.singlelistView.id,
        "ItemCateoryList",
        null,
        { is_active: "True" }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          const masters = this.state.masters;
          masters["ItemCateoryList"] =
            ItemCateoryList && ItemCateoryList.length > 0
              ? ItemCateoryList
              : [];
          this.setState({ masters, ItemCateoryList });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/item-type/list",
        null,
        // this.state.singlelistView.id,
        "ItemList",
        null,
        { is_active: "True" }
      )
      .then((ItemList) => {
        if (ItemList) {
          const masters = this.state.masters;
          masters["ItemList"] = ItemList && ItemList.length > 0 ? ItemList : [];
          this.setState({ masters, ItemList });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/hsn/list",
        null,
        // this.state.singlelistView.id,
        "HSNlist",
        null,
        { is_active: "True" }
      )
      .then((HSNlist) => {
        const masters = this.state.masters;
        masters["HSNlist"] = HSNlist && HSNlist.length > 0 ? HSNlist : [];
        this.setState({ masters });
        if (HSNlist) {
          const masters = this.state.masters;
          masters["HSNlist"] = HSNlist && HSNlist.length > 0 ? HSNlist : [];
          this.setState({ masters, HSNlist });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/contract-type/list",
        null,
        // this.state.singlelistView.id,
        "ContractTypeList",
        null,
        { is_active: "True" }
      )
      .then((ContractTypeList) => {
        if (ContractTypeList) {
          this.setState({ ContractTypeList });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/survey-company/list",
        null,
        // this.state.singlelistView.id,
        "SurveyCompanyList",
        null,
        { is_active: "True" }
      )
      .then((SurveyCompanyList) => {
        if (SurveyCompanyList) {
          this.setState({ SurveyCompanyList });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "arrival/list/",
        null,
        // this.state.singlelistView.id,
        "PackagingList",
        null,
        { is_active: "True" }
      )
      .then((PackagingList) => {
        if (PackagingList) {
          this.setState({ PackagingList });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/packaging/list",
        null,
        // this.state.singlelistView.id,
        "PackagingList",
        null,
        { is_active: "True" }
      )
      .then((PackagingList) => {
        if (PackagingList) {
          const masters = this.state.masters;
          masters["PackagingList"] =
            PackagingList && PackagingList.length > 0 ? PackagingList : [];
          this.setState({ masters });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/inco-term/list",
        null,
        //state.singlelistView.id,
        "Incoterms",
        null,
        { is_active: "True" }
      )
      .then((incoTermList) => {
        if (incoTermList) {
          const masters = this.state.masters;
          masters["incoTermList"] =
            incoTermList && incoTermList.length > 0 ? incoTermList : [];
          this.setState({ masters });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/currency/list",
        null,
        //state.singlelistView.id,
        "CurrencyList"
      )
      .then((CurrencyList) => {
        if (CurrencyList) {
          const masters = this.state.masters;
          masters["CurrencyList"] =
            CurrencyList && CurrencyList.length > 0 ? CurrencyList : [];
          this.setState({ masters });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/surveyor/list",
        null,
        // this.state.singlelistView.id,
        "allsurveyorList",
        null,
        { is_active: "True" }
      )
      .then((allsurveyorList) => {
        if (allsurveyorList) {
          this.setState({ allsurveyorList });
        }
      });
  };
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    const handleChange = () => {
      const fetchAgain = localStorage.getItem("fetchagain-bri");
      if (fetchAgain !== undefined) {
        if (fetchAgain === "shipper") {
          this.fetchShipper();
        } else if (fetchAgain === "notify") {
          this.fetchNotify();
        } else if (fetchAgain === "survey-company") {
          this.fetchSurvey();
        }
      }
    };

    window.addEventListener("storage", handleChange);

    this.fetchNotify();
    this.fetchShipper();
    this.fetchDEtails();
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Ports"] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {
            if (this.state.singlelistView && this.state.singlelistView.id) {
              this.props.PortsFunction(
                "get",
                this.props.user.uid,
                EndPoints["Ports"],
                this.state.singlelistView.id,
                "Ports"
              );
            }
          }
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    this.setState(
      {
        singlelistView: value,
      },
      () => {
        if (
          this.state.selctedTab !== "port Details" &&
          (this.state.tabNumber === 2 || this.state.tabNumber === 3)
        ) {
          this.fetchDispatchList(
            this.state.selctedTab === "Arrival"
              ? this.state.selctedTab2
              : this.state.selctedTab
          );
        }
        this.props
          .PortsFunction(
            "get",
            "dispatch/sales-order/list",
            null,
            "salesOrderList",
            null,
            {
              status: "OPEN",
              port_id: value.id,
            }
          )
          .then((salesOrderList) => {
            const masters = this.state.masters;
            masters["salesOrderList"] =
              salesOrderList && salesOrderList.length > 0 ? salesOrderList : [];
            this.setState({ masters });
          });
      }
    );
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  handleGodownChange = (godown) => {
    this.props
      .PortsFunction("get", "arrival/inventory/list", null, "InvList", null, {
        godown_id: godown,
      })
      .then((InvList) => {
        if (InvList && InvList.length > 0) {
          this.setState({ InvList: InvList });
        } else {
          this.setState({ InvList: [] });
        }
        this.props
          .PortsFunction(
            "get",
            "arrival/godown-history/list",
            null,
            "historyList",
            null,
            {
              godown_id: godown,
            }
          )
          .then((historyList) => {
            if (historyList && historyList.length > 0) {
              this.setState({
                historyList: historyList,
                loadingDispatch: false,
              });
            } else {
              this.setState({ historyList: [], loadingDispatch: false });
            }
          });
      });
    this.setState({
      selectGodown: godown,
    });
  };
  fetchDispatchList = (tab) => {
    if (
      (this.state.singlelistView && tab === "Arrival") ||
      (this.state.singlelistView && tab === "Intransit")
    ) {
      this.setState({ loadingDispatch: true, PortDispatchedList: [] });
      this.props
        .PortsFunction(
          "get",
          "arrival/list",
          null,
          //this.state.singlelistView.id,
          "PortDispatchedList",
          null,
          {
            port_id: this.state.singlelistView.id,
            arrival_status: "in_transit",
          }
        )
        .then((PortDispatchedList) => {
          if (PortDispatchedList && PortDispatchedList.length > 0) {
            this.setState({ loadingDispatch: true });
            PortDispatchedList.map((sr) => {
              let arr = [];
              sr.arrival_item_details.map((item) => {
                arr.push({
                  ...item.dispatch_items_details,
                  arrival_item_status: item.arrival_item_status,
                });
                // sr.subList= item.dispatch_items_details;
                return item;
              });
              sr.subList = arr;
              sr.portId = this.state.singlelistView.id;
              return sr;
              // sr.subList = sr.arrival_item_details.dispatch_items_details;
            });
            this.setState({ loadingDispatch: false });
          }
          if (PortDispatchedList) {
            this.setState({ PortDispatchedList, loadingDispatch: false });
          } else {
            this.setState({ loadingDispatch: false });
          }
        });
    }

    if (this.state.singlelistView && tab === "Reached") {
      //|| (this.state.singlelistView && tab === "Inventory")
      this.setState({ loadingDispatch: true, PortDispatchedList: [] });
      this.props
        .PortsFunction(
          "get",
          "arrival/list",
          null,
          //this.state.singlelistView.id,
          "PortDispatchedList",
          null,
          { port_id: this.state.singlelistView.id, arrival_status: "reached" }
        )
        .then((PortDispatchedList) => {
          if (PortDispatchedList && PortDispatchedList.length > 0) {
            this.setState({ loadingDispatch: true });
            PortDispatchedList.map((sr) => {
              let arr = [];
              let dispatchPack = [];
              let arrivalPack = [];
              sr.arrival_item_details.map((item) => {
                dispatchPack = item.dispatch_items_details.dispatch_packaging;
                arrivalPack = item.arrival_packagings;
                dispatchPack.map((sp, i) => {
                  let ret =
                    arrivalPack[i] && arrivalPack[i].returned_bags
                      ? arrivalPack[i].returned_bags
                      : 0;
                  sp.returned_bags = ret ? ret : "";
                  return sp;
                });

                item.dispatch_items_details.newPack = dispatchPack;
                arr.push({
                  ...item.dispatch_items_details,
                  arrival_item_status: item.arrival_item_status,
                });
                return item;
              });
              sr.subList = arr;
              sr.portId = this.state.singlelistView.id;
              return sr;
              // sr.subList = sr.arrival_item_details.dispatch_items_details;
            });
            this.setState({ loadingDispatch: false });
          } else {
            this.setState({ loadingDispatch: false });
          }
          if (PortDispatchedList) {
            this.setState({ PortDispatchedList, loadingDispatch: false });
          } else {
            this.setState({ loadingDispatch: false });
          }
        });
    }
    if (this.state.singlelistView && tab === "Shipping") {
      this.setState({ loadingDispatch: true, ShippingList: [] }, () => {
        this.props
          .PortsFunction(
            "get",
            "arrival/shipping-table/list",
            null,
            //this.state.singlelistView.id,
            "ShippingList",
            null,
            { port_id: this.state.singlelistView.id }
          )
          .then((ShippingList) => {
            if (ShippingList && ShippingList.length > 0) {
              this.setState({ loadingDispatch: false, ShippingList });
            }
          });
        this.props
          .PortsFunction(
            "get",
            "arrival/commercial-invoice/list",
            null,
            "PortCIs",
            null,
            {
              // sales_order_id: e.currentTarget.id,
              // // shipment_status: "EXPENSES CALCULATED",
              // // is_active: "True",
              // // port_id: singlelistView && singlelistView.id && singlelistView.id,
              // status: "DRAFT",
            }
          )
          .then((commercialList) => {
            if (commercialList && commercialList.length > 0) {
              this.setState({ loadingDispatch: false, commercialList });
            }
          });
        this.props
          .PortsFunction(
            "get",
            "arrival/custom-invoice/list",
            null,
            "PortCIs",
            null,
            {
              // sales_order_id: e.currentTarget.id,
              // // shipment_status: "EXPENSES CALCULATED",
              // // is_active: "True",
              // // port_id: singlelistView && singlelistView.id && singlelistView.id,
              // status: "DRAFT",
            }
          )
          .then((customList) => {
            if (customList && customList.length > 0) {
              this.setState({ loadingDispatch: false, customList });
            }
          });
      });
    }
    if (this.state.singlelistView && tab === "Inventory") {
      const { singlelistView } = this.state;
      this.setState({ loadingDispatch: true, GodownList: [] });
      if (
        singlelistView &&
        singlelistView.godown_details &&
        singlelistView.godown_details.length > 0 &&
        singlelistView.godown_details
      ) {
        const GodownList =
          singlelistView &&
          singlelistView.godown_details &&
          singlelistView.godown_details.length > 0 &&
          singlelistView.godown_details;
        this.setState(
          {
            GodownList: GodownList.sort(function (a, b) {
              return b.created_at - a.created_at;
            }),
          },
          () => {
            this.handleGodownChange(
              GodownList.sort((a, b) => {
                return b.created_at - a.created_at;
              })[0].id
            );
          }
        );
      } else {
        this.setState({
          InvList: [],
          historyList: [],
          GodownList: [],
          loadingDispatch: false,
        });
      }
    }
  };

  setInitialValues(props) {
    if (props[EndPoints["Ports"]] && props[EndPoints["Ports"]].length > 0) {
      props[EndPoints["Ports"]].sort((a, b) => b.id - a.id);
    }
    const { widget_permission } = getSingleModule_widget(
      props.user,
      accessText.ports,
      accessText.port_overview
    );
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      // has_read_permission:true,has_create_permission:true
      tab.widget = widget_permission?.widget
        ? { ...widget_permission?.widget }
        : null;
      tab.data =
        props[EndPoints["Ports"]] && props[EndPoints["Ports"]].length > 0
          ? props[EndPoints["Ports"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [];
      return tab;
    });
    this.setState({
      listViewArray:
        props[EndPoints["Ports"]] && props[EndPoints["Ports"]].length > 0
          ? props[EndPoints["Ports"]]
          : [],
      Ports: props.Ports,
      tabs,
      loading: props.loading,
    });
  }
  handleClear = () => {
    const { PortDispatchedItemList } = this.state;
    if (PortDispatchedItemList.length > 0) {
      PortDispatchedItemList.map((sItem) => {
        const details = sItem.dispatch_items_details;
        const packaging = sItem.arrival_packagings; //details.dispatch_packaging//
        packaging.map((sPack) => {
          return this.setState({
            [`${sPack.dispatch_packaging}Returned`]: false,
          });
        });
        this.setState({
          [`${details.po_item}Status`]: false,
          [`${details.po_item}Godown`]: false,
          [`${details.po_item}Remark`]: false,
          [`${details.po_item}Sales`]: false,
          [`${details.po_item}Cus`]: false,
          [`${details.po_item}customInvoices`]: [],
          [`${details.po_item}commercialInvoice`]: [],
        });
        return sItem;
      });
    }

    this.setState({
      arrival_date: new Date(),
      gross_weight: "",
      net_weight: "",
      tare_weight: "",
      surveyorCompany: "",
      surveyor: "",
      quality_deduction: "",
      freight_rate: "",
      freight_deduction: "",
      miller_bill_amount: "",
      miller_tds: "",
      miller_bill_weight: "",
      miller_gst_charge: "",
      miller_advance: "",
      miller_total_amount: "",
      brokerage_rate_for_bri: "",
      brokerage_amount_for_bri: "",
      brokerage_rate_for_miller: "",
      brokerage_amount_for_miller: "",
    });
  };

  setWidth = (list, header) => {
    const { poItemheader } = this.state;

    if (list && list.length > 0) {
      if (list[0]) {
        const widthList = {};
        const array = header
          ? header
          : poItemheader
          ? poItemheader
          : Object.keys(list[0]);

        if (array && array.length > 0) {
          array.map((single, i) => {
            if (single) {
              return (widthList[array[i]] = `${
                Math.floor(
                  10 *
                    (single.toString().length > this.longest(single, list)
                      ? single.toString().length
                      : this.longest(single, list)
                      ? this.longest(single, list)
                      : 1)
                ) < 110
                  ? 110
                  : Math.floor(
                      10 *
                        (single.toString().length > this.longest(single, list)
                          ? single.toString().length
                          : this.longest(single, list)
                          ? this.longest(single, list)
                          : 1)
                    )
              }px`);
            }
            return single;
          });
        }
        this.setState({ widthList });
      }
    }
  };
  longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  deleteItem = (godownNameToDelete) => {
    let { New } = this.state;

    let filteredGodown = New.filter(
      (godownName) => godownName !== godownNameToDelete
    );

    this.setState({
      New: filteredGodown,
    });
  };

  addClick = () => {
    if (!this.state.New.includes("")) {
      this.setState((prevState) => ({ New: [...prevState.New, ""] }));
    } else {
      this.setState({ error: "add" });
    }
  };
  handleChange1 = (i, event) => {
    let New = [...this.state.New];

    if (New[i] && New[i].name) {
      New[i].name = event.target.value;
    } else {
      New[i] = { name: event.target.value };
    }
    this.setState({ New, errorMsg: { ...this.state.errorMsg, Godown: false } });
  };
  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;
    this.setBrirokerageAmount({
      ...this.state,
      [event.target.name]:event.target.value
    })
    this.setBrirokerageAmountMiller({
      ...this.state,
      [event.target.name]:event.target.value
    })
    this.calcMillerTotalAmount({
      ...this.state,
      [event.target.name]:event.target.value
    })
    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };
  handleClose = () => {
    this.setState({ deleteDialog: false });
  };
  setBrirokerageAmount= ({
    net_weight,
    brokerage_rate_for_bri
    
    ,
  }) => {
    const brokerage_amount_for_bri_ = multiply(
      net_weight?Number(net_weight):1,
      brokerage_rate_for_bri ? Number(brokerage_rate_for_bri) : 0
    )
    // .toFixed(2);
    this.setState({brokerage_amount_for_bri:brokerage_amount_for_bri_}, () => {
      this.calcMillerTotalAmount({
        ...this.state
      })
    })}
    setBrirokerageAmountMiller= ({
      net_weight,
      brokerage_rate_for_miller
      
      ,
    }) => {
      const brokerage_amount_for_miller = multiply(
        net_weight?Number(net_weight):1,
        brokerage_rate_for_miller ? Number(brokerage_rate_for_miller) : 0
      )
      // .toFixed(2);
      this.setState({brokerage_amount_for_miller:brokerage_amount_for_miller},() => {
        this.calcMillerTotalAmount({
          ...this.state
        })
      })}
    setTdsAmount= ({
      miller_bill_amount,
      miller_tds,
    }) => {
      const tds_amount = multiply(
        divide(miller_tds ?? 0, 100),
        miller_bill_amount ? Number(miller_bill_amount) : 0
      ).toFixed(2);
      this.setState({tds_amount:tds_amount})}
     
  calcMillerTotalAmount = ({
    net_weight,
    PortDispatchedItemList,
    miller_bill_amount,
    miller_advance,
    miller_gst_charge,
    quality_deduction,
    miller_tds,
    singleDispatch,
  }) => {
    console.log("STATE", this.state,singleDispatch, Number(miller_advance ?? 0), Number(singleDispatch?.miller_advance ?? 0),)
    const tds_amount = multiply(
      divide(miller_tds ?? 0, 100),
      miller_bill_amount ? Number(miller_bill_amount) : 0
    ).toFixed(2);
    console.log("MILLER TOTAL :", multiply(
      net_weight,
      PortDispatchedItemList.length > 0
        ? Number(
            PortDispatchedItemList[0].dispatch_items_details
              ?.contract_rate ?? 0
          )
        : 1
    ), sum([
      Number(miller_advance ?? 0), 
      Number(tds_amount),
      Number(this.state?.brokerage_amount_for_bri ?? 0),
      Number(this.state?.brokerage_amount_for_miller ?? 0),
    ]), Number(miller_gst_charge ?? 0), quality_deduction ?? 0)
    this.setState({
      miller_total_amount: subtract(
        sum([
          // sum([
            Number(
              subtract(
                multiply(
                  net_weight,
                  PortDispatchedItemList.length > 0
                    ? Number(
                        PortDispatchedItemList[0].dispatch_items_details
                          ?.contract_rate ?? 0
                      )
                    : 1
                ),

                sum([
                  Number(miller_advance ?? 0), 
                  Number(tds_amount),
                  Number(this.state?.brokerage_amount_for_bri ?? 0),
                  Number(this.state?.brokerage_amount_for_miller ?? 0),
                ])
              )
            ),

            // Number(singleDispatch?.miller_advance ?? 0),
          // ]),
          Number(miller_gst_charge ?? 0),
        ]),
        quality_deduction ?? 0
      ),
    });
  };
  render() {
    const {
      loadingDialog,
      arrival_date,
      error,
      success,
      tabs,
      listViewArray,
      singlelistView,
      loading,
      portDialog,
      BrandList,
      GodownList,
      selectGodown,
      editList,
      ArrivalDialog,
      portUnreachedheader,
      widthList,
      colorArr,
      New,
      errorMsg,
      PortDispatchedList,
      PortDispatchedItemList,
      ItemList,
      ItemCateoryList,
      statusArrival,
      SurveyCompanyList,
      allsurveyorList,
      openAddBagDialog,
      loadingDispatch,
      editArrival,
      calenderOpen,
      deleteDialog,
      gross_weight,
      tare_weight,
      surveyorListForSurvay,
      surveyorCompanyId,
      surveyorCompany,
      surveyor,
      returnButtonError,
      masters,
      tabNumber,
      ShippingList,
      commercialList,
      customList,
      quality_deduction,
      freight_rate,
      freight_deduction,
      miller_bill_amount,
      miller_tds,
      miller_bill_weight,
      miller_gst_charge,
      miller_advance,
      miller_total_amount,
      brokerage_rate_for_bri,
      brokerage_amount_for_bri,
      brokerage_rate_for_miller,
      brokerage_amount_for_miller,
      packagingList,
      salesOrderList,
      PortDispatchedListPre,
    } = this.state;
    const { user } = this.props;

    const { widget_permission } = getSingleModule_widget(
      user,
      accessText.ports,
      accessText.port_overview
    );
    // const{commercialList}=this.props;

    const changeTo = (to, weigths, opposite) => {
      if (opposite) {
        if (to === "MT") {
          return multiply(weigths, 1000);
        } else if (to === "Kgs") {
          return weigths;
        } else if (to === "Qtl") {
          return multiply(weigths, 100);
        }
      } else {
        if (to === "MT") {
          return divide(weigths, 1000);
        } else if (to === "Kgs") {
          return weigths;
        } else if (to === "Qtl") {
          return divide(weigths, 100);
        }
      }
    };
    const changeUnit = (from, to, weigths, opposite) => {
      //default in kg

      if (opposite) {
        if (from === "MT") {
          return changeTo(to, divide(weigths, 1000), opposite);
        } else if (from === "Kgs") {
          return changeTo(to, weigths);
        } else if (from === "Qtl") {
          return changeTo(to, divide(weigths, 100), opposite);
        }
      } else {
        if (from === "MT") {
          return changeTo(to, multiply(weigths, 1000));
        } else if (from === "Kgs") {
          return changeTo(to, weigths);
        } else if (from === "Qtl") {
          return changeTo(to, multiply(weigths, 100));
        }
      }
    };
    var labelField = "name";

    const setData = (singleSub) => {
      const details = singleSub.dispatch_items_details;
      singleSub["Brand"] = filterData(BrandList, "id", details.brand, "onlyOne")
        ? filterData(BrandList, "id", details.brand, "onlyOne").brand_name
        : "";

      singleSub["Item Category"] = filterData(
        ItemCateoryList,
        "id",
        details.item_category,
        "onlyOne"
      )
        ? filterData(ItemCateoryList, "id", details.item_category, "onlyOne")
            .name
        : "";
      singleSub["Item Type"] = filterData(
        ItemList,
        "id",
        details.item_type,
        "onlyOne"
      )
        ? `${Number(
            filterData(ItemList, "id", details.item_type, "onlyOne")
              .broken_percentage
          ).toFixed(2)} `
        : "";
      singleSub["Packaging"] =
        details.dispatch_packaging && details.dispatch_packaging.length > 0
          ? `  ${
              details.dispatch_packaging.map((sP) => {
                const singlePack = sP.packaging_details;

                return singlePack
                  ? `${singlePack.size}${singlePack.unit}${" - "}${
                      singlePack.bag_type
                    }`
                  : "";
              })[0]
            } Total (...s)`
          : "-";
      singleSub["PackagingUI"] = (
        <ShowPackagingPort
          noTotalbags={true}
          returned_bags={true}
          details={details.dispatch_packaging.map((sP) => {
            const singlePack = sP.packaging_details;
            singlePack.used_bags = sP.total_bags_dispatched;

            singlePack.returned_bags = this.state[`${sP.id}Returned`]
              ? this.state[`${sP.id}Returned`]
              : "";
            // singlePack.new_remaining_bags = subtract(
            // this.state
            //   .editingDispatch !==
            //   false
            //   ? sum([
            //       sP.remaining_bags,
            //       sP.used_bags_fordiapatch,
            //     ])
            //   :
            // sP.remaining_bags,
            // this.state[`${singlePackaging.dispatch_packaging}Returned`]
            //   ? this.state[`${singlePackaging.dispatch_packaging}Returned`]
            //   :
            //   this.state[`${sP.id}Returned`] ? this.state[`${sP.id}Returned`] : ""
            // );

            return singlePack;
          })}
        />
      );
      // singleSub["HSN Code"] = filterData(HSNlist, "id", details.hsn_code, "onlyOne")
      //   ? filterData(HSNlist, "id", details.hsn_code, "onlyOne").hsn_code
      //   : "";
      singleSub["Total Bags"] = details.total_bags ? details.total_bags : "";
      // singleSub["Contract Rate"] = details.contract_rate ? details.contract_rate : "";

      singleSub["Remark"] = "WEFWFFFjhpikjhioihFty";
      singleSub["RemarkUI"] = (
        <CssTextField95
          // required={true}
          type="text"
          autoComplete="off"
          style={
            { width: "100%", maxWidth: "100%" } //disabled={tp}
          }
          // error={errorMsg[`${details.po_item}Remark`] ? true : false}
          // helperText={errorMsg[`${details.po_item}Remark`] ? errorMsg[`${details.po_item}Remark`] : ""}

          id="outlined-basic"
          label="Remark"
          variant="outlined"
          name={`${details.po_item}Remark`}
          value={
            this.state[`${details.po_item}Remark`]
              ? this.state[`${details.po_item}Remark`]
              : ""
          }
          onChange={(event) => {
            const errorMsg = this.state.errorMsg;
            errorMsg[`${details.po_item}Remark`] = false;

            this.setState({
              [event.target.name]: event.target.value,
            });
          }}
        />
      );
      singleSub["Status"] = "WEFWFFFjhpikjhioihFty";
      singleSub["StatusUI"] = (
        <CssTextField95
          required={true}
          autoComplete="off"
          value={
            this.state[`${details.po_item}Status`]
              ? this.state[`${details.po_item}Status`]
              : "" //
          }
          onChange={(e) => {
            const errorMsg = this.state.errorMsg;
            errorMsg[`${details.po_item}Status`] = false;

            this.setState({
              [`${details.po_item}StatusId`]: e.currentTarget.id,
              [`${details.po_item}Status`]: e.target.value,
            });
          }}
          style={
            { width: "100%" } //disabled={tp}
          }
          id="outlined-select-currency-native"
          select
          label="Status"
          variant="outlined"
          error={errorMsg[`${details.po_item}Status`] ? true : false}
          // disabled={true}
          InputLabelProps={{}}>
          {statusArrival &&
            statusArrival.length > 0 &&
            statusArrival.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
        </CssTextField95>
      );
      singleSub["Godown"] = "WEFWFFFjhpikjhioihFty";
      singleSub["GodownUI"] =
        this.state[`${details.po_item}Status`] &&
        this.state[`${details.po_item}Status`] === "Move to Godown" ? (
          <CssTextField95
            required={true}
            value={
              this.state[`${details.po_item}Godown`]
                ? this.state[`${details.po_item}Godown`]
                : "" //
            }
            error={errorMsg[`${details.po_item}Godown`] ? true : false}
            // error={errorMsg.ItemCategory ? true : false}
            // helperText={errorMsg.ItemCategory ? errorMsg.ItemCategory : ""}
            onChange={(e) => {
              const errorMsg = this.state.errorMsg;
              errorMsg[`${details.po_item}Godown`] = false;
              this.setState({
                [`${details.po_item}GodownId`]: e.currentTarget.id,
                [`${details.po_item}Godown`]: e.target.value,
              });
            }}
            style={
              { width: "100%" } //disabled={tp}
            }
            id="outlined-select-currency-native"
            select
            label="Godown"
            variant="outlined"
            // disabled={true}
            InputLabelProps={{}}>
            {singleSub.godowns_list &&
              singleSub.godowns_list.length > 0 &&
              singleSub.godowns_list.map((option) => (
                <MenuItem key={option.name} value={option.name} id={option.id}>
                  {option.name}
                </MenuItem>
              ))}
          </CssTextField95>
        ) : (
          "-"
        );
      singleSub["Sales Order"] = "WEFWFFFjhpikjhioihFty";
      singleSub["Commercial Invoice"] = "WEFWFFFjhpikjhioihFty";
      singleSub["Custom Invoice"] = "WEFWFFFjhpikjhioihFty";
      singleSub["Sales OrderUI"] =
        this.state[`${details.po_item}Status`] &&
        this.state[`${details.po_item}Status`] === "Continue to Shipping" ? (
          <CssTextField95
            required={true}
            autoComplete="off"
            value={
              this.state[`${details.po_item}Sales`]
                ? this.state[`${details.po_item}Sales`]
                : "" //
            }
            error={errorMsg[`${details.po_item}Sales`] ? true : false}
            helperText={
              errorMsg[`${details.po_item}Sales`]
                ? errorMsg[`${details.po_item}Sales`]
                : ""
            }
            onChange={(e) => {
              const errorMsg = this.state.errorMsg;
              errorMsg[`${details.po_item}Sales`] = false;
              const sales = masters.salesOrderList.find(
                (so) => so.id === Number(e.currentTarget.id)
              );

              this.props
                .PortsFunction(
                  "get",
                  "dispatch/so-items/list",
                  null,
                  "SO_Items_List",
                  null,
                  {
                    so_id: sales.id,
                  }
                )
                .then((SO_Items_List) => {
                  const so_packagings = SO_Items_List.map((it) => it.packaging)
                    .flat()
                    .map((ss) => Number(ss));

                  const dispatch_packagings = details.dispatch_packaging.map(
                    (s) => s.packaging_details.id
                  );
                  const existArr = [];
                  console.log(
                    dispatch_packagings,
                    so_packagings,
                    "dispatch_packagings"
                  );
                  dispatch_packagings.map((pack) => {
                    if (so_packagings.includes(Number(pack))) {
                      existArr.push(pack);
                    }
                  });
                  if (existArr.length === dispatch_packagings.length) {
                    this.setState({
                      [`${details.po_item}SalesId`]: sales.id,
                      [`${details.po_item}Sales`]: e.target.value,
                    });
                    this.props
                      .PortsFunction(
                        "get",
                        "arrival/commercial-invoice/list",
                        null,
                        "PortCIs",
                        null,
                        {
                          sales_order_id: sales.id,
                          // shipment_status: "EXPENSES CALCULATED",
                          // is_active: "True",
                          // port_id: singlelistView && singlelistView.id && singlelistView.id,
                          status: "DRAFT,OPEN",
                        }
                      )
                      .then((commercialInvoice) => {
                        if (commercialInvoice) {
                          this.setState({
                            [`${details.po_item}commercialInvoice`]:
                              commercialInvoice,
                            [`${details.po_item}ComId`]: false,
                            [`${details.po_item}Com`]: false,
                            [`${details.po_item}CusId`]: false,
                            [`${details.po_item}Cus`]: false,

                            [`${details.po_item}customInvoices`]: [],
                          });
                        } else {
                          this.setState({
                            [`${details.po_item}commercialInvoice`]: [],
                            [`${details.po_item}ComId`]: false,
                            [`${details.po_item}Com`]: false,
                            [`${details.po_item}CusId`]: false,
                            [`${details.po_item}Cus`]: false,

                            [`${details.po_item}customInvoices`]: [],
                          });
                        }
                      });
                  } else {
                    this.setState({ error: "packaging dosen't match!" });
                  }
                });
            }}
            style={
              { width: "100%" } //disabled={tp}
            }
            id="outlined-select-currency-native"
            select
            label="Sales Order"
            variant="outlined"
            InputLabelProps={{}}>
            {masters &&
            masters.salesOrderList &&
            masters.salesOrderList.length > 0 &&
            masters.salesOrderList.filter((s) =>
              s.so_items_brands.includes(details.brand)
            ) ? (
              masters.salesOrderList
                .filter((s) => s.so_items_brands.includes(details.brand))
                .sort((a, b) => b.id - a.id)
                .map((option) => (
                  <MenuItem
                    key={option.so_number}
                    value={option.so_number}
                    id={option.id}>
                    {option.so_number}
                  </MenuItem>
                ))
            ) : (
              <MenuItem
                disabled={true}
                key={"No data!"}
                value={"No data!"}
                id={"No data!"}>
                No data!
              </MenuItem>
            )}
          </CssTextField95>
        ) : (
          "-"
        );
      singleSub["Commercial InvoiceUI"] =
        this.state[`${details.po_item}Status`] &&
        this.state[`${details.po_item}Status`] === "Continue to Shipping" ? (
          this.state[`${details.po_item}Sales`] ? (
            <CssTextField95
              required={true}
              autoComplete="off"
              value={
                this.state[`${details.po_item}Com`]
                  ? this.state[`${details.po_item}Com`]
                  : "" //
              }
              error={errorMsg[`${details.po_item}Com`] ? true : false}
              helperText={
                errorMsg[`${details.po_item}Com`]
                  ? errorMsg[`${details.po_item}Com`]
                  : ""
              }
              onChange={(e) => {
                const errorMsg = this.state.errorMsg;
                errorMsg[`${details.po_item}Com`] = false;
                this.props
                  .PortsFunction(
                    "get",
                    "arrival/custom-invoice/list",
                    null,
                    "PortCIs",
                    null,
                    {
                      // sales_order: e.currentTarget.id,
                      commercial_invoice_id: e.currentTarget.id,
                      // shipment_status: "EXPENSES CALCULATED",
                      // is_active: "True",
                      // port_id: singlelistView && singlelistView.id && singlelistView.id,
                      // status: "OPEN",
                    }
                  )
                  .then((customInvoices) => {
                    if (customInvoices) {
                      this.setState({
                        [`${details.po_item}customInvoices`]: customInvoices,
                      });
                    } else {
                      this.setState({
                        [`${details.po_item}customInvoices`]: [],
                      });
                    }
                  });

                this.setState({
                  [`${details.po_item}ComId`]: e.currentTarget.id,
                  [`${details.po_item}Com`]: e.target.value,
                  [`${details.po_item}CusId`]: false,
                  [`${details.po_item}Cus`]: false,
                });
              }}
              style={
                { width: "100%" } //disabled={tp}
              }
              id="outlined-select-currency-native"
              select
              label="Commercial Invoice"
              variant="outlined"
              InputLabelProps={{}}>
              {this.state[`${details.po_item}commercialInvoice`] &&
              this.state[`${details.po_item}commercialInvoice`].length > 0 ? (
                this.state[`${details.po_item}commercialInvoice`]
                  .sort((a, b) => b.id - a.id)
                  .map((option) => (
                    <MenuItem
                      key={option.invoice_number}
                      value={option.invoice_number}
                      id={option.id}>
                      {option.invoice_number}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem
                  disabled={true}
                  key={"No data!"}
                  value={"No data!"}
                  id={"No data!"}>
                  No data!
                </MenuItem>
              )}
            </CssTextField95>
          ) : (
            "-"
          )
        ) : (
          "-"
        );
      singleSub["Custom InvoiceUI"] =
        this.state[`${details.po_item}Status`] &&
        this.state[`${details.po_item}Status`] === "Continue to Shipping" ? (
          this.state[`${details.po_item}Sales`] ? (
            <CssTextField95
              required={true}
              autoComplete="off"
              value={
                this.state[`${details.po_item}Cus`]
                  ? this.state[`${details.po_item}Cus`]
                  : "" //
              }
              error={errorMsg[`${details.po_item}Cus`] ? true : false}
              helperText={
                errorMsg[`${details.po_item}Cus`]
                  ? errorMsg[`${details.po_item}Cus`]
                  : ""
              }
              onChange={(e) => {
                const errorMsg = this.state.errorMsg;
                errorMsg[`${details.po_item}Cus`] = false;
                const selectedCiDetail = filterData(
                  this.state[`${details.po_item}customInvoices`],
                  "id",
                  Number(e.currentTarget.id),
                  "onlyone"
                )
                  ? filterData(
                      this.state[`${details.po_item}customInvoices`],
                      "id",
                      Number(e.currentTarget.id),
                      "onlyone"
                    )
                  : "";
                const checkCustomInvoice = (cI, packaging, noBags) => {
                  // pkg_counter
                  const array = [];
                  const requires = [];
                  cI.custom_invoice_item_details.map((item) => {
                    if (
                      item.pkg_counter[packaging.packaging] &&
                      (Number(item.pkg_counter[packaging.packaging]) >
                        Number(noBags) ||
                        Number(item.pkg_counter[packaging.packaging]) ===
                          Number(noBags))
                    ) {
                      array.push("success");
                    }
                    requires.push(item.pkg_counter);
                    return item;
                  });
                  if (array.length > 0) {
                    return "success";
                  } else {
                    return {
                      packagings: packaging.packaging_details,
                      bags: noBags,
                      ciPackagings: requires,
                    };
                  }
                };
                const weight = subtract(
                  Number(
                    selectedCiDetail.net_weight
                      ? selectedCiDetail.net_weight
                      : 0
                  ),
                  Number(
                    selectedCiDetail.used_weight
                      ? selectedCiDetail.used_weight
                      : 0
                  )
                );

                const unit = selectedCiDetail.unit;
                const id1 = [];
                const total_value = [];
                const arrpfShip = [];
                const errors = [];
                details.dispatch_packaging.map((singlePack) => {
                  const calculatedValue = changeUnit(
                    singlePack.packaging_details.unit,
                    unit,
                    singlePack.packaging_details.size
                  );
                  if (
                    !id1.includes(singlePack.packaging) &&
                    checkCustomInvoice(
                      selectedCiDetail,
                      singlePack,
                      subtract(
                        singlePack.total_bags_dispatched,
                        this.state[`${singlePack.id}Returned`]
                          ? this.state[`${singlePack.id}Returned`]
                          : 0
                      )
                    ) === "success"
                  ) {
                    total_value.push(
                      multiply(
                        Number(calculatedValue ? calculatedValue : 0),
                        subtract(
                          singlePack.total_bags_dispatched,
                          this.state[`${singlePack.id}Returned`]
                            ? this.state[`${singlePack.id}Returned`]
                            : 0
                        )
                      )
                    );
                    id1.push(singlePack.packaging);
                  } else {
                    errors.push(
                      checkCustomInvoice(
                        selectedCiDetail,
                        singlePack,
                        subtract(
                          singlePack.total_bags_dispatched,
                          this.state[`${singlePack.id}Returned`]
                            ? this.state[`${singlePack.id}Returned`]
                            : 0
                        )
                      )
                    );
                  }
                  arrpfShip.push({
                    // used_weight: sum([
                    //   Number(selectedCiDetail && selectedCiDetail.used_weight ? selectedCiDetail.used_weight : 0),
                    //   multiply(
                    //     Number(calculatedValue ? calculatedValue : 0),
                    //     subtract(
                    //       singlePack.total_bags_dispatched,
                    //       this.state[`${singlePack.id}Returned`] ? this.state[`${singlePack.id}Returned`] : 0
                    //     )
                    //   ),
                    // ]),
                    bl_number:
                      selectedCiDetail && selectedCiDetail.bl_number
                        ? selectedCiDetail.bl_number
                        : "",
                    // bag_number: subtract(
                    //   singlePack.total_bags_dispatched,
                    //   this.state[`${singlePack.id}Returned`] ? this.state[`${singlePack.id}Returned`] : 0
                    // ),
                    used_weight: {
                      [singlePack.packaging]:
                        // sum([
                        //   Number(selectedCiDetail && selectedCiDetail.used_weight ? selectedCiDetail.used_weight : 0),
                        multiply(
                          Number(calculatedValue ? calculatedValue : 0),
                          subtract(
                            singlePack.total_bags_dispatched,
                            this.state[`${singlePack.id}Returned`]
                              ? this.state[`${singlePack.id}Returned`]
                              : 0
                          )
                        ),
                      // ]),
                    },
                    packaging_count: {
                      [singlePack.packaging]: subtract(
                        singlePack.total_bags_dispatched,
                        this.state[`${singlePack.id}Returned`]
                          ? this.state[`${singlePack.id}Returned`]
                          : 0
                      ),
                    },
                    is_active: true,
                    custom_invoice: e.currentTarget.id, // selctedCi.id

                    commercial_invoice: this.state[`${details.po_item}ComId`], // selctedCi.id
                    coming_from: "DIRECT",
                    // packaging: singlePack.packaging,

                    brand: details.brand,
                    godown: this.state[`${details.po_item}GodownId`]
                      ? this.state[`${details.po_item}GodownId`]
                      : null,
                    port: singlelistView.id,
                  });
                  return singlePack;
                });
                if (
                  total_value &&
                  total_value.length > 0 &&
                  total_value.reduce(
                    (previousValue1, currentValue1) =>
                      Number(previousValue1) +
                      Number(currentValue1 ? currentValue1 : 0)
                  ) <= weight
                ) {
                  this.setState({
                    [`${details.po_item}CusId`]: e.currentTarget.id,
                    [`${details.po_item}arrpfShip`]: arrpfShip,
                    [`${details.po_item}Cus`]: e.target.value,
                  });
                } else {
                  this.setState({
                    error:
                      errors.length > 0
                        ? `${errors.map((s) => {
                            return `${s.bags} bags of ${s.packagings.size}${s.packagings.unit}`;
                          })} is not available in this custom invoice!`
                        : "Less Quantity is available in selected Custom Invoice!",
                  });
                }
              }}
              style={
                { width: "100%" } //disabled={tp}
              }
              id="outlined-select-currency-native"
              select
              label="Custom Invoice"
              variant="outlined"
              InputLabelProps={{}}>
              {this.state[`${details.po_item}customInvoices`] &&
              this.state[`${details.po_item}customInvoices`].length > 0 ? (
                this.state[`${details.po_item}customInvoices`]
                  .sort((a, b) => b.id - a.id)
                  .map((option) => (
                    <MenuItem
                      key={option.invoice_number}
                      value={option.invoice_number}
                      id={option.id}>
                      {option.invoice_number}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem
                  disabled={true}
                  key={"No data!"}
                  value={"No data!"}
                  id={"No data!"}>
                  No data!
                </MenuItem>
              )}
            </CssTextField95>
          ) : (
            "-"
          )
        ) : (
          "-"
        );
      return singleSub;
    };

    const filterChild = () => {
      var godwn = [];
      if (GodownList && GodownList.length > 0) {
        GodownList.map((item) => {
          godwn.push({
            value: item.id,
            label: item.name,
          });
          return item;
        });
      }
      return (
        <CssTextField95
          style={
            { width: "200px", margin: "0px" } //disabled={tp}
          }
          variant="outlined"
          select
          label="Godown"
          value={selectGodown}
          onChange={(e) => {
            this.handleGodownChange(e.target.value);
          }}>
          {godwn.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </CssTextField95>

        // <CssTextField
        //   required={true}
        //   autoComplete="off"
        //   style={
        //     { width: "200px", margin: "0px" } //disabled={tp}
        //   }
        //   id="outlined-select-currency-native"
        //   select
        //   label="Godown"
        //   variant="outlined"
        //   // disabled={true}
        //   InputLabelProps={{}}
        // >
        //   {/* {ItemCateoryList &&
        //   ItemCateoryList.length > 0 &&
        //   ItemCateoryList.map((option) => (
        //     <MenuItem key={option.name} value={option.name} id={option.id}>
        //       {option.name}
        //     </MenuItem>
        //   ))}*/}
        // </CssTextField>
      );
    };
    const getnWeitgh = (gross, tare, array) => {
      console.log(this.state.singleDispatch.dispatch_details.unit,"this.state.singleDispatch")
     
      const allValues =
        this.state.singleDispatch !== false &&
        this.state.singleDispatch?.dispatch_details?.purchase_order_details
          ?.contract_type_details?.contract_type === "FOR"
          ? [
            subtract(
              subtract(gross ? Number(gross) : 0, tare ? Number(tare) : 0),
              array.reduce(
                (previousValue, currentValue) =>
                  Number(previousValue) +
                  currentValue.arrival_packagings
                    .map((s) => {
                      const details =
                        currentValue.dispatch_items_details.dispatch_packaging.filter(
                          (ss) => ss.id === s.dispatch_packaging
                        )[0];

                      return {
                        ...s,
                        details: details.packaging_details,
                        ub: multiply(
                          Number(details.packaging_details.weight ?? 0),
                          Number(
                            subtract(
                              Number(details.total_bags_dispatched),
                              Number(s.returned_bags ?? 0)
                            )
                          )
                        ),
                      };
                    })
                    .reduce(
                      (previousValue1, currentValue1) =>
                        Number(previousValue1) + Number(currentValue1.ub),
                      0
                    ),
                0
              ),
            ),
            array.reduce(
              (previousValue, currentValue) =>
                Number(previousValue) +
                currentValue.arrival_packagings
                  .map((s) => {
                    const details =
                      currentValue.dispatch_items_details.dispatch_packaging.filter(
                        (ss) => ss.id === s.dispatch_packaging
                      )[0];

                    return {
                      ...s,
                      details: details.packaging_details,
                      ub: multiply(
                        Number( changeUnit(
                          details.packaging_details.unit,
                          this.state.singleDispatch.dispatch_details.unit,
                          Number( details.packaging_details.size)
                        )),
                        Number(
                          subtract(
                            Number(details.total_bags_dispatched),
                            Number(s.returned_bags ?? 0)
                          )
                        )
                      ),
                    };
                  })
                  .reduce(
                    (previousValue1, currentValue1) =>
                      Number(previousValue1) + Number(currentValue1.ub),
                    0
                  ),
              0
            ),
            Number(
              this.state.singleDispatch?.dispatch_details?.miller_accounts_details
                ?.miller_bill_weight ?? 0
            ),
           
          ]
          : [
              subtract(
                Number(this.state.singleDispatch?.dispatch_details?.net_weight),
                array.reduce(
                  (previousValue, currentValue) =>
                    Number(previousValue) +
                    currentValue.arrival_packagings
                      .map((s) => {
                        const details =
                          currentValue.dispatch_items_details.dispatch_packaging.filter(
                            (ss) => ss.id === s.dispatch_packaging
                          )[0];
                        return {
                          ...s,
                          details: details.packaging_details,
                          ub: multiply(
                            Number(details.packaging_details.weight ?? 0),
                            Number(
                              subtract(
                                Number(details.total_bags_dispatched),
                                Number(0)//s.returned_bags ?? 0
                              )
                            )
                          ),
                        };
                      })
                      .reduce(
                        (previousValue1, currentValue1) =>
                          Number(previousValue1) + Number(currentValue1.ub),
                        0
                      ),
                  0
                ),
              ),
              array.reduce(
                (previousValue, currentValue) =>
                  Number(previousValue) +
                  currentValue.arrival_packagings
                    .map((s) => {
                      const details =
                        currentValue.dispatch_items_details.dispatch_packaging.filter(
                          (ss) => ss.id === s.dispatch_packaging
                        )[0];

                      return {
                        ...s,
                        details: details.packaging_details,
                        ub: multiply(
                          Number( changeUnit(
                            details.packaging_details.unit,
                            this.state.singleDispatch.dispatch_details.unit,
                            Number( details.packaging_details.size)
                          )),
                          Number(
                            subtract(
                              Number(details.total_bags_dispatched),
                              Number(0)//s.returned_bags ?? 0
                            )
                          )
                        ),
                      };
                    })
                    .reduce(
                      (previousValue1, currentValue1) =>
                        Number(previousValue1) + Number(currentValue1.ub),
                      0
                    ),
                0
              ),
              Number(
                this.state.singleDispatch?.dispatch_details?.miller_accounts_details
                  ?.miller_bill_weight ?? 0
              ),
             
            ];

      console.log(allValues, getMinValue(...allValues), "wefallValueswegtr");
      return getMinValue(...allValues);
    };

    const portUnreachedTable = (
      edit,
      array,
      // arrayNew,
      addBag,
      noTotalQuantity,
      showTotal,
      width,
      submittedData
    ) => {
      return (
        <div
          style={{
            margin: "20px 0 20px 0",
            height: "calc(100% - 40px)",
            width: width ? width : edit ? "100%" : "calc(100% - 30px)",
            minWidth: width && "100%",
            fontSize: "0.9rem",
          }}>
          {/* { console.log(arrayNew,"kkkkkkkkkkkkkkkkkkkkkk") } */}

          {/* {PortDispatchedListPre.dispatch_details.dispatch_weight} */}
          <div style={{ display: "flex" }}>
            {/* <MuiPickersUtilsProvider
              libInstance={moment()}
              utils={DateFnsUtils}>
                   <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
{labelText("arrival Date")}
              <CssKeyboardDatePicker
                label={"Arrival Date"}
                variant="inline"
                onKeyUp={(ev) => {
                  ev.preventDefault();
                }}
                onKeyDown={(ev) => {
                  ev.preventDefault();
                }}
                onOpen={() => {
                  this.setState({ calenderOpen: true });
                }}
                onClose={() => {
                  this.setState({ calenderOpen: false });
                }}
                maxDate={new Date()}
                disableFuture
                // minDate={"2022-01-22"}
                error={errorMsg.arrival_date ? true : false}
                helperText={errorMsg.arrival_date ? errorMsg.arrival_date : ""}
                invalidLabel=""
                onKeyPress={(ev) => {
                  ev.preventDefault();
                }}
                allowKeyboardControl={false}
                keyboardIcon={calenderOpen ? <TopArrow /> : <BottomArrow />}
                autoOk
                inputVariant="outlined"
                style={
                  { width: "100%", maxWidth: "100%", marginRight: "20px" } //disabled={tp}
                }
                format="do MMM, yyyy"
                value={arrival_date ? arrival_date : new Date()}
                //views={['year', 'month']}
                InputAdornmentProps={{ position: "end" }}
                onChange={(newValue) => {
                  this.setState({
                    arrival_date: newValue,
                    errorMsg,
                  });
                }}
              />
              </div>
            </MuiPickersUtilsProvider> */}
            <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
              {labelText("Arrival Date")}
              <MuiPickersUtilsProvider
                libInstance={moment()}
                utils={DateFnsUtils}>
                <CssKeyboardDatePicker
                  // label={"Arrival Date"}
                  variant="inline"
                  onKeyUp={(ev) => {
                    ev.preventDefault();
                  }}
                  onKeyDown={(ev) => {
                    ev.preventDefault();
                  }}
                  onOpen={() => {
                    this.setState({ calenderOpen: true });
                  }}
                  onClose={() => {
                    this.setState({ calenderOpen: false });
                  }}
                  maxDate={new Date()}
                  disableFuture
                  // minDate={"2022-01-22"}
                  error={errorMsg.arrival_date ? true : false}
                  helperText={
                    errorMsg.arrival_date ? errorMsg.arrival_date : ""
                  }
                  invalidLabel=""
                  onKeyPress={(ev) => {
                    ev.preventDefault();
                  }}
                  allowKeyboardControl={false}
                  keyboardIcon={calenderOpen ? <EventIcon /> : <EventIcon />}
                  autoOk
                  inputVariant="outlined"
                  style={
                    { width: "100%", maxWidth: "100%", marginRight: "20px" } //disabled={tp}
                  }
                  format="do MMM, yyyy"
                  value={arrival_date ? arrival_date : new Date()}
                  //views={['year', 'month']}
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(newValue) => {
                    this.setState({
                      arrival_date: newValue,
                      errorMsg,
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
              {labelText("Port Gross Weight")}
              <CssTextField
                required={true}
                type="number"
                onFocus={StopScroll}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                onKeyDown={(evt) => {
                  // if (singleInput.only_number === true) {
                  //   evt.key === "." && evt.preventDefault();
                  // }
                  evt.key === "e" && evt.preventDefault();

                  evt.key === "E" && evt.preventDefault();
                  evt.key === "-" && evt.preventDefault();
                  evt.keyCode === 38 && evt.preventDefault();
                  evt.keyCode === 40 && evt.preventDefault();
                }}
                autoComplete="off"
                style={
                  { width: "100%" } //disabled={tp}
                }
                error={errorMsg.gross_weight ? true : false}
                helperText={errorMsg.gross_weight ? errorMsg.gross_weight : ""}
                id="outlined-basic"
                //   label="Port Gross Weight"
                variant="outlined"
                name={"gross_weight"}
                value={this.state.gross_weight ? this.state.gross_weight : ""}
                onChange={(event) => {
                  const errorMsg = this.state.errorMsg;
                  errorMsg.gross_weight = false;

                  errorMsg.net_weight = false;
                  if (
                    (event.target.value ? Number(event.target.value) : 0) <=
                    (tare_weight ? tare_weight : 0)
                  ) {
                    this.setState({
                      tare_weight: 0,
                      error:
                        "Tare weight can't be greater or equal to Gross weight!",
                      net_weight: 0,
                    });
                  } else {
                    this.calcMillerTotalAmount({
                      ...this.state,
                      net_weight: getnWeitgh(
                        event.target.value ? Number(event.target.value) : 0,
                        tare_weight ? tare_weight : 0,
                        array
                      ),
                    })
                    this.setBrirokerageAmountMiller({
                      ...this.state,
                      net_weight: getnWeitgh(
                        event.target.value ? Number(event.target.value) : 0,
                        tare_weight ? tare_weight : 0,
                        array),
                    })
                    this.setBrirokerageAmount({
                      ...this.state,
                      net_weight: getnWeitgh(
                        event.target.value ? Number(event.target.value) : 0,
                        tare_weight ? tare_weight : 0,
                        array),
                    });
                    this.setState({
                      net_weight: getnWeitgh(
                        event.target.value ? Number(event.target.value) : 0,
                        tare_weight ? tare_weight : 0,
                        array
                      ),
                    });
                  }
                  this.setState({
                    [event.target.name]: event.target.value,
                    errorMsg,
                  });

                  return event;
                }}
              />
            </div>
            <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
              {labelText("Port Tare Weight")}
              <CssTextField
                required={true}
                type="number"
                onFocus={StopScroll}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                onKeyDown={(evt) => {
                  // if (singleInput.only_number === true) {
                  //   evt.key === "." && evt.preventDefault();
                  // }
                  evt.key === "e" && evt.preventDefault();

                  evt.key === "E" && evt.preventDefault();
                  evt.key === "-" && evt.preventDefault();
                  evt.keyCode === 38 && evt.preventDefault();
                  evt.keyCode === 40 && evt.preventDefault();
                }}
                autoComplete="off"
                style={
                  { width: "100%", maxWidth: "100%", marginRight: "20px" } //disabled={tp}
                }
                error={errorMsg.tare_weight ? true : false}
                helperText={errorMsg.tare_weight ? errorMsg.tare_weight : ""}
                id="outlined-basic"
                //    label="Port Tare Weight"
                variant="outlined"
                name={"tare_weight"}
                value={this.state.tare_weight ? this.state.tare_weight : ""}
                onChange={(event) => {
                  const errorMsg = this.state.errorMsg;
                  errorMsg.tare_weight = false;
                  errorMsg.net_weight = false;

                  if (
                    (gross_weight ? Number(gross_weight) : 0) <=
                    (event.target.value ? event.target.value : 0)
                  ) {
                    this.setState({
                      tare_weight: 0,
                      error: "Tare weight can't be equal to Gross weight !",
                      net_weight: 0,
                    });
                  } else {
                    console.log(
                      gross_weight,
                      "gross_weight",
                      event.target.value
                    );
                    this.calcMillerTotalAmount({
                      ...this.state,
                      net_weight: getnWeitgh(
                        gross_weight ? Number(gross_weight) : 0,
                        event.target.value ? event.target.value : 0,
                        array
                      ),
                    })  ;
                      this.setBrirokerageAmountMiller({
                      ...this.state,
                      net_weight: getnWeitgh(
                        gross_weight ? Number(gross_weight) : 0,
                        event.target.value ? event.target.value : 0,
                        array),
                    })
                    this.setBrirokerageAmount({
                      ...this.state,
                      net_weight: getnWeitgh(
                        gross_weight ? Number(gross_weight) : 0,
                        event.target.value ? event.target.value : 0,
                        array),
                    })
                    this.setState({
                      [event.target.name]: event.target.value,

                      net_weight: getnWeitgh(
                        gross_weight ? Number(gross_weight) : 0,
                        event.target.value ? event.target.value : 0,
                        array
                      ),
                    });
                  }
                  this.setState({
                    errorMsg,
                  });

                  return event;
                }}
              />
            </div>
            <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
              {labelText("Port Net weight")}
              <CssTextField
                required={true}
                type="number"
                onFocus={StopScroll}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                onKeyDown={(evt) => {
                  // if (singleInput.only_number === true) {
                  //   evt.key === "." && evt.preventDefault();
                  // }
                  evt.key === "e" && evt.preventDefault();

                  evt.key === "E" && evt.preventDefault();
                  evt.key === "-" && evt.preventDefault();
                  evt.keyCode === 38 && evt.preventDefault();
                  evt.keyCode === 40 && evt.preventDefault();
                }}
                disabled={true}
                autoComplete="off"
                style={
                  { width: "100%", maxWidth: "100%", marginRight: "20px" } //disabled={tp}
                }
                error={errorMsg.net_weight ? true : false}
                helperText={errorMsg.net_weight ? errorMsg.net_weight : ""}
                id="outlined-basic"
                // label="Port Net weight"
                variant="outlined"
                name={"net_weight"}
                value={this.state.net_weight ? this.state.net_weight : ""}
                // onChange={(event) => {
                //   const errorMsg = this.state.errorMsg;
                //   errorMsg.net_weight = false;

                //   this.setState({
                //     [event.target.name]: event.target.value,
                //     errorMsg,
                //   });
                // }}
              />
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
              {labelText("Survey Company")}
              <CssTextField
                required={true}
                autoComplete="off"
                value={
                  surveyorCompany ? surveyorCompany : "" //
                }
                error={errorMsg.surveyorCompany ? true : false}
                helperText={
                  errorMsg.surveyorCompany ? errorMsg.surveyorCompany : ""
                }
                onChange={(e) => {
                  const errorMsg = this.state.errorMsg;
                  errorMsg.surveyorCompany = false;
                  if (SurveyCompanyList && SurveyCompanyList.length > 0) {
                    this.setState({ loadingDialog: true });
                    this.props
                      .PortsFunction(
                        "get",
                        "masters/surveyor/list",
                        null,
                        "",
                        null,
                        {
                          is_active: "True",
                          company_id: e.currentTarget.id,
                        }
                      )
                      .then((res) => {
                        if (res) {
                          this.setState({
                            surveyorListForSurvay: res,
                            loadingDialog: false,
                          });
                        } else {
                          this.setState({ loadingDialog: false });
                        }
                      });
                  }
                  this.setState(
                    {
                      errorMsg,
                      surveyorCompany: e.target.value,
                      surveyorCompanyId: e.currentTarget.id,
                    },
                    () => {}
                  );
                }}
                style={
                  { width: "100%" } //disabled={tp}
                }
                id="outlined-select-currency-native"
                select
                // label="Survey Company"
                variant="outlined"
                InputLabelProps={{}}>
                {SurveyCompanyList &&
                  SurveyCompanyList.length > 0 &&
                  SurveyCompanyList.map((option) => (
                    <MenuItem
                      key={option.name}
                      value={option.name}
                      id={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </CssTextField>
            </div>
            {surveyorCompanyId && (
              <>
                <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                  {labelText("Surveyor")}
                  <CssTextField
                    required={false}
                    autoComplete="off"
                    value={
                      surveyor ? surveyor : "" //
                    }
                    error={errorMsg.surveyor ? true : false}
                    helperText={errorMsg.surveyor ? errorMsg.surveyor : ""}
                    onChange={(e) => {
                      const errorMsg = this.state.errorMsg;
                      errorMsg.surveyor = false;
                      this.setState({
                        errorMsg,
                        surveyor: e.target.value,
                        surveyorId: e.currentTarget.id,
                      });
                    }}
                    style={
                      { width: "100%" } //disabled={tp}
                    }
                    id="outlined-select-currency-native"
                    select
                    // label="Surveyor"
                    variant="outlined"
                    InputLabelProps={{}}>
                    {surveyorListForSurvay &&
                      surveyorListForSurvay.length > 0 &&
                      surveyorListForSurvay.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.name}
                          id={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </CssTextField>
                </div>
              </>
            )}
            <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
              {labelText("Unloading Agent")}
              <CssTextField
                required={true}
                type="text"
                disabled={true}
                autoComplete="off"
                style={
                  { width: "100%" } //disabled={tp}
                }
                error={errorMsg.unloading_agent ? true : false}
                helperText={
                  errorMsg.unloading_agent ? errorMsg.unloading_agent : ""
                }
                id="outlined-basic"
                //  label="Unloading Agent"
                variant="outlined"
                name={"unloading_agent"}
                value={
                  singlelistView.unloading_agent
                    ? singlelistView.unloading_agent
                    : ""
                }
                // onChange={(event) => {
                //   const errorMsg = this.state.errorMsg;
                //   errorMsg.net_weight = false;

                //   this.setState({
                //     [event.target.name]: event.target.value,
                //     errorMsg,
                //   });
                // }}
              />
            </div>
            <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
              {labelText("Quality Deduction")}
              <CssTextField
                required={true}
                type="number"
                onFocus={StopScroll}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                onKeyDown={(evt) => {
                  // if (singleInput.only_number === true) {
                  //   evt.key === "." && evt.preventDefault();
                  // }
                  evt.key === "e" && evt.preventDefault();

                  evt.key === "E" && evt.preventDefault();
                  evt.key === "-" && evt.preventDefault();
                  evt.keyCode === 38 && evt.preventDefault();
                  evt.keyCode === 40 && evt.preventDefault();
                }}
                // disabled={true}
                autoComplete="off"
                style={
                  { width: "100%" } //disabled={tp}
                }
                error={errorMsg.quality_deduction ? true : false}
                helperText={
                  errorMsg.quality_deduction ? "Add Quality Deduction" : ""
                }
                id="outlined-basic"
                // label="Quality Deduction"
                variant="outlined"
                name={"quality_deduction"}
                value={
                  this.state.quality_deduction
                    ? this.state.quality_deduction
                    : ""
                }
                onChange={(event) => {
                  const errorMsg = this.state.errorMsg;
                  errorMsg.quality_deduction = false;
                  this.calcMillerTotalAmount({
                    ...this.state,
                    quality_deduction: event.target.value,
                  });
                  this.setState({
                    [event.target.name]: event.target.value,
                    errorMsg,
                  });
                }}
              />
            </div>
          </div>
          {this.state.singleDispatch !== false &&
            this.state.singleDispatch?.dispatch_details?.purchase_order_details
              ?.contract_type_details?.contract_type === "FOR" ? (
              <>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Miller Bill Amount")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      autoComplete="off"
                      //style={styles.withDivider50}
                      error={errorMsg.miller_bill_amount ? true : false}
                      helperText={
                        errorMsg.miller_bill_amount
                          ? "Add Miller Bill Amount"
                          : false
                      }
                      id="miller_bill_amount"
                      //label="SO NO."
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      // InputProps={{
                      //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                      // }}
                      variant="outlined"
                      name="miller_bill_amount"
                      // value={S0Number}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 20);
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Miller TDS")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      autoComplete="off"
                      //style={styles.withDivider50}
                      error={errorMsg.miller_tds ? true : false}
                      helperText={
                        errorMsg.miller_tds ? "Add Miller TDS" : false
                      }
                      id="miller_tds"
                      //label="SO NO."
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      // InputProps={{
                      //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                      // }}
                      variant="outlined"
                      name="miller_tds"
                      // value={S0Number}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 20);
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Miller Bill Weight")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      autoComplete="off"
                      //style={styles.withDivider50}
                      error={errorMsg.miller_bill_weight ? true : false}
                      helperText={
                        errorMsg.miller_bill_weight
                          ? "Add Miller Bill Weight"
                          : false
                      }
                      id="miller_bill_weight"
                      //label="SO NO."
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      // InputProps={{
                      //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                      // }}
                      variant="outlined"
                      name="miller_bill_weight"
                      // value={S0Number}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 20);
                      }}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Miller GST Charge")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      autoComplete="off"
                      //style={styles.withDivider50}
                      error={errorMsg.miller_gst_charge ? true : false}
                      helperText={
                        errorMsg.miller_gst_charge
                          ? "Add Miller GST Charge"
                          : false
                      }
                      id="miller_gst_charge"
                      //label="SO NO."
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      // InputProps={{
                      //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                      // }}
                      variant="outlined"
                      name="miller_gst_charge"
                      // value={S0Number}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 20);
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Miller Advance")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      autoComplete="off"
                      //style={styles.withDivider50}
                      error={errorMsg.miller_advance ? true : false}
                      helperText={
                        errorMsg.miller_advance ? "Add Miller Advance" : false
                      }
                      id="miller_advance"
                      //label="SO NO."
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      // InputProps={{
                      //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                      // }}
                      variant="outlined"
                      name="miller_advance"
                      // value={S0Number}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 20);
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Miller Total Amount")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      autoComplete="off"
                      //style={styles.withDivider50}
                      error={errorMsg.miller_total_amount ? true : false}
                      helperText={
                        errorMsg.miller_total_amount
                          ? "Add Miller Total Amount"
                          : false
                      }
                      id="miller_total_amount"
                      //label="SO NO."
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      // InputProps={{
                      //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                      // }}
                      variant="outlined"
                      name="miller_total_amount"
                      value={miller_total_amount}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 20);
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Brokerage Rate For Bri")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      autoComplete="off"
                      //style={styles.withDivider50}
                      error={errorMsg.brokerage_rate_for_bri ? true : false}
                      helperText={
                        errorMsg.brokerage_rate_for_bri
                          ? "Brokerage Rate For BRI"
                          : false
                      }
                      id="brokerage_rate_for_bri"
                      //label="SO NO."
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      // InputProps={{
                      //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                      // }}
                      variant="outlined"
                      name="brokerage_rate_for_bri"
                      // value={S0Number}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 20);
                      }}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Brokerage Amount For Bri")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      disabled={true}
                      autoComplete="off"
                      //style={styles.withDivider50}
                      error={errorMsg.brokerage_amount_for_bri ? true : false}
                      helperText={
                        errorMsg.brokerage_amount_for_bri
                          ? "Add Brokerage Amount For BRI"
                          : false
                      }
                      id="brokerage_amount_for_bri"
                      //label="SO NO."
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      // InputProps={{
                      //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                      // }}
                      variant="outlined"
                      name="brokerage_amount_for_bri"
                      value={brokerage_amount_for_bri}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 20);
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Brokerage Rate For Miller")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      autoComplete="off"
                      //style={styles.withDivider50}
                      error={errorMsg.brokerage_rate_for_miller ? true : false}
                      helperText={
                        errorMsg.brokerage_rate_for_miller
                          ? "Add Brokerage Rate For Miller"
                          : false
                      }
                      id="brokerage_rate_for_miller"
                      //label="SO NO."
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      // InputProps={{
                      //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                      // }}
                      variant="outlined"
                      name="brokerage_rate_for_miller"
                      value={brokerage_rate_for_miller}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 20);
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Brokerage Amount For Miller")}
                    <CssTextField
                      required={true}
                      type="text"
                      disabled={true}
                      onFocus={StopScroll}
                      autoComplete="off"
                      //style={styles.withDivider50}
                      error={
                        errorMsg.brokerage_amount_for_miller ? true : false
                      }
                      helperText={
                        errorMsg.brokerage_amount_for_miller
                          ? "Add Brokerage Amount For Miller"
                          : false
                      }
                      id="brokerage_amount_for_miller"
                      //label="SO NO."
                      // style={
                      //   { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      // }
                      style={{ width: "100%" }}
                      // InputProps={{
                      //   endAdornment: <InputAdornment position="start">/{Unit}</InputAdornment>,
                      // }}
                      variant="outlined"
                      name="brokerage_amount_for_miller"
                      value={brokerage_amount_for_miller}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 20);
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Unit")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      autoComplete="off"
                      error={
                        errorMsg.unit ? true : false
                      }
                      style={{ width: "100%" }}
                      variant="outlined"
                      name="unit"
                      value={this.state.singleDispatch.dispatch_details.unit??""}
                      disabled={true}>
                      </CssTextField>
                  </div>

                  <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                    {labelText("Contract Type")}
                    <CssTextField
                      required={true}
                      type="text"
                      onFocus={StopScroll}
                      autoComplete="off"
                      error={
                        errorMsg.unit ? true : false
                      }
                      style={{ width: "100%" }}
                      variant="outlined"
                      name="unit"
                      value={this.state.singleDispatch?.dispatch_details?.purchase_order_details
                        ?.contract_type_details?.contract_type ??""}
                      disabled={true}>
                      </CssTextField>
                  </div>               
                   </div>
              </>
            ):  ""}
          {this.state.singleDispatch !== false &&
            this.state.singleDispatch?.dispatch_details?.purchase_order_details
              ?.contract_type_details?.contract_type !== "FOR" && (
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                  {labelText("Freight Rate")}
                  <CssTextField
                    required={true}
                    type="number"
                    onFocus={StopScroll}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onKeyDown={(evt) => {
                      // if (singleInput.only_number === true) {
                      //   evt.key === "." && evt.preventDefault();
                      // }
                      evt.key === "e" && evt.preventDefault();

                      evt.key === "E" && evt.preventDefault();
                      evt.key === "-" && evt.preventDefault();
                      evt.keyCode === 38 && evt.preventDefault();
                      evt.keyCode === 40 && evt.preventDefault();
                    }}
                    // disabled={true}
                    autoComplete="off"
                    style={
                      { width: "100%" } //disabled={tp}
                    }
                    error={errorMsg.freight_rate ? true : false}
                    helperText={errorMsg.freight_rate ? "Add Freight Rate" : ""}
                    id="outlined-basic"
                    // label="Freight Rate"
                    variant="outlined"
                    name={"freight_rate"}
                    value={
                      this.state.freight_rate ? this.state.freight_rate : ""
                    }
                    onChange={(event) => {
                      const errorMsg = this.state.errorMsg;
                      errorMsg.freight_rate = false;

                      this.setState({
                        [event.target.name]: event.target.value,
                        errorMsg,
                      });
                    }}
                  />
                </div>
                <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
                  {labelText("Freight Deduction")}
                  <CssTextField
                    required={true}
                    type="number"
                    onFocus={StopScroll}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onKeyDown={(evt) => {
                      // if (singleInput.only_number === true) {
                      //   evt.key === "." && evt.preventDefault();
                      // }
                      evt.key === "e" && evt.preventDefault();

                      evt.key === "E" && evt.preventDefault();
                      evt.key === "-" && evt.preventDefault();
                      evt.keyCode === 38 && evt.preventDefault();
                      evt.keyCode === 40 && evt.preventDefault();
                    }}
                    // disabled={true}
                    autoComplete="off"
                    style={
                      { width: "100%" } //disabled={tp}
                    }
                    error={errorMsg.freight_deduction ? true : false}
                    helperText={
                      errorMsg.freight_deduction ? "Add Freight Deduction" : ""
                    }
                    id="outlined-basic"
                    //label="Freight Deduction"
                    variant="outlined"
                    name={"freight_deduction"}
                    value={
                      this.state.freight_deduction
                        ? this.state.freight_deduction
                        : ""
                    }
                    onChange={(event) => {
                      const errorMsg = this.state.errorMsg;
                      errorMsg.freight_deduction = false;

                      this.setState({
                        [event.target.name]: event.target.value,
                        errorMsg,
                      });
                    }}
                  />
                </div> <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
              {labelText("Unit")}
              <CssTextField
                required={true}
                type="text"
                onFocus={StopScroll}
                autoComplete="off"
                error={
                  errorMsg.unit ? true : false
                }
                style={{ width: "100%" }}
                variant="outlined"
                name="unit"
                value={this.state.singleDispatch.dispatch_details.unit??""}
                disabled={true}>
                </CssTextField>
            </div>

            <div style={{ width: "calc(25% - 20px)", margin: "10px" }}>
              {labelText("Contract Type")}
              <CssTextField
                required={true}
                type="text"
                onFocus={StopScroll}
                autoComplete="off"
                error={
                  errorMsg.unit ? true : false
                }
                style={{ width: "100%" }}
                variant="outlined"
                name="unit"
                value={this.state.singleDispatch?.dispatch_details?.purchase_order_details
                  ?.contract_type_details?.contract_type ??""}
                disabled={true}>
                </CssTextField>
            </div>  
              </div>
            )}
          <Paper
            className="d-flex d-flex-column  overflowXA positionR scrollBluePrimary "
            style={{
              height: "40arrival_table_details%",
              width: "100%",
              borderRadius: "7px",
            }}>
            <div
              style={{
                backgroundColor: "rgba(251, 224, 191, 1)",
                color: primary,
                borderColor: primary,
                width: "fit-content",
                minWidth: "100%",
                tableLayout: "fixed",
                display: "flex",
                alignItems: "center",
                height: "30px",
                minHeight: "30px",
                overflow: "hidden",
                borderRadius: "7px 7px 0 0",
              }}>
              <div
                className="d-flex alignC justifySE width-100"
                style={{ background: "rgba(241, 154, 48, 1)" }}>
                {portUnreachedheader &&
                  portUnreachedheader.map((name, i) =>
                    noTotalQuantity && name === "Total Quantity" ? (
                      ""
                    ) : (
                      <div
                        className="justifyC d-flex alignC"
                        style={{
                          margin: "12px 10px",
                          width:
                            widthList && widthList[portUnreachedheader[i]]
                              ? widthList[portUnreachedheader[i]]
                              : "fit-content",
                          minWidth: "fit-content",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}>
                        {name}
                      </div>
                    )
                  )}
                {edit && (
                  <div
                    className="justifyC d-flex alignC"
                    style={{
                      margin: "12px 10px",
                      width: "65px",
                      display: "flex",
                      alignItems: "center",
                      color: "#100f0f",
                      textTransform: "capitalize",
                    }}>
                    Edit
                  </div>
                )}
              </div>
            </div>
            {array && array.length > 0 ? (
              <>
                <div
                  className="scrollBluePrimary"
                  style={{
                    height: "fit-content",
                    overflowY: "auto",
                    width: "fit-content",
                    minWidth: "100%",
                    position: "relative",
                    background: "rgba(251, 224, 191, 1)",
                  }}>
                  {array.map(
                    (singleDate, i) =>
                      singleDate && (
                        <>
                          <div className="d-flex width-100 d-flex-column justifySE">
                            <div
                              className="d-flex width-100 justifySE"
                              style={{
                                // margin: "1px 0 0 0",
                                backgroundColor:
                                  colorArr && colorArr[i % colorArr.length],
                                height: edit === "checkBox" ? "44px" : "50px",
                              }}>
                              {portUnreachedheader &&
                                portUnreachedheader.map((name) =>
                                  noTotalQuantity &&
                                  name === "Total Quantity" ? (
                                    ""
                                  ) : (
                                    <div
                                      className="d-flex alignC justifyC "
                                      style={{
                                        margin: "12px 10px",
                                        width:
                                          widthList && widthList[name]
                                            ? widthList[name]
                                            : "fit-content",
                                        minWidth:
                                          widthList && widthList[name]
                                            ? widthList[name]
                                            : "fit-content",
                                        textTransform: "capitalize",
                                      }}>
                                      {singleDate[name]
                                        ? singleDate[`${name}UI`]
                                          ? singleDate[`${name}UI`]
                                          : singleDate[`${name}Unit`]
                                          ? singleDate[`${name}Unit`]
                                          : name === "Item Type"
                                          ? `${singleDate[name]} %`
                                          : singleDate[name]
                                        : "-"}
                                      {name === "Packaging" && addBag ? (
                                        <StyledButton
                                          color={
                                            returnButtonError === true
                                              ? "#FF3D00"
                                              : ""
                                          }
                                          onlyTextColor={
                                            returnButtonError === true &&
                                            "white"
                                          }
                                          onClick={() => {
                                            this.setState({
                                              openAddBagDialog: singleDate,
                                            });
                                          }}
                                          // onlyTextColor={errorMsg[`${singlePackaging.id}Returned`]&&"#FF3D00"}
                                          name={
                                            addBag === "showbagOnly"
                                              ? "Edit"
                                              : "Return"
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )
                                )}
                              {edit && (
                                <div
                                  className="d-flex alignC justifyC minWidthFC"
                                  style={{
                                    margin: "12px 10px",
                                    width: "65px",
                                    minWidth: "50px",
                                    textTransform: "capitalize",
                                  }}>
                                  {" "}
                                  <Tooltip
                                    title="Edit"
                                    placement="top"
                                    disableFocusListener
                                    disableTouchListener
                                    arrow>
                                    <StyledButton
                                      name={<EditIcon />}
                                      width="60px"
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </div>

                            <Divider className="width-100" />
                          </div>

                          <Divider />
                        </>
                      )
                  )}
                </div>
                {showTotal && (
                  <div className="d-flex alignC width-100">
                    {portUnreachedheader &&
                      portUnreachedheader.map((name, i) => (
                        <div
                          className=" d-flex justifyC alignC"
                          style={{
                            margin: "12px 10px",
                            width:
                              widthList && widthList[portUnreachedheader[i]]
                                ? widthList[portUnreachedheader[i]]
                                : "fit-content",
                            minWidth:
                              widthList && widthList[portUnreachedheader[i]]
                                ? widthList[portUnreachedheader[i]]
                                : "fit-content",
                            color: "#100f0f",
                            textTransform: "capitalize",
                          }}>
                          {i === 0 ? (
                            <div style={{ fontWeight: "500" }}>Total</div>
                          ) : i === 1 ||
                            i === 2 ||
                            i === portUnreachedheader.length - 1 ||
                            i === portUnreachedheader.length - 2 ? (
                            " "
                          ) : i === 3 ? (
                            array.reduce(
                              (previousValue, currentValue) =>
                                Number(previousValue) +
                                currentValue.arrival_packagings.reduce(
                                  (previousValue1, currentValue1) =>
                                    Number(previousValue1) +
                                    Number(
                                      this.state[
                                        `${currentValue1.dispatch_packaging}Returned`
                                      ]
                                        ? this.state[
                                            `${currentValue1.dispatch_packaging}Returned`
                                          ]
                                        : 0
                                    ),
                                  0
                                ),
                              0
                            )
                          ) : (
                            i === 4 &&
                            `${array.reduce(
                              (previousValue, currentValue) =>
                                Number(previousValue) +
                                Number(
                                  currentValue[name] ? currentValue[name] : 0
                                ),
                              0
                            )}`
                          )}
                        </div>
                      ))}
                    {edit && (
                      <div
                        className="d-flex alignC justifyC minWidthFC"
                        style={{
                          margin: "12px 10px",
                          width: "65px",
                          minWidth: "50px",
                          textTransform: "capitalize",
                        }}>
                        {" "}
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
                  No Data!
                </span>
              </div>
            )}
          </Paper>
        </div>
      );
    };

    return (
      <Layout
        loading={loading[EndPoints["Ports"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Ports`,
          labelField: labelField,
        }}
        fabClickHandler={(value) => {
          this.setState(
            {
              portDialog: true,
              loadingFun: false,
              editList: false,
            },
            () => {}
          );
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px">
        {ArrivalDialog === true ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "0px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "620px";

                target.style.height = "620px";
                target.style.minWidth = "90%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={true}
            onClose={() => {
              this.setState(
                {
                  ArrivalDialog: false,
                  editDialog: false,
                  added: false,
                },
                () => {
                  // this.reset("no snac");
                }
              );
            }}
            TransitionComponent={Transition}>
            {openAddBagDialog !== false ? (
              <Dialog
                ref={(e) => {
                  try {
                    const target =
                      ReactDOM.findDOMNode(e).children[2].children[0];
                    target.style.borderRadius = "0px";
                    target.style.maxHeight = "calc(100% - 40px)";
                    target.style.minHeight = "300px";

                    target.style.height = "fit-content";
                    target.style.minWidth = "300px";
                    target.style.maxWidth = "300px";
                    target.style.margin = "0";
                  } catch (e) {}
                }}
                open={true}
                onClose={() => {
                  this.setState({
                    openAddBagDialog: false,
                  });
                }}
                TransitionComponent={Transition}>
                {" "}
                <DialogActions
                  className="d-flex justifySB"
                  style={{
                    background: primary,
                    color: "white",
                    padding: "10px 24px",
                  }}
                  onClick={() => {
                    this.setState({
                      openAddBagDialog: false,
                    });
                  }}>
                  <h3 style={styles.heading}>Return Bags</h3>
                  <Tooltip title="close" arrow placement="top">
                    <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                      <CrossIcon strokeWidth="2" />
                    </IconButton>
                  </Tooltip>
                </DialogActions>
                <DialogContent
                  className="dashedDividerBottom  width-100 d-flex alignC justifyC d-flex-column"
                  style={{ height: "100%" }}>
                  {this.state.bagerror || this.state.bagsuccess ? (
                    <SnackbarComponent
                      autoHideDuration={error ? 5000 : 1500}
                      error={this.state.bagerror}
                      success={this.state.bagsuccess}
                      snackbarClose={() => {
                        this.setState({
                          bagerror: "",
                          bagsuccess: "",
                        });
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {openAddBagDialog &&
                    openAddBagDialog["arrival_packagings"] &&
                    openAddBagDialog["arrival_packagings"].map(
                      (singlePackaging) => {
                        const dispatchdetail = filterData(
                          openAddBagDialog["dispatch_items_details"][
                            "dispatch_packaging"
                          ],
                          "id",
                          singlePackaging.dispatch_packaging,
                          "onluOne"
                        )
                          ? filterData(
                              openAddBagDialog["dispatch_items_details"][
                                "dispatch_packaging"
                              ],
                              "id",
                              singlePackaging.dispatch_packaging,
                              "onluOne"
                            )
                          : "";

                        const total_bags_dispatched =
                          dispatchdetail.total_bags_dispatched
                            ? dispatchdetail.total_bags_dispatched
                            : "";

                        return (
                          <div
                            className="d-flex-column"
                            style={{
                              display: "flex",
                              width: "100%",
                              fontSize: "1rem",
                              color: "rgba(0, 0, 0, 0.87)",
                            }}>
                            {dispatchdetail
                              ? `${dispatchdetail.packaging_details.size} ${
                                  dispatchdetail.packaging_details.unit
                                } Total Bags ${
                                  this.state[
                                    `${singlePackaging.dispatch_packaging}Returned`
                                  ]
                                    ? subtract(
                                        total_bags_dispatched,
                                        this.state[
                                          `${singlePackaging.dispatch_packaging}Returned`
                                        ]
                                      )
                                    : total_bags_dispatched
                                }`
                              : ""}

                            <CssTextField95
                              required={true}
                              onInput={(e) => {
                                if (
                                  e.target.value > total_bags_dispatched
                                    ? Number(total_bags_dispatched)
                                    : 0
                                ) {
                                  this.setState({
                                    bagerror:
                                      "Can't be greater than total bags",
                                  });
                                }
                                e.target.value = e.target.value
                                  ? e.target.value > total_bags_dispatched
                                    ? Number(total_bags_dispatched)
                                    : 0
                                    ? Number(total_bags_dispatched)
                                    : e.target.value
                                  : "";
                              }}
                              error={
                                errorMsg[
                                  `${singlePackaging.dispatch_packaging}Returned`
                                ]
                                  ? true
                                  : false
                              }
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  max: total_bags_dispatched
                                    ? Number(total_bags_dispatched)
                                    : 0,
                                },
                              }}
                              type="number"
                              onFocus={StopScroll}
                              autoComplete="off"
                              id="outlined-basic"
                              label="Return Bags"
                              style={{ width: "100%", margin: "10px 0" }}
                              variant="outlined"
                              value={
                                this.state[
                                  `${singlePackaging.dispatch_packaging}Returned`
                                ]
                                  ? this.state[
                                      `${singlePackaging.dispatch_packaging}Returned`
                                    ]
                                  : ""
                              }
                              onChange={(e) => {
                                var errorMsg = this.state.errorMsg;
                                errorMsg[
                                  `${singlePackaging.dispatch_packaging}Returned`
                                ] = false;
                                this.setState({
                                  [`${singlePackaging.dispatch_packaging}Returned`]:
                                    e.target.value,

                                  // showBags:
                                  //   total_bags_dispatched - e.target.value,
                                  errorMsg,
                                  returnButtonError: false,
                                });

                                // if (e.target.value >= total_bags_dispatched) {
                                //   this.setState({ showBags: "zero" });
                                // }
                              }}
                            />
                          </div>
                        );
                      }
                    )}
                </DialogContent>
                <DialogActions
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    minHeight: "52.5px",
                  }}>
                  <StyledButton
                    onClick={() => {
                      // this.handleClear();
                      // const details=openAddBagDialog.dispatch_items_details;
                      // const packaging=details.arrival_packagings//details.dispatch_packaging//sItem.arrival_packagings;

                      // newArr.map((sObj) => {
                      //   if (sObj.po_item_id === openAddBagDialog.po_item_id) {
                      //     sObj["packagageArrUsed"] = submittedDataOfUsedBags[openAddBagDialog.po_item_id];
                      //   } else {
                      //   }
                      //   return sObj;
                      // });

                      this.setState({
                        net_weight: getnWeitgh(
                          gross_weight ? Number(gross_weight) : 0,
                          tare_weight ? tare_weight : 0,
                          PortDispatchedItemList.map((singleDispatch) => {
                            return setData(singleDispatch);
                          })
                        ),
                        // poItemsArrayTOPreviewCopy2: newArr,
                        openAddBagDialog: false,
                      });
                    }}
                    name={"Done"}
                    withBg={true}
                    background={primary}
                    width="120px"
                    margin="0 10px 0 0"
                  />
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}
            <DialogActions
              className="d-flex justifySB"
              style={{
                background: primary,
                color: "white",
                padding: "10px 24px",
              }}
              onClick={() => {
                this.setState({
                  ArrivalDialog: false,
                  editDialog: false,
                  added: false,
                });
              }}>
              {" "}
              <h3 style={styles.heading}>
                {" "}
                {/* <PurchaseIcon style={{ color: "white", margin: "0 5px 0 0" }} /> */}
                Mark as Reached (Selected Dispatched Item Details)
              </h3>
              <Tooltip title="close" arrow placement="top">
                <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                  <CrossIcon strokeWidth="2" />
                </IconButton>
              </Tooltip>
            </DialogActions>
            <DialogContent
              className="dashedDividerBottom"
              style={{ height: "100%" }}>
              {error || success ? (
                <SnackbarComponent
                  error={error}
                  autoHideDuration={error ? 5000 : 1500}
                  success={success}
                  snackbarClose={() => {
                    this.setState(
                      {
                        error: "",
                      },
                      () => {
                        if (success === "All items are updated!") {
                          this.setState({
                            success: "",
                            loadingDialog: false,
                            editArrival: false,
                            openAddBagDialog: false,
                            ArrivalDialog: false,
                            editDialog: false,
                            added: false,
                          });
                        } else {
                          this.setState({
                            success: "",
                          });
                        }
                      }
                    );
                  }}
                />
              ) : (
                ""
              )}
              {loadingDialog === true ? (
                <div className="alignC justiyC d-flex width-100 height-100">
                  <StyledCircularProgress color="inherit" />
                </div>
              ) : (
                portUnreachedTable(
                  false,
                  PortDispatchedItemList.map((singleDispatch) => {
                    console.log(PortDispatchedItemList, "aaaaaaaaaaaaa");
                    return setData(singleDispatch);
                  }),
                  // PortDispatchedListPre.map((singleDispatch) => {
                  //   console.log(PortDispatchedListPre,"bbbbbbbbbbbbbb")
                  //   return setData(singleDispatch);
                  // }),
                  "addBag",
                  false,
                  "showTotal"
                )
              )}
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                minHeight: "52.5px",
              }}>
              {" "}
              <div
                //className="d-flex alignC justifyFE"
                style={{
                  margin: "10px",
                  //width: "calc(25% - 20px)",
                  minWidth: "calc(25% - 20px)",
                  display: "flex",
                  justifyContent: "flex-end",
                }}>
                <StyledButton
                  name={editArrival ? "Reset to Default" : "Reset"}
                  onClick={() => {
                    this.handleClear();
                  }}
                />
                <StyledButton
                  name={editArrival ? "Update" : "Proceed"}
                  minWidth="30px"
                  width="150px"
                  margin="0 10px"
                  withBg={true}
                  onClick={() => {
                    if (PortDispatchedItemList.length > 0) {
                      if (this.state.arrival_date) {
                        if (this.state.gross_weight) {
                          if (this.state.tare_weight) {
                            if (this.state.net_weight) {
                              if (this.state.surveyorCompany) {
                                if (this.state.quality_deduction) {
                                  if (
                                    this.state.singleDispatch !== false &&
                                    this.state.singleDispatch?.dispatch_details
                                      ?.purchase_order_details
                                      ?.contract_type_details?.contract_type ===
                                      "FOR"
                                      ? true
                                      : this.state.freight_rate
                                  ) {
                                    if (
                                      this.state.singleDispatch !== false &&
                                      this.state.singleDispatch
                                        ?.dispatch_details
                                        ?.purchase_order_details
                                        ?.contract_type_details
                                        ?.contract_type === "FOR"
                                        ? true
                                        : this.state.freight_deduction
                                    ) {
                                      const newArr = [];
                                      const newPackagingArr = [];
                                      const moveShippingArr = [];
                                      this.setState({ loadingDialog: true });
                                      PortDispatchedItemList.map((sItem) => {
                                        const singlePack = [];
                                        const details =
                                          sItem.dispatch_items_details;
                                        const packaging =
                                          sItem.arrival_packagings; //details.dispatch_packaging//
                                        const returned_bags_of_packgings = {};
                                        if (
                                          this.state[`${details.po_item}Status`]
                                        ) {
                                          if (
                                            (this.state[
                                              `${details.po_item}Status`
                                            ] !== "Move to Godown" &&
                                              this.state[
                                                `${details.po_item}Sales`
                                              ]) ||
                                            this.state[
                                              `${details.po_item}Status`
                                            ] === "Move to Godown"
                                          ) {
                                            if (
                                              (this.state[
                                                `${details.po_item}Status`
                                              ] !== "Move to Godown" &&
                                                this.state[
                                                  `${details.po_item}Com`
                                                ]) ||
                                              this.state[
                                                `${details.po_item}Status`
                                              ] === "Move to Godown"
                                            ) {
                                              if (
                                                (this.state[
                                                  `${details.po_item}Status`
                                                ] !== "Move to Godown" &&
                                                  this.state[
                                                    `${details.po_item}Cus`
                                                  ]) ||
                                                this.state[
                                                  `${details.po_item}Status`
                                                ] === "Move to Godown"
                                              ) {
                                                if (
                                                  this.state[
                                                    `${details.po_item}Status`
                                                  ] === "Continue to Shipping"
                                                ) {
                                                  // moveShippingArr.concat(this.state[`${details.po_item}arrpfShip`]);
                                                  this.state[
                                                    `${details.po_item}arrpfShip`
                                                  ].map((s) => {
                                                    moveShippingArr.push(s);
                                                    return s;
                                                  });
                                                }
                                                packaging.map((sPack) => {
                                                  const dispatchdetail =
                                                    filterData(
                                                      details[
                                                        "dispatch_packaging"
                                                      ],
                                                      "id",
                                                      sPack.dispatch_packaging,
                                                      "onlyOne"
                                                    )
                                                      ? filterData(
                                                          details[
                                                            "dispatch_packaging"
                                                          ],
                                                          "id",
                                                          sPack.dispatch_packaging,
                                                          "onlyOne"
                                                        )
                                                      : "";

                                                  returned_bags_of_packgings[
                                                    dispatchdetail.packaging
                                                  ] = this.state[
                                                    `${sPack.dispatch_packaging}Returned`
                                                  ]
                                                    ? this.state[
                                                        `${sPack.dispatch_packaging}Returned`
                                                      ]
                                                    : 0;
                                                  const newObjForPack = {};
                                                  newObjForPack[
                                                    "dispatch_packaging"
                                                  ] = dispatchdetail.id;

                                                  newObjForPack[
                                                    "arrival_item"
                                                  ] = sPack.arrival_item;
                                                  newObjForPack.packaging =
                                                    dispatchdetail.packaging;

                                                  newObjForPack[
                                                    "returned_bags"
                                                  ] = this.state[
                                                    `${sPack.dispatch_packaging}Returned`
                                                  ]
                                                    ? this.state[
                                                        `${sPack.dispatch_packaging}Returned`
                                                      ]
                                                    : 0;
                                                  newObjForPack["godown"] =
                                                    this.state[
                                                      `${details.po_item}Godown`
                                                    ] &&
                                                    this.state[
                                                      `${details.po_item}Status`
                                                    ] === "Move to Godown"
                                                      ? this.state[
                                                          `${details.po_item}GodownId`
                                                        ]
                                                      : null;
                                                  newObjForPack.id = sPack.id;
                                                  singlePack.push(
                                                    newObjForPack
                                                  );
                                                  newPackagingArr.push(
                                                    newObjForPack
                                                  );

                                                  return sPack;
                                                });
                                                if (
                                                  singlePack.length ===
                                                  packaging.length
                                                ) {
                                                  // if (this.state[`${details.po_item}Remark`]) {

                                                  if (
                                                    this.state[
                                                      `${details.po_item}Status`
                                                    ] !== "Move to Godown" ||
                                                    this.state[
                                                      `${details.po_item}Godown`
                                                    ]
                                                  ) {
                                                    const newObj = {};

                                                    newObj["dispatch_items"] =
                                                      sItem.dispatch_items;
                                                    newObj["arrival"] = Number(
                                                      sItem.arrival
                                                    );

                                                    newObj["remark"] =
                                                      this.state[
                                                        `${details.po_item}Remark`
                                                      ];
                                                    // if (
                                                    //   this.state[`${details.po_item}Godown`] &&
                                                    //   this.state[`${details.po_item}Status`] === "Move to Godown"
                                                    // ) {
                                                    newObj["godown"] =
                                                      this.state[
                                                        `${details.po_item}Godown`
                                                      ] &&
                                                      this.state[
                                                        `${details.po_item}Status`
                                                      ] === "Move to Godown"
                                                        ? this.state[
                                                            `${details.po_item}GodownId`
                                                          ]
                                                        : null;
                                                    // }
                                                    newObj[
                                                      "returned_bags_of_packgings"
                                                    ] =
                                                      returned_bags_of_packgings;
                                                    newObj["returned_bags"] =
                                                      singlePack.reduce(
                                                        (
                                                          previousValue,
                                                          currentValue
                                                        ) =>
                                                          Number(
                                                            previousValue
                                                          ) +
                                                          Number(
                                                            currentValue.returned_bags
                                                              ? currentValue.returned_bags
                                                              : 0
                                                          ),
                                                        0
                                                      );
                                                    newObj.id = sItem.id;
                                                    newObj.port =
                                                      singlelistView.id;
                                                    newObj.arrival_status =
                                                      "reached";

                                                    newObj[
                                                      "arrival_item_status"
                                                    ] =
                                                      this.state[
                                                        `${details.po_item}Status`
                                                      ] === "Move to Godown"
                                                        ? "godown"
                                                        : "shipped";
                                                    newObj["return_status"] =
                                                      newObj["returned_bags"]
                                                        ? newObj[
                                                            "returned_bags"
                                                          ] ===
                                                          Number(
                                                            details.total_bags
                                                          )
                                                          ? "full_return"
                                                          : "semi_returned"
                                                        : "semi_returned";
                                                    newArr.push(newObj);
                                                  } else {
                                                    const errorMsg =
                                                      this.state.errorMsg;
                                                    errorMsg[
                                                      `${details.po_item}Godown`
                                                    ] = `Please add Godown !`;
                                                    return this.setState({
                                                      errorMsg,
                                                    });
                                                  }

                                                  // } else {
                                                  //   const errorMsg = this.state.errorMsg;
                                                  //   errorMsg[`${details.po_item}Remark`] = true;

                                                  //   return this.setState({ errorMsg });
                                                  // }
                                                }
                                              } else {
                                                this.setState({
                                                  errorMsg: {
                                                    ...errorMsg,
                                                    [`${details.po_item}Cus`]: true,
                                                  },
                                                });
                                              }
                                            } else {
                                              this.setState({
                                                errorMsg: {
                                                  ...errorMsg,
                                                  [`${details.po_item}Com`]: true,
                                                },
                                              });
                                            }
                                          } else {
                                            this.setState({
                                              errorMsg: {
                                                ...errorMsg,
                                                [`${details.po_item}Sales`]: true,
                                              },
                                            });
                                          }
                                        } else {
                                          const errorMsg = this.state.errorMsg;
                                          errorMsg[
                                            `${details.po_item}Status`
                                          ] = true;
                                          return this.setState({ errorMsg });
                                        }
                                        return sItem;
                                      });
                                      if (
                                        newArr.length ===
                                        PortDispatchedItemList.length
                                      ) {
                                        this.props
                                          .PortsFunction(
                                            "put",
                                            "arrival/items/bulk-update",
                                            null,
                                            "post",
                                            newArr
                                          )
                                          .then((res) => {
                                            if (res.error) {
                                              this.setState({
                                                error:
                                                  typeof res.data === "string"
                                                    ? res.data
                                                    : res.data.length > 0
                                                    ? res.data
                                                        .map((s) => {
                                                          return `${
                                                            s.field
                                                              ? `${s.field} :`
                                                              : ""
                                                          } ${
                                                            typeof s.message ===
                                                            "string"
                                                              ? s.message
                                                              : s.message
                                                                  .length > 0 &&
                                                                s.message[0] &&
                                                                s.message[0]
                                                          }`;
                                                        })
                                                        .join(" ,")
                                                    : res.data,
                                                loadingDialog: false,
                                              });
                                            } else {
                                              this.props
                                                .PortsFunction(
                                                  "put",
                                                  "arrival/packaging/bulk-update",
                                                  null,
                                                  "post",
                                                  newPackagingArr
                                                )
                                                .then((res2) => {
                                                  if (res2.error) {
                                                    this.setState({
                                                      error:
                                                        typeof res2.data ===
                                                        "string"
                                                          ? res2.data
                                                          : res2.data.length > 0
                                                          ? res2.data
                                                              .map((s) => {
                                                                return `${
                                                                  s.field
                                                                    ? `${s.field} :`
                                                                    : ""
                                                                } ${
                                                                  typeof s.message ===
                                                                  "string"
                                                                    ? s.message
                                                                    : s.message
                                                                        .length >
                                                                        0 &&
                                                                      s
                                                                        .message[0] &&
                                                                      s
                                                                        .message[0]
                                                                }`;
                                                              })
                                                              .join(" ,")
                                                          : res2.data,
                                                      loadingDialog: false,
                                                    });
                                                  } else {
                                                    const newData = {
                                                      arrival_status: "reached",

                                                      arrival_date: moment(
                                                        this.state.arrival_date
                                                      ).format("YYYY-MM-DD"),
                                                      gross_weight:
                                                        Number(this.state.gross_weight).toFixed(2)??0,
                                                      net_weight:
                                                        Number(this.state.net_weight).toFixed(2)??0,

                                                      tare_weight:
                                                        this.state.tare_weight,
                                                        unloading_bags_number:
                                                        newArr.reduce(
                                                          (
                                                            previousValue,
                                                            currentValue
                                                          ) =>
                                                            Number(
                                                              previousValue
                                                            ) +
                                                            currentValue.returned_bags
                                                              ? Number(
                                                                  currentValue.returned_bags
                                                                )
                                                              : 0,
                                                          0
                                                        ),
                                                      total_bags: 100,
                                                      survey_company:
                                                        this.state
                                                          .surveyorCompanyId,
                                                      surveyor: this.state
                                                        .surveyorId
                                                        ? this.state.surveyorId
                                                        : null,
                                                      unloading_agent:
                                                        singlelistView.unloading_agent,
                                                        // unloading_bags_number:this.state
                                                        // .unloading_bags_number
                                                        // ? this.state.unloading_bags_number
                                                        // : null,

                                                      quality_deduction:
                                                        Number(
                                                          this.state
                                                            .quality_deduction
                                                        ).toFixed(2) ?? 0,
                                                    };
                                                    if (
                                                      this.state
                                                        .singleDispatch !==
                                                        false &&
                                                      this.state.singleDispatch
                                                        ?.dispatch_details
                                                        ?.purchase_order_details
                                                        ?.contract_type_details
                                                        ?.contract_type !==
                                                        "FOR"
                                                    ) {
                                                      newData.freight_rate =
                                                        Number(
                                                          this.state
                                                            .freight_rate
                                                        ).toFixed(2) ?? 0;
                                                      newData.freight_deduction =
                                                        Number(
                                                          this.state
                                                            .freight_deduction
                                                        ).toFixed(2) ?? 0;
                                                    } else {
                                                      newData.miller_bill_amount =
                                                        Number(
                                                          this.state
                                                            .miller_bill_amount
                                                        ).toFixed(2) ?? 0;

                                                      newData.miller_tds =
                                                        Number(
                                                          this.state.miller_tds
                                                        ).toFixed(2) ?? 0;

                                                      newData.miller_bill_weight =
                                                        Number(
                                                          this.state
                                                            .miller_bill_weight
                                                        ).toFixed(2) ?? 0;

                                                      newData.miller_gst_charge =
                                                        Number(
                                                          this.state
                                                            .miller_gst_charge
                                                        ).toFixed(2) ?? 0;

                                                      newData.miller_advance =
                                                        Number(
                                                          this.state
                                                            .miller_advance
                                                        ).toFixed(2) ?? 0;

                                                      newData.miller_total_amount =
                                                        Number(
                                                          this.state
                                                            .miller_total_amount
                                                        ).toFixed(2) ?? 0;

                                                      newData.brokerage_rate_for_bri =
                                                        Number(
                                                          this.state
                                                            .brokerage_rate_for_bri
                                                        ).toFixed(2) ?? 0;

                                                      newData.brokerage_amount_for_bri =
                                                        Number(
                                                          this.state
                                                            .brokerage_amount_for_bri
                                                        ).toFixed(2) ?? 0;

                                                      newData.brokerage_rate_for_miller =
                                                        Number(
                                                          this.state
                                                            .brokerage_rate_for_miller
                                                        ).toFixed(2) ?? 0;

                                                      newData.brokerage_amount_for_miller =
                                                        Number(
                                                          this.state
                                                            .brokerage_amount_for_miller
                                                        ).toFixed(2) ?? 0;
                                                    }
                                                    this.props
                                                      .PortsFunction(
                                                        "patch",
                                                        "arrival",
                                                        newArr[0].arrival,
                                                        "post",
                                                        newData
                                                      )
                                                      .then((res3) => {
                                                        if (res3.error) {
                                                          this.setState({
                                                            error: res3.data,
                                                            loadingDialog: false,
                                                          });
                                                        } else {
                                                          if (
                                                            moveShippingArr.length >
                                                            0
                                                          ) {
                                                            this.props
                                                              .PortsFunction(
                                                                "post",
                                                                "arrival/commercial-invoice/packing-list/bulk-create",
                                                                null,
                                                                "post",
                                                                moveShippingArr
                                                              )
                                                              .then((res4) => {
                                                                if (
                                                                  res4.error
                                                                ) {
                                                                  this.setState(
                                                                    {
                                                                      error:
                                                                        res4.data,
                                                                      loadingDialog: false,
                                                                    }
                                                                  );
                                                                } else {
                                                                  this.handleClear();
                                                                  this.fetchDispatchList(
                                                                    editArrival
                                                                      ? "Reached"
                                                                      : "Arrival"
                                                                  );
                                                                  this.setState(
                                                                    {
                                                                      success:
                                                                        "All items are updated!",
                                                                      loadingDialog: false,
                                                                    }
                                                                  );
                                                                }
                                                              });
                                                          } else {
                                                            this.handleClear();

                                                            this.fetchDispatchList(
                                                              editArrival
                                                                ? "Reached"
                                                                : "Arrival"
                                                            );
                                                            this.setState({
                                                              success:
                                                                "All items are updated!",
                                                              loadingDialog: false,
                                                            });
                                                          }
                                                        }
                                                      });
                                                  }
                                                });
                                            }
                                          });
                                      } else {
                                        this.setState({ loadingDialog: false });
                                      }
                                    } else {
                                      const errorMsg = this.state.errorMsg;
                                      errorMsg.freight_deduction = true;

                                      return this.setState({ errorMsg });
                                    }
                                  } else {
                                    const errorMsg = this.state.errorMsg;
                                    errorMsg.freight_rate = true;

                                    return this.setState({ errorMsg });
                                  }
                                } else {
                                  const errorMsg = this.state.errorMsg;
                                  errorMsg.quality_deduction = true;

                                  return this.setState({ errorMsg });
                                }
                              } else {
                                const errorMsg = this.state.errorMsg;
                                errorMsg.surveyorCompany = true;

                                return this.setState({ errorMsg });
                              }
                            } else {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.net_weight = true;

                              return this.setState({ errorMsg });
                            }
                          } else {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.tare_weight = true;

                            return this.setState({ errorMsg });
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.gross_weight = true;

                          return this.setState({ errorMsg });
                        }
                      } else {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.arrival_date = true;

                        return this.setState({ errorMsg });
                      }
                    }
                  }}
                />
              </div>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}

        {portDialog === true || editList !== false ? (
          <CreateDialogForPort
            tableData={() => {
              return (
                <>
                  <div>
                    <div style={{ margin: "10px 0" }}>
                      <span style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Add Godown{" "}
                      </span>
                    </div>
                    <div style={{ width: "100%" }}>
                      {New.map((godownName, i) => {
                        return (
                          <>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              <CssTextField
                                required={false}
                                type="text"
                                autoComplete="off"
                                style={
                                  {
                                    // width: "calc(50% - 20px)",
                                    marginTop: "15px",
                                    // display: "flex",
                                  } //disabled={tp}
                                }
                                // error={errorMsg.Godown ? true : false}
                                // helperText={
                                //   errorMsg.Godown ? errorMsg.Godown : ""
                                // }
                                id="outlined-basic"
                                label="Godown Name"
                                variant="outlined"
                                name="Godown"
                                value={godownName ? godownName.name : ""}
                                onChange={(e) => {
                                  this.handleChange1(i, e);
                                }}
                              />
                              <div style={{ display: "flex" }}>
                                <DeleteIcon
                                  color="error"
                                  style={{
                                    marginLeft: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (!godownName.id)
                                      this.deleteItem(godownName);
                                    else
                                      this.setState({
                                        deleteDialog: godownName,
                                      });
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}

                      {editList && deleteDialog !== false ? (
                        <Dialog
                          open={true}
                          onClose={() => this.handleClose(false)}
                          aria-labelledby="confirm-dialog">
                          <DialogTitle id="confirm-dialog">
                            Make Changes ?
                          </DialogTitle>
                          <DialogContent>
                            Are you sure you want to delete this Godown ?
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="contained"
                              onClick={this.handleClose}
                              style={{
                                backgroundColor: "rgb(241 154 48)",
                                color: "white",
                              }}>
                              No
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                this.props
                                  .PortsFunction(
                                    "delete",
                                    "masters/godown",
                                    deleteDialog && deleteDialog.id,
                                    "postdata",
                                    null
                                  )
                                  .then((res3) => {
                                    if (res3.error) {
                                      this.setState({
                                        error: res3.data,
                                        loading: false,
                                      });
                                    } else {
                                      this.setState(
                                        {
                                          success: editList
                                            ? `${module} Edited`
                                            : "",
                                          deleteDialog: false,
                                        },
                                        () => {
                                          this.props
                                            .PortsFunction(
                                              "get",
                                              EndPoints["Ports"]
                                            )
                                            .then((res) => {
                                              if (
                                                res &&
                                                res.length > 0 &&
                                                singlelistView &&
                                                singlelistView.id
                                              ) {
                                                res.map((s) => {
                                                  if (
                                                    s.id === singlelistView.id
                                                  ) {
                                                    this.setState({
                                                      singlelistView: s,
                                                      New:
                                                        s.godown_details &&
                                                        s.godown_details
                                                          .length > 0
                                                          ? s.godown_details
                                                          : [""],
                                                    });
                                                  }
                                                  return s;
                                                });
                                              }
                                            });
                                          // if (editList) {
                                          //   this.props.fetchAgain();
                                          // }
                                        }
                                      );
                                    }
                                  });
                              }}
                              style={{
                                backgroundColor: "rgb(241 154 48)",
                                color: "white",
                              }}>
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        ""
                      )}
                    </div>

                    <Button
                      style={{
                        justifyContent: "left",
                        border: "1px solid rgb(30 53 89)",
                        padding: "3px 10px",
                        margin: "15px 0",
                      }}
                      onClick={this.addClick}>
                      add Godown
                    </Button>
                  </div>
                </>
              );
            }}
            textFieldWidth="calc(50% - 20px)"
            heightNew="500px"
            maxHeight="calc(100vh - 40px)"
            widthNew="900px"
            // onEditUpdate={(res) => {
            // if(res) { this.setState({ singlelistView: res,})}
            // }}
            // addFieldsToAdd={[{ name: "is_active", value: true }]}=
            module={"Port"}
            selectedList={false}
            editList={editList ? editList : false}
            reset={() => {
              this.setState({
                loadingFun: false,
                editList: false,
                New: [""],
              });
            }}
            handleClose={() => {
              this.setState({
                portDialog: false,
                loadingFun: false,
                editList: false,
                New: [""],
              });
            }}
            // settError={() => {
            //   this.setState({
            //     errorMsg: { ...errorMsg, Godown: "Please add godown" },
            //   });
            // }}
            postApi="masters/port/list"
            tableValueDetailArray={New}
            editApi="masters/port"
            inputArray={[
              {
                name: "name",
                type: "textField",
                dataType: "text",
                api: "name",
                defaultValue: {
                  value: editList && editList.name,
                },
              },
              {
                name: "address",
                type: "addressTextField",
                dataType: "text",
                defaultValue: {
                  value: editList && editList.address,
                },
                api: "address",
              },
              {
                name: "unloading Agent",
                type: "textField",
                dataType: "text",
                defaultValue: {
                  value: editList && editList.unloading_agent,
                },
                api: "unloading_agent",
              },

              {
                name: "state",
                type: "select",
                array: stateArray,
                value: "name",
                api: "state",
                defaultValue: {
                  value: editList && editList.state,
                },
              },
              {
                name: "city",
                type: "textField",
                dataType: "text",
                api: "city",
                defaultValue: {
                  value: editList && editList.city,
                },
              },
              {
                name: "pincode",
                type: "textField",
                onInput: "pincode",
                dataType: "number",
                api: "pincode",
                only_number: true,
                defaultValue: {
                  value: editList && editList.pincode,
                },
              },
            ]}
            postData={this.props.PortsFunction}
            fetchAgain={(msg) => {
              this.setState(
                {
                  portDialog: false,
                  loadingFun: false,
                  editList: false,
                  New: [""],
                },
                () => {
                  this.setState({ success: msg });
                }
              );
              this.props
                .PortsFunction("get", EndPoints["Ports"])
                .then((res) => {
                  if (
                    res &&
                    res.length > 0 &&
                    singlelistView &&
                    singlelistView.id
                  ) {
                    res.map((s) => {
                      if (s.id === singlelistView.id) {
                        this.setState({ singlelistView: s });
                      }
                      return s;
                    });
                  }
                });
            }}
          />
        ) : (
          ""
        )}
        {error || success ? (
          <SnackbarComponent
            error={error}
            autoHideDuration={error ? 5000 : 1500}
            success={success}
            snackbarClose={() => {
              this.setState({
                error: "",

                success: "",
              });
            }}
          />
        ) : (
          ""
        )}
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Ports === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <TabComponent
              selectedValue={tabNumber}
              onChange={(newVal) => {
                const selctedTab =
                  newVal === 4
                    ? "Shipping"
                    : newVal === 2
                    ? "Arrival"
                    : newVal === 3
                    ? "Inventory"
                    : newVal === 4 && "port Details";
                this.setState({ selctedTab, tabNumber: newVal }, () => {
                  if (newVal === 2 || newVal === 3 || newVal === 4) {
                    this.fetchDispatchList(
                      newVal === 2
                        ? "Arrival"
                        : newVal === 4
                        ? "Shipping"
                        : newVal === 3 && "Inventory"
                    );
                  }
                });
              }}
              list={[
                {
                  name: "Custom Invoices",
                  component: (
                    <CustomInvoice
                      {...this.state}
                      {...this.props}
                      PortsFunction={this.props.PortsFunction}
                      changeTab={(tab) => {
                        this.setState({ tabNumber: tab }, () => {
                          if (tab === 2 || tab === 3) {
                            this.fetchDispatchList(
                              tab === 2 ? "Arrival" : tab === 3 && "Inventory"
                            );
                          }
                        });
                      }}
                    />
                  ),
                },
                {
                  name: "Commercial Invoices",
                  component: (
                    <CommercialiInvoiceStepper
                      {...this.state}
                      {...this.props}
                      PortsFunction={this.props.PortsFunction}
                      changeTab={(tab) => {
                        this.setState({ tabNumber: tab }, () => {
                          if (tab === 2 || tab === 3) {
                            this.fetchDispatchList(
                              tab === 2 ? "Arrival" : tab === 3 && "Inventory"
                            );
                          }
                        });
                      }}
                    />
                  ),
                },

                {
                  name: "Arrival",
                  component: (
                    <TabComponent
                      onChange={(newVal) => {
                        const selctedTab2 =
                          newVal === 0
                            ? "Intransit"
                            : newVal === 1 && "Reached";
                        this.setState({ selctedTab2 }, () => {
                          if (newVal === 1 || newVal === 0) {
                            this.fetchDispatchList(
                              newVal === 0
                                ? "Intransit"
                                : newVal === 1 && "Reached"
                            );
                          }
                        });
                      }}
                      list={[
                        {
                          name: "Intransit",
                          component: (
                            <TableComponent
                              module={"Intransit"}
                              actions={[]}
                              {...this.state}
                              noAddButton={true}
                              loader={loadingDispatch === true ? true : false}
                              // bottomBar={true}
                              subMenu={true}
                              subMenuheader={[
                                "Sr.No.",
                                "Brand",
                                "Item Category",
                                "Item Type",
                                "Packaging",
                              ]}
                              header={[
                                "Sr.No.",
                                "Action",
                                "Dispatch Date",
                                "Purchase Order",
                                "Sales Order",
                                "Miller",
                                "Miller City",
                                "Truck Number",
                              ]}
                              tableList={
                                PortDispatchedList && PortDispatchedList.length
                                  ? PortDispatchedList.map((singleDispatch) => {
                                      singleDispatch["Dispatch Date"] = moment(
                                        singleDispatch.created_at
                                      ).format("ddd, MMM Do YYYY , h:mm A");
                                      singleDispatch["Action"] = () => {
                                        return (
                                          <Button
                                            // fontSize="large"
                                            style={{
                                              cursor: "pointer",
                                              backgroundColor:
                                                "rgb(241 154 48)",
                                              color: "white",
                                              fontSize: "small",
                                            }}
                                            withBg={true}
                                            // name="Mark as reached"
                                            onClick={() => {
                                              // this.props
                                              // .PortsFunction(
                                              //   "get",
                                              //   "arrival/list",
                                              //   null,
                                              //   //this.state.singlelistView.id,
                                              //   "PortDispatchedListPre",
                                              //   null,
                                              //   {
                                              //     arrival_id:
                                              //       singleDispatch.id,
                                              //   }
                                              // )
                                              // .then(
                                              //   (PortDispatchedListPre) => {
                                              //     {console.log(PortDispatchedListPre,"lllllllll")}
                                              //     if (
                                              //       PortDispatchedListPre
                                              //     ) {
                                              //       this.setState(
                                              //         {
                                              //           PortDispatchedListPre,
                                              //           loadingDialog: false,
                                              //         },
                                              //         // () => {
                                              //         //   this.setWidth(
                                              //         //     PortDispatchedItemList,
                                              //         //     portUnreachedheader
                                              //         //   );
                                              //         // }
                                              //       );
                                              //     } else {
                                              //       this.setState({

                                              //         PortDispatchedItemList:
                                              //           [],
                                              //         loadingDialog: false,
                                              //       });
                                              //     }
                                              //   }
                                              // );
                                              console.log(singleDispatch, "");
                                              this.props
                                                .PortsFunction(
                                                  "get",
                                                  "arrival/items/list",
                                                  null,
                                                  //this.state.singlelistView.id,
                                                  "PortDispatchedList",
                                                  null,
                                                  {
                                                    arrival_id:
                                                      singleDispatch.id,
                                                  }
                                                )
                                                .then(
                                                  (PortDispatchedItemList) => {
                                                    if (
                                                      PortDispatchedItemList
                                                    ) {
                                                      this.setState(
                                                        {
                                                          PortDispatchedItemList,
                                                          loadingDialog: false,
                                                          singleDispatch:
                                                            singleDispatch,
                                                        },
                                                        () => {
                                                          this.setWidth(
                                                            PortDispatchedItemList,
                                                            portUnreachedheader
                                                          );
                                                        }
                                                      );
                                                    } else {
                                                      this.setState({
                                                        PortDispatchedItemList:
                                                          [],

                                                        singleDispatch: false,
                                                        loadingDialog: false,
                                                      });
                                                    }
                                                  }
                                                );
                                              this.setState({
                                                ArrivalDialog: true,
                                                loadingDialog: true,
                                              });
                                              this.handleClear();
                                            }}>
                                            Mark Reached
                                          </Button>
                                          // <Tooltip title="Mark As Reached">
                                          //   <PublishedWithChangesIcon
                                          //     fontSize="large"
                                          //     style={{ cursor: "pointer" }}
                                          //     withBg={true}
                                          //     // name="Mark as reached"
                                          //     onClick={() => {
                                          //       this.props
                                          //         .PortsFunction(
                                          //           "get",
                                          //           "arrival/items/list",
                                          //           null,
                                          //           //this.state.singlelistView.id,
                                          //           "PortDispatchedList",
                                          //           null,
                                          //           {
                                          //             arrival_id: singleDispatch.id,
                                          //           }
                                          //         )
                                          //         .then((PortDispatchedItemList) => {
                                          //           if (PortDispatchedItemList) {
                                          //             this.setState(
                                          //               {
                                          //                 PortDispatchedItemList,
                                          //               },
                                          //               () => {
                                          //                 this.setWidth(PortDispatchedItemList, portUnreachedheader);
                                          //               }
                                          //             );
                                          //           }
                                          //         });
                                          //       this.setState({
                                          //         ArrivalDialog: true,
                                          //       });
                                          //       this.handleClear();
                                          //     }}
                                          //   />
                                          // </Tooltip>
                                        );
                                      };

                                      singleDispatch["Purchase Order"] =
                                        singleDispatch.arrival_table_details.purchase_order;
                                      singleDispatch["Sales Order"] =
                                        singleDispatch.arrival_table_details.sales_order;

                                      singleDispatch["Truck Number"] =
                                        singleDispatch.arrival_table_details.truck_number;

                                      singleDispatch["Miller"] =
                                        singleDispatch.arrival_table_details.miller;
                                      singleDispatch["Miller City"] =
                                        singleDispatch.arrival_table_details.city;

                                      singleDispatch["subList"] =
                                        singleDispatch.subList
                                          ? singleDispatch.subList.map(
                                              (singleSub) => {
                                                singleSub["Brand"] = filterData(
                                                  BrandList,
                                                  "id",
                                                  singleSub.brand,
                                                  "onlyOne"
                                                )
                                                  ? filterData(
                                                      BrandList,
                                                      "id",
                                                      singleSub.brand,
                                                      "onlyOne"
                                                    ).brand_name
                                                  : "";

                                                singleSub["Item Category"] =
                                                  filterData(
                                                    ItemCateoryList,
                                                    "id",
                                                    singleSub.item_category,
                                                    "onlyOne"
                                                  )
                                                    ? filterData(
                                                        ItemCateoryList,
                                                        "id",
                                                        singleSub.item_category,
                                                        "onlyOne"
                                                      ).name
                                                    : "";
                                                singleSub["Item Type"] =
                                                  filterData(
                                                    ItemList,
                                                    "id",
                                                    singleSub.item_type,
                                                    "onlyOne"
                                                  )
                                                    ? `${Number(
                                                        filterData(
                                                          ItemList,
                                                          "id",
                                                          singleSub.item_type,
                                                          "onlyOne"
                                                        ).broken_percentage
                                                      ).toFixed(2)} %`
                                                    : "";

                                                singleSub["PackagingUI"] = (
                                                  <ShowPackagingPort
                                                    noTotalbags={true}
                                                    details={singleSub.dispatch_packaging.map(
                                                      (sP) => {
                                                        const singlePack =
                                                          sP.packaging_details;
                                                        singlePack.used_bags =
                                                          sP.total_bags_dispatched;

                                                        return singlePack;
                                                      }
                                                    )}
                                                  />
                                                );
                                                singleSub["PackagingUIPdf"] =
                                                  singleSub.dispatch_packaging &&
                                                  singleSub.dispatch_packaging
                                                    .length > 0
                                                    ? singleSub.dispatch_packaging
                                                        .map((sP) => {
                                                          const singlePack =
                                                            sP.packaging_details;

                                                          return singlePack
                                                            ? `${
                                                                singlePack.size
                                                              }${
                                                                singlePack.unit
                                                              }${" - "}${
                                                                singlePack.bag_type
                                                              }`
                                                            : "";
                                                        })
                                                        .join(", ")
                                                    : "-";
                                                singleSub["Packaging"] =
                                                  singleSub.dispatch_packaging &&
                                                  singleSub.dispatch_packaging
                                                    .length > 0
                                                    ? singleSub.dispatch_packaging
                                                        .map((sP) => {
                                                          const singlePack =
                                                            sP.packaging_details;

                                                          return singlePack
                                                            ? `${
                                                                singlePack.size
                                                              }${
                                                                singlePack.unit
                                                              }${" - "}${
                                                                singlePack.bag_type
                                                              }`
                                                            : "";
                                                        })
                                                        .join(", ")
                                                    : "-";
                                                return singleSub;
                                              }
                                            )
                                          : [];
                                      // singleDispatch["Dispatch DateMonth"] = singleDispatch.dispatch_date;

                                      // singleDispatch["Gross Weight"] = singleDispatch.gross_weight;
                                      // ? singleDispatch
                                      //     .gross_weight.name
                                      // : "-";

                                      // singleDispatch["Tare Weight"] = singleDispatch.net_weight;

                                      // singleDispatch["Survey Company"] =
                                      //   singleDispatch.survey_company &&
                                      //   filterData(SurveyCompanyList, "id", singleDispatch.survey_company, "onlyOne")
                                      //     ? filterData(
                                      //         SurveyCompanyList,
                                      //         "id",
                                      //         singleDispatch.survey_company,
                                      //         "onlyOne"
                                      //       ).name
                                      //     : "";

                                      // singleDispatch["Surveyor"] =
                                      //   singleDispatch.surveyor &&
                                      //   filterData(allsurveyorList, "id", singleDispatch.surveyor, "onlyOne")
                                      //     ? filterData(allsurveyorList, "id", singleDispatch.surveyor, "onlyOne").name
                                      //     : "";
                                      return singleDispatch;
                                    })
                                      .sort((a, b) => {
                                        var dateB = moment(b.created_at).format(
                                          "MMM D YYYY h:mm:ss A"
                                        );
                                        var dateA = moment(a.created_at).format(
                                          "MMM D YYYY h:mm:ss A"
                                        );

                                        return (
                                          new Date(dateB) - new Date(dateA)
                                        );
                                      })
                                      .filter(
                                        (s) =>
                                          s.arrival_table_details.port ===
                                          singlelistView.name
                                      )
                                      .filter(
                                        (s) => s.arrival_status === "in_transit"
                                      )
                                  : []
                              }
                              {...this.props}
                            />
                          ),
                        },
                        {
                          name: "Reached",
                          component: (
                            <TableComponent
                              module={"Reached"}
                              noAddButton={true}
                              subMenu={true}
                              subMenuheader={[
                                "Sr.No.",
                                "Arrival Status",
                                "Brand",
                                "Item Category",
                                "Item Type",
                                "Packaging",
                              ]}
                              {...this.state}
                              loader={loadingDispatch === true ? true : false}
                              actions={[]}
                              header={[
                                "Sr.No.",
                                //"Edit",
                                "Arrival Date",
                                // "Dispatched From",
                                // "Dispatched To",
                                // "Dispatch Weight",
                                // "Contract Type",
                                // "Miller Bill Number",
                                // "Miller Bill Amount",
                                // "Miller Tcs",
                                // "Miller Advance",
                                // "Miller Total Amount",
                                // "Purchase Order",
                                // "Sales Order",
                                // "Transporter",
                                // "Truck",
                                // "Bilty Number",
                                // "Bilty Date",
                                "Gross Weight",
                                "Tare Weight",
                                "Net Weight",
                                "Total Bags",
                                "Returned Bags",
                                // "Freight Rate",
                                // "Total Freight",
                                // "Transporter Advance",
                                // "Transporter Balance",
                                "Survey Company",
                                "Surveyor",
                                "Unloading Agent",
                                //"Dispatch Status",
                                // "Miller Payment",
                                // "Transporter Payment",
                                // "Broker Payment",
                              ]}
                              tableList={
                                // PortDispatchedList && PortDispatchedList.length
                                //   ? PortDispatchedList.map((singleDispatch) => {
                                //       singleDispatch["Arrival Date"] = moment(
                                //         singleDispatch.arrival_date
                                //       ).format("ddd, MMM Do YYYY");

                                //       singleInventory["Date"] = moment(singleInventory.created_at).format("ddd, MMM Do YYYY");

                                PortDispatchedList && PortDispatchedList.length
                                  ? PortDispatchedList.map((singleDispatch) => {
                                      singleDispatch["Arrival Date"] = moment(
                                        singleDispatch.arrival_datetime
                                      ).format("ddd, MMM Do YYYY ,h:mm A");
                                      // singleDispatch["Arrival Date"] = moment(
                                      //   singleDispatch.arrival_time
                                      // ).format("h:mm:ss a");

                                      // singleDispatch["Edit"] = () => {
                                      //   return (
                                      //     <ModeEditIcon
                                      //       fontSize="medium"
                                      //       style={{ cursor: "pointer" }}
                                      //       withBg={true}
                                      //       // name="Mark as reached"
                                      //       onClick={() => {
                                      //         this.props
                                      //           .PortsFunction(
                                      //             "get",
                                      //             "arrival/items/list",
                                      //             null,
                                      //             //this.state.singlelistView.id,
                                      //             "PortDispatchedList",
                                      //             null,
                                      //             {
                                      //               arrival_id:
                                      //                 singleDispatch.id,
                                      //             }
                                      //           )
                                      //           .then(
                                      //             (PortDispatchedItemList) => {
                                      //               if (
                                      //                 PortDispatchedItemList
                                      //               ) {
                                      //                 if (
                                      //                   SurveyCompanyList &&
                                      //                   SurveyCompanyList.length >
                                      //                     0
                                      //                 ) {
                                      //                   this.props
                                      //                     .PortsFunction(
                                      //                       "get",
                                      //                       "masters/surveyor/list",
                                      //                       null,
                                      //                       "",
                                      //                       null,
                                      //                       {
                                      //                         company_id:
                                      //                           singleDispatch.survey_company,
                                      //                       }
                                      //                     )
                                      //                     .then((res) => {
                                      //                       if (res) {
                                      //                         this.setState({
                                      //                           surveyorListForSurvay:
                                      //                             res,
                                      //                         });
                                      //                       }
                                      //                     });
                                      //                 }
                                      //                 this.setState(
                                      //                   {
                                      //                     PortDispatchedItemList,
                                      //                     arrival_date:
                                      //                       singleDispatch.arrival_date,
                                      //                     gross_weight:
                                      //                       singleDispatch.gross_weight,
                                      //                     net_weight:
                                      //                       singleDispatch.net_weight,
                                      //                     tare_weight:
                                      //                       singleDispatch.tare_weight,
                                      //                     surveyorCompany:
                                      //                       singleDispatch[
                                      //                         "Survey Company"
                                      //                       ],
                                      //                     surveyor:
                                      //                       singleDispatch[
                                      //                         "Surveyor"
                                      //                       ],
                                      //                     surveyorCompanyId:
                                      //                       singleDispatch.survey_company,
                                      //                     surveyorId:
                                      //                       singleDispatch.surveyor,
                                      //                     // unloading_agent: singleDispatch.unloading_agent,

                                      //                     unloading_agent:
                                      //                       singlelistView.unloading_agent,
                                      //                   },
                                      //                   () => {
                                      //                     if (
                                      //                       PortDispatchedItemList.length >
                                      //                       0
                                      //                     ) {
                                      //                       PortDispatchedItemList.map(
                                      //                         (sItem) => {
                                      //                           const details =
                                      //                             sItem.dispatch_items_details;
                                      //                           const packaging =
                                      //                             sItem.arrival_packagings; //details.dispatch_packaging//

                                      //                           packaging.map(
                                      //                             (sPack) => {
                                      //                               this.setState(
                                      //                                 {
                                      //                                   [`${sPack.dispatch_packaging}Returned`]:
                                      //                                     sPack.returned_bags &&
                                      //                                     sPack.returned_bags
                                      //                                       ? sPack.returned_bags
                                      //                                       : false,
                                      //                                 },
                                      //                                 () => {}
                                      //                               );
                                      //                               return sPack;
                                      //                             }
                                      //                           );

                                      //                           this.setState({
                                      //                             [`${details.po_item}Status`]:
                                      //                               sItem.arrival_item_status ===
                                      //                               "godown"
                                      //                                 ? "Move to Godown"
                                      //                                 : "Continue to Shipping",
                                      //                             [`${details.po_item}Godown`]:
                                      //                               sItem.godown &&
                                      //                               sItem.godown
                                      //                                 ? filterData(
                                      //                                     sItem.godowns_list,
                                      //                                     "id",
                                      //                                     sItem.godown,
                                      //                                     "onlyOne"
                                      //                                   )
                                      //                                   ? filterData(
                                      //                                       sItem.godowns_list,
                                      //                                       "id",
                                      //                                       sItem.godown,
                                      //                                       "onlyOne"
                                      //                                     ).name
                                      //                                   : false
                                      //                                 : false,
                                      //                             [`${details.po_item}GodownId`]:
                                      //                               sItem.godown &&
                                      //                               sItem.godown
                                      //                                 ? sItem.godown
                                      //                                 : false,
                                      //                             [`${details.po_item}Remark`]:
                                      //                               sItem.remark &&
                                      //                               sItem.remark
                                      //                                 ? sItem.remark
                                      //                                 : false,
                                      //                           });
                                      //                           return sItem;
                                      //                         }
                                      //                       );
                                      //                     }
                                      //                     this.setWidth(
                                      //                       PortDispatchedItemList,
                                      //                       portUnreachedheader
                                      //                     );
                                      //                   }
                                      //                 );
                                      //               }
                                      //             }
                                      //           );
                                      //         this.setState({
                                      //           ArrivalDialog: true,
                                      //           editArrival: true,
                                      //         });
                                      //       }}
                                      //     />
                                      //   );
                                      // };

                                      // singleDispatch["Arrival Date"] = singleDispatch.arrival_date;

                                      singleDispatch["Gross Weight"] =
                                        singleDispatch.gross_weight;
                                      // ? singleDispatch
                                      //     .gross_weight.name
                                      // : "-";

                                      singleDispatch["Tare Weight"] =
                                        singleDispatch.tare_weight;
                                      singleDispatch["Net Weight"] =
                                        singleDispatch.net_weight;
                                      singleDispatch["Total Bags"] =
                                        singleDispatch.arrival_table_details.total_bags;
                                      singleDispatch["Returned Bags"] =
                                        singleDispatch.returned_bags
                                          ? singleDispatch.returned_bags
                                          : 0;

                                      singleDispatch["Survey Company"] =
                                        filterData(
                                          SurveyCompanyList,
                                          "id",
                                          singleDispatch.survey_company,
                                          "onlyOne"
                                        )
                                          ? filterData(
                                              SurveyCompanyList,
                                              "id",
                                              singleDispatch.survey_company,
                                              "onlyOne"
                                            ).name
                                          : "";

                                      singleDispatch["Surveyor"] =
                                        singleDispatch.surveyor
                                          ? filterData(
                                              allsurveyorList,
                                              "id",
                                              singleDispatch.surveyor,
                                              "onlyOne"
                                            )
                                            ? filterData(
                                                allsurveyorList,
                                                "id",
                                                singleDispatch.surveyor,
                                                "onlyOne"
                                              ).name
                                            : ""
                                          : "";
                                      singleDispatch["Unloading Agent"] =
                                        singleDispatch.unloading_agent;

                                      singleDispatch["subList"] =
                                        singleDispatch.subList
                                          ? singleDispatch.subList.map(
                                              (singleSub) => {
                                                singleSub["Brand"] = filterData(
                                                  BrandList,
                                                  "id",
                                                  singleSub.brand,
                                                  "onlyOne"
                                                )
                                                  ? filterData(
                                                      BrandList,
                                                      "id",
                                                      singleSub.brand,
                                                      "onlyOne"
                                                    ).brand_name
                                                  : "";

                                                singleSub["Item Category"] =
                                                  filterData(
                                                    ItemCateoryList,
                                                    "id",
                                                    singleSub.item_category,
                                                    "onlyOne"
                                                  )
                                                    ? filterData(
                                                        ItemCateoryList,
                                                        "id",
                                                        singleSub.item_category,
                                                        "onlyOne"
                                                      ).name
                                                    : "";
                                                singleSub["Item Type"] =
                                                  filterData(
                                                    ItemList,
                                                    "id",
                                                    singleSub.item_type,
                                                    "onlyOne"
                                                  )
                                                    ? `${Number(
                                                        filterData(
                                                          ItemList,
                                                          "id",
                                                          singleSub.item_type,
                                                          "onlyOne"
                                                        ).broken_percentage
                                                      ).toFixed(2)} %`
                                                    : "";
                                                singleSub["Packaging"] =
                                                  singleSub.newPack &&
                                                  singleSub.newPack.length > 0
                                                    ? `  ${
                                                        singleSub.newPack.map(
                                                          (sP) => {
                                                            const singlePack =
                                                              sP.packaging_details;

                                                            return singlePack
                                                              ? `${
                                                                  singlePack.size
                                                                }${
                                                                  singlePack.unit
                                                                }${" - "}${
                                                                  singlePack.bag_type
                                                                }`
                                                              : "";
                                                          }
                                                        )[0]
                                                      } Total (...s)`
                                                    : "-";
                                                singleSub["Arrival Status"] =
                                                  singleSub.arrival_item_status;

                                                singleSub["PackagingUI"] = (
                                                  <ShowPackagingPort
                                                    // noTotalbags={true}
                                                    // details={singleSub.dispatch_packaging.map(
                                                    //   (sP) => {
                                                    //     const singlePack =
                                                    //       sP.packaging_details;
                                                    //     singlePack.used_bags =
                                                    //       sP.total_bags_dispatched;

                                                    //     return singlePack;
                                                    //   }
                                                    // )}
                                                    noTotalbags={true}
                                                    returned_bags={true}
                                                    details={singleSub.dispatch_packaging.map(
                                                      (sP) => {
                                                        const singlePack =
                                                          sP.packaging_details;
                                                        singlePack.used_bags =
                                                          sP.total_bags_dispatched;

                                                        singlePack.returned_bags =
                                                          sP.returned_bags;
                                                        return singlePack;
                                                      }
                                                    )}
                                                  />
                                                );
                                                singleSub["PackagingUIPdf"] =
                                                  singleSub.dispatch_packaging &&
                                                  singleSub.dispatch_packaging
                                                    .length > 0
                                                    ? singleSub.dispatch_packaging
                                                        .map((sP) => {
                                                          const singlePack =
                                                            sP.packaging_details;

                                                          return singlePack
                                                            ? `${
                                                                singlePack.size
                                                              }${
                                                                singlePack.unit
                                                              }${" - "}${
                                                                singlePack.bag_type
                                                              }`
                                                            : "";
                                                        })
                                                        .join(", ")
                                                    : "-";
                                                return singleSub;
                                              }
                                            )
                                          : [];
                                      return singleDispatch;
                                    })
                                      // .sort((a, b) => b.id - a.id)
                                      .sort((a, b) => {
                                        var dateB = moment(
                                          b.arrival_datetime
                                        ).format("MMM D YYYY h:mm:ss A");
                                        var dateA = moment(
                                          a.arrival_datetime
                                        ).format("MMM D YYYY h:mm:ss A");

                                        return (
                                          new Date(dateB) - new Date(dateA)
                                        );
                                      })
                                      .filter(
                                        (s) =>
                                          s.arrival_table_details.port ===
                                          singlelistView.name
                                      )
                                      .filter(
                                        (s) => s.arrival_status === "reached"
                                      )
                                      .map((s) => {
                                        return s;
                                      })
                                  : []
                              }
                            />
                          ),
                        },
                      ]}
                    />
                  ),
                },

                {
                  name: "Inventory",
                  component:
                    loadingDispatch === true ? (
                      <div className="alignC justiyC d-flex width-100 height-100">
                        <StyledCircularProgress color="inherit" />
                      </div>
                    ) : (
                      <Inventory
                        {...this.state}
                        updateAgain={() => {
                          this.handleGodownChange(selectGodown);
                        }}
                        {...this.props}
                        filterChild={filterChild}
                        PortsFunction={this.props.PortsFunction}
                      />
                    ),
                },
                {
                  name: "Shipping",
                  component: (
                    <TableComponent
                      module={"Shipping"}
                      noAddButton={true}
                      // subMenu={true}
                      // subMenuheader={[
                      //   "Sr.No.",
                      //   "Arrival Status",
                      //   "Brand",
                      //   "Item Category",
                      //   "Item Type",
                      //   "Packaging",
                      // ]}
                      {...this.state}
                      loader={loadingDispatch === true ? true : false}
                      actions={[]}
                      header={[
                        "Sr.No.",
                        "Date",

                        "Sales Order",
                        "Commercial Invoice No.",
                        "Custom Invoice No.",
                        "Total Bags",
                        "Total Quantity",
                        "Brand",
                        "Item Category",
                        "Item Type",
                        "Packaging",
                      ]}
                      tableList={
                        ShippingList && ShippingList.length
                          ? ShippingList.map((singleDispatch) => {
                              singleDispatch["Date"] = moment(
                                singleDispatch.created_at
                              ).format("ddd, MMM Do YYYY , h:mm A");

                              singleDispatch["Sales Order"] = filterData(
                                masters.salesOrderList,
                                "id",
                                singleDispatch.sales_order,
                                "onlyOne"
                              )
                                ? filterData(
                                    masters.salesOrderList,
                                    "id",
                                    singleDispatch.sales_order,
                                    "onlyOne"
                                  ).so_number
                                : "";

                              singleDispatch["Commercial Invoice No."] =
                                filterData(
                                  commercialList,
                                  "id",
                                  singleDispatch.commercial_invoice,
                                  "onlyOne"
                                )
                                  ? filterData(
                                      commercialList,
                                      "id",
                                      singleDispatch.commercial_invoice,
                                      "onlyOne"
                                    ).invoice_number
                                  : "";

                              singleDispatch["Custom Invoice No."] = filterData(
                                customList,
                                "id",
                                singleDispatch.custom_invoice,
                                "onlyOne"
                              )
                                ? filterData(
                                    customList,
                                    "id",
                                    singleDispatch.custom_invoice,
                                    "onlyOne"
                                  ).invoice_number
                                : "";
                              singleDispatch["Total Bags"] =
                                singleDispatch.total_bags;
                              singleDispatch["Total Quantity"] =
                                singleDispatch.total_quantity;

                              // singleDispatch.subList
                              //   ? singleDispatch.subList.map(
                              //       (singleSub) => {
                              singleDispatch["Brand"] = filterData(
                                BrandList,
                                "id",
                                singleDispatch.brand,
                                "onlyOne"
                              )
                                ? filterData(
                                    BrandList,
                                    "id",
                                    singleDispatch.brand,
                                    "onlyOne"
                                  ).brand_name
                                : "";

                              singleDispatch["Item Category"] = filterData(
                                ItemCateoryList,
                                "id",
                                singleDispatch.item_category,
                                "onlyOne"
                              )
                                ? filterData(
                                    ItemCateoryList,
                                    "id",
                                    singleDispatch.item_category,
                                    "onlyOne"
                                  ).name
                                : "";
                              singleDispatch["Item Type"] = filterData(
                                ItemList,
                                "id",
                                singleDispatch.item_type,
                                "onlyOne"
                              )
                                ? `${Number(
                                    filterData(
                                      ItemList,
                                      "id",
                                      singleDispatch.item_type,
                                      "onlyOne"
                                    ).broken_percentage
                                  ).toFixed(2)} %`
                                : "";
                              singleDispatch["Packaging"] =
                                singleDispatch.newPack &&
                                singleDispatch.newPack.length > 0
                                  ? `  ${
                                      singleDispatch.newPack.map((sP) => {
                                        const singlePack = sP.packaging_details;

                                        return singlePack
                                          ? `${singlePack.size}${
                                              singlePack.unit
                                            }${" - "}${singlePack.bag_type}`
                                          : "";
                                      })[0]
                                    } Total (...s)`
                                  : "-";
                              // singleDispatch["Arrival Status"] =
                              //   singleDispatch.arrival_item_status;

                              singleDispatch["PackagingUI"] = (
                                <ShowPackagingPort
                                  // noTotalbags={true}
                                  // details={singleDispatch.dispatch_packaging.map(
                                  //   (sP) => {
                                  //     const singlePack =
                                  //       sP.packaging_details;
                                  //     singlePack.used_bags =
                                  //       sP.total_bags_dispatched;

                                  //     return singlePack;
                                  //   }
                                  // )}
                                  // noTotalbags={true}
                                  // returned_bags={true}
                                  noRemainingBags={true}
                                  noTotalbags={true}
                                  details={
                                    filterData(
                                      masters.packagingList,
                                      "id",
                                      singleDispatch.packaging,
                                      "onlyOne"
                                    )
                                      ? [
                                          filterData(
                                            masters.packagingList,
                                            "id",
                                            singleDispatch.packaging,
                                            "onlyOne"
                                          ),
                                        ]
                                      : []
                                  }
                                />
                              );
                              singleDispatch["PackagingUIPdf"] =
                                singleDispatch.dispatch_packaging &&
                                singleDispatch.dispatch_packaging.length > 0
                                  ? singleDispatch.dispatch_packaging
                                      .map((sP) => {
                                        const singlePack = sP.packaging_details;

                                        return singlePack
                                          ? `${singlePack.size}${
                                              singlePack.unit
                                            }${" - "}${singlePack.bag_type}`
                                          : "";
                                      })
                                      .join(", ")
                                  : "-";

                              //       return singleSub;
                              //     }
                              //   )
                              // : [];

                              return singleDispatch;
                            }).sort((a, b) => {
                              var dateB = moment(b.created_at).format(
                                "MMM D YYYY h:mm:ss A"
                              );
                              var dateA = moment(a.created_at).format(
                                "MMM D YYYY h:mm:ss A"
                              );

                              return new Date(dateB) - new Date(dateA);
                            })
                          : // .filter(
                            //   (s) =>
                            //     s.arrival_table_details.port ===
                            //     singlelistView.name
                            // )
                            // .filter((s) => s.arrival_status === "in_transit")
                            []
                      }
                      {...this.props}
                    />
                  ),
                },
                {
                  name: "port Details",
                  component: (
                    <>
                      <Paper
                        className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}>
                        <div className="width-100 d-flex justifyFE">
                          {widget_permission?.widget?.has_update_permission && (
                            <Tooltip title="Edit">
                              <EditIcon
                                onClick={() => {
                                  this.setState({
                                    editList: singlelistView,
                                    New:
                                      singlelistView.godown_details &&
                                      singlelistView.godown_details.length > 0
                                        ? singlelistView.godown_details
                                        : [""],
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          )}
                        </div>

                        <div
                          className="overflowYA scrollBluePrimary"
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 50px",
                            height: "calc(100% - 31.7px)",
                          }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}>
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}>
                              Port Name :{" "}
                            </p>
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                                wordBreak: "break-all",
                              }}>
                              {singlelistView ? singlelistView.name : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}>
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}>
                              Address :
                            </p>{" "}
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                                wordBreak: "break-all",
                              }}>
                              {singlelistView ? singlelistView.address : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}>
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}>
                              Unloading Agent :
                            </p>{" "}
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                                wordBreak: "break-all",
                              }}>
                              {singlelistView
                                ? singlelistView.unloading_agent
                                : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}>
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}>
                              City :{" "}
                            </p>{" "}
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                                wordBreak: "break-all",
                              }}>
                              {singlelistView ? singlelistView.city : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}>
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}>
                              State :{" "}
                            </p>{" "}
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                              }}>
                              {singlelistView ? singlelistView.state : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}>
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}>
                              Pincode :{" "}
                            </p>{" "}
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                              }}>
                              {singlelistView ? singlelistView.pincode : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}>
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}>
                              Godown Name :{" "}
                            </p>
                            <div
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                              }}>
                              {singlelistView &&
                                singlelistView.godown_details &&
                                singlelistView.godown_details.length > 0 &&
                                singlelistView.godown_details.map((a) => (
                                  <p
                                    style={{
                                      fontSize: "18px",

                                      margin: "10px 0 10px 10px",
                                      color: "#808080",
                                    }}>
                                    {a.name}
                                  </p>
                                ))}
                            </div>
                          </div>
                        </div>
                      </Paper>
                    </>
                  ),
                },
              ]}
            />
          )}
        </Paper>
      </Layout>
    );
  }
}

export default PortsComponent;
