import moment from "moment";
import React, { useEffect, useState } from "react";
import CustomStepper from "../../layoutComponents/CustomStepper";
import ShowDetails from "../../layoutComponents/ShowDetails";
import { filterData } from "../../Regex";
import { orange, primary, yellow, black } from "../../styles/colors";
import {
  CrossIcon,
  Download,
  EditIcon,
  EyeIcon,
  PlusIcon,
} from "../../styles/Icons";
import {
  CardDetail,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
  StyledSearchBar,
} from "../../styles/StyledComponents";
import CommercialInvoice from "./CommercialInvoice";
import CreateCommercial from "./CreateCommercial";
import DocumentUploads from "./DocumentUploads";
import EXpenseInput from "./EXpenseInput";
import ComPackingList from "./ComPackingList";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { divide, multiply } from "lodash";
import CustomIn from "./PDFS/CustomIn";
import CommercialIN from "./PDFS/CommercialIN";
import ShowSpecifications from "../../layoutComponents/ShowSpecifications";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import TableComponent from "../../layoutComponents/TableComponent";
import ItemDetailCommercial from "./ItemDetailCommercial";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import CachedIcon from "@mui/icons-material/Cached";
import { Tooltip } from "@material-ui/core";
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';

function CommercialiInvoiceStepper(props) {
  const {
    noAddButton,
    PortsFunction,
    singlelistView,
    masters,
    setSElectedCI,
    changeTab,
  } = props;
  const [tableListCopy, setTableListCopy] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [customInv, setCustomInv] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [loader, setLoad] = useState(false);
  const [editList, setEditList] = useState(false);
  const [selectedCustomInvoice, setSelectedCustomInvoice] = useState(false);
  const [defaultStep, setdefaultStep] = useState(1);
  const [downloadCI, setDownloadCI] = useState(false);
  const [currentStep, setcurrentStep] = useState(1);
  const [unit, setUnit] = useState(false);

  const [dowLoader, setDowLoad] = useState({});

  const [soItems, setSoItems] = useState([]);
  const fetchSoItems = (soId, download) => {
    
    if (soId) {
    
      if (download) {
        setDowLoad({ [download.id]: true });
      } else {
        setLoad(true);
      }
      PortsFunction("get", "dispatch/so-items/list", null, "PortCIs", null, {
        so_id: soId,
      }).then((soItems) => {
        setSoItems(soItems);
        if (download) {
          setDowLoad(false);
        } else {
          setLoad(false);
        }

      
        if (download) {
         
          download.unit = unit;
          setDownloadCI(download);
        }
      });
    }
  };
  const fetchCustomInvoices = (soId) => {
   
    if (!soId) {
      setCustomInv([]);
    } else {
      if (singlelistView) {
        setCustomInv("loading");
        PortsFunction(
          "get",
          "arrival/custom-invoice/list",
          null,
          "PortCIs",
          null,
          // noAddButton
          //   ?
          {
            sales_order: soId,
            status: "OPEN",
            is_active: "True",
          }
          // : {
          //     port_id: singlelistView && singlelistView.id && singlelistView.id,
          //   }
        ).then((customInvoices) => {
          const fData = customInvoices;
          // filterData(customInvoices, "sales_order", soId);
          if (
            editList &&
            editList.custom_invoice_details &&
            editList.custom_invoice_details.length > 0
          ) {
            editList.custom_invoice_details.map((s) => {
              if (s.status === "CLOSED") {
                fData.push(s);
              }
              return s;
            });
          }
          if (fData && fData.length > 0) {
            setCustomInv(fData);
          } else {
            setCustomInv([]);
          }
        });
      }
    }
  };
  const fetchComInvoices = () => {
    if (singlelistView) {
      setLoad(true);
      PortsFunction(
        "get",
        "arrival/commercial-invoice/list",
        null,
        "PortCIs",
        null,
        noAddButton
          ? {
              sales_order:
                singlelistView && singlelistView.id && singlelistView.id,
            }
          : {
              port_id: singlelistView && singlelistView.id && singlelistView.id,
            }
      ).then((customInvoices) => {
        const fData = customInvoices;
        //  filterData(
        //   customInvoices,
        //   noAddButton ? "sales_order" : "loading_port",
        //   singlelistView && singlelistView.id && singlelistView.id
        // );
        if (fData && fData.length > 0) {
          setTableListCopy(fData);
          setTableList(fData);
        } else {
          setTableListCopy([]);
          setTableList([]);
        }
        setLoad(false);
      });
    }
  };
  const updateValues = (res) => {
    // if (res.shipment_process_status) {
    //   // setdefaultStep(getStepIndex(res.shipment_process_status, res.status));
    //   // setcurrentStep(getStepIndex(res.shipment_process_status, res.status));
    // }

    fetchComInvoices();
    setSelectedCustomInvoice((prev) => {
      return { ...prev, status: "CLOSED" };
    });
  };
  const getStepIndex = (type, status, defaultI, h) => {
    switch (type) {
      case "EXPENSES CALCULATED":
        return noAddButton || h ? 0 : 1;
      case "PACKAGING LIST GENERATED":
        return noAddButton || h ? 1 : 2;
      case "COMMERCIAL INVOICE GENERATED":
        return status === "CLOSED"
          ? defaultI
            ? 2
            : h
            ? 2
            : 3
          : noAddButton
          ? 2
          : 2;
      default:
    }
  };
  const formatData = (s) => {
    const selectedConsignee = s.sales_order_details;
   
    const newObj = {
      "Cus In. No.": s.invoice_number,
      "Cus In. Date": moment(s.invoice_date).format("ddd, MMM Do YYYY"),
      "Exporter Ref.": s && s.exporter_ref_no ? s.exporter_ref_no : "",
      "BL No.": s.bl_number,
      "Port of Discharge": s.discharge_port,
      "Place of Delivery": s.delivery_place,
      Consignee: s.sales_order_details[0].consignee,
      Buyer:
        s && s.buyer
          ? filterData(masters && masters.buyersList, "id", s.buyer, "onlyOne")
            ? filterData(
                masters && masters.buyersList,
                "id",
                s.buyer,
                "onlyOne"
              ).client_name
            : ""
          : "",
    };
    const selected = selectedCustomInvoice
      ? selectedCustomInvoice
      : downloadCI
      ? downloadCI
      : {};
    
    const items =
      s.custom_invoice_item_details && s.custom_invoice_item_details.length > 0
        ? s.custom_invoice_item_details.map((sCItem) => {
            const details = filterData(soItems, "id", sCItem.so_item, "onlyOne")
              ? filterData(soItems, "id", sCItem.so_item, "onlyOne")
              : false;
            const commercialItem = filterData(
              selected.commercial_invoice_item_details,
              "custom_invoice_item",
              sCItem.id,
              "onlyOne"
            )
              ? filterData(
                  selected.commercial_invoice_item_details,
                  "custom_invoice_item",
                  sCItem.id,
                  "onlyOne"
                )
              : false;
            const obj = {};
            const customItem = sCItem;
            if (commercialItem && customItem) {
              const singleSo = details;

              obj["container_number"] = commercialItem.container_numbers
                ? commercialItem.container_numbers
                : [];
              obj["amount_in_other"] = commercialItem.amount_in_other;
              obj["Gross Weight"] = commercialItem.gross_weight;
              obj["Net Weight"] = commercialItem.net_weight;
              singleSo.packaging &&
                singleSo.packaging.length > 0 &&
                singleSo.packaging.map((sP) => {
           
                  if (commercialItem.actual_pkg_counter) {
                    obj[`${sP}-bags`] =
                      commercialItem.actual_pkg_counter &&
                      commercialItem.actual_pkg_counter[sP]
                        ? commercialItem.actual_pkg_counter[sP]
                        : "";
                  }
                  return sP;
                });
            }
            // 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
      
            return details
              ? {
                  ...sCItem,
                  Details: "Update Details ",
                  DetailsUI: (
                    <ItemDetailCommercial
                      rateOfGst={selected.igst_value ? selected.igst_value : 0}
                      ci_type={
                        selected["invoice_type"] ? selected["invoice_type"] : ""
                      }
                      editData={obj ? obj : false}
                      onlyView={true}
                      errorMsg={{}}
                      alldisabled={true}
                      masters={masters}
                      selected={{
                        ...s,
                        packaging: details.packaging.map((sP) => {
                          const pVal = filterData(
                            masters.PackagingList,
                            "id",
                            Number(sP),
                            "onlyOne"
                          )
                            ? filterData(
                                masters.PackagingList,
                                "id",
                                Number(sP),
                                "onlyOne"
                              )
                            : "";

                          return {
                            ...pVal,
                            total_bags:
                              customItem &&
                              customItem.pkg_counter &&
                              customItem.pkg_counter[sP]
                                ? customItem.pkg_counter[sP]
                                : "",
                          };
                        }),
                        Brand: details.brand_details.brand_name,
                        "Item Category": filterData(
                          masters.ItemCateoryList,
                          "id",
                          details.brand_details.item_category,
                          "onlyOne"
                        )
                          ? filterData(
                              masters.ItemCateoryList,
                              "id",
                              details.brand_details.item_category,
                              "onlyOne"
                            ).name
                          : "",
                        "Item Type": filterData(
                          masters.ItemList,
                          "id",
                          details.brand_details.item_type,
                          "onlyOne"
                        )
                          ? `${Number(
                              filterData(
                                masters.ItemList,
                                "id",
                                details.brand_details.item_type,
                                "onlyOne"
                              ).broken_percentage
                            ).toFixed(2)} `
                          : "",
                        "HSN Code": filterData(
                          masters.HSNlist,
                          "id",
                          details.hsn_code,
                          "onlyOne"
                        )
                          ? filterData(
                              masters.HSNlist,
                              "id",
                              details.hsn_code,
                              "onlyOne"
                            ).hsn_code
                          : "",
                        "Total Item Quantity": details.total_quantity,
                        "Contract Rate": details.contract_rate,
                        "Buyer Marking": details.buyers_marking
                          ? details.buyers_marking
                          : "-",
                        Currency: filterData(
                          masters["CurrencyList"],
                          "id",
                          details.currency,
                          "onlyOne"
                        )
                          ? filterData(
                              masters["CurrencyList"],
                              "id",
                              details.currency,
                              "onlyOne"
                            ).name
                          : "",
                      }}
                      selectedCi={selectedCustomInvoice}
                      selectedSo={
                        selectedCustomInvoice &&
                        selectedCustomInvoice.custom_invoice_details[0]
                          .sales_order_details[0]
                      }
                    />
                  ),
                  Brand: `${details.brand_details.brand_name} - ${
                    filterData(
                      masters.ItemCateoryList,
                      "id",
                      details.brand_details.item_category,
                      "onlyOne"
                    )
                      ? filterData(
                          masters.ItemCateoryList,
                          "id",
                          details.brand_details.item_category,
                          "onlyOne"
                        ).name
                      : ""
                  } - ${
                    filterData(
                      masters.ItemList,
                      "id",
                      details.brand_details.item_type,
                      "onlyOne"
                    )
                      ? `${Number(
                          filterData(
                            masters.ItemList,
                            "id",
                            details.brand_details.item_type,
                            "onlyOne"
                          ).broken_percentage
                        ).toFixed(2)}%`
                      : ""
                  }`,
                  "Item Category": filterData(
                    masters.ItemCateoryList,
                    "id",
                    details.brand_details.item_category,
                    "onlyOne"
                  )
                    ? filterData(
                        masters.ItemCateoryList,
                        "id",
                        details.brand_details.item_category,
                        "onlyOne"
                      ).name
                    : "",
                  "Item Type": filterData(
                    masters.ItemList,
                    "id",
                    details.brand_details.item_type,
                    "onlyOne"
                  )
                    ? `${Number(
                        filterData(
                          masters.ItemList,
                          "id",
                          details.brand_details.item_type,
                          "onlyOne"
                        ).broken_percentage
                      ).toFixed(2)} `
                    : "",
                  Packaging: "deferferfeeeeeeeeeee",
                  PackagingUI: (
                    <ShowPackaging
                      totalCiBags={true}
                      noTotalbags={true}
                      returned_bags={false}
                      noBags={true}
                      details={details.packaging.map((sP) => {
                        const pVal = filterData(
                          masters.PackagingList,
                          "id",
                          Number(sP),
                          "onlyOne"
                        )
                          ? filterData(
                              masters.PackagingList,
                              "id",
                              Number(sP),
                              "onlyOne"
                            )
                          : "";

                        return {
                          ...pVal,
                          total_bags:
                            commercialItem &&
                            commercialItem.actual_pkg_counter &&
                            commercialItem.actual_pkg_counter[sP]
                              ? commercialItem.actual_pkg_counter[sP]
                              : "",
                        };
                      })}
                    />
                  ),
                  packagingDetail: details.packaging.map((sP) => {
                    const pVal = filterData(
                      masters.PackagingList,
                      "id",
                      Number(sP),
                      "onlyOne"
                    )
                      ? filterData(
                          masters.PackagingList,
                          "id",
                          Number(sP),
                          "onlyOne"
                        )
                      : "";

                    return {
                      ...pVal,
                      total_bags:
                        commercialItem &&
                        commercialItem.actual_pkg_counter &&
                        commercialItem.actual_pkg_counter[sP]
                          ? commercialItem.actual_pkg_counter[sP]
                          : "",
                    };
                  }),
                  "HSN Code": filterData(
                    masters.HSNlist,
                    "id",
                    details.hsn_code,
                    "onlyOne"
                  )
                    ? filterData(
                        masters.HSNlist,
                        "id",
                        details.hsn_code,
                        "onlyOne"
                      ).hsn_code
                    : "",
                  "Total Item Quantity": `${details.total_quantity} ${
                    unit ? unit : ""
                  }`,
                  "Contract Rate": `${
                    filterData(
                      masters["CurrencyList"],
                      "id",
                      details.currency,
                      "onlyOne"
                    )
                      ? filterData(
                          masters["CurrencyList"],
                          "id",
                          details.currency,
                          "onlyOne"
                        ).name
                      : ""
                  } ${Number(details.contract_rate).toFixed(2)}/${
                    unit ? unit : ""
                  }`,
                  "Buyer Marking": details.buyers_marking
                    ? details.buyers_marking
                    : "-",
                  Currency: filterData(
                    masters["CurrencyList"],
                    "id",
                    details.currency,
                    "onlyOne"
                  )
                    ? filterData(
                        masters["CurrencyList"],
                        "id",
                        details.currency,
                        "onlyOne"
                      ).name
                    : "",
                  Amount:
                    commercialItem && commercialItem.amount_in_other
                      ? `${Number(commercialItem.amount_in_other).toFixed(2)} ${
                          filterData(
                            masters["CurrencyList"],
                            "id",
                            details.currency,
                            "onlyOne"
                          )
                            ? filterData(
                                masters["CurrencyList"],
                                "id",
                                details.currency,
                                "onlyOne"
                              ).name
                            : ""
                        }`
                      : "",
                  ...commercialItem,
                  // Specifications: "WEFERFRT4G56YH6",
                  // SpecificationsUI: (
                  //   <ShowSpecifications
                  //     details={
                  //       details.so_specifications && details.so_specifications.length > 0
                  //         ? details.so_specifications.map((sP) => {
                  //             // sP.used_bags =0
                  //             return sP;
                  //           })
                  //         : []
                  //     }
                  //   />
                  // ),
                }
              : { ...sCItem };
          })
        : [];
    return { ...s, ...newObj, subList: items };
  };

  const ciItemTable = () => {
    return (
      <div
        style={{ width: "100%", height: "fit-content", margin: "0px 0 10px 0" }}
      >
        <HeadingLine
          maxWidth="100%"
          width="fit-content"
          children={`Custom Invoice Details`}
          center={true}
          margin={"0 0 10px 0px"}
        />
        <TableComponent
          padding={"0px"}
          nobottomBarAction={true}
          loading={loader}
          heightNew="800px"
          maxHeight="calc(100vh - 40px)"
          widthNew="100%"
          module={"Bag details"}
          actions={[]}
          noAddButton={true}
          subMenu={true}
          subMenuheader={[
            "Details",
            "Brand",
            "Packaging",
            "HSN Code",
            "Contract Rate",
            "Total Item Quantity",
            "Amount",
            // "Buyer Marking",
            // "Specifications",
          ]}
          header={[
            "Cus In. No.",
            "Cus In. Date",
            "Exporter Ref.",
            "BL No.",
            "Port of Discharge",
            "Place of Delivery",
            "Consignee",
            "Buyer",
          ]}
          noSearchBar={true}
          {...props}
          tableList={
            selectedCustomInvoice &&
            selectedCustomInvoice.custom_invoice_details &&
            selectedCustomInvoice.custom_invoice_details.length
              ? selectedCustomInvoice.custom_invoice_details
                  .sort((a, b) => b.id - a.id)
                  .map((s) => {
                    return formatData(s);
                  })
              : []
          }
          filterChild={false}
        />
      </div>
    );
  };

  const steps = [
    {
      label: "Commercial Invoice",
      expanded: defaultStep === 0 ? true : false,
      onClick: () => {
        if (defaultStep === 0) {
          setdefaultStep(currentStep);
        } else {
          setdefaultStep(0);
        }
      },
      content: () => {
        return (
          <>
            <ShowDetails
              heightFc={true}
              editButton={
                noAddButton || selectedCustomInvoice.status === "CLOSED"
                  ? false
                  : () => {
                      setOpenDialog(true);
                      setEditList(selectedCustomInvoice);
                    }
              }
              loading={
                dowLoader[selectedCustomInvoice.id]
                  ? dowLoader[selectedCustomInvoice.id]
                  : false
              }
              download={(e) => {
                // e.stopPropagation();
                if (
                  selectedCustomInvoice.custom_invoice_details &&
                  selectedCustomInvoice.custom_invoice_details.length > 0
                ) {
                  fetchSoItems(
                    selectedCustomInvoice.custom_invoice_details[0]
                      .sales_order_details[0].id,
                    selectedCustomInvoice
                  );
                }
              }}
              minLeftWidth={"200px"}
              array={[
                {
                  label: "Com. Invoice No.",
                  value:
                    selectedCustomInvoice &&
                    selectedCustomInvoice.invoice_number
                      ? selectedCustomInvoice.invoice_number
                      : "-",
                  label2: "Com. Invoice Date",
                  value2:
                    selectedCustomInvoice && selectedCustomInvoice.invoice_date
                      ? moment(selectedCustomInvoice.invoice_date).format(
                          "ddd, MMM Do YYYY"
                        )
                      : new Date(),
                },
                {
                  label: "Status",
                  value:
                    selectedCustomInvoice && selectedCustomInvoice.status
                      ? selectedCustomInvoice.status
                      : "DRAFT",
                  label2: "Unit",
                  value2:
                    selectedCustomInvoice &&
                    selectedCustomInvoice.custom_invoice_details &&
                    selectedCustomInvoice.custom_invoice_details.length > 0 &&
                    selectedCustomInvoice.custom_invoice_details[0]
                      .sales_order_details
                      ? selectedCustomInvoice.custom_invoice_details[0]
                          .sales_order_details[0]
                        ? selectedCustomInvoice.custom_invoice_details[0]
                            .sales_order_details[0].unit
                        : ""
                      : "",
                },
                {
                  label: "Sales Order",
                  value:
                    selectedCustomInvoice &&
                    selectedCustomInvoice.custom_invoice_details &&
                    selectedCustomInvoice.custom_invoice_details.length > 0 &&
                    selectedCustomInvoice.custom_invoice_details[0]
                      .sales_order_details
                      ? selectedCustomInvoice.custom_invoice_details[0]
                          .sales_order_details[0]
                        ? selectedCustomInvoice.custom_invoice_details[0]
                            .sales_order_details[0].so_number
                        : ""
                      : "",
                  label2: "Contract Date",
                  value2:
                    selectedCustomInvoice &&
                    selectedCustomInvoice.custom_invoice_details &&
                    selectedCustomInvoice.custom_invoice_details.length > 0 &&
                    selectedCustomInvoice.custom_invoice_details[0]
                      .sales_order_details
                      ? selectedCustomInvoice.custom_invoice_details[0]
                          .sales_order_details[0]
                        ? moment(
                            selectedCustomInvoice.custom_invoice_details[0]
                              .sales_order_details[0]
                          ).format("ddd, MMM Do YYYY")
                        : ""
                      : "",
                  dontCapitalize: true,
                },
                selectedCustomInvoice &&
                  selectedCustomInvoice.invoice_type === "GST" && {
                    label: "Rate of IGST (IN %)",
                    value:
                      selectedCustomInvoice && selectedCustomInvoice.igst_value
                        ? `${Number(selectedCustomInvoice.igst_value).toFixed(
                            2
                          )}%`
                        : "",
                  },

                // {
                //   label: "Shipper",
                //   value:
                //     selectedCustomInvoice && selectedCustomInvoice.shipper
                //       ? filterData(masters && masters.shippersList, "id", selectedCustomInvoice.shipper, "onlyOne")
                //         ? filterData(masters && masters.shippersList, "id", selectedCustomInvoice.shipper, "onlyOne")
                //             .name
                //         : ""
                //       : "",

                //   label2: "Beneficiary",
                //   value2:
                //     selectedCustomInvoice && selectedCustomInvoice.beneficiary
                //       ? filterData(
                //           masters && masters.benificiarysList,
                //           "id",
                //           selectedCustomInvoice.beneficiary,
                //           "onlyOne"
                //         )
                //         ? filterData(
                //             masters && masters.benificiarysList,
                //             "id",
                //             selectedCustomInvoice.beneficiary,
                //             "onlyOne"
                //           ).name
                //         : ""
                //       : "",
                // },
                // {
                //   label: "Buyer",
                //   value:
                //     selectedCustomInvoice && selectedCustomInvoice.buyer
                //       ? filterData(masters && masters.buyersList, "id", selectedCustomInvoice.buyer, "onlyOne")
                //         ? filterData(masters && masters.buyersList, "id", selectedCustomInvoice.buyer, "onlyOne")
                //             .client_name
                //         : ""
                //       : "",

                //   label2: "Exporter Ref No.",
                //   value2: selectedCustomInvoice && selectedCustomInvoice.exporter_ref_no,
                // },
                // {
                //   label: "Discharge Port",
                //   value:
                //     selectedCustomInvoice && selectedCustomInvoice.discharge_port
                //       ? selectedCustomInvoice.discharge_port
                //       : "",

                //   label2: "Delivery Port",
                //   value2:
                //     selectedCustomInvoice && selectedCustomInvoice.delivery_place
                //       ? selectedCustomInvoice.delivery_place
                //       : "",
                // },
                // {
                //   label: "Notify 1",
                //   value:
                //     selectedCustomInvoice && selectedCustomInvoice.notify_1
                //       ? filterData(
                //           masters && masters.notifyList,
                //           "id",
                //           Number(selectedCustomInvoice.notify_1),
                //           "onlyOne"
                //         )
                //         ? filterData(
                //             masters && masters.notifyList,
                //             "id",
                //             Number(selectedCustomInvoice.notify_1),
                //             "onlyOne"
                //           ).notify
                //         : ""
                //       : "",

                //   label2: "Notify 2",
                //   value2:
                //     selectedCustomInvoice && selectedCustomInvoice.notify_2
                //       ? filterData(
                //           masters && masters.notifyList,
                //           "id",
                //           Number(selectedCustomInvoice.notify_2),
                //           "onlyOne"
                //         )
                //         ? filterData(
                //             masters && masters.notifyList,
                //             "id",
                //             Number(selectedCustomInvoice.notify_2),
                //             "onlyOne"
                //           ).notify
                //         : ""
                //       : "",
                // },
                // {
                //   label: "Vessel/Flight Name",
                //   value:
                //     selectedCustomInvoice && selectedCustomInvoice.vessel_number
                //       ? selectedCustomInvoice.vessel_number
                //       : "",

                //   label2: "Vessel/Flight no.",
                //   value2:
                //     selectedCustomInvoice && selectedCustomInvoice.flight_number
                //       ? selectedCustomInvoice.flight_number
                //       : "",
                // },
                // {
                //   label: "Payment Term",
                //   value:
                //     selectedCustomInvoice && selectedCustomInvoice.payment_terms
                //       ? filterData(
                //           masters && masters.soPaymentTermsList,
                //           "id",
                //           Number(selectedCustomInvoice.payment_terms),
                //           "onlyOne"
                //         )
                //         ? filterData(
                //             masters && masters.soPaymentTermsList,
                //             "id",
                //             Number(selectedCustomInvoice.payment_terms),
                //             "onlyOne"
                //           ).term
                //         : ""
                //       : "",
                // },
                // {
                //   label: "Delivery terms",
                //   value:
                //     selectedCustomInvoice &&
                //     selectedCustomInvoice.custom_invoice_item_details &&
                //     selectedCustomInvoice.custom_invoice_item_details.length > 0
                //       ? selectedCustomInvoice.custom_invoice_item_details.map((singleItem, i) => {
                //           const details = filterData(soItems, "id", singleItem.so_item, "onlyOne")
                //             ? filterData(soItems, "id", singleItem.so_item, "onlyOne")
                //             : false;
                //           return (
                //             singleItem.delivery_terms && (
                //               <div style={{ textAlign: "left", width: "100%" }}>
                //                 {`${singleItem.delivery_terms[0]} ${Number(details.contract_rate).toFixed(2)}/${
                //                   singleItem.delivery_terms[2]
                //                 }, ${singleItem.delivery_terms[3]}, ${singleItem.delivery_terms[4]},
                //          ${singleItem.delivery_terms[5]}, ${
                //                   singleItem.delivery_terms[6] && singleItem.delivery_terms[6].length > 0
                //                     ? singleItem.delivery_terms[6].map((s) => s)
                //                     : ""
                //                 }${
                //                   singleItem.delivery_terms[7] && singleItem.delivery_terms[7].length > 0
                //                     ? typeof singleItem.delivery_terms[7] === "string"
                //                       ? `, ${singleItem.delivery_terms[7]}.`
                //                       : `, ${singleItem.delivery_terms[7].map((s) => s)}.`
                //                     : "."
                //                 }`}
                //               </div>
                //             )
                //           );
                //         })
                //       : "",
                // },
              ]}
              childData={ciItemTable()}
            />
          </>
        );
      },
    },
    {
      label: "Expense Input",
      expanded: defaultStep === 0 ? true : false,
      content: () => {
        return (
          <EXpenseInput
            {...props}
            selectedCustomInvoice={selectedCustomInvoice}
            postData={PortsFunction}
            updateValues={updateValues}
          />
        );
      },
      onClick: () => {
        if (defaultStep === 0 && currentStep > 0) {
          setdefaultStep(currentStep);
        } else {
          setdefaultStep(2);
        }
      },
    },
    {
      label: "Packing List",
      expanded: defaultStep === 1 ? true : false,
      content: () => {
        return (
          <ComPackingList
            {...props}
            selectedCustomInvoice={selectedCustomInvoice}
            postData={PortsFunction}
            updateValues={updateValues}
            changeTab={changeTab}
          />
        );
      },
      onClick: () => {
        if (defaultStep === 1 && currentStep > 1) {
          setdefaultStep(currentStep);
        } else {
          setdefaultStep(3);
        }
      },
    },
  ];

  const stepsHorizontal = [
    {
      label: "Expense",
      onClick: () => {
        if (defaultStep === 2 && currentStep > 2) {
          setdefaultStep(currentStep);
        } else {
          setdefaultStep(2);
        }
      },
    },
    {
      label: "Packing",
      onClick: () => {
        if (defaultStep === 3 && currentStep > 3) {
          setdefaultStep(currentStep);
        } else {
          setdefaultStep(3);
        }
      },
    },
  ];
  const [masterss, setmasterss] = useState({});

  const [successMsg, setSuccess] = useState("");
  useEffect(() => {
    fetchComInvoices();
  }, [singlelistView]);
  useEffect(() => {
    setmasterss(masters ? masters : {});
  }, [masters]);
  useEffect(() => {
    setSelectedCustomInvoice(false);
  }, [singlelistView]);
  useEffect(() => {
    if (
      selectedCustomInvoice &&
      selectedCustomInvoice !== false &&
      selectedCustomInvoice &&
      selectedCustomInvoice.custom_invoice_details &&
      selectedCustomInvoice.custom_invoice_details.length > 0 &&
      selectedCustomInvoice.custom_invoice_details[0].sales_order_details
    ) {
      setUnit(
        selectedCustomInvoice.custom_invoice_details[0].sales_order_details[0]
          .unit
      );
      fetchSoItems(
        selectedCustomInvoice.custom_invoice_details[0].sales_order_details[0]
          .id
      );
    }
  }, [selectedCustomInvoice]);
  return (
    <div
      className={
        noAddButton
          ? selectedCustomInvoice
            ? "width-100 height-100 d-flex d-flex-column"
            : "width-100 minHeightFC d-flex d-flex-column"
          : "width-100 height-100 d-flex d-flex-column"
      }
    >
      {successMsg ? (
        <SnackbarComponent
          autoHideDuration={5000}
          success={successMsg}
          snackbarClose={() => {
            // setError(false);
            setSuccess("");
          }}
        />
      ) : (
        ""
      )}
      {openDialog === true || editList ? (
        <CreateCommercial
          customInv={customInv}
          fetchCustomInvoices={fetchCustomInvoices}
          singlelistView={singlelistView}
          onClose={() => {
            setOpenDialog(false);
            setEditList(false);
          }}
          masters={masterss}
          editList={editList}
          fetchAgain={(msg) => {
            setOpenDialog(false);
            setEditList(false);
            setSuccess(msg);
            fetchComInvoices();
          }}
          postData={PortsFunction}
        />
      ) : (
        ""
      )}
      {downloadCI !== false ? (
        <CommercialIN
          masters={masters}
          singlelistView={singlelistView}
          downloadCI={downloadCI}
          subMenuheader={[
            "Brand",
            "Packaging",
            "HSN Code",
            "Contract Rate",
            "Total Item Quantity",
            "Amount",
            // "Buyer Marking",
            // "Specifications",
          ]}
          header={[
            "Cus In. No.",
            "Cus In. Date",
            "Exporter Ref.",
            "BL No.",
            "Port of Discharge",
            "Place of Delivery",
            "Consignee",
            "Buyer",
          ]}
          noSearchBar={true}
          {...props}
          tableList={
            downloadCI &&
            downloadCI.custom_invoice_details &&
            downloadCI.custom_invoice_details.length
              ? downloadCI.custom_invoice_details
                  .sort((a, b) => b.id - a.id)
                  .map((s) => {
                    return formatData(s);
                  })
              : []
          }
          onClose={() => {
            setDownloadCI(false);
          }}
          comInLists={selectedCustomInvoice}
        />
      ) : (
        ""
      )}
      {selectedCustomInvoice ? (
        <>
          <div
            className=" d-flex borderRadius7 padding5"
            style={{ width: "100%", background: "rgba(158, 158, 158, 0.48)" }}
          >
            {/* Tooltip */}
            <div
              className="height-100 d-flex alignC"
              style={{ width: "calc(100% - 40px)", fontSize: "1.1rem" }}
            >
              <div
                style={{ width: "34px" }}
                className="height-100 d-flex alignC"
              >
                <InsertDriveFileOutlinedIcon />
              </div>
              Invoice Number{" "}
              {selectedCustomInvoice &&
                selectedCustomInvoice.invoice_number &&
                selectedCustomInvoice.invoice_number}
            </div>
            <StyledButton
              tooltip="Close"
              name={<CrossIcon color={primary} />}
              onClick={() => {
                setSelectedCustomInvoice(false);
                if (setSElectedCI) {
                  setSElectedCI(false);
                }
                setdefaultStep(0);
              }}
              width={"40px"}
              minWidth={"40px"}
            />
          </div>
          <div
            style={{
              height: noAddButton
                ? "calc(100% - 56.81px)"
                : "calc(100% - 36.81px)",
              marginTop: "30px",
            }}
            className="width-100 d-flex d-flex-column overflowYA scrollBluePrimary"
          >
            <CustomStepper
              steps={steps}
              defaultStep={defaultStep}
              currentAStep={currentStep}
              enableAll={true}
            />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: noAddButton ? "20px" : "",
            }}
          >
            {noAddButton ? (
              <div
                className="widthFC fontWeight400 alignC d-flex"
                style={{ fontSize: "23px" }}
              >
                All Custom Invoices
              </div>
            ) : (
              ""
            )}
            <StyledSearchBar
              padding={"9px"}
              bgC={"#F2F2F2"}
              fieldName={[
                "invoice_number",
                "net_weight",
                "status",
                "used_weight",
              ]}
              copyData={tableListCopy}
              filteredData={(filterNotify) => {
                setTableList(filterNotify);
              }}
              onClear={() => {
                setTableList(tableListCopy);
              }}
              placeholder={`Search Custom Invoice!`}
            />

            {noAddButton ? (
              ""
            ) : (
              <StyledButton
                withBg={true}
                onClick={() => {
                  setOpenDialog(true);
                }}
                name="Add New Commercial Invoice"
                icon={<PlusIcon />}
              />
            )}
          </div>

          <div
            className="width-100"
            style={{
              padding: noAddButton ? "0 2px" : "",
              margin: "20px 0 0 0",
              height: "calc(100% - 58px)",
            }}
          >
            {loader ? (
              <StyledCircularProgress />
            ) : (
              <div
                className="d-flex d-flex-column scrollBluePrimary overflowXA height-100"
                style={{ width: "calc(100% + 4px)", margin: "0 -2px" }}
              >
                <CardDetail
                  keyId={"-1"}
                  height="30px"
                  width="calc(100% - 4px)"
                  background="rgba(158, 158, 158, 0.48)"
                  margin="10px 2px"
                  children={() => {
                    return (
                      <div className="width-100 d-flex">
                        <div
                          className="height-100 d-flex alignC"
                          style={{
                            width: noAddButton
                              ? "calc(100% - 43px)"
                              : "calc(100% - 86px)",
                          }}
                        >
                          <div
                            style={{ width: "322.39px", minWidth: "322.39px" }}
                            className="d-flex"
                          >
                            <div
                              style={{
                                margin: "0 10px 0 0",
                                minWidth: "12.69px",
                              }}
                            >
                              {" "}
                              {""}
                            </div>
                            <div
                              style={{ minWidth: "72.39px" }}
                              className="d-flex justifyC"
                            >
                              Invoice No.
                            </div>
                            <div
                              style={{ margin: "0 10px", minWidth: "135px" }}
                              className="d-flex justifyC"
                            >
                              Shipped Weight
                            </div>
                            {/* <div
                              style={{ minWidth: "71.78px" }}
                              className="d-flex justifyC"
                            >
                              Action
                            </div> */}
                            <div
                              style={{ minWidth: "71.78px" }}
                              className="d-flex justifyC"
                            >
                              Type
                            </div>
                            <div
                              style={{ minWidth: "71.78px" }}
                              className="d-flex justifyC"
                            >
                              Status
                            </div>{" "}
                          </div>
                          <div
                            className="d-flex justifyC"
                            style={{
                              minWidth: "calc(100% - 322.39px)",
                              overflow: "hidden",
                              margin: "0 0 0 10px",
                            }}
                          ></div>
                        </div>
                        <div
                          style={{ minWidth: noAddButton ? "43px" : "86px" }}
                          className="d-flex justifyC"
                        >
                          {noAddButton ? "View" : "Actions"}
                        </div>
                      </div>
                    );
                  }}
                />

                {
                  // filterData(
                  //   tableList,
                  //   noAddButton ? "sales_order" : "loading_port",
                  //   singlelistView && singlelistView.id && singlelistView.id
                  // ) &&
                  // filterData(
                  //   tableList,
                  //   noAddButton ? "sales_order" : "loading_port",
                  //   singlelistView && singlelistView.id && singlelistView.id
                  // )
                  tableList.length > 0 ? (
                    <>
                      {
                        // filterData(
                        //   tableList,
                        //   noAddButton ? "sales_order" : "loading_port",
                        //   singlelistView && singlelistView.id && singlelistView.id
                        // ) &&
                        //   filterData(
                        //     tableList,
                        //     noAddButton ? "sales_order" : "loading_port",
                        //     singlelistView && singlelistView.id && singlelistView.id
                        //   )
                        tableList
                          .sort((a, b) => b.id - a.id)
                          .map((singleList, i) => (
                            <CardDetail
                              background={
                                !((i + 1) % 2)
                                  ? "rgba(158, 158, 158, 0.05)"
                                  : ""
                              }
                              keyId={i}
                              height="40px"
                              width="calc(100% - 4px)"
                              margin="10px 2px"
                              children={() => {
                                return (
                                  <div
                                    className="width-100 d-flex cursorP"
                                    onClick={(e) => {
                                      // if (singleList.shipment_process_status) {
                                      //   //   setdefaultStep(
                                      //   //     getStepIndex(singleList.shipment_process_status, singleList.status, "defaultI")
                                      //   //   );
                                      //   setcurrentStep(
                                      //     getStepIndex(singleList.shipment_process_status, singleList.status)
                                      //   );
                                      // }
                                      setSelectedCustomInvoice(singleList);
                                      if (setSElectedCI) {
                                        setSElectedCI(singleList);
                                      }
                                    }}
                                  >
                                    <div
                                      className="height-100 d-flex alignC"
                                      style={{
                                        width: noAddButton
                                          ? "calc(100% - 86px)"
                                          : "calc(100% - 129px)",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "322.39px",
                                          minWidth: "322.39px",
                                        }}
                                        className="d-flex alignC"
                                      >
                                        <div
                                          style={{ margin: "0 10px 0 0" }}
                                          className="d-flex justifyC alignC"
                                        >
                                          {tableList.length - i < 10
                                            ? `0${tableList.length - i}`
                                            : tableList.length - i}
                                        </div>
                                        <div
                                          style={{ minWidth: "72.39px" }}
                                          className="heightFC textOEllipse"
                                        >
                                          {singleList.invoice_number
                                            ? singleList.invoice_number
                                            : "232424"}
                                        </div>
                                        <div
                                          style={{
                                            margin: "0 10px",
                                            minWidth: "135px",
                                            fontSize: "0.6rem",
                                            maxWidth: "135px",
                                            overflow: "hidden",
                                          }}
                                          className="d-flex justifyC  d-flex-column alignC"
                                        >
                                          {/* <div
                                            style={{
                                              width: "100%",
                                              height: "5px",
                                              // boxShadow:
                                              //   "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px green, 0px 1px 8px 0px rgba(251, 224, 191, 1)",
                                              zIndex: "1",
                                              fontSize: "0.7rem",
                                              position: "relative",
                                              borderRadius: "7px",
                                              margin: "5px 0",
                                              background: "#E5E5E5",
                                            }}
                                          > */}
                                          {/* <div
                                              className={`progressbar ${
                                                multiply(
                                                  divide(
                                                    singleList.used_weight
                                                      ? singleList.used_weight
                                                      : 0,
                                                    singleList.net_weight
                                                  ),
                                                  100
                                                ) === 100
                                                  ? "borderRadius7"
                                                  : ""
                                              }`}
                                              style={{
                                                width: `${multiply(
                                                  divide(
                                                    Number(
                                                      singleList.used_weight
                                                        ? singleList.used_weight
                                                        : 0
                                                    ).toFixed(3),
                                                    singleList.net_weight
                                                      ? Number(
                                                          singleList.net_weight
                                                        ).toFixed(3)
                                                      : Number(0.0).toFixed(3)
                                                  ),
                                                  100
                                                )}%`,
                                              }}
                                              // style={{
                                              //   width: `${multiply(
                                              //     divide(
                                              //       singleList.used_weight ? singleList.used_weight : 0,
                                              //       singleList.net_weight
                                              //     ),
                                              //     100
                                              //   )}%`,
                                              // }}
                                            ></div> */}
                                          {/* </div> */}
                                          <div className="d-flex width-100 justifyC">
                                            {Number(
                                              singleList.used_weight
                                                ? singleList.used_weight
                                                : 0
                                            ).toFixed(3)}
                                            {/* / */}
                                            {/* {singleList.net_weight &&
                                            singleList.custom_invoice_details
                                              .length > 0
                                              ? `${Number(
                                                  singleList.net_weight
                                                ).toFixed(3)}${
                                                  singleList
                                                    .custom_invoice_details[0]
                                                    .sales_order_details[0].unit
                                                }`
                                              : ""} */}
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            minWidth: "71.78px",
                                            color:
                                              singleList.invoice_type === "GST"
                                                ? "#e74c3c"
                                                : "#27ae60",
                                          }}
                                          className="d-flex justifyC alignC"
                                        >
                                          {singleList.invoice_type}
                                        </div>
                                      </div>{" "}
                                      <div
                                        style={{
                                          minWidth: "80.78px",

                                          color: !singleList.status
                                            ? "#00008B"
                                            : singleList.status === "OPEN"
                                            ? orange
                                            : singleList.status === "DRAFT" &&
                                              "#00008B",
                                        }}
                                        className="d-flex justifyC alignC"
                                      >
                                        <div style={{}}>
                                          {singleList.status
                                            ? singleList.status
                                            : "Draft"}
                                        </div>
                                        {/* {singleList.status === "CLOSED" ? (
                                          <>
                                            <Tooltip
                                              title="Complete Commercial Invoice Process"
                                              arrow
                                              placement="top"
                                            >
                                              <div
                                                style={{
                                                  color: "black",
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                <CachedIcon />
                                              </div>
                                            </Tooltip>
                                          </>
                                        ) : (
                                          ""
                                        )} */}
                                      </div>
                                      <div
                                        className="d-flex justifyC alignC"
                                        style={{
                                          width: "calc(100% - 322.39px)",
                                          overflow: "hidden",
                                          margin: "0 0 0 10px",
                                        }}
                                      >
                                        {singleList.status === "DRAFT" ? (
                                          "Not Shipped Yet"
                                        ) : (
                                          <CustomStepper
                                            steps={stepsHorizontal}
                                            currentAStep={1}
                                            onlyLabel={true}
                                            orientation={"Horizontal"}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <StyledButton
                                      tooltip="Download PDF"
                                      name={<Download />}
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        setUnit(
                                          singleList.custom_invoice_details[0]
                                            .sales_order_details[0].unit
                                        );
                                        fetchSoItems(
                                          singleList.custom_invoice_details[0]
                                            .sales_order_details[0].id,
                                          singleList
                                        );
                                      }}
                                      width={"43px"}
                                      minWidth={"43px"}
                                      onlyTextColor={primary}
                                    />
                                    <StyledButton
                                      tooltip="View"
                                      name={<EyeIcon />}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // if (singleList.shipment_process_status) {
                                        //   setdefaultStep(
                                        //     getStepIndex(
                                        //       singleList.shipment_process_status,
                                        //       singleList.status,
                                        //       "defaultI"
                                        //     )
                                        //   );
                                        //   setcurrentStep(
                                        //     getStepIndex(
                                        //       singleList.shipment_process_status,
                                        //       singleList.status,
                                        //       null,
                                        //       "h"
                                        //     )
                                        //   );
                                        // }
                                        setSelectedCustomInvoice(singleList);
                                        if (setSElectedCI) {
                                          setSElectedCI(singleList);
                                        }
                                      }}
                                      width={"43px"}
                                      minWidth={"43px"}
                                      onlyTextColor={primary}
                                    />
                                    {noAddButton ? (
                                      ""
                                    ) : singleList.status === "CLOSED" ? (
                                      <div
                                        className="justifyC alignC d-flex "
                                        style={{
                                          height: "36px",
                                          width: "43px",
                                        }}
                                      >
                                        -
                                      </div>
                                    ) : (
                                      // && singleList.status === "DRAFT" ? (
                                      //   <div
                                      //     className="justifyC alignC d-flex "
                                      //     style={{
                                      //       height: "36px",
                                      //       width: "43px",
                                      //     }}
                                      //   >
                                      //     -
                                      //   </div>
                                      <StyledButton
                                        tooltip={
                                          singleList.status === "DRAFT"
                                            ? "Complete the process"
                                            : "Edit"
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setOpenDialog(true);
                                          setEditList(singleList);
                                        }}
                                        name={
                                          singleList.status === "DRAFT" ? (
                                            <NewReleasesOutlinedIcon style={{color:"#F5E216"}}/>
                                          ) : (
                                            <EditIcon />
                                          )
                                        }
                                        width={"43px"}
                                        minWidth={"43px"}
                                        onlyTextColor={primary}
                                      />
                                    )}
                                  </div>
                                );
                              }}
                            />
                          ))
                      }
                      {
                        <div
                          style={{ marginBottom: noAddButton ? "80px" : "" }}
                        ></div>
                      }
                    </>
                  ) : (
                    <div
                      className="width-100 justifyC alignC d-flex"
                      style={{ height: "150px" }}
                    >
                      Custom Invoice is not created yet!
                    </div>
                  )
                }
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default CommercialiInvoiceStepper;
