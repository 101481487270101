import { Divider } from "@material-ui/core";
import React, { useState } from "react";
import MainView from "../../layoutComponents/MainView";
import MainViewTable from "../../layoutComponents/MainViewTable";
import {
  CssTextField,
  StyledButton,
  CssTextField95,
} from "../../styles/StyledComponents";
import { StyledCircularProgress } from "../../styles/StyledComponents";
import Chats from "../Chats/ChatsComponent";
import ChatScreen from "./ChatScreen";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ReactDOM from "react-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { primary } from "../../styles/colors";
import { CrossIcon } from "../../styles/Icons";
import { Button } from "@material-ui/core";
import {
  Paper,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import moment from "moment";
import { useNavigate } from "react-router";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import PurchaseOrdersContainer from "../PurchaseOrders/PurchaseOrdersContainer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@material-ui/core/Typography";
import MouseOverPopover from "./MouseOverPopover";
import NearMeIcon from '@mui/icons-material/NearMe';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ChatsContainer from "../Chats/ChatsContainer";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Offers(props) {
  const {
    selectedRate,
    ItemCateoryList,
    ItemList,
    onBackButtonClick,
    purchaseIntentList,pagination,
    tabList,
  } = props;
  const [set, setdata] = useState(false);
  const [childState, setChildState] = useState(false);
  const [chatScreen, setchatScreen] = useState({ open: false, type: "" });
  const [ButtonTrue, setButtonTrue] = useState({ open: false, type: "" });

  const handleBackButtonClick = () => {
    // Pass the child state to the parent component
    onBackButtonClick(childState);
  };
  const [snack, setSnack] = useState({});

  const navigate = useNavigate();
  const updatePurchaseIntent = () => {
    // ButtonTrue.type==="approve"?"approved":"rejected";
    console.log(ButtonTrue.data, "ButtonTrue.data");
    props
      .RatesFunction(
        "patch",
        "dispatch/sales-intent",
        ButtonTrue.data.id,

        null,

        {
          status: ButtonTrue.type === "approve" ? "Accepted" : "Rejected",
          purchase_rate: selectedRate.id,
        },
        null,
        "noeditparam"
      )
      .then((res) => {
        console.log(res,"resresresresres")
        if (res&&Array.isArray(res)) {  console.log("resresresresresertgrttr","")
        setSnack({error:res.map((s)=>`${s.field}-${s.message.length>0?s.message.map((s)=>s).join(""):s.message}`).join('')})
        } else {
        console.log(ButtonTrue.data,"ButtonTrue.data")
        setButtonTrue((p) => {
          return {
            ...p,
            open: false,
            createPo: ButtonTrue.type === "approve" ? true : false,
          };
        });
      }
      });
    
    // nvigate("PurchaseOrders", {
    //   purchase_intent: ButtonTrue.data,
    //   replace: true,
    // });
  };
  const openChat=(data)=>{
    // chat_room_id
    localStorage.setItem("room",JSON.stringify(data));
    localStorage.removeItem("oldchatarr")
    setchatScreen((p) => {
      return {
        ...p,
        data:data,
        open: true,
      };
    });
  }
  const create_po = (data) => {
           setButtonTrue((p) => {
          return {
            ...p,
            data:data,
            open: false,
            createPo:true
          };
        });
  };
  return  (
    <div
      style={{
        display: "flex",
        justifyContent: "left",
        padding: "20px 20px",
        flexDirection: "column",
        height: "100%",
      }}> {snack.error || snack.success ? (
        <SnackbarComponent
          autoHideDuration={snack.error ? 5000 : 1500}
          error={snack.error}
          success={snack.success}
          snackbarClose={() => {
            setSnack({});
          }}
        />
      ) : (
        ""
      )}
       
      {ButtonTrue.createPo ? (
        <PurchaseOrdersContainer
          onlyCreate={ButtonTrue.data ?{...ButtonTrue.data,...selectedRate}: true}
          onClose={() => {
            setButtonTrue({});
            if(props.fetchAgain){
              props.fetchAgain()
            }
          }}
        />
      ) : (
        ""
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          cursor: "pointer",
        }}
       >
        <div style={{ margin: "5px 5px 0 0" }}  onClick={handleBackButtonClick}>
          {/* <SummarizeOutlinedIcon fontSize="small" /> */}
         <NearMeIcon fontSize="small" color="rgb(241, 154, 48)" />
        </div>

        <div  onClick={handleBackButtonClick}
          style={{
            fontSize: "1rem",
            fontWeight: "500",
            color: "rgb(241 154 48)a6",
          }}>
          Rate
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}>
          {" "}
          <KeyboardArrowRightIcon
            background="rgb(241 154 48)a6"
            fontSize="small"
          />{" "}
        </div>
        <div style={{ marginRight: "10px" }} onClick={()=>{setchatScreen({open:false})}} >
          {" "}
          {selectedRate.purchase_rate_no}
        </div>
        {/* <div style={{ width: "100%", maxWidth: "150px" }}>
            <CssTextField95
              // required={true}
              autoComplete="off"
            
              style={
                { width: "calc(100% - 30px)", margin: "10px" } //disabled={tp}
              }
              id="outlined-select-currency-native"
              select
              label="Offers"
              variant="outlined"
              InputLabelProps={{}}
            >
            
            </CssTextField95>
          </div> */}
        {"  "}
        <Divider
          variant="vertical"
          style={{ height: "100%", minHeight: "29.19px" }}
        />
        <div onClick={()=>{setchatScreen({open:false})}} 
          style={{
            margin: "0 10px",
            fontWeight: "500",
            color: "rgb(241 154 48)a6",
          }}>
          {moment(selectedRate.date).format("MMM-D-YYYY")}
        </div>
       {chatScreen.open?<> 
       | <div style={{margin:"0 5px",cursor:"pointer"}}>
          {`${chatScreen.data?
          chatScreen.data.created_by_profile.first_name?chatScreen.data.created_by_profile.first_name:"" + 
          chatScreen.data.created_by_profile.last_name?chatScreen.data.created_by_profile.last_name:"":""}'s Chat Room`}
          </div></>:""}
        
      </div>
      {chatScreen.open?<ChatsContainer goBack={()=>{}}chat_room_id={chatScreen.data.chat_room_id}/>:<>
      <div
        style={{
          background: "white",
          display: "flex",
          margin: "10px 0",
          width: "100%",
        }}>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            // display: "flex",
            padding: "20px 50px",
            color: "rgba(150, 150, 150, 1)",
            width: "calc(100% - 20px)",
          }}>
          <span style={{ fontWeight: 500, fontSize: "0.8rem" }}>
            Item Category
          </span>
          <div
            style={{
              display: "flex",
              fontWeight: 600,
              fontSize: "1rem",
              color: "rgb(241 154 48)",
            }}>
            {ItemCateoryList &&
            ItemCateoryList.length > 0 &&
            ItemCateoryList.filter(
              (data1) => data1.id === selectedRate.item_category
            ) &&
            ItemCateoryList.filter(
              (data1) => data1.id === selectedRate.item_category
            )[0] &&
            ItemCateoryList.filter(
              (data1) => data1.id === selectedRate.item_category
            )[0].name
              ? ItemCateoryList.filter(
                  (data1) => data1.id === selectedRate.item_category
                )[0].name
              : "-"}
          </div>
        </div>
        {/* <Divider
          variant="vertical"
          style={{ height: "100%", minHeight: "100px" }}
        /> */}
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            // display: "flex",
            padding: "20px 50px",
            color: "rgba(150, 150, 150, 1)",
            width: "calc(100% - 20px)",
          }}>
          <span style={{ fontWeight: 500, fontSize: "0.8rem" }}>Item Type</span>
          <div
            style={{
              display: "flex",
              fontWeight: 600,
              fontSize: "1rem",
              color: "rgb(241 154 48)",
            }}>
            {" "}
            {ItemList &&
            ItemList.length > 0 &&
            ItemList.filter((data1) => data1.id === selectedRate.item_type) &&
            ItemList.filter(
              (data1) => data1.id === selectedRate.item_type
            )[0] &&
            ItemList.filter((data1) => data1.id === selectedRate.item_type)[0]
              .broken_percentage
              ? ItemList.filter(
                  (data1) => data1.id === selectedRate.item_type
                )[0].broken_percentage
              : "-"}
            %
          </div>
        </div>
        {/* <Divider
          variant="vertical"
          style={{ height: "100%", minHeight: "100px" }}
        /> */}

        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            // display: "flex",
            padding: "20px 50px",
            color: "rgba(150, 150, 150, 1)",
            width: "calc(100% - 20px)",
          }}>
          <span style={{ fontWeight: 500, fontSize: "0.8rem" }}>Amount</span>
          <div
            style={{
              display: "flex",
              fontWeight: 600,
              fontSize: "1rem",
              color: "rgb(241 154 48)",
            }}>
            ₹ {selectedRate.amount}/ {selectedRate.unit}
          </div>
        </div>
        {/* <Divider
          variant="vertical"
          style={{ height: "100%", minHeight: "100px" }}
        /> */}
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            // display: "flex",
            padding: "20px 50px",
            color: "rgba(150, 150, 150, 1)",
            width: "calc(100% - 20px)",
          }}>
          <span style={{ fontWeight: 500, fontSize: "0.8rem" }}>Quantity</span>
          <div
            style={{
              display: "flex",
              fontWeight: 600,
              fontSize: "1rem",
              color: "rgb(241 154 48)",
            }}>
            {selectedRate.required_qty} {selectedRate.unit}
          </div>
        </div>
        {/* <Divider
          variant="vertical"
          style={{ height: "100%", minHeight: "100px" }}
        /> */}
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            // display: "flex",
            padding: "20px 50px",
            color: "rgba(150, 150, 150, 1)",
            width: "calc(100% - 20px)",
          }}>
          <span style={{ fontWeight: 500, fontSize: "0.8rem" }}>Status</span>
          <div
            style={{
              display: "flex",
              fontWeight: 600,
              fontSize: "1rem",
              color: "rgb(241 154 48)",
            }}>
            {selectedRate.status}
          </div>
        </div>
      </div>

      <div style={{ height: "calc(100% - 149.21px)" }} className="width-100">
        <MainView
          noAddButton={true}
          noSearchBar={true}
          noHeader={true}
          sidePadding={true}
          header={[
            "Sr.No.",
            "Date",

            "Revised Rate",
            "Required qty",
            
            // "Purchased qty",
            // "Pending qty",
            // "Purchased",
            "Created By",
            "User Name",
            "Broker Detail",
            "Miller Detail",
            // "Pending",

            "Chat",
            "Action/Status",
          ]}
          actions={[]}
         
            pagination={pagination}
          tableListRate={
            purchaseIntentList && purchaseIntentList.length > 0
              ? purchaseIntentList.map((data) => {
                  data["Date"] = moment(data.date).format("ddd, MMM Do YYYY");
                  data["Revised Rate"] = `${
                    data.amount ? data.amount + " / " + data.unit : "-"
                  }`;
                  data["Required qty"] = `${
                    data.quantity ? data.quantity + " " + data.unit : "-"
                  }`;
                  // data["Purchased qty"] = `${
                  //   data.purchased ? data.purchased + " " + data.unit : "-"
                  // }`;
                  // data["Role"]=data.role?data.role:"";

                  data["Created By"] =data.created_by_profile.role?data.created_by_profile.role:"";
                   
                
                    data["User Name"]=data.created_by_profile.first_name?data.created_by_profile.first_name:"" + data.created_by_profile.last_name?data.created_by_profile.last_name:"";
                  data["Broker Detail"] = () => {
                    return (
                      <>
                      {data && data.broker_profile &&data.broker_profile.name
                            ?(<>
                        
                        <div onClick={() => {}}>
                          {data && data.broker_profile && data.broker_profile
                            ? data.broker_profile.name
                            : ""}
                        </div>
                        <div style={{ margin: "5px 0 0 5px" }}>
                          <MouseOverPopover
                            msg={
                              <InfoOutlinedIcon
                                fontSize="small"
                                style={{ color: "rgb(241, 154, 48)" }}
                              />
                            }
                            details={
                              <div className="column-content">
                                {/* <Typography variant="subtitle1">
                             Name :  { data && data.broker_profile && data.broker_profile
                      ? data.broker_profile.name
                      : ""}
                                </Typography> */}
                                <Typography variant="subtitle1">
                                  {" "}
                                  Phone NO. :{" "}
                                  {data &&
                                  data.broker_profile &&
                                  data.broker_profile
                                    ? data.broker_profile.phone
                                    : ""}
                                </Typography>
                                <Typography variant="subtitle1">
                                  {" "}
                                  Address :{" "}
                                  {data &&
                                  data.broker_profile &&
                                  data.broker_profile
                                    ? data.broker_profile.address
                                    : ""}
                                </Typography>
                              </div>
                            }
                          />
                        </div></>):"-"}
                      </>
                    );
                  };
                  // data["Broker Name"] =
                  //   data && data.broker_profile && data.broker_profile
                  //     ? data.broker_profile.name
                  //     : "";
                  // data["Broker Phone No"] =
                  //   data && data.broker_profile && data.broker_profile
                  //     ? data.broker_profile.phone
                  //     : "";
                  // data["Broker Address"] =
                  //   data && data.broker_profile && data.broker_profile
                  //     ? data.broker_profile.address
                  //     : "";
console.log(data,"salesintent")
                  data["Miller Detail"] = () => {
                    return (
                      <>
                      {data && data.broker_profile &&data.broker_profile.name
                            ?(<>
                        
                        <div onClick={() => {}}>
                          {data && data.miller_profile && data.miller_profile
                            ? data.miller_profile.name
                            : ""}
                        </div>
                        <div style={{ margin: "5px 0 0 5px" }}>
                          <MouseOverPopover
                            msg={
                             
                                data &&
                                data.miller_profile &&
                                data.miller_profile
                                  &&data.miller_profile.name ?(<>
                                <InfoOutlinedIcon
                                  fontSize="small"
                                  style={{ color: "rgb(241, 154, 48)" }}
                                /></>):"-"
                              
                            }
                            details={
                              <div className="column-content">
                                {/* <Typography variant="subtitle1">
                             Name :  { data && data.miller_profile && data.miller_profile
                      ? data.miller_profile.name
                      : ""}
                                </Typography> */}
                                <Typography variant="subtitle1">
                                  {" "}
                                  Phone NO. :{" "}
                                  {data &&
                                  data.miller_profile &&
                                  data.miller_profile
                                    ? data.miller_profile.phone
                                    : ""}
                                </Typography>
                                <Typography variant="subtitle1">
                                  {" "}
                                  Address :{" "}
                                  {data &&
                                  data.miller_profile &&
                                  data.miller_profile
                                    ? data.miller_profile.address
                                    : ""}
                                </Typography>
                              </div>
                            }
                          />
                        </div></>):"-"}
                      </>
                    );
                  };
              //     data["Miller Detail"] = () => {
              //       return (
              //         <>
              //         {data && data.miller_profile && data.miller_profile
              //               ? data.miller_profile.name?(<>
              //           <div onClick={() => {}}>
              //             {data && data.miller_profile && data.miller_profile
              //               ? data.miller_profile.name
              //               : ""}
              //           </div>
              //           <div style={{ margin: "5px 0 0 5px" }}>
              //             <MouseOverPopover
              //               msg={
              //                 <InfoOutlinedIcon
              //                   fontSize="small"
              //                   style={{ color: "rgb(241, 154, 48)" }}
              //                 />
              //               }
              //               details={
              //                 <div className="column-content">
              
              //                   <Typography variant="subtitle1">
              //                     {" "}
              //                     Phone NO. :{" "}
              //                     {data &&
              //                     data.miller_profile &&
              //                     data.miller_profile
              //                       ? data.miller_profile.phone
              //                       : ""}
              //                   </Typography>
              //                   <Typography variant="subtitle1">
              //                     {" "}
              //                     Address :{" "}
              //                     {data &&
              //                     data.miller_profile &&
              //                     data.miller_profile
              //                       ? data.broker_profile.address
              //                       : ""}
              //                   </Typography>
              //                 </div>
              //               }
              //             />
              //           </div>
              // </> ) :"":""}</>)}
                  // data["Miller Name"] =
                  //   data && data.miller_profile && data.miller_profile
                  //     ? data.miller_profile.name
                  //     : "";

                  // data["Miller Phone No"] =
                  //   data && data.miller_details && data.miller_details
                  //     ? data.miller_details.phone
                  //     : "";
                  // data["Miller Address"] =
                  //   data && data.miller_details && data.miller_details
                  //     ? data.miller_details.address
                  //     : "";
                  data["Pending"] = `${
                    data.pending ? data.pending + " " + data.unit : "-"
                  }`;

                  data["Chat"] = () => {
                    return (
                      <>
                       
                      {data.chat_room_id?   <div style={{cursor:"pointer"}} onClick={() => {
                      openChat({...data,room_id:data.chat_room_id})
                    }}>
                         <Tooltip title="chat" arrow placement="top">
                    <ChatOutlinedIcon fontSize="small" style={{color:"rgb(241, 154, 48)"}}/>
                    </Tooltip>
                        
                   </div>:"-"}
                      </>
                    );
                  };
               
                  data["Action/Status"] = () => {
                    return (
                      <>
                       {data.status==="Accepted"&&data.is_po_created===false?   <div
                     
                        >
                          <Button
                            style={{
                             // border: "1px solid rgb(241, 154, 48)",
                              boxShadow:"1px 1px 7px #80808087",
                              marginRight: "10px",
                              color: "black",
                              fontWeight: "500",
                            }}
                            onClick={() => {
                              create_po(data)
                            }}>
                            Create PO
                          </Button>
                        </div>:data.status==="Pending"?
                        <div
                  
                        >
                          <Button
                            style={{
                              // border: "1px solid rgb(241, 154, 48)",
                              boxShadow:"1px 1px 7px #80808087",
                              marginRight: "10px",
                              color: "green",
                              fontWeight: "500",
                            }}
                            onClick={() => {
                              setButtonTrue({
                                open: true,
                                type: "approve",
                                data: data,
                              });
                            }}>
                            Accept
                          </Button>
                          <Button
                            style={{
                             
                              boxShadow:"1px 1px 7px #80808087",
                              color: "red",
                              fontWeight: "500",
                            }}
                            onClick={() => {
                              setButtonTrue({
                                open: true,
                                type: "reject",
                                data: data,
                              });
                            }}>
                            Reject
                          </Button>
                        </div>:data.status}
                      </>
                    );
                  };
        
                  return data;
                })
              : []
          }
          list={[
            // {
            //   name: "Chats",
            //   component: <ChatScreen selectedRate={true} />,
            // },
            {
              name: "Purchase Intent",
            },
          ]}
        />
      </div>
</>}
      <Dialog
        ref={(e) => {
          try {
            const target = ReactDOM.findDOMNode(e).children[2].children[0];
            target.style.borderRadius = "0px";
            target.style.maxHeight = "calc(100% - 400px)";
            target.style.minHeight = "280px";

            target.style.height = "calc(100% - 700px)";

            target.style.minWidth = "250px";

            target.style.maxWidth = "calc(100% - 800px)";
            target.style.margin = "32px 75px";
          } catch (e) {}
        }}
        open={ButtonTrue.open}
        // onClose={() => {
        //   this.setState({
        //     openStatusDialog: false,
        //     broken_amount: "",
        //     editDialog: false,
        //     added: false,
        //     selectedListArray: false,
        //     showInvoice: false,
        //   });
        // }}
        TransitionComponent={Transition}>
        <DialogActions
          className="d-flex justifySB"
          style={{
            background: primary,
            color: "white",
            padding: "0 24px",
          }}
          onClick={() => {
            setButtonTrue({ open: false });
          }}>
          {" "}
          <h3>Confirmation</h3>
          <Tooltip title="close" arrow placement="top">
            <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
              <CrossIcon strokeWidth="2" />
            </IconButton>
          </Tooltip>
        </DialogActions>{" "}
        <DialogContent
          className="dashedDividerBottom d-flex d-flex-column justifyC alignC"
          style={{ height: "100%" }}>
          {ButtonTrue.type === "approve"
            ? "Are You Sure You Want to Create Purchase Order with this offer ?"
            : "Are You Sure You Want to reject this ?"}
        </DialogContent>
        <div
          style={{
            justifyContent: "end",
            display: "flex",
            padding: "10px",
          }}>
          <Button
            onClick={() => {
              setButtonTrue({ open: false });
            }}
            style={{
              color: "rgb(241 154 48)",
              margin: "0 10px 0 0",
              padding: "2px 5px",
            }}>
            No
          </Button>
          <Button
            onClick={() => {
              // ButtonTrue.type==="approve"

              updatePurchaseIntent();
            }}
            style={{
              background: "rgb(241 154 48)",
              color: "white",
              margin: "0 0 0 10px",
              padding: "2px 5px",
              padding: "2px 5px",
            }}>
            Yes
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default Offers;
