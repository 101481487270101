import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PurchaseOrdersComponent from "./PurchaseOrdersComponent";
import { PurchaseOrdersFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";
import { useLocalStorage } from "./useLocalStorage";
function PurchaseOrdersContainer(props) {
  const [masters, setMasters] = useState({});

  const [loader, setLoader] = useState(true);
  const [reload, setreload] = useState();
  // const fetchAgain=fetchAgain?localStorage.getItem("fetchagain-bri"):undefined
  const fetchAgain = useLocalStorage("fetchagain-bri");
  useEffect(() => {
    fetchUsers();
    fetchAllData();
  }, []);
  useEffect(() => {
    const handleChange = () => {
      if (fetchAgain !== undefined) {
        if(fetchAgain==="survey-company"){
          fetchSurvey();
        }else if(fetchAgain==="transporter"){fetchTranspoter()}
       
      //   }if(fetchAgain==="inco-term"){
      //     // client
      //     fetchIncoTerm()
       
      //   }else if(fetchAgain==="client")
      //     fetchClients()
      //     else if(fetchAgain==="so-payment-term")
      //     fetchPaymentTerm()
        
     
      }
    };

    window.addEventListener("storage", handleChange);

    return () => {
      window.removeEventListener("storage", handleChange);
    };
  }, []);
  useEffect(() => {
    console.log(fetchAgain, reload, "fetchfetchAgainAgainreload");
    if (fetchAgain !== undefined || reload === true) {
      fetchAllData();
      setreload(false);
      localStorage.removeItem("fetchagain-bri");
    }
  }, [fetchAgain, reload]);
  const fetchAllData = () => {
    props
      .PurchaseOrdersFunction(
        "get",
        "masters/cash-discount/list",
        null,
        "CashDiscountList",
        null,
        { is_active: "True" }
      )
      .then((CashDiscountList) => {
        if (CashDiscountList) {
          const newMasters = masters;
          newMasters.CashDiscountList = CashDiscountList;
          setMasters(newMasters);
        }
        props
          .PurchaseOrdersFunction(
            "get",
            "masters/miller/list",
            null,
            "MillerList",
            null,
            { is_active: "True" }
          )
          .then((MillerList) => {
            if (MillerList) {
              const newMasters = masters;
              newMasters.MillerList = MillerList;
              setMasters(newMasters);
            }
            props
              .PurchaseOrdersFunction(
                "get",
                "masters/item-category/list",
                null,
                //state.singlelistView.id,
                "ItemCateoryList",
                null,
                { is_active: "True" }
              )
              .then((ItemCateoryList) => {
                if (ItemCateoryList) {
                  const newMasters = masters;
                  newMasters.ItemCateoryList = ItemCateoryList;
                  setMasters(newMasters);
                }
                props
                  .PurchaseOrdersFunction(
                    "get",
                    "masters/item-type/list",
                    null,
                    //state.singlelistView.id,
                    "ItemList",
                    null,
                    { is_active: "True" }
                  )
                  .then((ItemList) => {
                    if (ItemList) {
                      const newMasters = masters;
                      newMasters.ItemList = ItemList;
                      setMasters(newMasters);
                    }
                    props
                      .PurchaseOrdersFunction(
                        "get",
                        "masters/brand/list",
                        null,
                        //state.singlelistView.id,
                        "BrandList",
                        null,
                        { is_active: "True",is_group:"False" }
                      )
                      .then((BrandList) => {
                        if (BrandList) {
                          const newMasters = masters;
                          newMasters.BrandList = BrandList;
                          setMasters(newMasters);
                          console.log(BrandList,"sssssssssssssssssss")
                     
                        }
                        props
                          .PurchaseOrdersFunction(
                            "get",
                            "masters/packaging/list",
                            null,
                            //state.singlelistView.id,
                            "PackagingList",
                            null,
                            { is_active: "True" }
                          )
                          .then((PackagingList) => {
                            if (PackagingList) {
                              const newMasters = masters;
                              newMasters.PackagingList = PackagingList;
                              setMasters(newMasters);
                            }
                            props
                              .PurchaseOrdersFunction(
                                "get",
                                "masters/broker/list",
                                null,
                                //state.singlelistView.id,
                                "BrokerList",
                                null,
                                { is_active: "True" }
                              )
                              .then((BrokerList) => {
                                if (BrokerList) {
                                  console.log(
                                    masters,
                                    "mastallers",
                                    BrokerList
                                  );
                                  const newMasters = masters;
                                  newMasters.BrokerList = BrokerList;
                                  setMasters(newMasters);
                                }
                                props
                                  .PurchaseOrdersFunction(
                                    "get",
                                    "masters/contract-type/list",
                                    null,
                                    //state.singlelistView.id,
                                    "ContractTypeList",
                                    null,
                                    { is_active: "True" }
                                  )
                                  .then((ContractTypeList) => {
                                    if (ContractTypeList) {
                                      const newMasters = masters;
                                      newMasters.ContractTypeList =
                                        ContractTypeList;
                                      setMasters(newMasters);
                                    }
                                    props
                                      .PurchaseOrdersFunction(
                                        "get",
                                        "masters/port/list",
                                        null,
                                        //state.singlelistView.id,
                                        "PortsList"
                                      )
                                      .then((PortsList) => {
                                        if (PortsList) {
                                          const newMasters = masters;
                                          newMasters.PortsList = PortsList;
                                          setMasters(newMasters);
                                        }
                                        props
                                          .PurchaseOrdersFunction(
                                            "get",
                                            "masters/payment-terms/list",
                                            null,
                                            //state.singlelistView.id,
                                            "PaymentTermList",
                                            null,
                                            { is_active: "True" }
                                          )
                                          .then((PaymentTermList) => {
                                            if (PaymentTermList) {
                                              const newMasters = masters;
                                              newMasters.PaymentTermList =
                                                PaymentTermList;
                                              setMasters(newMasters);
                                            }
                                            // props
                                            //   .PurchaseOrdersFunction(
                                            //     "get",
                                            //     "masters/transporter/list",
                                            //     null,
                                            //     //state.singlelistView.id,
                                            //     "TransporterList",
                                            //     null,
                                            //     { is_active: "True" }
                                            //   )
                                            //   .then((TransporterList) => {
                                            //     if (TransporterList) {
                                            //       const newMasters = masters;
                                            //       newMasters.TransporterList =
                                            //         TransporterList;
                                            //       setMasters(newMasters);
                                            //     }
                                                props
                                                  .PurchaseOrdersFunction(
                                                    "get",
                                                    "masters/hsn/list",
                                                    null,
                                                    //state.singlelistView.id,
                                                    "HSNlist",
                                                    null,
                                                    { is_active: "True" }
                                                  )
                                                  .then((HSNlist) => {
                                                    if (HSNlist) {
                                                      const newMasters =
                                                        masters;
                                                      newMasters.HSNlist =
                                                        HSNlist;
                                                      setMasters(newMasters);
                                                    }
                                                    props
                                                      .PurchaseOrdersFunction(
                                                        "get",
                                                        "dispatch/sales-order/list",
                                                        null,
                                                        //state.singlelistView.id,
                                                        "SalesOrderList"
                                                      )
                                                      .then(
                                                        (SalesOrderList) => {
                                                          if (SalesOrderList) {
                                                            const newMasters =
                                                              masters;
                                                            newMasters.SalesOrderList =
                                                              SalesOrderList;
                                                            setMasters(
                                                              newMasters
                                                            );
                                                          }
                                                          fetchSurvey()
                                                          fetchTranspoter()
                                                          // props
                                                          //   .PurchaseOrdersFunction(
                                                          //     "get",
                                                          //     "masters/survey-company/list",
                                                          //     null,
                                                          //     //state.singlelistView.id,
                                                          //     "SurveyCompanyList",
                                                          //     null,
                                                          //     {
                                                          //       is_active:
                                                          //         "True",
                                                          //     }
                                                          //   )
                                                          //   .then(
                                                          //     (
                                                          //       SurveyCompanyList
                                                          //     ) => {
                                                          //       if (
                                                          //         SurveyCompanyList
                                                          //       ) {
                                                          //         const newMasters =
                                                          //           masters;
                                                          //         newMasters.SurveyCompanyList =
                                                          //           SurveyCompanyList;
                                                          //         setMasters(
                                                          //           newMasters
                                                          //         );
                                                          //       }

                                                                props
                                                                  .PurchaseOrdersFunction(
                                                                    "get",
                                                                    "dispatch/rice-bag-movement/list",
                                                                    null,
                                                                    //state.singlelistView.id,
                                                                    "ricebag",
                                                                    null,
                                                                    {
                                                                      is_active:
                                                                        "True",
                                                                    }
                                                                  )
                                                                  .then(
                                                                    (
                                                                      ricebag
                                                                    ) => {
                                                                      if (
                                                                        ricebag
                                                                      ) {
                                                                        const newMasters =
                                                                          masters;
                                                                        newMasters.ricebag =
                                                                          ricebag;
                                                                        setMasters(
                                                                          newMasters
                                                                        );
                                                                      }
                                                                      props
                                                                        .PurchaseOrdersFunction(
                                                                          "get",
                                                                          "dispatch/po-packaging/list",
                                                                          null,
                                                                          //state.singlelistView.id,
                                                                          "poPackahging",
                                                                          null,
                                                                          {
                                                                            is_active:
                                                                              "True",
                                                                          }
                                                                        )
                                                                        .then(
                                                                          (
                                                                            poPackahging
                                                                          ) => {
                                                                            if (
                                                                              poPackahging
                                                                            ) {
                                                                              const newMasters =
                                                                                masters;
                                                                              newMasters.poPackahging =
                                                                                poPackahging;
                                                                              setMasters(
                                                                                newMasters
                                                                              );
                                                                            }
                                                                            props
                                                                              .PurchaseOrdersFunction(
                                                                                "get",
                                                                                "masters/surveyor/list",
                                                                                null,
                                                                                //state.singlelistView.id,
                                                                                "allsurveyorList",
                                                                                null,
                                                                                {
                                                                                  is_active:
                                                                                    "True",
                                                                                }
                                                                              )
                                                                              .then(
                                                                                (
                                                                                  allsurveyorList
                                                                                ) => {
                                                                                  if (
                                                                                    allsurveyorList
                                                                                  ) {
                                                                                    const newMasters =
                                                                                      masters;
                                                                                    newMasters.allsurveyorList =
                                                                                      allsurveyorList;
                                                                                    setMasters(
                                                                                      newMasters
                                                                                    );
                                                                                    setLoader(
                                                                                      false
                                                                                    );
                                                                                  } else {
                                                                                    setLoader(
                                                                                      false
                                                                                    );
                                                                                  }
                                                                                }
                                                                              );
                                                                          }
                                                                        );
                                                                    }
                                                                  );
                                                              }
                                                            );
                                                      //   }
                                                      // );
                                                  });
                                              });
                                          // });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });
  };
  const fetchSurvey = () => {

  
    props
    .PurchaseOrdersFunction(
      "get",
      "masters/survey-company/list",
      null,
      //state.singlelistView.id,
      "SurveyCompanyList",
      null,
      {
        is_active:
          "True",
      }
    )
    .then(
      (
        SurveyCompanyList
      ) => {
        setreload(false);
        localStorage.removeItem("fetchagain-bri");
        if (
          SurveyCompanyList
        ) {
          const newMasters =
            masters;
          newMasters.SurveyCompanyList =
            SurveyCompanyList;
          setMasters(
            (p)=>{return {...newMasters}}
          );
        }
    
  })
}



const fetchTranspoter = () => {
props
.PurchaseOrdersFunction(
  "get",
  "masters/transporter/list",
  null,
  //state.singlelistView.id,
  "TransporterList",
  null,
  { is_active: "True" }
)
.then((TransporterList) => {
  setreload(false);
  localStorage.removeItem("fetchagain-bri");
  if (TransporterList) {
    const newMasters = masters;
    newMasters.TransporterList =
      TransporterList;
  
    setMasters(
      (p)=>{return {...newMasters}}
    );
  }

})
}
  // const fetchSurvey = () => {
  //   props
  //   .PurchaseOrdersFunction(
  //     "get",
  //     "masters/survey-company/list",
  //     null,
  //     //state.singlelistView.id,
  //     "SurveyCompanyList",
  //     null,
  //     {
  //       is_active:
  //         "True",
  //     }
  //   )
  //   .then(
  //     (
  //       SurveyCompanyList
  //     ) => {
  //       setreload(false);
  //       localStorage.removeItem("fetchagain-bri");
  //       if (
  //         SurveyCompanyList
  //       ) {
  //         const newMasters =
  //           masters;
  //         newMasters.SurveyCompanyList =
  //           SurveyCompanyList;
  //         setMasters(
  //           newMasters
  //         );
  //       }
  // }
  const fetchUsers = () => {
    if (props.user.uid) {
      // props.PurchaseOrdersFunction("get", EndPoints["PurchaseOrders"]);
    }
  };
  useEffect(() => {
    if (props.onLoader) {
      props.onLoader(loader);
    }
  }, [loader]);
  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return (
    <PurchaseOrdersComponent
      {...props}
      params={params}
      masters={masters}
      setreload={setreload}
      loader={loader}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  PurchaseOrders: state.modules.PurchaseOrders,
  loading: state.modules.loading,
  [EndPoints["PurchaseOrders"]]: state.modules[EndPoints["PurchaseOrders"]],
  PODispatchedList: state.modules.PODispatchedList,
  PO_Items_List: state.modules.PO_Items_List,
});
const mapDispatchToProps = {
  PurchaseOrdersFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrdersContainer);
