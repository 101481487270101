import React, { useState } from "react";
import { StyledButton } from "../styles/StyledComponents";
import { List, ListItem, Popover } from "@material-ui/core";

import ClickAwayListener from "@mui/base/ClickAwayListener";

import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
function ShowPackaging(props) {
  const [openPopover, setOpenPopover] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const { title, details, noTotalbags, noBags, returned_bags, totalCiBags,noTotalWeight } = props;
  return (
    <>
      {title
        ? title
        : details &&
          details[0] &&
          `${details[0].size} ${details[0].unit} ${
            details.length > 1 ? `.. (${details.length > 9 ? details.length : `0${details.length}`})` : ""
          }`}
     
      <StyledButton
        name={<ArrowDropDownSharpIcon fontSize="medium" width="20" />}
        onClick={(e) => {
          e.stopPropagation();
          setOpenPopover(true);
          setAnchorEl(e.currentTarget);
        }}
        padding="0"
        minWidth="20px"
      />

      <Popover
        open={openPopover}
        // onRequestClose={() => {
        //   setOpenPopover(false);
        //   setAnchorEl(null);
        // }}
        anchorEl={anchorEl}
        style={{ marginTop: "-2.5%" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <ClickAwayListener
          onClickAway={(e) => {
            e.stopPropagation();
            setOpenPopover(false);
            setAnchorEl(null);
          }}
        >
          <div
            style={{
              height: "auto",
              minHeight: "fit-content",
              width: "100%",
              padding: "8px 0",
            }}
            className="d-flex d-flex-column alignC justifyC"
          >
            {details &&
              details.map((sDetails) => (
                
                <div key={sDetails.size && sDetails.size.toString()} style={{ width: "100%", padding: "8px 16px" }}>
                 
                  <div className="d-flex alignC textC" style={{ margin: "0", fontSize: "0.9rem", height: "24px" }}>
                    {<span style={{ fontWeight: "600", minWidth: "fit-content" }}>{"Size Unit "}</span>}{" "}
                    <div style={{ marginRight: "3px" }} />
                    <span style={{ width: "fit-content", minWidth: "96.28px" }}>
                      {` : ${sDetails.size} ${sDetails.unit} , `}{" "}
                    </span>
                    <div style={{ marginRight: "3px" }} />{" "}
                    <span style={{ fontWeight: "600", minWidth: "fit-content" }}>{" Bag Type "}</span>{" "}
                    <div style={{ marginRight: "3px" }} />
                    <span style={{ width: "fit-content", minWidth: "50.99px" }}>
                      {` : ${sDetails.bag_type && sDetails.bag_type ? sDetails.bag_type : ""} , `}
                    </span>
                    {noTotalWeight ? (
                      ""
                    ) : (
                      <>
                    <div style={{ marginRight: "3px" }} />{" "}
                    <span style={{ fontWeight: "600", minWidth: "fit-content" }}>{" Bag Weight "}</span>{" "}
                    <div style={{ marginRight: "3px" }} />
                    <span style={{ width: "fit-content", minWidth: "50.99px" }}>
                      {` :    ${sDetails.bag_weight && sDetails.bag_weight ? sDetails.bag_weight : ""} , `}
                    </span>
                    </>)}
                   
                    {noTotalbags ? (
                      ""
                    ) : (
                      <>
                        <div style={{ marginRight: "3px" }} />
                        <div style={{ marginRight: "3px" }} />{" "}
                        <span style={{ fontWeight: "600", minWidth: "fit-content" }}>{"  Remaining Bags "}</span>
                        <div style={{ marginRight: "3px" }} />{" "}
                        <span style={{ width: "fit-content", minWidth: "35px" }}>
                          {" "}
                          {` : ${
                            sDetails.new_remaining_bags || sDetails.new_remaining_bags === 0
                              ? sDetails.new_remaining_bags
                              : sDetails.remaining_bags
                              ? sDetails.remaining_bags
                              : ""
                          }`}{" "}
                        </span>
                      </>
                    )}
                    {totalCiBags ? (
                      <>
                        {" "}
                        <div style={{ marginRight: "3px" }} />
                        <span style={{ fontWeight: "600", minWidth: "fit-content" }}>{"  Total Bags "}</span>
                        <div style={{ marginRight: "3px" }} />
                        <span style={{ width: "fit-content", minWidth: "35px" }}>{` : ${
                          sDetails.total_bags ? sDetails.total_bags : ""
                        }`}</span>
                      </>
                    ) : (
                      ""
                    )}
                    {noBags ? (
                      ""
                    ) : (
                      <>
                        {" "}
                        <div style={{ marginRight: "3px" }} />
                        <span style={{ fontWeight: "600", minWidth: "fit-content" }}>{"  Used Bags "}</span>
                        <div style={{ marginRight: "3px" }} />
                        <span style={{ width: "fit-content", minWidth: "35px" }}>{` : ${
                          sDetails.used_bags ? sDetails.used_bags : ""
                        }`}</span>
                      </>
                    )}
                    {returned_bags && (
                      <>
                        <div style={{ marginRight: "3px" }} />
                        <span style={{ fontWeight: "600", minWidth: "fit-content" }}>{"  Returned Bags "}</span>
                        <div style={{ marginRight: "3px" }} />
                        <span style={{ width: "fit-content", minWidth: "35px" }}>{` : ${
                          sDetails.returned_bags ? sDetails.returned_bags : ""
                        }`}</span>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </ClickAwayListener>
      </Popover>
    </>
  );
}

export default ShowPackaging;
