import React from "react";
export const CrossIcon = (props) => (
  <svg
    style={{
      fill: props.color ? props.color : "white",
      stroke: props.color ? props.color : "white",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: props.strokeWidth ? props.strokeWidth : "3",
    }}
    width="14.828"
    height={props.height ? props.height : "14.828"}
    viewBox="0 0 14.828 14.828">
    <g transform="translate(-4.586 -4.586)">
      <line x1="12" y2="12" transform="translate(6 6)" />
      <line x2="12" y2="12" transform="translate(6 6)" />
    </g>
  </svg>
);

export const SOIcon = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.4227 13.5431C27.5727 13.9033 25.6568 13.7519 22.5658 13.6451C22.2854 13.6451 22.0611 13.4208 22.056 13.1404C22.051 12.86 22.2803 12.6306 22.5608 12.6255C27.4728 12.8257 30.8798 13.2135 30.5086 10.6322C30.2365 8.83274 27.9453 8.162 25.4921 9.44429C25.2576 9.16393 24.967 8.92434 24.6458 8.74593C25.5533 8.21571 26.4913 7.89453 27.4549 7.79253C32.098 7.30427 32.8466 12.8884 29.4227 13.5431Z"
      fill={props.color ? props.color : "currentColor"}
    />
    <path
      d="M27.256 17.2647L26.4301 14.7871C25.1379 14.7786 23.7458 14.7028 23.1011 14.6902C22.8564 14.8227 22.5811 14.889 22.2956 14.889C20.9859 14.889 20.1257 13.5021 20.7152 12.3299C21.016 11.7283 21.6227 11.3509 22.2956 11.3509C22.6929 11.3509 23.08 11.5054 23.2439 11.6365C23.6949 11.6475 24.4686 11.6907 25.4054 11.7283L25.0893 10.7749C24.9137 10.237 24.5173 9.81842 24.0748 9.59724C23.5942 9.35255 23.0265 9.30002 22.4995 9.48L16.0046 11.6263C15.4948 11.7945 15.0564 12.1718 14.8168 12.6561L8.66858 24.922C8.42385 25.4114 8.38305 25.9722 8.5564 26.4922C8.72975 27.0122 9.09169 27.4302 9.58111 27.6749L18.2427 32.0184C18.5333 32.1612 18.8391 32.2377 19.1603 32.2377C19.9454 32.2377 20.649 31.7992 20.9956 31.1008L27.1439 18.8349C27.3886 18.3506 27.4294 17.7796 27.256 17.2647ZM19.6038 27.7412C19.5172 27.9196 19.3337 28.0216 19.1501 28.0216C19.0737 28.0216 18.9921 28.0063 18.9207 27.9706L12.6246 24.8047C12.3697 24.6773 12.2678 24.3714 12.3952 24.1216C12.5227 23.8718 12.8286 23.7698 13.0784 23.8922L19.3795 27.058C19.6294 27.1855 19.7313 27.4914 19.6038 27.7412ZM21.1282 24.5804C21.0364 24.7588 20.858 24.8608 20.6694 24.8608C20.5929 24.8608 20.5164 24.8404 20.445 24.8047L14.1439 21.6439C13.8941 21.5165 13.7921 21.2106 13.9196 20.9557C14.047 20.7059 14.3529 20.604 14.6027 20.7314L20.8988 23.8922C21.1537 24.0196 21.2556 24.3255 21.1282 24.5804Z"
      fill={props.color ? props.color : "currentColor"}
    />
  </svg>
);
export const POIcon = (props) => (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6883 22.9822C12.6883 23.9205 11.9288 24.6797 10.9911 24.6797C10.0539 24.6797 9.29559 23.9205 9.29559 22.9822C9.29559 22.0433 10.0539 21.283 10.9911 21.283C11.9288 21.283 12.6883 22.0433 12.6883 22.9822ZM19.4503 22.9822C19.4503 23.9205 18.6903 24.6797 17.7531 24.6797C16.8171 24.6797 16.057 23.9205 16.057 22.9822C16.057 22.0433 16.8171 21.283 17.7531 21.283C18.6903 21.283 19.4503 22.0433 19.4503 22.9822ZM21.68 4.92088C21.7912 4.92406 21.8952 4.96451 21.9749 5.04453L22.3615 5.43019C22.5417 5.61248 22.5192 5.92708 22.31 6.13682L18.9127 9.53819C18.7486 9.70228 18.4355 9.7147 18.2595 9.53819L16.6874 7.96375C16.4782 7.75431 16.456 7.43971 16.6377 7.25713L17.0231 6.87146C17.2051 6.68946 17.5194 6.71344 17.7285 6.92259L18.5863 7.78146L21.2692 5.09537C21.3879 4.97635 21.5387 4.91771 21.68 4.92088ZM19.5 2.36133C16.7784 2.36133 14.5739 4.56988 14.5724 7.29353C14.5724 10.0172 16.7784 12.2254 19.5 12.2254C22.2202 12.2254 24.4261 10.0172 24.4261 7.29353C24.4261 4.56988 22.2202 2.36133 19.5 2.36133Z"
      fill="white"
    />
    <path
      d="M2.5639 2.47717C1.3315 2.51184 1.13419 4.37257 2.32903 4.69786L3.8847 5.24733L5.71741 14.7971C5.93523 15.9423 6.29923 16.9759 6.80652 17.866C7.55128 19.177 8.8455 20.1427 10.4416 20.1427H18.371C19.8019 20.1696 19.8019 17.8796 18.371 17.907H10.4419C9.31379 17.7623 8.84579 17.1929 8.43325 16.3597H18.8968C19.8568 16.3597 20.3938 15.5087 20.6301 14.5218L20.9112 13.5607C20.4565 13.6629 19.9845 13.7187 19.4983 13.7187C15.9548 13.7187 13.0812 10.8414 13.0812 7.29382C13.0812 6.56351 13.2045 5.86237 13.4302 5.20891L6.14005 5.21468L5.73821 3.97911C5.62843 3.60991 5.35023 3.32853 4.99952 3.22915L2.5639 2.47717Z"
      fill="white"
    />
  </svg>
);
export const PORTIcon = (props) => (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_133_551)">
      <path
        d="M25.6381 8.76144L13.4506 0.636441C13.3171 0.547474 13.1603 0.5 12.9999 0.5C12.8395 0.5 12.6827 0.547474 12.5492 0.636441L0.361745 8.76144C0.21667 8.85818 0.106564 8.99902 0.0476993 9.16315C-0.0111658 9.32728 -0.0156671 9.506 0.0348606 9.67288C0.0853883 9.83977 0.188264 9.98598 0.328285 10.0899C0.468306 10.1938 0.638043 10.2499 0.812412 10.2499H2.16658V25.4166C2.16658 25.7039 2.28071 25.9795 2.48388 26.1826C2.68704 26.3858 2.96259 26.4999 3.24991 26.4999H4.33324V14.3124C4.33324 13.865 4.69941 13.4999 5.14575 13.4999H20.8541C21.3015 13.4999 21.6666 13.865 21.6666 14.3124V26.4999H22.7499C23.0372 26.4999 23.3128 26.3858 23.5159 26.1826C23.7191 25.9795 23.8332 25.7039 23.8332 25.4166V10.2499H25.1874C25.3618 10.2499 25.5315 10.1938 25.6715 10.0899C25.8116 9.98598 25.9144 9.83977 25.965 9.67288C26.0155 9.506 26.011 9.32728 25.9521 9.16315C25.8933 8.99902 25.7832 8.85818 25.6381 8.76144Z"
        fill="white"
      />
      <path
        d="M10.0208 21.0835V21.896C10.0208 22.1115 9.93522 22.3181 9.78285 22.4705C9.63047 22.6229 9.42381 22.7085 9.20832 22.7085C8.99283 22.7085 8.78617 22.6229 8.6338 22.4705C8.48142 22.3181 8.39582 22.1115 8.39582 21.896V21.0835H6.49999C6.35633 21.0835 6.21855 21.1406 6.11697 21.2421C6.01539 21.3437 5.95832 21.4815 5.95832 21.6252V25.9585C5.95832 26.1022 6.01539 26.2399 6.11697 26.3415C6.21855 26.4431 6.35633 26.5002 6.49999 26.5002H11.9167C12.0603 26.5002 12.1981 26.4431 12.2997 26.3415C12.4013 26.2399 12.4583 26.1022 12.4583 25.9585V21.6252C12.4583 21.4815 12.4013 21.3437 12.2997 21.2421C12.1981 21.1406 12.0603 21.0835 11.9167 21.0835H10.0208ZM17.6042 21.0835V21.896C17.6042 22.1115 17.5186 22.3181 17.3662 22.4705C17.2138 22.6229 17.0071 22.7085 16.7917 22.7085C16.5762 22.7085 16.3695 22.6229 16.2171 22.4705C16.0648 22.3181 15.9792 22.1115 15.9792 21.896V21.0835H14.0833C13.9397 21.0835 13.8019 21.1406 13.7003 21.2421C13.5987 21.3437 13.5417 21.4815 13.5417 21.6252V25.9585C13.5417 26.1022 13.5987 26.2399 13.7003 26.3415C13.8019 26.4431 13.9397 26.5002 14.0833 26.5002H19.5C19.6436 26.5002 19.7814 26.4431 19.883 26.3415C19.9846 26.2399 20.0417 26.1022 20.0417 25.9585V21.6252C20.0417 21.4815 19.9846 21.3437 19.883 21.2421C19.7814 21.1406 19.6436 21.0835 19.5 21.0835H17.6042ZM13.8125 14.5835V15.396C13.8125 15.6115 13.7269 15.8181 13.5745 15.9705C13.4221 16.1229 13.2155 16.2085 13 16.2085C12.7845 16.2085 12.5778 16.1229 12.4255 15.9705C12.2731 15.8181 12.1875 15.6115 12.1875 15.396V14.5835H10.2917C10.148 14.5835 10.0102 14.6406 9.90864 14.7421C9.80706 14.8437 9.74999 14.9815 9.74999 15.1252V19.4585C9.74999 19.6022 9.80706 19.7399 9.90864 19.8415C10.0102 19.9431 10.148 20.0002 10.2917 20.0002H15.7083C15.852 20.0002 15.9898 19.9431 16.0913 19.8415C16.1929 19.7399 16.25 19.6022 16.25 19.4585V15.1252C16.25 14.9815 16.1929 14.8437 16.0913 14.7421C15.9898 14.6406 15.852 14.5835 15.7083 14.5835H13.8125Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_133_551">
        <rect
          width="26"
          height="26"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const SettingIcon = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 1.55988C11.7138 1.55988 10.66 2.61366 10.66 3.89988C10.66 5.00722 11.4418 5.93568 12.48 6.17488V9.40876C11.5364 9.54483 10.6841 10.0467 10.1075 10.806L7.39364 9.13238C7.49074 8.87251 7.54033 8.59729 7.54002 8.31988C7.54002 7.03366 6.48624 5.97988 5.20002 5.97988C3.9138 5.97988 2.86002 7.03366 2.86002 8.31988C2.86002 9.6061 3.9138 10.6599 5.20002 10.6599C5.82662 10.6599 6.39524 10.4074 6.81696 10.0018L9.60364 11.716C9.45232 12.1159 9.36002 12.5483 9.36002 12.9999C9.36002 13.4492 9.45362 13.8776 9.6039 14.2754L6.8089 15.9899C6.38744 15.589 5.8222 15.3399 5.20002 15.3399C3.9138 15.3399 2.86002 16.3937 2.86002 17.6799C2.86002 18.9661 3.9138 20.0199 5.20002 20.0199C6.48624 20.0199 7.54002 18.9661 7.54002 17.6799C7.54002 17.391 7.48282 17.1157 7.38558 16.8593L10.1075 15.1854C10.673 15.9314 11.5126 16.4516 12.48 16.5913V19.8249C11.4418 20.0641 10.66 20.9925 10.66 22.0999C10.66 23.3861 11.7138 24.4399 13 24.4399C14.2862 24.4399 15.34 23.3861 15.34 22.0999C15.34 20.9925 14.5582 20.0641 13.52 19.8249V16.591C14.4875 16.4516 15.327 15.9314 15.8925 15.1854L18.6145 16.8593C18.5135 17.1212 18.4612 17.3992 18.46 17.6799C18.46 18.9661 19.5138 20.0199 20.8 20.0199C22.0862 20.0199 23.14 18.9661 23.14 17.6799C23.14 16.3937 22.0862 15.3399 20.8 15.3399C20.1776 15.3399 19.6121 15.589 19.1911 15.9899L16.3961 14.2754C16.5464 13.8776 16.64 13.4492 16.64 12.9999C16.64 12.5477 16.548 12.1159 16.3961 11.716L19.1833 10.0018C19.6045 10.4074 20.1734 10.6599 20.8 10.6599C22.0862 10.6599 23.14 9.6061 23.14 8.31988C23.14 7.03366 22.0862 5.97988 20.8 5.97988C19.5138 5.97988 18.46 7.03366 18.46 8.31988C18.4597 8.59729 18.5093 8.87251 18.6064 9.13238L15.8925 10.8063C15.316 10.0468 14.4638 9.5447 13.52 9.4085V6.17488C14.5582 5.93568 15.34 5.00722 15.34 3.89988C15.34 2.61366 14.2862 1.55988 13 1.55988Z"
      fill="white"
    />
  </svg>
);
export const VendorsIcon = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_133_554)">
      <path
        d="M6.29686 20.7189C5.68749 21.5314 4.67186 22.1001 3.53436 22.1001C2.43749 22.0595 1.42186 21.5314 0.812489 20.7189C0.324989 21.0032 -1.14441e-05 21.4907 -1.14441e-05 22.1001V25.3095C-1.14441e-05 25.5126 0.162489 25.7157 0.406239 25.7157H6.70311C6.90624 25.7157 7.10936 25.5532 7.10936 25.3095V22.1001C7.06874 21.4907 6.78436 21.0032 6.29686 20.7189Z"
        fill="white"
      />
      <path
        d="M3.53438 21.2875C4.99275 21.2875 6.175 20.1235 6.175 18.6875C6.175 17.2516 4.99275 16.0875 3.53438 16.0875C2.076 16.0875 0.893752 17.2516 0.893752 18.6875C0.893752 20.1235 2.076 21.2875 3.53438 21.2875Z"
        fill="white"
      />
      <path
        d="M25.1875 20.7189C24.5781 21.5314 23.5625 22.1001 22.425 22.1001C21.2875 22.1001 20.3125 21.572 19.6625 20.7189C19.175 21.0032 18.85 21.4907 18.85 22.1001V25.3095C18.85 25.5126 19.0125 25.7157 19.2562 25.7157H25.5937C25.7969 25.7157 26 25.5532 26 25.3095V22.1001C26 21.4907 25.675 21.0032 25.1875 20.7189Z"
        fill="white"
      />
      <path
        d="M22.4656 21.2875C23.924 21.2875 25.1062 20.1235 25.1062 18.6875C25.1062 17.2516 23.924 16.0875 22.4656 16.0875C21.0072 16.0875 19.825 17.2516 19.825 18.6875C19.825 20.1235 21.0072 21.2875 22.4656 21.2875Z"
        fill="white"
      />
      <path
        d="M9.83125 9.91252H16.1281C16.3312 9.91252 16.5344 9.75002 16.5344 9.50627V6.2969C16.5344 5.72815 16.2094 5.20002 15.7219 4.91565C15.1125 5.72815 14.0969 6.25627 12.9594 6.25627C11.8219 6.25627 10.8469 5.72815 10.1969 4.91565C9.70937 5.20002 9.38437 5.68752 9.38437 6.2969V9.50627C9.46562 9.75002 9.62812 9.91252 9.83125 9.91252Z"
        fill="white"
      />
      <path
        d="M13 5.48442C14.4584 5.48442 15.6406 4.32036 15.6406 2.88442C15.6406 1.44848 14.4584 0.284424 13 0.284424C11.5416 0.284424 10.3594 1.44848 10.3594 2.88442C10.3594 4.32036 11.5416 5.48442 13 5.48442Z"
        fill="white"
      />
      <path
        d="M18.4437 20.0281L15.6 18.3625L13.4062 14.625V11.4156C13.4062 11.2125 13.2437 11.0094 13 11.0094C12.7969 11.0094 12.5937 11.1719 12.5937 11.4156V14.625L10.4 18.3625L7.55625 20.0281C7.35312 20.15 7.3125 20.3938 7.43437 20.5563C7.51562 20.6781 7.6375 20.7594 7.75937 20.7594C7.84062 20.7594 7.88125 20.7594 7.9625 20.7188L10.8062 19.0532H15.2344L18.0781 20.7188C18.1594 20.7594 18.2 20.7594 18.2812 20.7594C18.4031 20.7594 18.5656 20.6781 18.6062 20.5563C18.6875 20.3938 18.6469 20.15 18.4437 20.0281ZM11.375 18.2813L13 15.5188L14.625 18.2813H11.375Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_133_554">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const BrandIcon = (props) => (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5296 7.14223C22.3397 5.75367 22.75 4.34073 22.75 2.93754C22.75 2.71167 22.6557 2.49635 22.4908 2.34279C22.3259 2.18923 22.1032 2.11123 21.879 2.12748C20.8536 2.20223 19.7519 2.61742 18.5169 3.39498C14.8541 2.79617 11.1459 2.79617 7.48311 3.39498C6.24811 2.61742 5.14636 2.20223 4.12099 2.12748C3.89674 2.11123 3.67411 2.18923 3.50918 2.34279C3.34424 2.49635 3.24999 2.71167 3.24999 2.93754C3.24999 4.34073 3.6603 5.75367 4.47036 7.14223C3.9333 11.3624 3.9333 15.6377 4.47036 19.8579C3.6603 21.2464 3.24999 22.6594 3.24999 24.0625C3.24999 24.2884 3.34424 24.5037 3.50918 24.6573C3.67411 24.81 3.89674 24.8872 4.12099 24.8726C5.14636 24.7979 6.24811 24.3827 7.48311 23.6051C11.1459 24.2039 14.8541 24.2039 18.5169 23.6051C19.7519 24.3827 20.8536 24.7979 21.879 24.8726C22.1211 24.8872 22.334 24.8035 22.4908 24.6573C22.6557 24.5037 22.75 24.2884 22.75 24.0625C22.75 22.6594 22.3397 21.2464 21.5296 19.8579C22.0659 15.6377 22.0659 11.3624 21.5296 7.14223ZM18.6875 19.1875C18.6875 19.636 18.3235 20 17.875 20H8.12499C7.67649 20 7.31249 19.636 7.31249 19.1875V7.81254C7.31249 7.36404 7.67649 7.00004 8.12499 7.00004H17.875C18.3235 7.00004 18.6875 7.36404 18.6875 7.81254V19.1875Z"
      fill="white"
    />
    <path
      d="M13.8832 14.0567C13.8434 14.0632 13.8068 14.0282 13.8133 13.9876C13.9141 13.3254 14.4056 12.2196 14.625 11.8751C13.9425 12.1294 12.9968 13.2905 13 14.1461V15.9376H14.625V16.7501H10.5625V15.9376H12.1875V14.5783C12.1704 13.5846 11.0443 12.7486 10.3586 12.4967C10.3334 12.4877 10.3131 12.5162 10.3277 12.5389C10.6665 13.0524 10.9168 13.609 11.0216 14.272C11.0256 14.2964 11.0029 14.3175 10.9785 14.3134C8.91313 13.9234 8.9375 12.4041 8.9375 11.5452C8.9375 11.3064 9.12925 11.0626 9.36569 11.0626C10.4593 11.0618 11.5676 11.4217 12.2208 12.2952C12.8074 10.8116 14.2456 10.2501 15.821 10.2501C16.0583 10.2501 16.25 10.4532 16.25 10.6921C16.2492 12.4357 15.8949 13.7406 13.8832 14.0567Z"
      fill="white"
    />
  </svg>
);

export const CancelIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <g id="Group_8952" data-name="Group 8952" transform="translate(-0.395)">
      <circle
        id="Ellipse_336"
        data-name="Ellipse 336"
        cx="12"
        cy="12"
        r="12"
        transform="translate(0.395)"
        fill={props.background ? props.background : "#bdbdbdd1"}
      />
      <g
        id="Group_6325"
        data-name="Group 6325"
        transform="translate(12.256 3.702) rotate(45)">
        <g id="Add">
          <rect
            id="Rectangle_799"
            data-name="Rectangle 799"
            width="12.022"
            height="2.404"
            rx="1.202"
            transform="translate(0 4.809)"
            fill="#fff"
          />
          <rect
            id="Rectangle_800"
            data-name="Rectangle 800"
            width="12.022"
            height="2.404"
            rx="1.202"
            transform="translate(7.213) rotate(90)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const SearchIcon = () => (
  <svg
    style={{
      color: "#bdbdbdd1",
      fill: "none",
      stroke: "#bdbdbdd1",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "2px",
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="15.414"
    height="15.414"
    viewBox="0 0 18.414 18.414">
    <g transform="translate(-2 -2)">
      <circle
        className="a"
        cx="7.111"
        cy="7.111"
        r="7.111"
        transform="translate(3 3)"
      />
      <line
        className="a"
        x1="3.867"
        y1="3.867"
        transform="translate(15.133 15.133)"
      />
    </g>
  </svg>
);

export const PlusIcon = (props) => (
  <svg
    fill={props.color ? props.color : "#FFFFFF"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={props.width ? props.width : "20px"}
    height={props.height ? props.height : "20px"}>
    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z" />
  </svg>
);
export const Reload = (props) => (
  <svg
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-refresh-ccw">
    <polyline points="1 4 1 10 7 10" />
    <polyline points="23 20 23 14 17 14" />
    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" />
  </svg>
);
export const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-upload">
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="17 8 12 3 7 8" />
    <line x1="12" y1="3" x2="12" y2="15" />
  </svg>
);
export const Download = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-download">
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="7 10 12 15 17 10" />
    <line x1="12" y1="15" x2="12" y2="3" />
  </svg>
);

export const FliterIcon = (props) => (
  <svg
    enable-background="new 0 0 36 30"
    height="20px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 36 30"
    width="20px"
    xml="preserve">
    <polygon
      fill="#231F20"
      points="14,30 22,25 22,17 35.999,0 17.988,0 0,0 14,17 "
    />
  </svg>
);

export const SendIcon = (props) => (
<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.79029 0.122798L22.8603 10.2008C23.073 10.3026 23.2525 10.4625 23.3782 10.662C23.5039 10.8615 23.5706 11.0925 23.5706 11.3283C23.5706 11.5641 23.5039 11.7951 23.3782 11.9946C23.2525 12.1941 23.073 12.354 22.8603 12.4558L1.79029 22.5338C1.5742 22.6374 1.3329 22.6767 1.09512 22.647C0.85734 22.6173 0.633104 22.5199 0.449121 22.3663C0.265138 22.2128 0.129162 22.0096 0.0573883 21.781C-0.0143853 21.5524 -0.0189315 21.3079 0.0442913 21.0768L2.15229 13.3488C2.1781 13.2541 2.23121 13.1691 2.30501 13.1043C2.3788 13.0396 2.47002 12.998 2.56729 12.9848L12.7773 11.5978C12.8199 11.5918 12.8602 11.575 12.8943 11.5489C12.9285 11.5228 12.9553 11.4883 12.9723 11.4488L12.9903 11.3858C12.9982 11.33 12.987 11.2731 12.9585 11.2245C12.93 11.1758 12.8858 11.1382 12.8333 11.1178L12.7783 11.1028L2.57829 9.7168C2.4812 9.70338 2.3902 9.66172 2.3166 9.59701C2.24299 9.53229 2.19003 9.44737 2.16429 9.3528L0.0442913 1.5798C-0.0189315 1.34866 -0.0143853 1.10422 0.0573883 0.875598C0.129162 0.646972 0.265138 0.443795 0.449121 0.290265C0.633104 0.136735 0.85734 0.0393237 1.09512 0.009633C1.3329 -0.0200577 1.5742 0.0192236 1.79029 0.122798Z" fill="#5E5873"/>
</svg>
);

export const ExportIcon = (props) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2803 2.21967C11.9874 1.92678 11.5126 1.92678 11.2197 2.21967L6.21967 7.21967C5.92678 7.51256 5.92678 7.98744 6.21967 8.28033C6.51256 8.57322 6.98744 8.57322 7.28033 8.28033L11 4.56066V18.25C11 18.6642 11.3358 19 11.75 19C12.1642 19 12.5 18.6642 12.5 18.25V4.56066L16.2197 8.28033C16.5126 8.57322 16.9874 8.57322 17.2803 8.28033C17.5732 7.98744 17.5732 7.51256 17.2803 7.21967L12.2803 2.21967ZM5.25 20.5C4.83579 20.5 4.5 20.8358 4.5 21.25C4.5 21.6642 4.83579 22 5.25 22H18.25C18.6642 22 19 21.6642 19 21.25C19 20.8358 18.6642 20.5 18.25 20.5H5.25Z"
      fill="#FFFFFF"
    />
  </svg>
);
export const ThreeDotIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#FFFFFF"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-more-vertical">
    <circle cx="12" cy="12" r="1" />
    <circle cx="12" cy="5" r="1" />
    <circle cx="12" cy="19" r="1" />
  </svg>
);
export const BottomArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "24"}
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-down">
    <polyline points="6 9 12 15 18 9" />
  </svg>
);
export const RightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    class="feather feather-chevron-right">
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);
export const TopArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-up">
    <polyline points="18 15 12 9 6 15" />
  </svg>
);

export const MoreVertical = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-more-vertical">
    <circle cx="12" cy="12" r="1" />
    <circle cx="12" cy="5" r="1" />
    <circle cx="12" cy="19" r="1" />
  </svg>
);
export const Share = (props) => (
  <svg
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-share">
    <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
    <polyline points="16 6 12 2 8 6" />
    <line x1="12" y1="2" x2="12" y2="15" />
  </svg>
);
export const PowerButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="rgb(241 154 48)"
    strokeWidth="4"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-power">
    <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
    <line x1="12" y1="2" x2="12" y2="12"></line>
  </svg>
);
export const EditIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    stroke={props.color ? props.color : "currentColor"}
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-edit">
    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
  </svg>
);
export const EyeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    stroke={props.color ? props.color : "currentColor"}
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-eye">
    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
    <circle cx="12" cy="12" r="3"></circle>
  </svg>
);
export const ThreeDots = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    stroke={props.color ? props.color : "currentColor"}
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-more-vertical">
    <circle cx="12" cy="12" r="1"></circle>
    <circle cx="12" cy="5" r="1"></circle>
    <circle cx="12" cy="19" r="1"></circle>
  </svg>
);

export const InfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    stroke={props.color ? props.color : "currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-info">
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="16" x2="12" y2="12"></line>
    <line x1="12" y1="8" x2="12.01" y2="8"></line>
  </svg>
);
export const MoveIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    stroke={props.color ? props.color : "currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-move">
    <polyline points="5 9 2 12 5 15"></polyline>
    <polyline points="9 5 12 2 15 5"></polyline>
    <polyline points="15 19 12 22 9 19"></polyline>
    <polyline points="19 9 22 12 19 15"></polyline>
    <line x1="2" y1="12" x2="22" y2="12"></line>
    <line x1="12" y1="2" x2="12" y2="22"></line>
  </svg>
);
export const UploadCloud = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.225"
    height={"15.019"}
    viewBox="0 0 24.225 20.019"
    style={{
      fill: "none",
      stroke: props.color ? props.color : "#707070",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "2px",
    }}>
    <g transform="translate(0.161 -1.981)">
      <path d="M16,16l-4-4L8,16" />
      <line y2="9" transform="translate(12 12)" />
      <path d="M20.39,18.39A5,5,0,0,0,18,9H16.74A8,8,0,1,0,3,16.3" />
      <path d="M16,16l-4-4L8,16" />
    </g>
  </svg>
);
export const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    stroke={props.color ? props.color : "#707070"}
    viewBox="0 0 20 24"
    fill="none"
    stroke-width="1"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-trash-2">
    <polyline points="3 6 5 6 21 6"></polyline>
    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
    <line x1="10" y1="11" x2="10" y2="17"></line>
    <line x1="14" y1="11" x2="14" y2="17"></line>
  </svg>
);
export const PdfIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-file-text">
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
    <polyline points="14 2 14 8 20 8"></polyline>
    <line x1="16" y1="13" x2="8" y2="13"></line>
    <line x1="16" y1="17" x2="8" y2="17"></line>
    <polyline points="10 9 9 9 8 9"></polyline>
  </svg>
);
