import moment from "moment";
import React from "react";
import { filterData } from "../../../Regex";

function PackingList(props) {
  const {
    downloadCI,
    selectedShipper,
    selectedBenificiary,
    selectedSo,
    details,
    buyer,
    masters,
    soItemList,
    tableList,
  } = props;
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: "0.8rem",
        }}
      >
        PACKING LIST
      </div>
      <div
        style={{
          width: "100%",
          color: "black",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          margin: "10px 0 0 0",
        }}
      >
        <div
          style={{
            width: "100%",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "calc(40% - 0.5px)",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            {details(
              "SHIPPER :",
              selectedShipper && selectedShipper.name,
              selectedShipper && selectedShipper.address,

              `GST Number: ${
                selectedShipper && selectedShipper.gst_no
                  ? selectedShipper.gst_no
                  : ""
              }`
            )}
          </div>
          <div style={{ width: "calc(60% - 0.5px)" }}>
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "calc(50% - 0.5px)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "calc(50% - 0.5px)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  {details(
                    "INVOICE NO.:",
                    downloadCI && downloadCI.invoice_number
                  )}
                </div>
                <div style={{ width: "calc(50% - 0.5px)" }}>
                  {details(
                    "DATE :",
                    moment(downloadCI.invoice_date).format("ddd, MMM Do YYYY")
                  )}
                </div>
              </div>
              <div style={{ width: "calc(50% - 0.5px)" }}>
                {details(
                  "EXPORTER REF :",
                  downloadCI && downloadCI.exporter_ref_no
                    ? downloadCI.exporter_ref_no
                    : ""
                )}
              </div>
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  width: "calc(50% - 0.5px)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                {details(
                  "Contract Number :",
                  selectedSo && selectedSo.so_number ? selectedSo.so_number : ""
                )}
              </div>
              <div style={{ width: "calc(50% - 0.5px)" }}>
                {details(
                  "Dated :",
                  moment(downloadCI.sales_order_date).format("ddd, MMM Do YYYY")
                )}
              </div>
            </div>
            {/* <div style={{ width: "100%", display: "flex", textTransform: "uppercase" }}>
              {details("Bl Number :", selectedSo && selectedSo.bl_number ? selectedSo.bl_number : "")}
            </div> */}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "calc(40% - 0.5px)",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              {details(
                "CONSIGNEE :",
                selectedSo && selectedSo.consignee ? selectedSo.consignee : "",
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                "50px"
              )}
            </div>{" "}
            {details(
              "NOTIFY :",

              `${
                filterData(
                  masters && masters.notifyList,
                  "id",
                  downloadCI && downloadCI.notify_1
                    ? Number(downloadCI.notify_1)
                    : "",
                  "onlyOne"
                )
                  ? filterData(
                      masters && masters.notifyList,
                      "id",
                      downloadCI && downloadCI.notify_1
                        ? Number(downloadCI.notify_1)
                        : "",
                      "onlyOne"
                    ).notify
                  : ""
              }` +
                " " +
                "," +
                " " +
                `${
                  filterData(
                    masters && masters.notifyList,
                    "id",
                    downloadCI && downloadCI.notify_2
                      ? Number(downloadCI.notify_2)
                      : "",
                    "onlyOne"
                  )
                    ? filterData(
                        masters && masters.notifyList,
                        "id",
                        downloadCI && downloadCI.notify_2
                          ? Number(downloadCI.notify_2)
                          : "",
                        "onlyOne"
                      ).notify
                    : ""
                }`,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              "50px"
            )}
          </div>
          <div
            style={{
              width: "calc(60% - 0.5px)",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              {details(
                "Other references :",
                " ",
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                "10px"
              )}
            </div>
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              {details(
                "BUYER :",
                buyer ? buyer.client_name : "",
                buyer && `${buyer.address} ${buyer.city} ${buyer.state}`,
                `Phone : ${buyer && buyer.phone}`,
                null,
                null,
                null,
                null,
                null,
                null
              )}{" "}
            </div>
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "calc(50% - 0.5px)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "uppercase",
                }}
              >
                {details("pre-carriage by:", "")}
              </div>
              <div
                style={{
                  width: "calc(50% - 0.5px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "uppercase",
                }}
              >
                {details(
                  "place of receipt by pre-carrier:",
                  downloadCI && downloadCI.delivery_place
                    ? downloadCI.delivery_place
                    : ""
                )}
              </div>
            </div>{" "}
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  width: "calc(50% - 0.5px)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "uppercase",
                }}
              >
                {details("Country of origin of Goods:", "INDIA")}
              </div>
              <div
                style={{
                  width: "calc(50% - 0.5px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "uppercase",
                }}
              >
                {details(
                  "Country of Final Destination:",
                  "INDIA",
                  null,
                  null,
                  null,
                  null,
                  null,
                  "center"
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            display: "flex",
            textTransform: "uppercase",
          }}
        >
          <div
            style={{
              width: "calc(40% - 0.5px)",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            {details(
              "name of carrying vessel/flight no.",
              `${downloadCI && downloadCI.vessel_number} ${
                downloadCI && downloadCI.flight_number
              }`,
              null,
              null,
              null,
              "border",
              null,
              null
              // "27.46px"
            )}{" "}
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              {details("Port of loading:", "any port in INDIA")}
            </div>
            {details(
              "Port of discharge",
              downloadCI && downloadCI.discharge_port
                ? downloadCI.discharge_port
                : ""
            )}
          </div>
          <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
            {details(
              "term of delivery & payment :",
              `Delivery : ${
                downloadCI && downloadCI.delivery_place
                  ? downloadCI.delivery_place
                  : ""
              } ,`,
              `Payment : ${
                filterData(
                  masters && masters.soPaymentTermsList,
                  "id",
                  selectedSo && selectedSo.so_payment_term
                    ? Number(selectedSo.so_payment_term)
                    : "",
                  "onlyOne"
                )
                  ? filterData(
                      masters && masters.soPaymentTermsList,
                      "id",
                      selectedSo && selectedSo.so_payment_term
                        ? Number(selectedSo.so_payment_term)
                        : "",
                      "onlyOne"
                    ).term
                  : "false"
              } `,
              null,
              null,
              "border"
            )}
          </div>
        </div>
        {downloadCI.custom_invoice_item_details &&
          downloadCI.custom_invoice_item_details.length > 0 && (
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                display: "flex",
                textTransform: "uppercase",
              }}
            >
              <div
                style={{
                  width: "calc(40% - 0.5px)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "calc(40% - 0.5px)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  {details(
                    "marks and number of container no.",
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    "center"
                  )}
                </div>
                <div style={{ width: "calc(60% - 0.5px)" }}>
                  {details(
                    "No. and kind of package",
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    "center",
                    "27.46px"
                  )}
                </div>
              </div>
              <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                <div
                  style={{
                    width: "calc(50% - 0.5px)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  {details(
                    "Description of goods",
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    "center",
                    "27.46px"
                  )}
                </div>
                <div style={{ width: "calc(50% - 0.5px)" }}>
                  {details(
                    `Quantity in (${
                      downloadCI && downloadCI.unit ? downloadCI.unit : ""
                    })`,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    "center",
                    "27.46px"
                  )}
                </div>
              </div>
            </div>
          )}
        {downloadCI.custom_invoice_item_details &&
          downloadCI.custom_invoice_item_details.length > 0 &&
          downloadCI.custom_invoice_item_details.map((s, i) => {
            const soItemDetail = filterData(
              soItemList,
              "id",
              s.so_item,
              "onlyOne"
            )
              ? filterData(soItemList, "id", s.so_item, "onlyOne")
              : false;
            return (
              <>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    textTransform: "uppercase",
                  }}
                >
                  <div
                    style={{
                      width: "calc(40% - 0.5px)",
                      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "calc(40% - 0.5px)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      className="d-flex d-flex-column justifySA"
                    >
                      {/* {downloadCI && downloadCI.container_details && downloadCI.container_details.length > 0
                        ? downloadCI.container_details.map((s) =>
                            details(s.container_brand, null, null, null, null, null, null, null, null, null, true)
                          )
                        : ""}
               
                      {downloadCI && downloadCI.container_details && downloadCI.container_details.length > 0
                        ? downloadCI.container_details.map((s) =>
                            s.container_number && s.container_number.length > 0
                              ? s.container_number.map((ss) =>
                                  details(ss, null, null, null, null, null, null, null, null, null, true)
                                )
                              : ""
                          )
                        : ""}
                    </div> */}

                      <div style={{ padding: "5px" }}>
                        {details(
                          soItemDetail.buyers_marking
                            ? soItemDetail.buyers_marking
                            : "-",
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          null,
                          null,
                          true
                        )}
                      </div>
                      {details(
                        "Country of Origin",
                        downloadCI && downloadCI.origin_country
                          ? downloadCI.origin_country
                          : "",
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center"
                      )}
                    </div>
                    <div style={{ width: "calc(60% - 0.5px)" }}>
                      {details(
                        downloadCI &&
                          downloadCI.container_details &&
                          downloadCI.container_details.length > 0
                          ? `${downloadCI.container_details.map((s) =>
                              s.container_number &&
                              s.container_number.length > 0
                                ? s.container_number.length
                                : ""
                            )} X  20' CONTS`
                          : "",
                        downloadCI.number_of_containers &&
                          downloadCI.number_of_containers,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center",
                        "27.46px"
                      )}
                      {/* {details(
                        `Number of bags`,
                        soItemDetail
                          ? soItemDetail.packaging.map((sP) => {
                              // const pVal = filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
                              //   ? filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
                              //   : "";

                              return `${s.pkg_counter[sP] ? s.pkg_counter[sP] : ""}`;
                            })
                          : "",
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center",
                        "27.46px"
                      )} */}
                      {details(
                        soItemDetail
                          ? `Packaging in  ${soItemDetail.packaging.map(
                              (sP, i) => {
                                const pVal = filterData(
                                  masters.PackagingList,
                                  "id",
                                  Number(sP),
                                  "onlyOne"
                                )
                                  ? filterData(
                                      masters.PackagingList,
                                      "id",
                                      Number(sP),
                                      "onlyOne"
                                    )
                                  : "";
                              
                                return `${
                                  s.pkg_counter[sP] ? s.pkg_counter[sP] : ""
                                } bags of ${pVal.size ? pVal.size : ""}${
                                  pVal.unit ? pVal.unit : ""
                                } ${pVal.bag_type ? pVal.bag_type : ""} `;
                              }
                            )}${
                              soItemDetail.packaging.length === i + 1
                                ? "."
                                : " "
                            }`
                          : "",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center",
                        "27.46px"
                      )}
                    </div>
                  </div>
                  <div style={{ width: "calc(60% - 0.5px)", display: "flex" }}>
                    <div
                      className="d-flex d-flex-column justifySA textAC"
                      style={{
                        width: "calc(50% - 0.5px)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      {details(
                        soItemDetail && soItemDetail.brand_details
                          ? `${soItemDetail.brand_details.brand_name} - ${
                              filterData(
                                masters.ItemCateoryList,
                                "id",
                                soItemDetail.brand_details.item_category,
                                "onlyOne"
                              )
                                ? filterData(
                                    masters.ItemCateoryList,
                                    "id",
                                    soItemDetail.brand_details.item_category,
                                    "onlyOne"
                                  ).name
                                : ""
                            } - ${
                              filterData(
                                masters.ItemList,
                                "id",
                                soItemDetail.brand_details.item_type,
                                "onlyOne"
                              )
                                ? `${Number(
                                    filterData(
                                      masters.ItemList,
                                      "id",
                                      soItemDetail.brand_details.item_type,
                                      "onlyOne"
                                    ).broken_percentage
                                  ).toFixed(2)}%`
                                : ""
                            }`
                          : "",
                        "",
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center"
                        // "20px"
                      )}{" "}
                      {/* {i === 0 &&
                        details(
                          "Notify 2 :",
                          // downloadCI && downloadCI.notify_2 ? downloadCI.notify_2 : "",
                          `${filterData(
                            masters && masters.notifyList,
                            "id",
                            downloadCI && downloadCI.notify_2 ? Number(downloadCI.notify_2) : "",
                            "onlyOne"
                          )
                            ? filterData(
                                masters && masters.notifyList,
                                "id",
                                downloadCI && downloadCI.notify_2 ? Number(downloadCI.notify_2) : "",
                                "onlyOne"
                              ).notify
                            : ""}`,
                          null,
                          null,
                          null,
                          null,
                          null,
                          null,
                          "center",
                          ""
                        )} */}
                    </div>
                    <div style={{ width: "calc(50% - 0.5px)" }}>
                      {details(
                        `(GROSS WT.)`,
                        s.gross_weight
                          ? `${s.gross_weight} ${downloadCI && downloadCI.unit}`
                          : "",
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center",
                        "20px"
                      )}
                      {details(
                        `(NET WT.)`,
                        s.net_weight
                          ? `${s.net_weight} ${downloadCI && downloadCI.unit}`
                          : "",
                        null,
                        null,
                        null,
                        null,
                        null,
                        "center",
                        "20px"
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            minHeight: "60px",
            height: "60px",
          }}
        >
          <div style={{ width: "50%", padding: "5px" }}>
            <div
              style={{
                width: "100%",
                fontWeight: "bold",
                padding: "5px",
              }}
            >
              Declaration:
            </div>
            <div
              style={{
                width: "100%",
                padding: "5px",
              }}
            >
              {" "}
              We declare that this invoice shows the actual price of the goods
              described and that all particulars are true and correct.
            </div>
          </div>
          <div
            style={{
              padding: "5px",
              display: "flex",
              minHeight: "100%",
              height: "100%",
              width: "50%",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                padding: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
                  BALAJI RICE INDUSTRIES
              <div style={{ margin: "0 20px 0 0" }}></div>
            </div>
            <div
              style={{
                padding: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              Director/Authorized Signatory
              <div style={{ margin: "0 20px 0 0" }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackingList;
