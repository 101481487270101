import { useContext, useEffect, useRef, useState } from "react";
import "./Chat.css";
import moment from "moment";
import MessageBox from "./MessageBox";
// import InfiniteScroll from "react-infinite-scroll-component";
import {
  CssTextField,
  StyledButton,
  StyledCircularProgress,
  StyledLoadingButton,
} from "../../styles/StyledComponents";
import { filterData, scrollDiv } from "../../Regex";
import {
  Avatar,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { primary } from "../../styles/colors";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CreateDialog from "../../layoutComponents/CreateDialog";
import ReactDOM from "react-dom";
import { RollerShades } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { CrossIcon, SendIcon } from "../../styles/Icons";
import { Transition } from "../Rates/Offers";
import PurchaseOrdersContainer from "../PurchaseOrders/PurchaseOrdersContainer";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
const styles = {
  divPaper: {
    background: "white",
    margin: "20px",
    boxShadow: "1px 1px 6px 1px #8080804f",
    width: "100%",
    maxWidth: "600px",
    borderRadius: "10px",
  },
  divIPaper: {
    background: "rgb(241, 154, 48)",
    fontSize: "1.2rem",
    borderRadius: "10px 10px 0 0",
    padding: "10px",
    justifyContent: "center",
    alignItem: "center",
    color: "white",
    display: "flex",
    fontWeight: "600",
  },
};
function ChatUI(props) {
  const {
    item,
    modulesFunction,
    messagesList,
    user,
    singlelistView,
    fetchPurchaseIntent,
  } = props;
  const [fieldValue, setFieldValue] = useState({});
  const [totalDataLength, setTotalDataLength] = useState(49);
  const [notScroll, setDontScroll] = useState("msg");
  const [autoFocus, setAutoFocus] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [allSenderUid, setAllSenderUid] = useState([]);
  const [pagination, setPagination] = useState({ has_next: true });
  const [listData, setListData] = useState([]);

  const [ButtonTrue, setButtonTrue] = useState({ open: false, type: "" });
  const [socket, setSocket] = useState(null);
  const fetchPageData = (pageNumber) => {
    // console.log(pagination, paginationLoader, socket,"fetchagain");

    console.log(pagination, paginationLoader, socket, "fetchagain   1");
    if (socket) {
      // && socket.readyState === WebSocket.OPEN
      // Send a message to the server requesting data for a specific page number
      socket.send(
        JSON.stringify({
          // type: "FETCH_PAGE_DATA",

          page: pageNumber,
        })
      );
    }
  };
  const reloadData = (pageNumber) => {
    if (socket) {
      socket.send(
        JSON.stringify({
          reload: true,
        })
      );
    }
  };
  function isScrolledToBottom(element) {
    if (!element) return false;
    return (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    );
  }

  const [paginationLoader, setPagingLoding] = useState(false);
  useEffect(() => {
    localStorage.setItem("pagination_loader", JSON.stringify(paginationLoader));
  }, [paginationLoader]);
  function isScrolledToTop(element) {
    if (!element) return false;
    return element.scrollTop === 0;
  }

  useEffect(() => {
    const scrollElement = document.getElementById("chatList");

    const handleScroll = () => {
      if (isScrolledToTop(scrollElement)) {
        // entries.forEach((entry:any) => {
        //   if (entry.isIntersecting) {
        if (pagination.has_next && notScroll === false) {
          if (!paginationLoader) {
            setPagingLoding(true);
            fetchPageData(pagination.page_number + 1);
            // }
          }
        }
        //   }
        // });
      }
    };

    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [pagination, paginationLoader, notScroll]);

  const updatePurchaseIntent = () => {
    props
      .modulesFunction(
        "patch",
        "dispatch/sales-intent",
        ButtonTrue.data.id,

        null,

        {
          status: ButtonTrue.type === "approve" ? "Accepted" : "Rejected",
          purchase_rate: ButtonTrue.data.purchase_rate,
        },
        null,
        "noeditparam"
      )
      .then((res) => { console.log(res,"resresresresres")

        if (res&&Array.isArray(res)) {  console.log("resresresresresertgrttr","")
        setSnack({error:res.map((s)=>`${s.field}-${s.message.length>0?s.message.map((s)=>s).join(""):s.message}`).join('')})
        } else {
          setButtonTrue((p) => {
            return {
              ...p,
              open: false,
              createPo: ButtonTrue.type === "approve" ? true : false,
            };
          });
          setSnack({
            success:
              ButtonTrue.type === "approve"
                ? "Offered approved"
                : "Offered rejected",
          });
        }
      });
    // navigate("PurchaseOrders", {
    //   purchase_intent: ButtonTrue.data,
    //   replace: true,
    // });
  };

  const create_po = (data) => {
    setButtonTrue((p) => {
      return {
        ...p,
        data: data,
        open: false,
        createPo: true,
        loader: true,
      };
    });
  };
  const [selectedItem, setItem] = useState(null);
  useEffect(() => {
    const roomData = localStorage.getItem("room");
    const roomDataDetail = roomData ? JSON.parse(roomData) : item;
    if (
      selectedItem === null ||
      selectedItem.room_id !== roomDataDetail.room_id
    ) {
      if (socket) {
        socket.close();
      }
      setListData([]);
      setLoader(true);
      setPagingLoding(false);
      setAllSenderUid([]);
      setItem(roomDataDetail);
      setDontScroll("msg");
      const newSocket = new WebSocket(
        `wss://dev.grainbyte.app/ws/chat/${
          roomDataDetail.room_id
        }/?token=${user && user.uid ? user.uid : ""}`
      );

      // Event listener for when the connection is opened
      newSocket.onopen = () => {
        fetchPageData(1);
      };

      // Event listener for receiving messages from the server
      newSocket.onmessage = (event) => {
        if (JSON.parse(event.data).message) {
          const chatList =
            JSON.parse(event.data).message?.chat_details &&
            JSON.parse(event.data).message?.chat_details.length > 0
              ? JSON.parse(event.data).message?.chat_details.map((s) => {
                  const msg = s.negotiation_details
                    ? { ...s.negotiation_details, ...s }
                    : s;
                  return {
                    ...msg,
                    message_type:
                      msg.details || msg.new_offer || msg.old_offer
                        ? "buy"
                        : "message",
                  };
                })
              : [];
          const singleMssage = JSON.parse(event.data).message?.message
            ? { ...JSON.parse(event.data).message, message_type: "message" }
            : "";
            const extraMsgs=chatList.filter((chat)=>chat.message_type==="buy"&&chat.message)
          if (
            chatList.length > 0
              ? roomDataDetail.room_id !== chatList[0].chat
              : false
          ) {
            setPagingLoding(false);
          }
          if (roomDataDetail.room_id) {
            console.log([...chatList,...(extraMsgs.length>0?extraMsgs.map((s)=>{return {...s, message_type: "message"}}):[])], "new msgsingleMssage", chatList);
            setMessagesFormat(
              [...chatList,...(extraMsgs.length>0?extraMsgs.map((s)=>{return {...s, message_type: "message"}}):[])],
              singleMssage,
              singleMssage
                ? false
                : chatList.length > 0
                ? roomDataDetail.room_id !== chatList[0].chat
                : false,
              roomDataDetail
            );
          }
          if (!singleMssage) {
            setPagination(JSON.parse(event.data).message?.metadata);
          }
        }
        // setMessage(event.data);
      };
      setSocket(newSocket);
      // Event listener for when the connection is closed
      newSocket.onclose = () => {
        console.log("WebSocket connection closed");
      };
    }
    // Cleanup function to close the connection when the component unmounts

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, selectedItem]);
  const sendMessage = () => {
    setDontScroll("msg");
    if (fieldValue.text) {
      setFieldValue((prev) => {
        return {
          ...prev,
          [`loading`]: true,
        };
      });
      const options = {
        message: fieldValue.text,
        // method: "add",
        // collection: "transactions",
        // hideGlobalLoader: true,
        // data: {
        //   message: fieldValue.text,
        //   message_type: "message",
        //   itemId: item.id,
        // },
        // // dateCreationFields: ["created_at", "updatedAt"],
        // state: "post",
      };
      if (fieldValue.text.trim() !== "") {
        // Send the message to the server
        if (socket && socket.readyState === WebSocket.OPEN) {
          const dataToSend = {
            message: fieldValue.text,
          };
          socket.send(JSON.stringify(dataToSend));
        } else {
        }
        setFieldValue((prev) => {
          return {
            ...prev,
            [`loading`]: false,
            text: "",
          };
        });

        setAutoFocus(true);
      }

      // options
      // modulesFunction(options).then((res) => {
      //   if (!res.error) {
      //     setFieldValue((prev) => {
      //       return {
      //         ...prev,
      //         [`loading`]: false,
      //         text: "",
      //       };
      //     });
      //   }
      //   setAutoFocus(true);
      // });
    } else {
      setFieldValue((prev) => {
        return {
          ...prev,
          [`Error`]: true,
        };
      });
    }
  };
  const handleButtonClick = (msg) => {
    setDialogOpen({
      open: true,
      data: msg.details ?? msg.new_offer ?? {},
      data_all: msg,
    });
  };
  const setMessagesFormat = (list, newMsg, firstTime, roomDataDetail) => {
    console.log(
      list,
      "list, newMsg, firstTime"
    );
    const old = localStorage.getItem("oldchatarr")
      ? JSON.parse(localStorage.getItem("oldchatarr"))
      : [];

    const oldArr = old.filter((s) => s.chat === roomDataDetail.room_id);
    const loaderData =
      oldArr.length === 0
        ? false
        : localStorage.getItem("pagination_loader")
        ? JSON.parse(localStorage.getItem("pagination_loader"))
        : [];

    const allSenderUids =
      oldArr.length === 0
        ? []
        : localStorage.getItem("allSenderUids")
        ? JSON.parse(localStorage.getItem("allSenderUids"))
        : [];
    console.log(allSenderUids, "oldAfirstTimerroldArr", firstTime);
    // const loaderData = [];
    const lastDivId = [];
    if (firstTime === false || !firstTime) {
      // const allSenderUids = [];
      console.log(allSenderUids, "allSenderUids");
      if (oldArr.length > 0 && allSenderUids.length > 0) {
        const data = allSenderUids.sort((a, b) => {
          var dateA = new Date(
            moment(new Date(b.date)).format("MMM D YYYY h:mm:ss A")
          );
          var dateB = new Date(
            moment(new Date(a.date)).format("MMM D YYYY h:mm:ss A")
          );

          return dateB - dateA;
        })[0];
        const dataArr = oldArr;
        const arrayOfMsgs =
          dataArr && dataArr.length > 0
            ? dataArr
                .filter(
                  (list) => moment(list.created_at).format("ll") === data.date
                )
                .sort((a, b) => {
                  var dateA = new Date(
                    moment(new Date(b.created_at)).format(
                      "MMM D YYYY h:mm:ss A"
                    )
                  );
                  var dateB = new Date(
                    moment(new Date(a.created_at)).format(
                      "MMM D YYYY h:mm:ss A"
                    )
                  );

                  return dateA - dateB;
                })
            : [];
        const lastMsg = arrayOfMsgs.sort((a, b) => {
          var dateA = new Date(
            moment(new Date(b.created_at)).format("MMM D YYYY h:mm:ss A")
          );
          var dateB = new Date(
            moment(new Date(a.created_at)).format("MMM D YYYY h:mm:ss A")
          );

          return dateA - dateB;
        })[0];

        console.log(arrayOfMsgs, lastMsg, "arrayOeeefMsgs");
        if (lastMsg) {
          lastDivId.push(lastMsg);
        }
      }
    } else {
    }
    const alist =
      newMsg && oldArr
        ? [...oldArr, newMsg]
        : loaderData === true
        ? [...oldArr, ...list]
        : list;

    const arr = alist.filter((s) => s.chat === roomDataDetail.room_id);
    const arrayList = filterData(arr, "chat", roomDataDetail.room_id)
      ? filterData(arr, "chat", roomDataDetail.room_id)
      : [];
    const dates = [];
    const allUids = [];
    if (arrayList.length > 0) {
      arrayList.forEach((data1) => {
        if (!dates.includes(moment(data1.created_at).format("ll"))) {
          const uid = [];
          arrayList
            .filter(
              (p) =>
                moment(p.created_at).format("ll") ===
                moment(data1.created_at).format("ll")
            )
            .sort((a, b) => {
              var dateA = new Date(
                moment(new Date(b.created_at)).format("MMM D YYYY h:mm:ss A")
              );
              var dateB = new Date(
                moment(new Date(a.created_at)).format("MMM D YYYY h:mm:ss A")
              );

              return dateB - dateA;
            })
            .forEach((list) => {
              uid.push(list.created_by);
            });

          if (
            !allUids.some(
              (e) => e.date === moment(data1.created_at).format("ll")
            )
          ) {
            allUids.push({
              uid: uid,
              date: moment(data1.created_at).format("ll"),
              created_at: data1.created_at,
            });
          } else {
            allUids.map((item) =>
              item.date === moment(data1.created_at).format("ll")
                ? {
                    uid: uid,
                    date: moment(data1.created_at).format("ll"),
                  }
                : item
            );
          }
          return dates.push(moment(data1.created_at).format("ll"));
        }
      });

      // colorsUid.forEach((h, i) => userColor.push({ uid: h, color: colors[i] }));
      // setUserColor(userColor);
    }
    if (roomDataDetail.room_id) {
      setAllSenderUid(allUids);
      if (arrayList.length > 4 && !newMsg) {
        setDontScroll(false);
      }
      // if (notScroll !== true) {
      console.log(arrayList, "arrayList");
      const keepUniqueObjects = (arr) => {
        const uniqueIds = [];
        return arr.filter((obj) => {
          if (!uniqueIds.includes(`${obj.message_type}-${obj.id}`)) {
            uniqueIds.push(`${obj.message_type}-${obj.id}`);
            return true;
          }
          return false;
        });
      };

      setListData(keepUniqueObjects(arrayList));

      setLoader(false);
      localStorage.setItem("oldchatarr", JSON.stringify(arrayList));

      localStorage.setItem("allSenderUids", JSON.stringify(allUids));
      if (loaderData === true) {
        if (lastDivId && lastDivId.length > 0 && lastDivId[0].id) {
          //  scrollToBottom(lastDivId[0].id);
          const chat = document.getElementById(lastDivId[0].id);
          if (chat) {
            chat.scrollIntoView();
          }
        }
      } else if (oldArr.length === 0) {
        scrollToBottom();
      }
      setPagingLoding(false);
    }
    // } else {
    //   setListData(arrayList);
    // }
  };

  function scrollToBottom() {
    const element = document.getElementById("chatList");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  const infiniteFetchNext = () => {
    if (pagination && pagination.has_next) {
      setPagingLoding(true);
      fetchPageData(pagination.page_number + 1); // Fetch next page
    }
  };
  const leftIcon = (color) => {
    return (
      <div>
        <svg
          className="rce-mbox-left-notch"
          fill={color ? color : "#fff"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20">
          <defs>
            <filter id="filter1" x="0" y="0">
              <feOffset
                result="offOut"
                in="SourceAlpha"
                dx="-2"
                dy="-5"></feOffset>
              <feGaussianBlur
                result="blurOut"
                in="offOut"
                stdDeviation="3"></feGaussianBlur>
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal"></feBlend>
            </filter>
          </defs>
          <path d="M20 0v20L0 0" filter="url(#filter1)"></path>
        </svg>
      </div>
    );
  };
  const rightIcon = (color) => {
    return (
      <svg
        className="rce-mbox-right-notch"
        fill={color ? color : "#fff"}
        viewBox="0 0 20 20">
        <path d="M0 0v20L20 0"></path>
      </svg>
    );
  };
  const userRole =
    props.user && props.user.roles
      ? props.user.roles.toString().toLowerCase()
      : "";
  const [snack, setSnack] = useState({});
  return (
    <div className="width-100 height-100" style={{ position: "relative" }}>
      {snack.error || snack.success ? (
        <SnackbarComponent
          autoHideDuration={snack.error ? 5000 : 1500}
          error={snack.error}
          success={snack.success}
          snackbarClose={() => {
            setSnack({});
          }}
        />
      ) : (
        ""
      )}
      {ButtonTrue.loader ? (
        <Backdrop
          style={{ zIndex: 99999, color: "#fff" }}
          open={ButtonTrue.loader ?? false}>
          <StyledCircularProgress color="rgb(241, 154, 48)" />
        </Backdrop>
      ) : (
        ""
      )}
      {ButtonTrue.createPo ? (
        <PurchaseOrdersContainer
          onlyCreate={ButtonTrue.data ?? true}
          onClose={() => {
            setButtonTrue({});
            console.log("pageNumber");
            reloadData();
          }}
          onLoader={(valll) => {
            setButtonTrue((p) => {
              return {
                ...p,
                loader: valll,
              };
            });
          }}
        />
      ) : (
        ""
      )}
      <Dialog
        ref={(e) => {
          try {
            const target = ReactDOM.findDOMNode(e).children[2].children[0];
            target.style.borderRadius = "0px";
            target.style.maxHeight = "calc(100% - 400px)";
            target.style.minHeight = "280px";

            target.style.height = "calc(100% - 700px)";

            target.style.minWidth = "250px";

            target.style.maxWidth = "calc(100% - 800px)";
            target.style.margin = "32px 75px";
          } catch (e) {}
        }}
        open={ButtonTrue.open}
        // onClose={() => {
        //   this.setState({
        //     openStatusDialog: false,
        //     broken_amount: "",
        //     editDialog: false,
        //     added: false,
        //     selectedListArray: false,
        //     showInvoice: false,
        //   });
        // }}
        TransitionComponent={Transition}>
        <DialogActions
          className="d-flex justifySB"
          style={{
            background: primary,
            color: "white",
            padding: "0 24px",
          }}
          onClick={() => {
            setButtonTrue({ open: false });
          }}>
          {" "}
          <h3>Confirmation</h3>
          <Tooltip title="close" arrow placement="top">
            <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
              <CrossIcon strokeWidth="2" />
            </IconButton>
          </Tooltip>
        </DialogActions>{" "}
        <DialogContent
          className="dashedDividerBottom d-flex d-flex-column justifyC alignC"
          style={{ height: "100%" }}>
          {ButtonTrue.type === "approve"
            ? "Are You Sure You Want to Create Purchase Order ?"
            : "Are You Sure You Want to reject this ?"}
        </DialogContent>
        <div
          style={{
            justifyContent: "end",
            display: "flex",
            padding: "10px",
          }}>
          <Button
            onClick={() => {
              setButtonTrue({ open: false });
            }}
            style={{
              color: "rgb(241 154 48)",
              margin: "0 10px 0 0",
              padding: "2px 5px",
            }}>
            No
          </Button>
          <Button
            onClick={() => {
              // ButtonTrue.type==="approve"

              updatePurchaseIntent();
            }}
            style={{
              background: "rgb(241 154 48)",
              color: "white",
              margin: "0 0 0 10px",
              padding: "2px 5px",
              padding: "2px 5px",
            }}>
            Yes
          </Button>
        </div>
      </Dialog>
      {isDialogOpen && isDialogOpen.open && (
        <CreateDialog
          formatData={(data) => {
            console.log(data, isDialogOpen.data, "isDialogOpen.data");
            const newData = {};
            const commondata = {
              amount: data.Amount,
              quantity: data?.Quantity ?? "",
            };
            if (
              isDialogOpen.data.status === "Negotiated"
              // .is_revised
            ) {
              if (userRole !== "admin") {
                newData.revised_sales_intent = {
                  //other user sales intent for 2nd time
                  purchase_intent: isDialogOpen.data.id,
                  ...commondata,
                  status: "Pending",
                };
              } else {
                newData.revised_purchase_intent = {
                  sales_intent: isDialogOpen.data.id,
                  ...commondata,
                  status: "Negotiated",
                };
              }
            } else {
              newData.purchase_intent = isDialogOpen.data.purchase_intent_id
                ? {
                    sales_intent: isDialogOpen.data.id,
                    ...commondata,
                    status: userRole !== "admin" ? "Pending" : "Negotiated",
                    id: isDialogOpen.data.purchase_intent_id,
                  }
                : {
                    sales_intent: isDialogOpen.data.id,
                    ...commondata,
                    status: userRole !== "admin" ? "Pending" : "Negotiated",
                  };
            }
            return newData;
          }}
          textFieldStyle={{ width: "calc(100% - 20px)", margin: "10px" }}
          heightNew="550px"
          maxHeight="calc(100vh - 140px)"
          widthNew="400px"
          borderRadius="20px"
          closeOnSubmit={true}
          handleClose={() => {
            setDialogOpen(false);
          }}
          module={"Data"}
          postApi={
            userRole && userRole === "admin"
              ? "dispatch/purchase-intent/create"
              : "dispatch/sales-intent/create"
          }
          editApi="dispatch/purchase-intent"
          selectedList={{}}
          inputArray={[
            userRole && userRole === "broker"
              ? {
                  name: "Miller",
                  type: "textField",
                  dataType: "text",
                  not_required: true,
                  disabled: true,
                  value: "name",
                  api: "miller",
                }
              : false,
            {
              name: "Item Category",
              type: "textField",
              dataType: "text",
              disabled: true,
              value: "amount",
              defaultValue: {
                value:
                  isDialogOpen.data && isDialogOpen.data
                    ? isDialogOpen.data.item_category
                    : "",

                id: "",
              },
              api: "item_category",
            },
            {
              name: "Item Type",
              type: "textField",
              dataType: "text",
              disabled: true,
              value: "broken_percentage",
              defaultValue: {
                value:
                  isDialogOpen.data && isDialogOpen.data
                    ? isDialogOpen.data.item_type
                    : "",
                id: "",
              },
              api: "item_type",
            },
            {
              name: "Unit",
              type: "textField",
              dataType: "text",
              disabled: true,
              value: "unit",
              defaultValue: {
                value:
                  isDialogOpen.data && isDialogOpen.data
                    ? isDialogOpen.data.unit
                    : "",
              },
              api: "unit",
            },

            {
              name: "Quantity",
              type: "textField",
              not_required: true,
              dataType: "text",
              endAdornment:
                isDialogOpen.data && isDialogOpen.data
                  ? isDialogOpen.data.unit
                  : "",

              defaultValue: {
                value:
                  isDialogOpen.data && isDialogOpen.data
                    ? isDialogOpen.data.quantity ?? ""
                    : "",
              },
              api: "required_qty",
            },
            {
              name: "Amount",
              type: "textField",
              not_required: true,
              dataType: "text",
              endAdornment: `/${
                isDialogOpen.data && isDialogOpen.data
                  ? isDialogOpen.data.unit
                  : ""
              }`,

              startAdornment: "₹",
              defaultValue: {
                value:
                  isDialogOpen.data && isDialogOpen.data
                    ? isDialogOpen.data.amount
                    : "",
              },
              // InputProps={{
              //   startAdornment: "₹",
              //   endAdornment: "/" + Unit,
              // }}
              api: "amount",
            },
            // {name:"Status",
            // api:"status"},
          ]}
          postData={props.modulesFunction}
          fetchAgain={() => {
            reloadData();
          }}
        />
      )}
      <div className="chatsListWrapper overflowYA scrollPrimary" id="chatList">
        {loader === true ? (
          <StyledCircularProgress color="rgb(241, 154, 48)" />
        ) : allSenderUid.length > 0 ? (
          <>
            {pagination.has_next ? (
              <div
                className={`width-100 d-flex justifyC`}
                style={{ margin: "20px 0 0" }}>
                <div
                  className={`widthFC d-flex justifyC colorPrimary fontSize11 ${
                    loader === true ? "" : "cursorP hoverBg  borderR7"
                  }`}
                  style={{ padding: "2px 10px" }}
                  onClick={() => {
                    if (paginationLoader !== true) {
                      setPagingLoding(true);
                      infiniteFetchNext();
                    }
                  }}>
                  {paginationLoader ? (
                    <div className="alignC justiyC d-flex width-100 ">
                      <StyledCircularProgress color="inherit" />
                    </div>
                  ) : (
                    "Load more"
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {allSenderUid
              .sort((a, b) => {
                var dateA = new Date(
                  moment(new Date(b.date)).format("MMM D YYYY h:mm:ss A")
                );
                var dateB = new Date(
                  moment(new Date(a.date)).format("MMM D YYYY h:mm:ss A")
                );

                return dateB - dateA;
              })
              .map((data, i) => {
                const dataArr = listData;
                const arrayOfMsgs =
                  dataArr && dataArr.length > 0
                    ? dataArr
                        .filter(
                          (list) =>
                            moment(list.created_at).format("ll") === data.date
                        )
                        .sort((a, b) => {
                          var dateA = new Date(
                            moment(new Date(b.created_at)).format(
                              "MMM D YYYY h:mm:ss A"
                            )
                          );
                          var dateB = new Date(
                            moment(new Date(a.created_at)).format(
                              "MMM D YYYY h:mm:ss A"
                            )
                          );

                          return dateA - dateB;
                        })
                    : [];

                console.log(listData, "listData");

                return (
                  <div
                    key={data.date}
                    className="width-100  d-flex d-flex-column alignC">
                    <div className={"dateTime  d-flex  justifyC textTransC"}>
                      {data.date === moment(new Date()).format("ll")
                        ? "Today"
                        : data.date ===
                          moment(new Date()).subtract(1, "days").format("ll")
                        ? "yesterday"
                        : data.date}
                    </div>
                    {arrayOfMsgs
                      .sort((a, b) => {
                        var dateA = new Date(
                          moment(new Date(b.created_at)).format(
                            "MMM D YYYY h:mm:ss A"
                          )
                        );
                        var dateB = new Date(
                          moment(new Date(a.created_at)).format(
                            "MMM D YYYY h:mm:ss A"
                          )
                        );

                        return dateB - dateA;
                      })
                      .map((s, index) => {
                        console.log(s, "hhhhhhhhhhhhhhhhhhhhhhh");
                        const msg = s.negotiation_details
                          ? { ...s.negotiation_details, ...s }
                          : s;
                        const selfMsg = msg.created_by === user.credential_id;
                        const oldMsg = msg.old_offer;
                        const newMsg = msg.new_offer;
                        const contentTable = (data) => {
                          console.log(msg, "nnnnnnnnnnnnnnnnnnnnnn");
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                padding: "15px 20px",
                              }}>
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                  marginRight: "10px",
                                }}>
                                <div
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "5px",
                                  }}>
                                  Date
                                </div>
                                <div>
                                  {data &&
                                    moment(data.created_at).format(
                                      "MM-DD-YYYY"
                                    )}
                                </div>
                              </div>
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                  marginRight: "10px",
                                }}>
                                <div
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "5px",
                                  }}>
                                 Contract Type
                                </div>
                                <div>
                                  {msg.new_offer
                                    ? msg.new_offer.contract_type
                                    : data
                                    ? data.contract_type
                                    : ""}
                                </div>
                              </div>
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                  marginRight: "10px",
                                }}>
                                <div
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "5px",
                                  }}>
                                  Item Category
                                </div>
                                <div>
                                  {msg.new_offer
                                    ? msg.new_offer.item_category
                                    : data
                                    ? data.item_category
                                    : ""}
                                </div>
                              </div>
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                  marginRight: "10px",
                                }}>
                                <div
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "5px",
                                  }}>
                                  Item Type
                                </div>
                                <div>
                                  {msg.new_offer
                                    ? msg.new_offer.item_type
                                    : data
                                    ? data.item_type
                                    : ""}
                                </div>
                              </div>
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                  marginRight: "10px",
                                }}>
                                <div
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "5px",
                                  }}>
                                  Offered Rate
                                </div>
                                <div>{data ? data.amount : ""}</div>
                              </div>
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                  marginRight: "10px",
                                }}>
                                <div
                                  style={{
                                    fontWeight: "600",
                                    marginBottom: "5px",
                                  }}>
                                  Quantity
                                </div>
                                <div>
                                  {/* {(data&&oldMsg)||(data&&msg.new_offer)? oldMsg.required_qty :msg.new_offer.quantity} */}
                                  {console.log(oldMsg, "sssssssssssssss")}
                                  {data && data.required_qty
                                    ? data.required_qty
                                    : data?.quantity ??
                                      msg.details?.quantity ??
                                      ""}
                                  {/* {oldMsg ? oldMsg.required_qty : (newMsg && newMsg.quantity)} */}
                                </div>
                              </div>
                            </div>
                          );
                        };
                        // msg.details.status === "Negotiated"
                        const salesCard = (
                          <div style={styles.divPaper}>
                            <div style={styles.divIPaper}>
                              {/* {msg.new_offer &&
                              (msg.new_offer.is_revised ||
                                msg.new_offer.sales_intent)
                                ? "Purchase Intent Negotiation"
                                : "Sales Intent Negotiation"} */}

                              {/* {msg.new_offer &&
    (msg.new_offer.is_revised ||
        msg.new_offer.sales_intent) ?
        (
            // Nested condition starts here
            msg.new_offer.is_revised === "true" ?
            "Revised Purchase Intent Negotiation"
               :"Purchase Intent Negotiation"
            // Nested condition ends here
        ) : "Sales Intent Negotiation"
        
} */}

                              {msg.new_offer && msg.new_offer.is_revised
                                ? msg.new_offer.sales_intent
                                  ? "Revised Purchase Intent Negotiation"
                                  : "Revised Sales Intent Negotiation"
                                : msg.new_offer && msg.new_offer.sales_intent
                                ? "Purchase Intent Negotiation"
                                : "Sales Intent Negotiation"}
                            </div>

                            {oldMsg && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  padding: "10px 20px 10px 20px",
                                  fontWeight: 600,
                                  background: "#80808029",
                                }}>
                                Old Offer
                              </div>
                            )}
                            {contentTable(msg.old_offer ?? msg.details)}
                            {oldMsg ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    padding: "10px 20px 10px 20px",
                                    fontWeight: 600,
                                    background: "#80808029",
                                  }}>
                                  New Offer
                                </div>
                                {contentTable(msg.new_offer)}
                              </>
                            ) : (
                              ""
                            )}

                            {/* {msg.details && (msg.details.status === "Pending")&& ( */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingBottom: "15px",
                              }}>
                              {userRole === "admin" &&
                              msg.new_offer &&
                              msg.new_offer.status === "Accepted" &&
                              msg.new_offer.is_po_created === false ? (
                                <div style={{ marginRight: "10px" }}>
                                  <StyledButton
                                    onClick={() => {
                                      create_po(msg.new_offer ?? msg.details);
                                    }}
                                    name={
                                      ButtonTrue.loader
                                        ? "loading...."
                                        : "Create PO"
                                    }
                                    // withBg={true}
                                    background={"white"}
                                    width="100px"
                                    boxShadow="rgba(128, 128, 128, 0.53) 1px 1px 7px"
                                    color="green"
                                    fontWeight="500"
                                  />
                                </div>
                              ) : userRole === "admin" &&
                                msg.new_offer &&
                                msg.new_offer.status === "Pending" ? (
                                <div style={{ marginRight: "10px" }}>
                                  <StyledButton
                                    onClick={() => {
                                      setButtonTrue({
                                        open: true,
                                        type: "approve",
                                        data: msg.new_offer ?? msg.details,
                                      });
                                    }}
                                    name={"Accept"}
                                    // withBg={true}
                                    background={"white"}
                                    width="100px"
                                    boxShadow="rgba(128, 128, 128, 0.53) 1px 1px 7px"
                                    color="green"
                                    fontWeight="500"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {userRole === "admin" &&
                              msg.new_offer &&
                              msg.new_offer.status === "Pending" ? (
                                <div style={{ marginRight: "10px" }}>
                                  {" "}
                                  <StyledButton
                                    onClick={() => {
                                      setButtonTrue({
                                        open: true,
                                        type: "reject",
                                        data: msg.new_offer ?? msg.details,
                                      });
                                    }}
                                    name={"Reject"}
                                    background={"white"}
                                    color="red"
                                    width="100px"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {(userRole === "admin" &&
                                msg.new_offer &&
                                msg.new_offer.status === "Pending") ||
                              (userRole !== "admin" &&
                                msg.new_offer &&
                                msg.new_offer.status === "Negotiated") ? (
                                selfMsg ? (
                                  ""
                                ) : (
                                  <div>
                                    <StyledButton
                                      name={"Negotiation"}
                                      background={"white"}
                                      onClick={() => {
                                        handleButtonClick(msg);
                                      }}
                                      width="100px"
                                    />
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                        console.log(msg.message_type,"msg.message_type")
                        return (
                          <div key={msg.id} className="width-100" id={msg.id}>
                            {msg.created_by !== user.credential_id ? (
                              msg.message_type === "buy" ? (
                                <div
                                  className="single-chat-wrapper"
                                  key={msg.created_by}>
                                  {salesCard}
                                </div>
                              ) : (
                                <div
                                  className="single-chat-wrapper"
                                  key={msg.created_by}>
                                  {index === 0 || //index !== 0 &&
                                  (index !== 0 &&
                                    data.uid[index - 1] !== undefined &&
                                    msg.created_by !== data.uid[index - 1]) ||
                                  (index !== 0 &&
                                    arrayOfMsgs[index - 1].message_type ===
                                      "buy") ? (
                                    <>
                                      {
                                        <div className="width-100 d-flex  margin-same">
                                          <div
                                            className={`rce-mbox ${
                                              msg.message_type !== "message"
                                                ? "right-chat-padding   colorWhite" //right
                                                : "left-chat-msg-padding  " //rightMsg
                                            } ${
                                              msg.message_type === "message"
                                                ? "chat-box-msg"
                                                : "chat-box-width-web"
                                            }`}
                                            style={{
                                              background:
                                                msg.message_type !== "message"
                                                  ? primary
                                                  : "white",
                                              minHeight: "fit-content",
                                              marginTop: "15px",
                                            }}>
                                            {/* /* {icon("left", msg.message_type === "message" ? "white" : primary)} */}

                                            <MessageBox
                                              // color={
                                              //   userColors?.filter((p) => p.uid === msg.created_by)[0].color
                                              //     ? userColors.filter((p) => p.uid === msg.created_by)[0].color
                                              //     : undefined
                                              // }
                                              detail={msg}
                                              item={item}
                                              user={user}
                                              type="left"
                                            />
                                            {leftIcon(
                                              msg.message_type !== "message"
                                                ? primary
                                                : "white"
                                            )}
                                          </div>
                                        </div>
                                      }
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className={`rce-mbox ${
                                          arrayOfMsgs[index + 1] &&
                                          arrayOfMsgs[index + 1]
                                            .message_type === "buy"
                                            ? "margin-all"
                                            : "margin-same"
                                        } ${
                                          msg.message_type !== "message"
                                            ? "right-chat-padding  colorWhite" //right-only
                                            : "left-chat-msg-padding" //rightMsg-only
                                        }  ${
                                          msg.message_type === "message"
                                            ? "chat-box-msg"
                                            : "chat-box-width-web"
                                        }`}
                                        style={{
                                          background:
                                            msg.message_type !== "message"
                                              ? primary
                                              : "white",
                                          minHeight: "fit-content",
                                          // marginLeft: "50px",
                                          borderRadius: "5px",
                                        }}>
                                        <MessageBox
                                          detail={msg}
                                          item={item}
                                          user={user}
                                          type="left"
                                          noName={true}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              )
                            ) : (
                              <div
                                className="single-chat-wrapper"
                                key={msg.created_by}>
                                {index === 0 ||
                                (index !== 0 &&
                                  data.uid[index - 1] !== undefined &&
                                  msg.created_by !== data.uid[index - 1]) ||
                                arrayOfMsgs[index - 1]?.message_type ===
                                  "buy" ? (
                                  <div className="margin-same width-100 d-flex justifyFE ">
                                    {/* margin-all */}
                                    {msg.message_type === "buy" ? (
                                      salesCard
                                    ) : (
                                      <div
                                        className={`rce-mbox rce-mbox-right ${
                                          "left-chat-padding colorWhite" //left-chat-msg
                                        }  ${"chat-box-msg"}`}
                                        style={{
                                          background: "#eef2ff",
                                        }}>
                                        {/* {icon(
                                    "right",
                                    msg.message_type === "message" ? "white" : primary
                                  )} */}
                                        <MessageBox
                                          detail={msg}
                                          item={item}
                                          user={user}
                                          type="right"
                                          noName={true}
                                        />
                                        {rightIcon("#eef2ff")}
                                      </div>
                                    )}
                                    {/* <Avatar
                                // image={avatar ?? undefined}
                                shape="circle"
                                className={`d-flex alignC justifyC chatAvatar colorPrimary`}
                              >
                                <div className="pi pi-user" />
                              </Avatar> */}
                                  </div>
                                ) : msg.message_type === "buy" ? (
                                  <div className="margin-same width-100 d-flex justifyFE ">{salesCard}</div>
                                ) : (
                                  <>
                                    <div
                                      className={`rce-mbox rce-mbox-right ${
                                        arrayOfMsgs[index + 1] &&
                                        arrayOfMsgs[index + 1].message_type ===
                                          "buy"
                                          ? "margin-all"
                                          : "margin-same"
                                      } ${
                                        msg.message_type !== "message"
                                          ? "left-chat-padding  colorWhite" //left-chat-only
                                          : "left-chat-msg-padding " //left-chat-msg-only
                                      }  ${
                                        msg.message_type === "message"
                                          ? "chat-box-msg"
                                          : "chat-box-width-web"
                                      }`}
                                      style={{
                                        background:
                                          msg.message_type !== "message"
                                            ? primary
                                            : "#eef2ff",

                                        borderRadius: "5px",
                                      }}>
                                      <MessageBox
                                        detail={msg}
                                        item={item}
                                        user={user}
                                        type="right"
                                        noName={true}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                            <div id={`${msg.id}${msg.id}`} />
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </>
        ) : (
          // </InfiniteScroll>
          <div className="d-flex height-100 width-100 justifyC alignC fontSize1">
            No Message Yet
          </div>
        )}
        {allSenderUid.length !== 0 && (
          <div style={{ marginBottom: "100px" }}></div>
        )}
      </div>
      <div className="chatActionWrapper d-flex alignC justifyC d-flex-column">
        <style>{`.p-button .p-button-label {

  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  /* identical to box height */

  display: flex !important;
  align-items: center !important;

  color: #f7f7f7 !important;
}`}</style>
        <div className=" chatAction d-flex alignC">
          {/* <StyledLoadingButton
            id="myBtn"
            loading={fieldValue.buyerLoading ?? false}
            label={"Buy"}
            onClick={(_e) => {
              // setFieldValue((prev) => {
              //   return { ...prev, buyerLoading: true };
              // });
              // fetchArray("suppliers")
              new Promise(async (resolve, reject) => {
                await setAutoFocus(false);
                resolve(true);
              }).then(() => {
                setDontScroll("msg");
                // setAddData({ open: true, type: "buy" });
              });
            }}
            type="submit"
            className="BuyButton flex-reverse"
          />
          <style>{`.p-button .p-button-label{ font-size:14px !important;}`}</style>
          <StyledLoadingButton
            id="myBtn"
            loading={fieldValue.sellLoading ?? false}
            label={"Sell"}
            onClick={(_e) => {
              // setFieldValue((prev) => {
              //   return { ...prev, sellLoading: true };
              // });
              // fetchArray("customers");
              new Promise(async (resolve, reject) => {
                await setAutoFocus(false);
                resolve(true);
              }).then(() => {
                // inputRef.current.blur();
                setDontScroll("msg");
                // setAddData({ open: true, type: "sell" });
              });
            }}
            type="submit"
            className="sellButton flex-reverse"
          /> */}
          {/* <input
            ref={inputRef}
            autoFocus={autoFocus}
            onMouseDown={(e) => {
              if (autoFocus === true) {
                inputRef.current.focus();
              }
            }}
            onBlur={() => {
              if (autoFocus === true) {
                inputRef.current.focus();
              }
            }}
            {...{
              type: "text",
              onKeyPress: (ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  sendMessage();
                }
              },
              // error: fieldValue[`Error`] ? fieldValue[`Error`] : false,
              value: fieldValue["text"] ? fieldValue["text"] : "",
              disabled: fieldValue.loading ?? false,
              style: { minHeight: "40px", maxHeight: "41px", width: "100%", maxWidth: "100%" },
              onChange: (e) => {
                setFieldValue((prev) => {
                  return {
                    ...prev,
                    text: e.target.value,
                    [`Error`]: false,
                  };
                });
              },
            }}
          ></input> */}

          {/* ----------------------------- */}
          {/* <StyledLoadingButton
            onClick={() => {
              handleButtonClick();
            }}
            name={<AddIcon />}
            loading={fieldValue.loading}
          /> */}
          {/* ----------------------------- */}
          <CssTextField
            // ref={inputRef}
            autoFocus={autoFocus}
            onMouseDown={(e) => {
              if (autoFocus === true) {
                // inputRef.current.focus();
              }
            }}
            onBlur={() => {
              if (autoFocus === true) {
                // inputRef.current.focus();
              }
            }}
            type={"text"}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                sendMessage();
              }
            }}
            error={fieldValue[`Error`] ? fieldValue[`Error`] : false}
            value={fieldValue["text"] ? fieldValue["text"] : ""}
            disabled={fieldValue.loading ?? false}
            mainStyle={{
              maxWidth: "calc(100% - 104.26px)",
              width: "calc(100% - 104.26px)",
              maxHeight: "41px",
            }}
            placeholder={"Enter the message"}
            style={{
              minHeight: "40px",
              maxHeight: "41px",
              width: "100%",
              maxWidth: "100%",
              marginLeft: "20px",
            }}
            onChange={(e) => {
              setFieldValue((prev) => {
                return {
                  ...prev,
                  text: e.target.value,
                  [`Error`]: false,
                };
              });
            }}
          />
          <StyledLoadingButton
            onClick={() => {
              setAutoFocus(true);
              sendMessage();
            }}
            name={<SendIcon />}
            loading={fieldValue.loading}
          />
        </div>
      </div>
    </div>
  );
}

export default ChatUI;
