import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { moduleIdURL, moduleURL } from "../../http/config";
import { requests } from "../../requests";
const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: "white", // Customize the background color
    border: "1px solid #ddd", // Customize the border
    borderRadius: theme.shape.borderRadius, // Use the theme's border radius
    marginBottom: theme.spacing(1), 
    padding:"0 10px"// Add margin at the bottom
  },
  summary: {
    width: "100%",
  },
  
}));
const accordionStyle = {
  width: "100%", // Set the Accordion width to 100%
};

const tableContainerStyle = {
  width: "100%", // Set the TableContainer width to 100%
};

export default function BasicAccordion({ id, onCheckboxChange,value,submittedData }) {
  const classes = useStyles();
  const [checkedState, setCheckedState] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modulenames, setmodulenames] = useState([]);
  const [subModulesPO,setsubModulesPO]=useState([]);
  const header = [
    { id: 0, header: "Create" },
    { id: 1, header: "Edit" },
    { id: 2, header: "View" },
    // { id: 3, header: "Delete" },
  ];
  useEffect(()=>{
    if(value&&value.length>0){
      const newObj={}
      value[0].modules.map((item)=>{
        const module_name=item.module.module_name
        item.widgets.map((wid)=>{
         
        const widget_name=wid.widget.widget_name

        newObj[`${module_name}-${widget_name}-all`]=wid.widget.has_create_permission&&wid.widget.has_update_permission&&
        wid.widget.has_read_permission?true: false;
         newObj[`${module_name}-${widget_name}-${header[0].header}`]=wid.widget.has_create_permission
        //  newObj[`${module_name}-${widget_name}-${header[3].header}`]=wid.widget.has_delete_permission
         newObj[`${module_name}-${widget_name}-${header[1].header}`]=wid.widget.has_update_permission
         newObj[`${module_name}-${widget_name}-${header[2].header}`]=wid.widget.has_read_permission
       
         newObj[`${module_name}-${widget_name}-permission_id`]=wid.widget.permission_id??""
        })
      })
      setCheckedState((prev) => {
       return newObj
      });
    }
  },[value])

  useEffect(()=>{
    const formatted=[]
    modulenames.map((module,index)=>{

      if(value&&value.length>0){
        
          module.widgets.map((sub)=>{
            // if(checkedState[
            //   `${module.module_name}-${sub.widget.widget_name}-all`
            // ]
            // ||checkedState[
            //   `${module.module_name}-${sub.widget.widget_name}-${header[0].header}`
            // ]||
            // checkedState[
            //   `${module.module_name}-${sub.widget.widget_name}-${header[1].header}`
            // ]||
            // checkedState[
            //   `${module.module_name}-${sub.widget.widget_name}-${header[2].header}`
            // ]
            // ){
              console.log(submittedData,"submittedData")
              formatted.push({   "has_create_permission": checkedState[
                    `${module.module_name}-${sub.widget.widget_name}-${header[0].header}`
                  ]??false,
                  "has_read_permission":  checkedState[
                    `${module.module_name}-${sub.widget.widget_name}-${header[2].header}`
                  ]??false,
                  "has_update_permission": checkedState[
                    `${module.module_name}-${sub.widget.widget_name}-${header[1].header}`
                  ]??false,
                  "has_delete_permission": false,
                  "role":submittedData['RoleId']?? 0,
                  "module":module.id,
                  "widget":  sub.widget.id,//"id"
                  "id":  checkedState[`${module.module_name}-${sub.widget.widget_name}-permission_id`]

            // "assigned_to": 1
              })
            
          });
         
      }else{
const newObj={
  "id": module.id,
  "module_name": module.module_name,
  "widgets": []
};
const widgets=[];
  module.widgets.map((sub)=>{
    // if(checkedState[
    //   `${module.module_name}-${sub.widget.widget_name}-all`
    // ]){
      widgets.push({  
        access:{"create": checkedState[
          `${module.module_name}-${sub.widget.widget_name}-${header[0].header}`
        ]??false,
        "read":  checkedState[
          `${module.module_name}-${sub.widget.widget_name}-${header[2].header}`
        ]??false,
        "update":  checkedState[
          `${module.module_name}-${sub.widget.widget_name}-${header[1].header}`
        ]??false,
        "delete": false,},
        "id": sub.widget.id,
        "widget_name":sub.widget.widget_name,
      })
    // }
  });
  newObj.widgets=widgets;
  if(widgets.length>0){
  formatted.push(newObj)}
      }
  });
  // bri/api/users-and-permissions/permissions-create/
   onCheckboxChange(formatted)
  },[checkedState]);


useEffect(()=>{
  const getUrl = moduleURL("users-and-permissions/access-area-management");
  requests({
    method: "get",
    url: getUrl,
  })
    
    .then((r) => {
      setmodulenames([]);
      console.log(r,"edfghrfergew")
      setmodulenames(r.data);
    });
  },[])
  return (
    <div style={{ width: "100%" }}>
  
      <div style={{ margin: "10px 10px" }}>
        <span style={{ fontSize: "1rem" ,fontWeight:"500"}}>Modules & their Widgets List</span>
      </div>
      <div style={{margin:"0 10px"}}>
      {modulenames.map((module, index) => (
        <Accordion key={index} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.summary}>
            <Typography>{module.module_name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Include the table component within AccordionDetails */}
            <TableContainer component={Paper}>
              <Table style={tableContainerStyle}>
                <TableHead>
                  <TableRow>
                    <TableCell>Action</TableCell>
                    <TableCell>Widgets</TableCell>
                    {header.map((a, headerIndex) => (
                      <TableCell key={headerIndex}>{a.header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {module.widgets?.map((subModule) => (
                    <TableRow key={subModule.id}>
                      
                      <TableCell>
                        <Checkbox
                          checked={
                            checkedState[
                              `${module.module_name}-${subModule.widget.widget_name}-all`
                            ] ?? false
                          }
                          onChange={() => {
                        
                            setCheckedState((prev) => {
                              const newState =
                                !checkedState[
                                  `${module.module_name}-${subModule.widget.widget_name}-all`
                                ];
                              return {
                                ...prev,
                                [`${module.module_name}-${subModule.widget.widget_name}-all`]:
                                  newState,

                                [`${module.module_name}-${subModule.widget.widget_name}-${header[0].header}`]:
                                  newState,

                                [`${module.module_name}-${subModule.widget.widget_name}-${header[1].header}`]:
                                  newState,

                                [`${module.module_name}-${subModule.widget.widget_name}-${header[2].header}`]:
                                  newState,
                                  //  [`${module.module_name}-${subModule.widget.widget_name}-${header[3].header}`]:
                                  // newState,
                              };
                            });
                          }}
                        />

                    
                      </TableCell>

                      <TableCell>{subModule.widget.widget_name}</TableCell>
                      {header.map((a, headerIndex) => (
                        <TableCell key={headerIndex}>
                       
                          <FormControlLabel
                            control={
                              <Checkbox /* Add radio button logic here */
                                checked={
                                  checkedState[
                                    `${module.module_name}-${subModule.widget.widget_name}-${a.header}`
                                  ] ?? false
                                }
                                onChange={() => {
                         
                                  setCheckedState((prev) => {
                                    const newState =
                                      !checkedState[
                                        `${module.module_name}-${subModule.widget.widget_name}-${a.header}`
                                      ];
                                    const getAllState = (obj) => {
                                      return obj[
                                        `${module.module_name}-${subModule.widget.widget_name}-${header[0].header}`
                                      ] &&
                                        obj[
                                          `${module.module_name}-${subModule.widget.widget_name}-${header[1].header}`
                                        ] &&
                                        obj[
                                          `${module.module_name}-${subModule.widget.widget_name}-${header[2].header}`
                                        ]
                                        ? true
                                        : false;
                                    };
                              
                                    return {
                                      ...prev,
                                      [`${module.module_name}-${subModule.widget.widget_name}-${a.header}`]:
                                        newState,
                                      [`${module.module_name}-${subModule.widget.widget_name}-all`]:getAllState({
                                      ...prev,
                                      [`${module.module_name}-${subModule.widget.widget_name}-${a.header}`]:newState}),
                                    };
                                  });
                                }}
                              />
                            }
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
     </div>
    </div>
  );
}
