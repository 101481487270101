import { MenuItem, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../layoutComponents/ConfirmDialog";
import AlwaysShowSidebar from "../../layoutComponents/PDF/AlwaysShowSidebar";
import WithoutToolbarExample from "../../layoutComponents/PDF/WithoutToolbarExample";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import { filterData } from "../../Regex";
import { red, primary } from "../../styles/colors";
import { DeleteIcon, UploadCloud } from "../../styles/Icons";
import {
  CardDetail,
  CssDialog,
  CssTextField95,
  HeadingLine,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import ShowPdf from "../Ports/ShowPdf";
const UploadSoFile = (props) => {

// function UploadSoFile(props) {

  const { onChange, postData, updateValues, noAddButton, error, uploadedFile, edit } = props;
  const [notGiven, setNotGiven] = useState(false);
  const [fileArray, setFileArray] = useState([]);
  const [success, setSuccess] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileInput_ref, setFileInputRef] = useState({});
  const [loader, setLoad] = useState(false);
  const ss = async () => {
    setLoad(true);
    return "";
  };
  useEffect(() => {
    if (error) {
      setNotGiven(error);
    }
  }, [error]);
  useEffect(() => {
    if (uploadedFile) {
   
      setFileArray([uploadedFile]);
    } else {
      setFileArray([]);
    }
  }, [uploadedFile]);
  const handleUpdate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    ss().then(() => {
      let fileObj = [];
      const fileA = [];
      fileObj.push(e.target.files);
      for (let i = 0; i < fileObj[0].length; i++) {
        fileA.push(fileObj[0][i]);
      }
      setFileArray(fileA);
      if (onChange) {
        onChange(fileA[0]);
      }
      setLoad(false);
    });
  };

  const pdfUi = (uploaded, i) => {
    return (
      <CardDetail
        keyId={"-1"}
        height="150px"
        width="fit-content"
        margin="10px 0 0"
        padding="0"
        noBoxShadow={true}
        children={() => {
          return (
            <div
              className="width-100 height-100 d-flex d-flex-column"
              onClick={(e) => {
                e.stopPropagation();
                setShowPdf(uploaded);
              }}
            >
              <div className="width-100 d-flex marginB10">
                <div className="leftText1 d-flex alignC justifyC">
                  {uploaded.pdf_file ? uploaded.pdf_file.split(".pdf") : uploaded.name ? uploaded.name : ""}
                </div>
              </div>
              <div
                className="width-100  d-flex alignC justifyC postionR disabled"
                style={{ height: "calc(100% - 30.57px)" }}
              >
                {uploaded && !uploaded.name ? (
                  <iframe
                    title="file"
                    scrolling="no"
                    seamless="seamless"
                    className="width-100 height-100 objFC example overflowH"
                    src={uploaded}
                  ></iframe>
                ) : (
                  <WithoutToolbarExample fileUrl={URL.createObjectURL(uploaded)} />
                )}
              </div>
            </div>
          );
        }}
      />
    );
  };

  const createForm = () => {
    return (
      <>
        {fileArray.length > 0 ? (
          showUploadedPdf()
        ) : (
          <>
            <input
              accept="application/pdf"
              ref={(ref) => {
                setFileInputRef(ref);
              }}
              id="files"
              style={{
                visibility: "hidden",
                width: "0",
                maxHeight: "20px",
              }}
              type="file"
              onChange={handleUpdate}
            />
            <label htmlFor="files" className="btn">
              <div
                className={
                  notGiven === "Please upload a file !"
                    ? "upload-a-file-container-assignment-error"
                    : "upload-a-file-container-assignment"
                }
              >
                <div
                  className="upload-a-file-content-container"
                  style={{
                    color: notGiven === "Please upload a file !" ? "#db0011" : primary,
                  }}
                >
                  <div className="upload-a-file-icon-container">
                    <UploadCloud color={notGiven === "Please upload a file !" ? "#db0011" : primary} />
                  </div>
                  <p
                    style={{
                      margin: "0 3% 0 0",
                      fontSize: "0.8rem",
                      color: notGiven === "Please upload a file !" ? "#db0011" : primary,
                    }}
                  >
                    {fileArray.length > 0 ? "File Uploaded." : "Upload files."}
                  </p>
                </div>
              </div>
            </label>
          </>
        )}
      </>
    );
  };
  const showUploadedPdf = () => {
    return loader ? (
      <StyledCircularProgress />
    ) : (
      <div className={"upload-a-file-container-assignment"}>
        {/* <HeadingLine
          maxWidth="100%"
          width="100%"
          children={`Uploaded Files:`}
          margin="0 0 10px"
        /> */}
        {/* <input
          accept="application/pdf"
          ref={(ref) => {
            setFileInputRef(ref);
          }}
          id="files"
          style={{
            visibility: "hidden",
            width: "0",
            maxHeight: "0",
          }}
          type="file"
          onChange={handleUpdate}
        /> */}
        {fileArray && fileArray.map((uploaded, i) => pdfUi(uploaded, i))}
        {/* <label htmlFor="files" className="btn"> */}
        <StyledButton
          name="Clear"
          padding="0"
          maxHeight="24.29px"
          onlyTextColor={red}
          onClick={() => {
            if (onChange) {
              onChange();
            }
          }}
        />
        {/* </label> */}
      </div>
    );
  };
  return (
    <Paper className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary d-flex d-flex-column height-100 width-100 textC paddingTB20">
      {showPdf !== false ? (
        <ShowPdf
          fromSO={true}
          close={() => {
            setShowPdf(false);
          }}
          open={true}
          loader={loader}
          details={showPdf}
        />
      ) : (
        ""
      )}

      {createForm()}
    </Paper>
  );
}

// export default ;
export default React.memo(UploadSoFile);