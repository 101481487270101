import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import { moduleURL } from "../../../http/config";
import { requests } from "../../../requests";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Backdrop } from "@material-ui/core";
import { StyledCircularProgress } from "../../../styles/StyledComponents";
export const labelText = (msg, not_required) => {
  return (
    <div
      style={{
        fontSize: "0.85rem",
        color: "rgb(76, 89, 103)",
        fontWeight: 500,
        margin: "0 0 5px 0",
      }}>{`${msg} ${not_required === true ? "" : "*"}`}</div>
  );
};
const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: "15px",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  textField: {
    width: "40%",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
};

const NewForm = ({ closePopup, getFn }) => {
  const [fieldValues, setFieldValues] = useState({
    categoryNameInput: "",
    statusInput: true,
  });

  const [isValid, setIsValid] = useState({
    categoryNameInput: true,
  });
  const [pushNotier, setPushNotier] = useState(false);
  const [error, setError] = useState("");
  const [pushNoti, setPushNoti] = useState(false);
  const [loading, setLoading] = useState(false);

  const addNewHandler = (e) => {
    e.preventDefault();
    if (fieldValues.categoryNameInput.length === 0) {
      setIsValid((prev) => ({ ...prev, categoryNameInput: false }));
    }
    if (fieldValues.categoryNameInput.length > 0) {
      setLoading(true);

      const postURL = moduleURL("masters/item-category/list");
      requests({
        method: "post",
        url: postURL,
        data: {
          name: fieldValues.categoryNameInput,
          is_active: fieldValues.statusInput,
          // created_by: 0,
          // modified_by: 0,
        },
      }).then((res) => {
        if (res.error) {
         
          setError(res.data[0].message[0]);
         
          setPushNotier(true);
        } else {
          getFn();
          setError("");
          setPushNoti(true);
        }
      });
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={addNewHandler}>
        <div style={classes.formGroup}>
        <div
                  style={{
                    width: "40%",

                    //// marginRight: "20px",
                  }}>
                  {labelText("Name")}
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
            // label="Name *"
            value={fieldValues.categoryNameInput}
            style={{width:"100%"}}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, categoryNameInput: true }));
              }
              setFieldValues((prev) => ({
                ...prev,
                categoryNameInput: target.value,
              }));
            }}
            error={!isValid.categoryNameInput}
            helperText={isValid.categoryNameInput ? "" : "Enter Category Name"}
          />
          </div>
        </div>
        <div style={classes.formActions}>
          <FormControlLabel
            control={
              <Switch
                disabled
                style={{ color: "#B0B0B0" }}
                defaultChecked
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: true,
                    }));
                  } else {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: false,
                    }));
                  }
                }}
              />
            }
            label={fieldValues.statusInput ? "Active" : "Inactive"}
          />

          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "rgb(241 154 48)",
              color: "white",
              textTransform: "capitalize",
            }}
          >
            Add
          </Button>
        </div>
      </form>
      {loading ? (
        <Backdrop
          style={{ zIndex: 1, color: "#fff", position: "absolute" }}
          open={loading}
        >
          <StyledCircularProgress color="rgb(30, 53, 89)" />
        </Backdrop>
      ) : (
        ""
      )}
      <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setPushNoti(false);
          setLoading(false);
          closePopup();
        }}
      >
        <Alert style={{ backgroundColor: "rgb(241 154 48)" }}>
          New Item Category Added!
        </Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
      <Snackbar
        open={pushNotier}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="error"
        autoHideDuration={5000}
        onClose={() => {
          // setOpenPopup(false);
          setPushNotier(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}
      >
        <Alert severity="error">{error}</Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
    </div>
  );
};

export default NewForm;
